export enum ScoringMethod {
    EXACT_MATCH = "EXACT_MATCH",
    MANUAL = "MANUAL",
    PREDICTIVE = "PREDICTIVE",
}

export function resolveNameForScoringMethod(method: ScoringMethod) {
    switch (method) {
        case ScoringMethod.EXACT_MATCH:
            return "Exact Match"
        case ScoringMethod.MANUAL:
            return "Manual"
        case ScoringMethod.PREDICTIVE:
            return "Predictive"
    }
}