export interface CompletionOptionsJson {
    numberOfCompletions: number
    temperature: number
    topP: number | null
    presencePenalty: number | null
    frequencyPenalty: number |  null
    maxTokens: number | null
}


export const DEFAULT_COMPLETION_OPTIONS: CompletionOptionsJson = {
    temperature: 0.6,
    numberOfCompletions: 1,
    topP: 1,
    maxTokens: null,
    presencePenalty: null,
    frequencyPenalty: null
}