import React from 'react';
import {DisplayFormat} from "../../api/enum/DisplayFormat"
import ReactMarkdown from "react-markdown"
import {Typography} from "@mui/material"
import styles from "../examples/ExampleCard/ExampleCard.module.scss"

function TemplateRendererSection({value, display, dynamicSize}: { value: string, display?: DisplayFormat, dynamicSize?: boolean }) {
    const shorty = dynamicSize && value.length < 32

    switch (display ?? DisplayFormat.PLAIN_TEXT) {
        case DisplayFormat.MARKDOWN:
            return (
                <ReactMarkdown skipHtml={true}
                               disallowedElements={["img"]}
                               linkTarget={"_blank"}
                               className={styles.markdown}>
                    {value}
                </ReactMarkdown>
            )
        case DisplayFormat.CODE:
            return <code className={styles.textBlock}>{value}</code>
        case DisplayFormat.PLAIN_TEXT:
            return (
                <Typography variant={shorty ? "subtitle2" : "body2"} className={styles.textBlock}>
                    {value}
                </Typography>
            )
    }
}

export default TemplateRendererSection;