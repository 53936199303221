import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import AsyncButton from "../button/AsyncButton";
import ApiError from "../../api/ApiError"
import ModalBase from "./ModalBase"

interface ConfirmDeleteModalProps {
    heading: string
    body: string
    deleteButtonTitle?: string
    checkboxLabel?: string
    open: boolean
    setOpen: (open: boolean) => void
    confirmedDelete: (checkboxValue?: boolean) => void
    error?: ApiError
}

function ConfirmDeleteModal({
                                open,
                                setOpen,
                                heading,
                                body,
                                deleteButtonTitle,
                                checkboxLabel,
                                confirmedDelete,
                                error,
                            }: ConfirmDeleteModalProps) {
    const [deleteInProgress, setDeleteInProgress] = useState(false)
    const [checkboxValue, setCheckboxValue] = useState<boolean>()

    useEffect(() => setDeleteInProgress(false), [error])

    const handleDelete = () => {
        setDeleteInProgress(true)
        confirmedDelete(checkboxValue)
    }

    return (
        <ModalBase
            open={open}
            setOpen={setOpen}
            title={heading}
            error={error}
            buttons={
                <>
                    <Button variant="outlined"
                            color="secondary"
                            disabled={deleteInProgress}
                            onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <AsyncButton variant="contained"
                                 waiting={deleteInProgress}
                                 color="error"
                                 onClick={handleDelete} sx={{flex: 1}}>
                        {deleteButtonTitle ?? "Delete"}
                    </AsyncButton>
                </>
            }
        >
            <Stack spacing={2}>
                <Typography>{body}</Typography>
                {checkboxLabel &&
                    <FormControlLabel control={<Checkbox checked={checkboxValue}
                                                         onChange={(e) => setCheckboxValue(e.target.checked)}/>}
                                      label={checkboxLabel}/>
                }
            </Stack>
        </ModalBase>
    )
}

export default ConfirmDeleteModal;