import React, {useCallback, useEffect, useState} from 'react';
import {FormControl, MenuItem, Select} from "@mui/material"
import {ProjectJson} from "../../../api/json/ProjectJson"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import {ProjectRequests} from "../../../api/requests/ProjectRequests"

function QuickProjectSelect() {
    const [projects, setProjects] = useState<ProjectJson[]>([])

    const {projectId} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const pathComponents = location.pathname.split("/")

    const projectPath = projectId ? pathComponents[3] : undefined

    const loadList = useCallback(() => {
        ProjectRequests
            .list()
            .then(pagedResponse => {
                const topProjects = pagedResponse.content.slice(0, 10)
                setProjects(topProjects)
            })
    }, [projectId])

    useEffect(loadList, [loadList, projectId])

    if(projects.length <= 1) {
        return <></>
    }

    return (
        <FormControl sx={{width: 1}}>
            <Select
                value={projectId ?? ""}
                size="small"
                displayEmpty
                onChange={(e) => navigate(`/projects/${e.target.value}/${projectPath ?? "overview"}`)}
                sx={{width: 1}}
            >
                <MenuItem disabled value="">Jump to recent</MenuItem>
                {projects.map(project => (
                    <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default QuickProjectSelect;