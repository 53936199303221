import styles from "./IntegrationCard.module.scss"
import {IntegrationDetails} from "./IntegrationDetails"
import {ModelPlatform} from "../../api/enum/ModelPlatform"

export const integrationDetailsList: IntegrationDetails[] = [
    {
        platform: ModelPlatform.OPEN_AI,
        className: styles.openAi,
        iconSrc: "/openai.svg",
        path: "open-ai",
        subheading: "Fine-tune GPT-3.5 & synthesize",
        apiKeysUrl: "https://platform.openai.com/account/api-keys",
    },
    {
        platform: ModelPlatform.AI21,
        className: styles.ai21,
        path: "ai21",
        subheading: "Fine-tune Jurassic-2",
        apiKeysUrl: "https://studio.ai21.com/account/api-key",
    },
    {
        platform: ModelPlatform.REPLICATE,
        className: styles.replicate,
        iconSrc: "/replicate.svg",
        path: "replicate",
        subheading: "Fine-tune open-source models",
        apiKeysUrl: "https://replicate.com/account/api-tokens",
    },
    {
        platform: ModelPlatform.ANTHROPIC,
        className: styles.anthropic,
        iconSrc: "/anthropic.svg",
        path: "anthropic",
        subheading: "Prompt Claude 3",
        apiKeysUrl: "https://console.anthropic.com/settings/keys",
    },
    {
        platform: ModelPlatform.GOOGLE_AI,
        className: styles.googleAi,
        iconSrc: "/google-cloud.svg",
        path: "google-ai",
        subheading: "Prompt Gemini Pro",
        apiKeysUrl: "https://aistudio.google.com/app/apikey",
    },
    {
        platform: ModelPlatform.GROQ_CLOUD,
        className: styles.groqCloud,
        iconSrc: "/groq.svg",
        path: "groq-cloud",
        subheading: "Fast AI inference",
        apiKeysUrl: "https://console.groq.com/keys",
    },
]