import React from 'react';
import {Navigate, Route, Routes, useLocation, useParams} from "react-router-dom"
import CenteredBoxLayout from "./components/layouts/CenteredBoxLayout"
import LoginPage from "./components/accounts/LoginPage"
import LogoutPage from "./components/accounts/LogoutPage"
import SelectOrganizationPage from "./components/accounts/SelectOrganizationPage"
import AcceptInvitePage from "./components/accounts/InviteAcceptPage"
import DeleteOrganizationPage from "./components/accounts/DeleteOrganizationPage"
import OnboardingUserPage from "./components/accounts/OnboardingUserPage"
import OnboardingOrganizationPage from "./components/accounts/OnboardingOrganizationPage"
import NavLayout from "./components/layouts/NavLayout"
import FullwidthContainer from "./components/layouts/FullwidthContainer"
import ProjectsPage from "./components/projects/ProjectsPage/ProjectsPage"
import IntegrationsPage from "./components/integrations/IntegrationsPage"
import CurrentOrganizationPage from "./components/accounts/CurrentOrganizationPage"
import CurrentUserPage from "./components/accounts/CurrentUserPage"
import MembersPage from "./components/accounts/MembersPage/MembersPage"
import ProjectLayout from "./components/layouts/ProjectLayout"
import ProjectOverviewPage from "./components/projects/ProjectOverviewPage"
import ExamplesPage from "./components/examples/ExamplesPage"
import TemplatedModelsPage from "./components/models/TemplatedModelsPage"
import StartFinetunePage from "./components/finetunes/StartFinetunePage"
import FinetuneDetailPage from "./components/finetunes/FinetuneDetailPage"
import PlaygroundPage from "./components/projects/Playground/PlaygroundPage"
import CsvImportPage from "./components/import/CsvImport/CsvImportPage"
import TemplateDetailPage from "./components/templates/TemplateDetailPage"
import ProjectSettingsFields from "./components/fields/FieldsPage"
import SettingsPage from "./components/settings/SettingsPage"
import Auth0CallbackPage from "./components/accounts/Auth0CallbackPage"
import GrayBrandedBackground from "./components/layouts/GrayBrandedBackground"
import SynthesisPage from "./components/projects/SynthesisPage"
import OnboardingVerifyEmail from "./components/accounts/OnboardingVerifyEmail"
import AdminHomePage from "./components/admin/AdminHomePage"
import AdminContainer from "./components/layouts/AdminContainer"
import AdminOrganizationsPage from "./components/admin/AdminOrganizationsPage"
import AdminUsersPage from "./components/admin/AdminUsersPage"
import AdminProjectsPage from "./components/admin/AdminProjectsPage"
import CheckoutSuccessPage from "./components/billing/CheckoutSuccessPage"
import AdminUserDetailPage from "./components/admin/AdminUserDetailPage"
import AdminOrganizationDetailPage from "./components/admin/AdminOrganizationDetailPage"
import AdminProjectDetailPage from "./components/admin/AdminProjectDetailPage"
import TemplatedModelSharePage from "./components/models/TemplatedModelSharePage"
import NotFound404Page from "./components/error/NotFound404Page"
import AdminPromoCodesPage from "./components/admin/upgradecode/AdminUpgradeCodesPage"
import CompletionsPage from "./components/completions/CompletionsPage"
import TemplatesPage from "./components/templates/TemplatesPage"
import TransformsPage from "./components/transforms/TransformsPage"
import TransformDetailPage from "./components/transforms/TransformDetailPage"
import TemplatedModelDetailPage from "./components/models/TemplatedModelDetailPage"
import AdminDemoDatasetsPage from "./components/admin/demodataset/AdminDemoDatasetsPage"
import IntegrationDetailPage from "./components/integrations/IntegrationDetailPage"
import AdminMarketingEventsPage from "./components/admin/marketingevent/AdminMarketingEventsPage"

function AppRouter() {

    const RedirectToModel = () => {
        const { finetuneId } = useParams()
        return <Navigate replace to={`/share/${finetuneId}${useLocation().search}`} />
    };

    const RedirectToModels = () => {
        const { projectId } = useParams()
        return <Navigate replace to={`/projects/${projectId}/models`} />
    };

    return (
        <Routes>
            <Route element={<GrayBrandedBackground/>}>
                <Route path="auth0-callback" element={<Auth0CallbackPage/>}/>
                <Route path="/fine-tunes/:finetuneId" element={<RedirectToModel />}/>
                <Route path="/share/:modelId" element={<TemplatedModelSharePage/>}/>
                <Route element={<CenteredBoxLayout/>}>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/logout" element={<LogoutPage/>}/>
                    <Route path="/organizations/select" element={<SelectOrganizationPage/>}/>
                    <Route path="/accept-invite/:code" element={<AcceptInvitePage/>}/>
                    <Route path="/delete-organization/:code" element={<DeleteOrganizationPage/>}/>
                    <Route path="/onboarding/verify-email" element={<OnboardingVerifyEmail/>}/>
                    <Route path="/onboarding/user" element={<OnboardingUserPage/>}/>
                    <Route path="/onboarding/organization" element={<OnboardingOrganizationPage/>}/>
                </Route>
            </Route>

            <Route element={<NavLayout/>}>
                <Route element={<FullwidthContainer/>}>
                    <Route path="/" element={<ProjectsPage/>}/>
                    <Route path="/integrations" element={<IntegrationsPage/>}/>
                    <Route path="/integrations/:path" element={<IntegrationDetailPage/>}/>
                    <Route path="/organization" element={<CurrentOrganizationPage/>}/>
                    <Route path="/user" element={<CurrentUserPage/>}/>
                    <Route path="/organization/members" element={<MembersPage/>}/>
                    <Route path="/checkouts/success" element={<CheckoutSuccessPage/>}/>=
                </Route>

                <Route element={<ProjectLayout/>}>
                    <Route path="/projects/:projectId/overview" element={<ProjectOverviewPage/>}/>
                    <Route path="/projects/:projectId/examples" element={<ExamplesPage/>}/>
                    <Route path="/projects/:projectId/models" element={<TemplatedModelsPage/>}/>
                    <Route path="/projects/:projectId/models/:modelId" element={<TemplatedModelDetailPage/>}/>
                    <Route path="/projects/:projectId/fine-tunes" element={<RedirectToModels/>}/>
                    <Route path="/projects/:projectId/fine-tunes/start" element={<StartFinetunePage/>}/>
                    <Route path="/projects/:projectId/fine-tunes/:finetuneId" element={<FinetuneDetailPage/>}/>
                    <Route path="/projects/:projectId/playground" element={<PlaygroundPage/>}/>
                    <Route path="/projects/:projectId/completions" element={<CompletionsPage/>}/>
                    <Route path="/projects/:projectId/import-jobs/:importJobId" element={<CsvImportPage/>}/>
                    <Route path="/projects/:projectId/templates" element={<TemplatesPage/>}/>
                    <Route path="/projects/:projectId/templates/new" element={<TemplateDetailPage/>}/>
                    <Route path="/projects/:projectId/templates/:templateId" element={<TemplateDetailPage/>}/>
                    <Route path="/projects/:projectId/fields" element={<ProjectSettingsFields/>}/>
                    <Route path="/projects/:projectId/settings" element={<SettingsPage/>}/>
                    <Route path="/projects/:projectId/synthesis" element={<SynthesisPage/>}/>
                    <Route path="/projects/:projectId/transforms" element={<TransformsPage/>}/>
                    <Route path="/projects/:projectId/transforms/new" element={<TransformDetailPage/>}/>
                    <Route path="/projects/:projectId/transforms/:transformId" element={<TransformDetailPage/>}/>
                </Route>

                <Route element={<AdminContainer/>}>
                    <Route path="/admin" element={<AdminHomePage/>}/>
                    <Route path="/admin/users" element={<AdminUsersPage/>}/>
                    <Route path="/admin/users/:userId" element={<AdminUserDetailPage/>}/>
                    <Route path="/admin/organizations" element={<AdminOrganizationsPage/>}/>
                    <Route path="/admin/organizations/:organizationId" element={<AdminOrganizationDetailPage/>}/>
                    <Route path="/admin/projects" element={<AdminProjectsPage/>}/>
                    <Route path="/admin/projects/:projectId" element={<AdminProjectDetailPage/>}/>
                    <Route path="/admin/upgrade-codes" element={<AdminPromoCodesPage/>}/>
                    <Route path="/admin/demo-datasets" element={<AdminDemoDatasetsPage/>}/>
                    <Route path="/admin/marketing-events" element={<AdminMarketingEventsPage/>}/>
                </Route>
            </Route>

            <Route path="*" element={<NotFound404Page/>}/>
        </Routes>
    )
}

export default AppRouter;