export enum PlanInterval {
    YEARLY = "YEARLY",
    MONTHLY = "MONTHLY"
}

export function resolveNameForPlanInterval(interval: PlanInterval) {
    switch (interval) {
        case PlanInterval.YEARLY:
            return "Yearly"
        case PlanInterval.MONTHLY:
            return "Monthly"
    }
}
