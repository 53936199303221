import React from 'react';
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import {TemplateType} from "../../api/enum/TemplateType"
import {Box, Typography} from "@mui/material"

function AdminTemplateDetails({template}: { template: AnyTemplateJson }) {
    switch (template.type) {
        case TemplateType.STANDARD:
            return (
                <>
                    <Typography variant="h3">Prompt</Typography>
                    <Box sx={{whiteSpace: "pre-wrap"}}>{template.prompt}</Box>

                    <Typography variant="h3" sx={{mt: 2}}>Completion</Typography>
                    <Box sx={{whiteSpace: "pre-wrap"}}>{template.completion}</Box>

                    <Typography variant="h3" sx={{mt: 2}}>Stop Sequence</Typography>
                    <Box sx={{whiteSpace: "pre-wrap"}}>{template.stopSequence}</Box>

                    <Typography variant="h3" sx={{mt: 2}}>Separator</Typography>
                    <Box sx={{whiteSpace: "pre-wrap"}}>{template.separator}</Box>
                </>
            )
        case TemplateType.CHAT:
            return (
                <>
                    <Typography variant="h3">System</Typography>
                    <Box sx={{whiteSpace: "pre-wrap"}}>{template.system}</Box>

                    <Typography variant="h3" sx={{mt: 2}}>User</Typography>
                    <Box sx={{whiteSpace: "pre-wrap"}}>{template.user}</Box>

                    <Typography variant="h3" sx={{mt: 2}}>Assistant</Typography>
                    <Box sx={{whiteSpace: "pre-wrap"}}>{template.assistant}</Box>
                </>
            )
    }
}

export default AdminTemplateDetails;