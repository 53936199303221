import {PagedMarketingLeadJson} from "../json/PagedResponseJson"
import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"

export class MarketingLeadRequests {
    static list(): Promise<PagedMarketingLeadJson> {
        return new RequestBuilder(Method.Get, `marketing-leads`)
            .withoutOrganization()
            .buildAndSend()
    }

    static delete(id: string): Promise<PagedMarketingLeadJson> {
        return new RequestBuilder(Method.Delete, `marketing-leads/${id}`)
            .withoutOrganization()
            .buildAndSend()
    }
}