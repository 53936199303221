export enum JsonlStructure {
    TEXT = "TEXT",
    PROMPT_COMPLETION = "PROMPT_COMPLETION",
    OPEN_AI_CHAT_MESSAGES = "OPEN_AI_CHAT_MESSAGES"
}

export function resolveNameForJsonlStructure(syntax: JsonlStructure) {
    switch (syntax) {
        case JsonlStructure.TEXT:
            return "Text"
        case JsonlStructure.PROMPT_COMPLETION:
            return "Prompt & Completion"
        case JsonlStructure.OPEN_AI_CHAT_MESSAGES:
            return "OpenAI Chat Messages"
    }
}