import React, {ReactNode, useEffect, useState} from 'react'
import {InviteRequests} from "../../api/requests/InviteRequests";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Skeleton, Stack, Typography} from "@mui/material";
import {InviteAcceptJson} from "../../api/json/InviteAcceptJson";
import styles from "./Accounts.module.scss"
import {useAuth0} from "@auth0/auth0-react"
import {useApi} from "../../hooks/useApi"
import ApiError from "../../api/ApiError"
import ErrorMessage from "../error/ErrorMessage"

function InviteAcceptPage() {
    const [invite, setInvite] = useState<InviteAcceptJson>()
    const [error, setError] = useState<ApiError>()
    const [acceptError, setAcceptError] = useState<ApiError>()

    const {code} = useParams()

    const {loginWithRedirect, logout} = useAuth0()
    const navigate = useNavigate()
    const {currentUser, setCurrentOrganization, clearAll} = useApi()

    useEffect(() => {
        if (!code) return

        InviteRequests.get(code)
            .then(setInvite)
            .catch(setError)
    }, [code])

    const handleAccept = () => {
        InviteRequests.accept(code!)
            .then(membership => {
                setCurrentOrganization({...membership.organization, role: membership.role})
                navigate("/")
            })
            .catch(setAcceptError)
    }

    if (error) {
        return <ErrorMessage error={error}/>
    }

    if (!invite) {
        return <>
            <Skeleton/>
            <Skeleton/>
        </>
    }

    let form: ReactNode

    // Show a simple accept invite button or require authentication
    if (currentUser?.id) {
        if (currentUser.email === invite.email) {
            form = <>
                <Typography paragraph>Would you like to accept?</Typography>
                <Button
                    variant="contained"
                    className={styles.primaryButton}
                    onClick={handleAccept}
                >
                    Accept
                </Button>
            </>
        } else {
            form = <>
                <Typography paragraph>This invite is for {invite.email}, but you are logged in
                    as {currentUser.email}. Please log out and try again.</Typography>
                <Button
                    variant="contained"
                    className={styles.primaryButton}
                    onClick={() => {
                        clearAll()
                        logout()
                    }}
                >
                    Logout
                </Button>
            </>
        }
    } else {
        form =
            <>
                <Typography paragraph>Please log in or sign up as {invite.email} to accept.</Typography>
                <Button
                    variant="contained"
                    className={styles.primaryButton}
                    onClick={() => loginWithRedirect({appState: {returnTo: window.location.pathname}})}
                >
                    Continue
                </Button>
            </>
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h1">Entry Point Invitation</Typography>
            <Typography paragraph>You're invited to join the {invite.organization.name} organization.</Typography>
            {form}
            {acceptError && <Alert severity="error">{acceptError?.message}</Alert>}
        </Stack>
    )
}

export default InviteAcceptPage