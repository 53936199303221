import React from 'react'
import {Outlet} from "react-router-dom"
import ProjectSidebar from "./Nav/ProjectSidebar"
import {ProjectProvider} from "../../hooks/useProjectData"
import ProjectLayoutContainer from "./ProjectLayoutContainer";
import ImportProgressOverlay from "../import/ImportProgressOverlay"

function ProjectLayout() {
    return (
        <ProjectProvider>
            <ProjectSidebar/>
            <ImportProgressOverlay />
            <ProjectLayoutContainer>
                <Outlet/>
            </ProjectLayoutContainer>
        </ProjectProvider>
    )
}

export default ProjectLayout