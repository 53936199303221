import React, {useEffect, useState} from 'react';
import {TemplateChatParts} from "../../api/json/TemplateJson"
import {AnyFieldJson} from "../../api/json/FieldJson"
import {Stack, Typography} from "@mui/material"
import TemplateUtil from "../../library/TemplateUtil"
import TemplateRenderer from "./TemplateRenderer"
import FieldReferenceTextField from "./FieldReferenceTextField"
import {TemplateType} from "../../api/enum/TemplateType"

interface TemplateEditorChatProps {
    fields: AnyFieldJson[]
    templateParts: TemplateChatParts
    readonly?: boolean
    onChange: (properties: TemplateChatParts) => void
}

function TemplateEditorChat({fields, templateParts, readonly, onChange}: TemplateEditorChatProps) {
    const [system, setSystem] = useState<string>(templateParts?.system)
    const [user, setUser] = useState<string>(templateParts?.user)
    const [assistant, setAssistant] = useState<string>(templateParts?.assistant)
    const [activeSection, setActiveSection] = useState<"system" | "user" | "assistant" | null>(null)

    const currentProperties: TemplateChatParts = {
        type: TemplateType.CHAT,
        system: system,
        user: user,
        assistant: assistant,
    }

    useEffect(() => {
        onChange(currentProperties)
    }, [system, user, assistant])

    const usedReferences = TemplateUtil.findAllReferences(currentProperties)
    const unreferencedFields = fields.filter(f => usedReferences.indexOf(f.reference) === -1)

    if (readonly) {
        return (
            <TemplateRenderer templateParts={templateParts}
                              showLabels
                              staticSystemPromptTreatment="show"
            />
        )
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h3" gutterBottom>Prompt</Typography>

            <FieldReferenceTextField
                label="System"
                value={system}
                setValue={setSystem}
                unreferencedFields={unreferencedFields}
                showInsertButtons={activeSection === "system"}
                didFocus={() => setActiveSection("system")}
            />

            <FieldReferenceTextField
                label="User"
                value={user}
                setValue={setUser}
                unreferencedFields={unreferencedFields}
                showInsertButtons={activeSection === "user"}
                didFocus={() => setActiveSection("user")}
                sx={{pb: 2}}
            />

            <Typography variant="h3">Completion</Typography>

            <FieldReferenceTextField
                label="Assistant"
                value={assistant}
                setValue={setAssistant}
                unreferencedFields={unreferencedFields}
                showInsertButtons={activeSection === "assistant"}
                didFocus={() => setActiveSection("assistant")}
            />
        </Stack>
    );
}

export default TemplateEditorChat;