import React from 'react';
import TransformJson from "../../../api/json/TransformJson"
import {Chip, Stack, Typography} from "@mui/material"
import styles from "./TransformCard.module.scss"
import animations from "../../../Animations.module.scss"

interface TransformCardProps {
    transform: TransformJson
    onClick?: () => void
}

function TransformCard({transform, onClick}: TransformCardProps) {
    return (
        <Stack spacing={2} sx={{p: 2}} className={`${styles.cardContainer} ${animations.defaultIn}`} onClick={onClick}>
            <Typography variant="subtitle1">{transform.name}</Typography>
            <Typography sx={{whiteSpace: "pre-wrap"}}>{transform.systemPrompt}</Typography>
            <Typography sx={{whiteSpace: "pre-wrap"}}>{transform.userPrompt}</Typography>
            {transform.hasActiveJob &&
                <Chip color="primary" variant="outlined" label="Job in progress" sx={{alignSelf: "flex-start"}}/>
            }
        </Stack>
    );
}

export default TransformCard;