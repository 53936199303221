import React, {useEffect, useState} from 'react'
import {
    Box,
    Chip,
    CircularProgress,
    Grid,
    Link,
    Stack,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import AdminHeader from "./AdminHeader"
import {Link as RouterLink, useParams} from "react-router-dom"
import {AdminRequests} from "../../api/requests/AdminRequests"
import ApiError from "../../api/ApiError"
import ActivityCard from "../activities/ActivityCard/ActivityCard"
import {AdminProjectDetailJson} from "../../api/json/AdminProjectDetailJson"
import {resolveNameForProjectType} from "../../api/enum/ProjectType"
import {resolveNameForFieldType} from "../../api/enum/FieldType"
import FinetuneStatusInfo from "../finetunes/FinetuneStatusInfo"
import Logo from "../accounts/Logo"
import ExampleCard from "../examples/ExampleCard/ExampleCard"
import AdminTemplateDetails from "./AdminTemplateDetails"
import {resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"
import ExportButton from "../layouts/Nav/ExportButton"
import SpaceBetween from "../common/SpaceBetween"
import TemplateSelect from "../templates/TemplateSelect"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import ErrorMessage from "../error/ErrorMessage"

function AdminProjectDetailPage() {
    const [details, setDetails] = useState<AdminProjectDetailJson>()
    const [template, setTemplate] = useState<AnyTemplateJson>()
    const [error, setError] = useState<ApiError>()

    const {projectId} = useParams()

    useEffect(() => {
        if (!projectId) return

        AdminRequests.getProjectDetails(projectId)
            .then(setDetails)
            .catch(setError)
    }, [projectId])

    if (error) {
        return (
            <>
                <AdminHeader/>
                <ErrorMessage error={error}/>
            </>
        )
    }

    if (!details) {
        return (
            <>
                <AdminHeader/>
                <CircularProgress/>
            </>
        )
    }

    const project = details.project
    const organization = details.organization
    return (
        <>
            <AdminHeader/>
            <Typography variant="h1">{project.name}</Typography>

            <Box sx={{mt: 2}}>
                <Link component={RouterLink} to={`/admin/organizations/${organization.id}`}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Logo image={organization.logo}/>
                        <Typography fontWeight={500}>{organization.name}</Typography>
                    </Stack>
                </Link>
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" sx={{mt: 3}}>
                <Chip variant="outlined" label={`${project.stats.numberOfTrainingExamples} training examples`}/>
                <Chip variant="outlined" label={`${project.stats.numberOfValidationExamples} validation examples`}/>
                <Chip variant="outlined" label={`Created ${new Date(project.created).toLocaleDateString()} `}/>
                <Chip variant="outlined" label={`Updated ${new Date(project.updated).toLocaleDateString()} `}/>
            </Stack>

            <Grid container spacing={3} sx={{mt: 4}}>
                <Grid item sm={6} xs={12}>
                    <Typography variant="h3">Fine-tunes</Typography>
                    <Table size="small" sx={{mt: 2}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Platform</TableCell>
                                <TableCell># Training Examples</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        {details.finetunes.map(finetune => (
                            <TableRow>
                                <TableCell>
                                    {finetune.name}
                                </TableCell>
                                <TableCell>
                                    {resolveNameForModelPlatform(finetune.platform)}
                                </TableCell>
                                <TableCell>
                                    {finetune.trainingExampleCount}
                                </TableCell>
                                <TableCell><FinetuneStatusInfo showTime finetune={finetune}/></TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Typography variant="h3">Activities</Typography>
                    <Stack spacing={3} sx={{mt: 4}}>
                        {details.activities.map(activity => (
                            <ActivityCard key={activity.id} activity={activity} adminContext/>
                        ))}
                    </Stack>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Typography variant="h3">Fields</Typography>
                    <Table size="small" sx={{mt: 2}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Reference</TableCell>
                                <TableCell>Type</TableCell>
                            </TableRow>
                        </TableHead>
                        {project.fields.map(field => (
                            <TableRow>
                                <TableCell>
                                    {field.name}
                                </TableCell>
                                <TableCell>
                                    {field.reference}
                                </TableCell>
                                <TableCell>
                                    {resolveNameForFieldType(field.type)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </Grid>

                <Grid item sm={6} xs={12}>
                    /* TODO: Add template selector */
                    <AdminTemplateDetails template={project.templates[0]}/>
                </Grid>

                <Grid item xs={12}>
                    <SpaceBetween>
                        <Typography variant="h3">Examples</Typography>
                        <ExportButton project={project}/>
                    </SpaceBetween>

                    <TemplateSelect templates={project.templates} onChange={setTemplate}/>

                    {template &&
                        <Grid container spacing={3} sx={{mt: 1}}>
                            {details.examples.map(example => (
                                    <Grid item md={4} sm={6} xs={12}>
                                        <ExampleCard example={example} project={project} template={template}/>
                                    </Grid>
                                ),
                            )}
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default AdminProjectDetailPage;