import React, {useCallback, useEffect, useState} from 'react'
import {
    Card,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material"
import {useProjectData} from "../../hooks/useProjectData"
import {ProjectRequests} from "../../api/requests/ProjectRequests";
import {ExampleRequests} from "../../api/requests/ExampleRequests";
import ExampleJson from "../../api/json/ExampleJson";
import ExampleCard from "../examples/ExampleCard/ExampleCard";
import ActivityCard from "../activities/ActivityCard/ActivityCard";
import ProjectPageHeader from "../layouts/ProjectPageHeader";
import {AnyActivityJson} from "../../api/json/AnyActivityJson";
import animations from "../../Animations.module.scss";
import ApiError from "../../api/ApiError"
import {useIntegrations} from "../../hooks/useIntegrations"
import ShuffleIcon from '@mui/icons-material/Shuffle';
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import ErrorMessage from "../error/ErrorMessage"
import ProjectActionsMenu from "./ProjectsPage/ProjectActionsMenu"
import {useNavigate} from "react-router-dom"
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';

function ProjectOverviewPage() {
    const {id, project, reloadProject} = useProjectData()

    const [activities, setActivities] = useState<(AnyActivityJson)[]>()
    const [loadingActivities, setLoadingActivities] = useState(false)
    const [activitiesError, setActivitiesError] = useState<ApiError>()

    const [randomExamples, setRandomExamples] = useState<ExampleJson[]>([])
    const [loadingRandomExamples, setLoadingRandomExamples] = useState(false)
    const [randomExamplesError, setRandomExamplesError] = useState<ApiError>()

    const [template, setTemplate] = useState<AnyTemplateJson | undefined>()

    const {reloadIntegrations} = useIntegrations()

    useEffect(() => reloadIntegrations, [])

    useEffect(() => {
        if (project && project.templates.length > 0)
            setTemplate(project.templates[0])
    }, [project])

    useEffect(() => {
        if (loadingActivities) return

        setLoadingActivities(true)
        ProjectRequests.listActivities(id)
            .then(page => setActivities(page.content))
            .catch(setActivitiesError)
            .finally(() => setLoadingActivities(false))
    }, [])

    const loadRandomExamples = useCallback(() => {
        if (loadingRandomExamples) return
        setLoadingRandomExamples(true)
        ExampleRequests.getRandom(id)
            .then(page => setRandomExamples(page.content))
            .catch(setRandomExamplesError)
            .finally(() => setLoadingRandomExamples(false))
    }, [id, loadingRandomExamples, setLoadingRandomExamples, setRandomExamples])
    useEffect(() => loadRandomExamples(), [])

    const navigate = useNavigate()

    if (!project) {
        return <CircularProgress/>
    }

    return (
        <>
            <ProjectPageHeader pageTitle={project.name} breadcrumbs={[]} nextToTitle={
                <ProjectActionsMenu project={project}
                                    didRename={() => reloadProject()}
                                    didCopy={copy => navigate(`/projects/${copy.id}/overview`)}
                                    didDelete={() => navigate("/")}
                />
            }>
                <Typography variant="body2">{project.description}</Typography>
            </ProjectPageHeader>

            <Grid container rowSpacing={4} columnSpacing={6}>
                <Grid item lg={8} md={6} sm={12}>
                    {(project && project.stats.totalExamples > 0 && project.templates.length > 0) ?
                        <>
                            <Stack direction="row" justifyContent="space-between">
                                <Typography variant="h2">Example Roulette</Typography>
                                <IconButton aria-label="refresh" onClick={loadRandomExamples}>
                                    <ShuffleIcon/>
                                </IconButton>
                            </Stack>
                            <Stack spacing={2} className={animations.defaultIn} sx={{mt: 2}}>
                                {template && randomExamples.map(example =>
                                    <ExampleCard
                                        key={example.id}
                                        example={example}
                                        template={template}
                                        project={project}
                                        didUpdate={(updated) => {
                                            let newRandomExamples = [...randomExamples]
                                            let index = newRandomExamples.findIndex(example => example.id === updated.id)
                                            newRandomExamples[index] = updated
                                            setRandomExamples(newRandomExamples)
                                        }
                                        }
                                        didDelete={(deleted) => {
                                            let newRandomExamples = [...randomExamples]
                                            let index = newRandomExamples.findIndex(example => example.id === deleted.id)
                                            newRandomExamples.splice(index, 1)
                                            setRandomExamples(newRandomExamples)
                                        }
                                        }/>,
                                )}
                            </Stack>
                        </> :
                        <>
                            <Card variant="outlined" sx={{mt: 3}}>
                                <CardContent>
                                    <Typography variant="subtitle1">Guides to get started</Typography>
                                    <List>
                                        <ListItemButton target="_blank" href="https://docs.entrypointai.com/guides/evaluate-a-prompt">
                                            <ListItemIcon><ScienceOutlinedIcon/></ListItemIcon>
                                            <ListItemText primary="Evaluate a prompt"/>
                                        </ListItemButton>
                                        <ListItemButton target="_blank" href="https://docs.entrypointai.com/guides/build-a-dataset">
                                            <ListItemIcon><DataObjectOutlinedIcon/></ListItemIcon>
                                            <ListItemText primary="Build a dataset"/>
                                        </ListItemButton>
                                        <ListItemButton target="_blank" href="https://docs.entrypointai.com/guides/fine-tune-a-model">
                                            <ListItemIcon><RocketLaunchOutlinedIcon/></ListItemIcon>
                                            <ListItemText primary="Fine-tune a model"/>
                                        </ListItemButton>
                                    </List>
                                </CardContent>
                            </Card>
                        </>
                    }
                    {randomExamplesError && <ErrorMessage error={randomExamplesError}/>}
                </Grid>
                <Grid item lg={4} md={6} sm={12}>
                    <Typography variant="h2">Recent Activity</Typography>
                    <Stack spacing={3} className={animations.defaultIn} sx={{mt: 3}}>
                        {loadingActivities && <CircularProgress/>}
                        {activities && activities.map(activity =>
                            <ActivityCard key={activity.id} activity={activity} projectContext/>,
                        )}
                        {activitiesError && <ErrorMessage error={activitiesError}/>}
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default ProjectOverviewPage