import React, {createContext, useContext, useState} from "react"
import {Alert, Snackbar} from "@mui/material"
import ApiError from "../api/ApiError"

export interface UseToastState {
    show: boolean
    message: string
    severity?: "error" | "info" | "warning" | "success"
}

export interface UseToastActions {
    clear: () => void
    showError: (error: Error | ApiError) => void
    showSaved: () => void
    showMessage: (message: string, severity?: "error" | "info" | "warning" | "success") => void
}

const Context = createContext<UseToastState & UseToastActions>({
    show: false,
    message: "",
    severity: undefined,
    showError: () => {},
    showSaved: () => {},
    showMessage: () => {},
    clear: () => {},
})

export const useToasts = (): UseToastState & UseToastActions => {
    const context = useContext(Context)
    if (context === null) {
        throw new Error('useToasts must be used within a ToastProvider tag')
    }
    return context
}

export const ToastProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
    const [state, setState] = useState<UseToastState>({show: false, message: ""})

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setState( {show: false, message: state.message, severity: state.severity })
    };

    return (
        <Context.Provider value={{
            ...state,
            showError: (error) => setState(
                {
                    show: true,
                    message: "httpCode" in error ? error.resolveUserMessage() : error.message,
                    severity: "error"
                }
            ),
            showSaved: () => setState({show: true, message: "Save successful.", severity: "success"}),
            showMessage: (message, severity) => setState({show: true, message: message, severity: severity}),
            clear: () => setState({show: false, message: ""})
        }
        }>
            {props.children}
            <Snackbar open={state.show}
                      onClose={handleClose}
                      autoHideDuration={state.severity === "success" ? 3000 : undefined}
            >
                <Alert onClose={handleClose} severity={state.severity} >
                    {state.message}
                </Alert>
            </Snackbar>
        </Context.Provider>
    )
}
