import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import {AnyIntegrationJson} from "../json/IntegrationJson";
import {ReplicateModelJson} from "../json/ReplicateModelJson"
import {ReplicateDestinationJson} from "../json/ReplicateDestinationJson"
import {ModelPlatform} from "../enum/ModelPlatform"

export class IntegrationRequests {
    static create(integration: AnyIntegrationJson): Promise<AnyIntegrationJson> {
        return new RequestBuilder(Method.Post, `integrations`)
            .withJson(integration)
            .buildAndSend()
    }

    static update(integration: AnyIntegrationJson): Promise<AnyIntegrationJson> {
        return new RequestBuilder(Method.Put, `integrations/${integration.id}`)
            .withJson(integration)
            .buildAndSend()
    }

    static list(platform?: ModelPlatform): Promise<AnyIntegrationJson[]> {
        const queryParams = new URLSearchParams()
        if (platform) queryParams.append("platform", platform)

        return new RequestBuilder(Method.Get, `integrations?${queryParams.toString()}`)
            .buildAndSend()
    }

    static get(id: string): Promise<AnyIntegrationJson | undefined> {
        return new RequestBuilder(Method.Get, `integrations/${id}`)
            .buildAndSend()
    }

    static delete(id: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `integrations/${id}`)
            .buildAndSend()
    }

    static getReplicateModel(modelOwner: string, modelName: string): Promise<ReplicateModelJson> {
        return new RequestBuilder(Method.Get, `integrations/replicate/models/${modelOwner}/${modelName}`)
            .buildAndSend()
    }

    static getReplicateLatestDestination(projectId: string): Promise<ReplicateDestinationJson | null> {
        return new RequestBuilder(Method.Get, `integrations/replicate/projects/${projectId}/destinations/latest`)
            .buildAndSend()
    }

    static createReplicateModel(ownerName: string, modelName: string, hardwareSku: string): Promise<ReplicateModelJson> {
        return new RequestBuilder(Method.Post, `integrations/replicate/models`)
            .withJson({
                ownerName: ownerName,
                modelName: modelName,
                hardwareSku: hardwareSku,
            })
            .buildAndSend()
    }
}