import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Stack, TextField, Typography} from "@mui/material"
import {ProjectJson} from "../../api/json/ProjectJson"
import AsyncButton from "../button/AsyncButton"
import ApiError from "../../api/ApiError"
import {useProjectData} from "../../hooks/useProjectData"
import useEnterShortcut from "../../hooks/useEnterShortcut"
import TemplateSelect from "../templates/TemplateSelect"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import {TemplatedModelRequests} from "../../api/requests/TemplatedModelRequests"
import {TemplatedModelJson} from "../../api/json/TemplatedModelJson"
import {AnyModelSpecJson} from "../../api/json/ModelSpecJson"
import ModelFinder from "./ModelFinder"
import ModalBase from "../modal/ModalBase"
import TemplateRenderer from "../templates/TemplateRenderer"

interface AddModelModalProps {
    project: ProjectJson
    open: boolean
    setOpen: (open: boolean) => void
    didCreateModel?: (newModel: TemplatedModelJson) => void
}

function NewTemplatedModelModal({project, open, setOpen, didCreateModel}: AddModelModalProps) {
    const {id} = useProjectData()
    const [name, setName] = useState("")

    const [template, setTemplate] = useState<AnyTemplateJson | undefined>(project.templates.length > 0 ? project.templates[0] : undefined)
    const [model, setModel] = useState<AnyModelSpecJson>()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<ApiError>()

    const disabled = name.length === 0 || !model || !template

    const modalRef = useRef<HTMLElement>(null)

    useEnterShortcut(modalRef, () => {
        if (open && !disabled) {
            handleCreate()
        }
    })

    const handleCreate = () => {
        if (!template || !model) return

        setSaving(true)
        TemplatedModelRequests.create(id, model.name, name, template.id)
            .then(model => {
                setOpen(false)
                setError(undefined)
                didCreateModel && didCreateModel(model)
            })
            .catch(setError)
            .finally(() => setSaving(false))
    }

    useEffect(() => {
        if (model && template) {
            let defaultName = `${template.name} on ${model.displayName}`
            if (defaultName.length >= 60) {
                defaultName = model.displayName
            }
            setName(defaultName.slice(0, 60))
        }
    }, [model, template])

    return (
        <ModalBase
            open={open}
            setOpen={open => setOpen(open)}
            size="large"
            title="Apply a Template"
            buttons={
                <>
                    <Button variant="outlined"
                            color="secondary"
                            onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <AsyncButton variant="contained"
                                 disabled={disabled}
                                 waiting={saving}
                                 onClick={() => handleCreate()}
                    >
                        Add to project
                    </AsyncButton>
                </>
            }
            error={error}
        >
            <Stack direction="row" spacing={2}>
                <Stack spacing={2} flex={1}>
                    <TemplateSelect templates={project.templates} value={template} onChange={setTemplate}/>

                    <ModelFinder value={model} onChange={setModel}/>

                    {model && template &&
                        <TextField label="Name"
                                   autoComplete="model-name"
                                   value={name}
                                   onChange={e => {
                                       setName(e.target.value.slice(0, 60))
                                   }}
                        />
                    }
                </Stack>
                <Box flex={1}>
                    {template &&
                        <Box sx={{pl: 2, pr: 2, maxHeight: 320, overflowY: "auto"}}>
                            <Typography variant="subtitle1" gutterBottom>Template Preview</Typography>
                            <TemplateRenderer templateParts={template} staticSystemPromptTreatment="show"/>
                        </Box>
                    }
                </Box>
            </Stack>
        </ModalBase>
    );
}

export default NewTemplatedModelModal;