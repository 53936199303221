import React, {useState} from 'react';
import {Button, FormControlLabel, Stack, Switch, SxProps, TextField, Typography} from "@mui/material"
import AsyncButton from "../../button/AsyncButton"
import {DemoDatasetRequests} from "../../../api/requests/DemoDatasetRequests"
import ApiError from "../../../api/ApiError"
import ErrorMessage from "../../error/ErrorMessage"
import {DemoDatasetJson} from "../../../api/json/DemoDatasetJson"
import NumberInput from "../../form/NumberInput"

interface DemoDatasetFormProps {
    sx?: SxProps
    existing?: DemoDatasetJson
    didUpdate?: (dataset: DemoDatasetJson) => void
    didCreate?: (dataset: DemoDatasetJson) => void
}

function DemoDatasetForm({didCreate, didUpdate, existing, sx}: DemoDatasetFormProps) {
    const [name, setName] = useState(existing?.name ?? "")
    const [description, setDescription] = useState(existing?.description ?? "")
    const [validationPercent, setValidationPercent] = useState(existing ? existing.validationPercent : 0.2)
    const [publicValue, setPublicValue] = useState<boolean>(existing?.public ?? false)
    const [file, setFile] = useState<File>()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<ApiError>()

    const handleUpdate = () => {
        if (!name || !description || !existing)
            return

        setSaving(true)
        DemoDatasetRequests.update(existing.id, name, description, validationPercent, publicValue)
            .then(updated => {
                didUpdate && didUpdate(updated)
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setSaving(false))
    }

    const handleCreate = () => {
        if (!file || !name || !description)
            return

        setSaving(true)
        DemoDatasetRequests.create(name, description, validationPercent, publicValue, file)
            .then(demoDataset => didCreate && didCreate(demoDataset))
            .catch(setError)
            .finally(() => setSaving(false))
    }

    const disabled = !name || !description || (!existing && !file)

    return (
        <Stack spacing={2} sx={sx}>
            <TextField
                label="Name"
                value={name}
                onChange={(e) => {
                    if (e.target.value.length > 60) return
                    setName(e.target.value)
                }}
            />

            <TextField
                label="Description"
                multiline
                value={description}
                onChange={(e) => {
                    if (e.target.value.length > 200) return
                    setDescription(e.target.value)
                }}
            />

            <NumberInput
                label="Validation Percent"
                helperText={`${validationPercent * 100}% of records reserved for validation`}
                min={0}
                max={1}
                value={validationPercent} onChange={setValidationPercent}
            />


            <FormControlLabel
                control={
                    <Switch checked={publicValue} onChange={e => setPublicValue(e.target.checked)}/>
                }
                label="Public"
            />

            <input
                accept="text/csv"
                hidden
                id="choose-file"
                multiple
                type="file"
                onChange={event => {
                    const files = event.target.files
                    if (!files || files.length !== 1) return
                    setFile(files[0])
                }}
            />

            {!existing &&
                <Stack spacing={2} alignItems="center" direction={{xs: "column", sm: "row"}}>
                    <label htmlFor="choose-file">
                        <Button variant="outlined" component="span">
                            Choose .csv file
                        </Button>
                    </label>
                    {file && <Typography variant="caption">{file.name}</Typography>}
                </Stack>
            }

            <Stack direction="row" justifyContent="flex-end">
                {existing ?
                    <AsyncButton waiting={saving} disabled={disabled} onClick={() => handleUpdate()}>
                        Update
                    </AsyncButton>
                    :
                    <AsyncButton waiting={saving} disabled={disabled} onClick={() => handleCreate()}>
                        Create
                    </AsyncButton>
                }
            </Stack>

            <ErrorMessage error={error}/>
        </Stack>
    );
}

export default DemoDatasetForm;