import React, {ReactNode} from 'react';
import {Card, Chip, Grid, Paper, Typography} from "@mui/material"
import styles from "../../../Card.module.scss"
import {DemoDatasetJson} from "../../../api/json/DemoDatasetJson"

interface DatasetCardProps {
    dataset: DemoDatasetJson
    onClick: (dataset: DemoDatasetJson) => void
}

function DemoDatasetCard({dataset, onClick}: DatasetCardProps) {
    return (
        <Card variant="outlined" sx={{p: 2, minHeight: 160}} className={`${styles.cardContainer} ${styles.hoverable}`} onClick={() => onClick(dataset)}>
            <Typography variant="subtitle1">{dataset.name}</Typography>
            <Typography paragraph>{dataset.description}</Typography>
            <Chip variant="outlined" label={`${dataset.numberOfRecords} examples`}/>
        </Card>
    );
}

export default DemoDatasetCard;