import React, {useState} from 'react';
import {Chip, IconButton, Stack, TextField, Tooltip} from "@mui/material";
import {Clear} from "@mui/icons-material";
import ConfirmDeleteModal from "../modal/ConfirmDeleteModal";
import {PredefinedOptionJson} from "../../api/json/PredefinedOptionJson";
import {FieldRequests} from "../../api/requests/FieldRequests";
import {ProjectJson} from "../../api/json/ProjectJson";
import ApiError from "../../api/ApiError"

interface FieldPredefinedOptionsEditorRowProps {
    project: ProjectJson
    fieldId?: string
    option: PredefinedOptionJson
    didUpdate: (newValue: string) => void
    didDelete: () => void
}

function FieldPredefinedOptionsEditorRow({ project, fieldId, option, didUpdate, didDelete }: FieldPredefinedOptionsEditorRowProps) {
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [deleteError, setDeleteError] = useState<ApiError>()

    const handleDelete = (option: PredefinedOptionJson) => {
        if (!fieldId || !option.id) {
            setShowRemoveConfirmation(false)
            return didDelete()
        }

        FieldRequests.deletePredefinedOption(project.id, fieldId, option.id)
            .then(() => {
                setShowRemoveConfirmation(false)
                didDelete()
            })
            .catch(setDeleteError)
    }

    return (
        <>
            <Stack direction="row" spacing={1} justifyContent="equal-spacing" alignItems="center">
                <TextField
                    value={option.value}
                    size="small"
                    onChange={(e) => didUpdate(e.target.value)}
                    sx={{flex: 1}}
                />
                <Stack sx={{minWidth: 50}} justifyContent="center">
                    {option.numberOfExamples === 0 &&
                        <IconButton onClick={() => setShowRemoveConfirmation(true)} sx={{ width: 40, margin: "0 auto" }}>
                            <Clear/>
                        </IconButton>
                    }
                    {option.numberOfExamples !== undefined && option.numberOfExamples > 0 &&
                        <Tooltip title={`Found in ${option.numberOfExamples} examples`}>
                            <Chip variant="outlined" label={`${option.numberOfExamples}`}/>
                        </Tooltip>
                    }
                </Stack>
            </Stack>

            <ConfirmDeleteModal
                heading={`Remove "${option.value}" predefined option?`}
                body="This option will be removed from the list immediately."
                open={showRemoveConfirmation}
                setOpen={setShowRemoveConfirmation}
                confirmedDelete={() => handleDelete(option)}
                error={deleteError}
            />
        </>
    )
}

export default FieldPredefinedOptionsEditorRow;