import ApiError from "./ApiError"

export default class RequestSender {
    static async sendRequest(request: Request, fileDownload = false): Promise<any> {
        let response: Response
        try {
            response = await fetch(request)
        } catch(error: any) {
            throw new ApiError(`Service unavailable (${(error as Error).message})`, 0)
        }

        if (!response.ok) {
            if (response.status === 401) {
                // TODO: Trigger a modal for 401s that say "Your session has expired" and a logout button
                throw new ApiError("Please log out and log back in.", 401)
            }

            let json
            try {
                // Try parsing the JSON
                json = await response.json();
            } catch (error) {
                throw new ApiError(`Something went wrong (${response.status})`, response.status)
            }

            // If JSON parses successfully and API returned an error
            const errorMessage = json.message || 'An error occurred without a specific message.'
            throw new ApiError(errorMessage, response.status)
        } else if (response.status === 204) {
            return null
        }

        if (fileDownload) {
            return await response.arrayBuffer()
        }
        return await response.json()
    }
}