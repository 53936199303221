import React, {useEffect, useState} from 'react';
import {Alert, Box, Grid, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {OrganizationRequests} from "../../api/requests/OrganizationRequests";
import ConfirmDeleteModal from "../modal/ConfirmDeleteModal";
import PageHeader from "../layouts/PageHeader";
import {useToasts} from "../../hooks/useToasts"
import OrganizationDetailsForm from "./OrganizationDetailsForm"
import DangerActionBox from "../form/DangerActionBox"
import DangerZone from "../form/DangerZone"
import {useApi} from "../../hooks/useApi"
import BillingPortalButton from "../billing/BillingPortalButton"
import FormContainer from "../layouts/FormContainer"
import ApiError from "../../api/ApiError"
import BreadcrumbsOrganization from "../layouts/BreadcrumbsOrganization"
import {resolveNameForPlanTier} from "../../api/enum/PlanTier"
import {OrganizationStatsJson} from "../../api/json/OrganizationStatsJson"
import animations from "../../Animations.module.scss"
import RedeemUpgradeCodeForm from "./RedeemUpgradeCodeForm"
import ErrorMessage from "../error/ErrorMessage"

function CurrentOrganizationPage() {
    const [error, setError] = useState<ApiError>()

    const [confirmingDeleteRequest, setConfirmingDeleteRequest] = useState(false)
    const [deleteEmailSent, setDeleteEmailSent] = useState(false)
    const [stats, setStats] = useState<OrganizationStatsJson>()

    const toasts = useToasts()
    const {currentOrganization, setCurrentOrganization} = useApi()

    useEffect(() => loadCurrentOrganization(), [])

    const loadCurrentOrganization = () => {
        if (!currentOrganization) return

        OrganizationRequests.get(currentOrganization.id)
            .then(setCurrentOrganization)
            .catch(setError)
    }

    useEffect(() => {
        if (!currentOrganization) return

        OrganizationRequests.getStats(currentOrganization.id)
            .then(setStats)
            .catch(toasts.showError)
    }, [])

    const handleDelete = () => {
        setConfirmingDeleteRequest(true)
        OrganizationRequests.delete(currentOrganization!.id)
            .then(() => setDeleteEmailSent(true))
            .catch(toasts.showError)
            .finally(() => setConfirmingDeleteRequest(false))
    }

    if (error) {
        return <ErrorMessage error={error}/>
    }

    if (!currentOrganization) {
        return (
            <Box sx={{maxWidth: 450}}>
                <Skeleton height={60}/>
                <Skeleton height={60}/>
                <Skeleton width={100} height={40}/>
            </Box>
        )
    }

    return (
        <>
            <PageHeader pageTitle={"Organization Settings"}/>

            <Grid container spacing={4} justifyContent="space-between">
                <Grid item md={6} xs={12}>
                    <FormContainer>
                        <OrganizationDetailsForm organization={currentOrganization}
                                                 didSaveDetails={setCurrentOrganization}
                                                 didAddLogo={setCurrentOrganization}
                        />
                    </FormContainer>

                    <DangerZone>
                        {deleteEmailSent ? <Alert severity="success">An email has been sent to confirm deletion.</Alert>
                            : currentOrganization.billingDetails?.activePlan ?
                                <Typography variant="body1">Deletion not available while on an active plan.</Typography>
                                : (
                                    <DangerActionBox title="Delete this organization"
                                                     buttonLabel="Delete"
                                                     description="All your projects will be deleted."
                                                     onAction={() => setConfirmingDeleteRequest(true)}
                                                     actionPending={confirmingDeleteRequest}/>
                                )}
                    </DangerZone>
                </Grid>

                <Grid item md={4} xs={12}>
                    <Paper variant="outlined" sx={{p: 2}} className={animations.defaultIn}>
                        <Stack spacing={0.5}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h2">Plan</Typography>
                                {currentOrganization?.billingDetails &&
                                    <BillingPortalButton variant="contained">Billing</BillingPortalButton>
                                }
                            </Stack>

                            <Typography variant="subtitle1" sx={{mt: 2}}>
                                {currentOrganization.billingDetails ?
                                    <>{resolveNameForPlanTier(currentOrganization.billingDetails.planTier)}</>
                                    : <>Free</>}
                            </Typography>

                            {stats &&
                                <>
                                    <Typography>
                                        {stats.exampleCount.toLocaleString()} of {currentOrganization.usageLimits.numberOfExamples.toLocaleString()} examples
                                    </Typography>
                                    <Typography>
                                        {stats.userCount.toLocaleString()} of {currentOrganization.usageLimits.numberOfUsers.toLocaleString()} users
                                    </Typography>
                                </>
                            }
                            {currentOrganization.upgradeCodeRedemption &&
                                <Typography sx={{fontStyle: "italic"}}>
                                    Upgrade code applied
                                </Typography>
                            }
                        </Stack>
                    </Paper>

                    <RedeemUpgradeCodeForm organization={currentOrganization} didRedeem={loadCurrentOrganization}/>
                </Grid>
            </Grid>

            <ConfirmDeleteModal
                heading={`Delete ${currentOrganization.name} entirely`}
                body="If you are sure you want to delete this organization and all its data, we'll send you a link to verify."
                deleteButtonTitle="Continue"
                open={confirmingDeleteRequest}
                setOpen={setConfirmingDeleteRequest}
                confirmedDelete={handleDelete}
            />
        </>
    )
}

export default CurrentOrganizationPage;