import React, { useState, useEffect, createContext, useContext } from 'react';
import CurrentUserJson from "../api/json/CurrentUserJson"
import {OrganizationWithRoleJson} from "../api/json/OrganizationWithRoleJson"
import API from "../api/API"
import {UserRequests} from "../api/requests/UserRequests"
import {OrganizationRequests} from "../api/requests/OrganizationRequests"

export type ApiHook = {
    currentUser?: CurrentUserJson;
    currentOrganization?: OrganizationWithRoleJson;
    token?: string;
    setToken: (token: string) => void;
    clearToken: () => void;
    setCurrentUser: (currentUser: CurrentUserJson) => void;
    clearCurrentUser: () => void;
    setCurrentOrganization: (organization: OrganizationWithRoleJson) => void;
    clearCurrentOrganization: () => void;
    clearAll: () => void;
};

function useApiData(): ApiHook {
    const [token, setToken] = useState<string | undefined>(API.getToken())
    const [currentUser, setCurrentUser] = useState<CurrentUserJson | undefined>(API.getCurrentUser())
    const [currentOrganization, setCurrentOrganization] = useState<OrganizationWithRoleJson | undefined>(API.getCurrentOrganization())

    function clearToken() {
        setToken(undefined)
        API.clearToken()
    }

    function clearCurrentUser() {
        setCurrentUser(undefined)
        API.clearCurrentUser()
    }

    function clearCurrentOrganization() {
        setCurrentOrganization(undefined)
        API.clearCurrentOrganization()
    }

    function clearAll() {
        clearToken()
        clearCurrentUser()
        clearCurrentOrganization()
    }

    function setApiToken(token: string) {
        setToken(token)
        API.setToken(token)
    }

    function setApiCurrentUser(currentUser: CurrentUserJson) {
        setCurrentUser(currentUser);
        API.setCurrentUser(currentUser)
    }

    function setApiCurrentOrganization(organization: OrganizationWithRoleJson) {
        setCurrentOrganization(organization);
        API.setCurrentOrganization(organization)
    }

    useEffect(() => {
        UserRequests.getCurrentUser().then(setCurrentUser)
    }, [])

    return {
        currentUser,
        currentOrganization: currentOrganization,
        token,
        setToken: setApiToken,
        clearToken,
        setCurrentUser: setApiCurrentUser,
        clearCurrentUser,
        setCurrentOrganization: setApiCurrentOrganization,
        clearCurrentOrganization: clearCurrentOrganization,
        clearAll,
    };
}

// ApiContext
const ApiContext = createContext<ApiHook | null>(null);

type ApiProviderProps = {
    children: React.ReactNode;
};

// Higher-order component ApiProvider
const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
    const api = useApiData();
    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

// Custom hook to access ApiContext
const useApi = (): ApiHook => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useApi must be used within an ApiProvider');
    }
    return context;
};

export { ApiProvider, useApi };