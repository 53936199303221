import React, {useState} from 'react';
import {Box, Button, CircularProgress, Divider, Grid, Stack, Typography} from "@mui/material"
import {ModalOpenProps} from "../modal/ModalOpenProps"
import ImportModalOption from "./ImportModalOption"
import ModalBase from "../modal/ModalBase"
import {useDropzone} from "react-dropzone"
import {ImportJobRequests} from "../../api/requests/ImportJobRequests"
import ApiError from "../../api/ApiError"
import {FieldRequests} from "../../api/requests/FieldRequests"
import {downloadArrayBuffer} from "../../library/DownloadHelper"
import {useProjectData} from "../../hooks/useProjectData"
import {useNavigate} from "react-router-dom"
import ErrorMessage from "../error/ErrorMessage"
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import {useToasts} from "../../hooks/useToasts"

function ImportModal({open, setOpen}: ModalOpenProps) {
    const {id, project} = useProjectData()

    const [uploading, setUploading] = useState<boolean>(false)
    const [error, setError] = useState<ApiError>()

    const navigate = useNavigate()
    const toasts = useToasts()

    const handleDownloadCsvTemplate = () => {
        FieldRequests.exportCsvTemplate(id)
            .then(response => downloadArrayBuffer(response, "template.csv"))
    }

    const onDrop = (acceptedFiles: File[]) => {
        if (!acceptedFiles || acceptedFiles.length < 1) {
            toasts.showMessage("No file was accepted", "error")
            return
        }

        if (!project) return

        const file = acceptedFiles[0]

        setUploading(true)
        ImportJobRequests.create(project.id, file)
            .then(response => {
                setOpen(false)
                setError(undefined)
                navigate(`/projects/${id}/import-jobs/${response.importJob.id}`)
            })
            .catch(setError)
            .finally(() => setUploading(false))
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        disabled: uploading,
        maxFiles: 1,
        accept: {"text/csv": [".csv"]},
    })

    return (
        <ModalBase open={open}
                   setOpen={setOpen}
                   title="Import Data"
                   size="large"
                   noContentPadding
                   keepMounted={false}
                   topRightButton={project && project.fields.length > 0 &&
                       <Button size="small" onClick={() => handleDownloadCsvTemplate()}>
                           Download CSV template
                       </Button>
                   }>
            <Grid container>
                <Grid item xs={12} sm={5}>
                    <Stack spacing={2} sx={{p: 2.5}}>
                        <ImportModalOption title="Import CSV"
                                           caption="Upload a Comma Separate Values file from your computer."
                                           selected={true}
                                           onClick={() => {
                                           }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={7} sx={{backgroundColor: isDragActive ? "#f5f5ff" : "#efefef", minHeight: 400}}>
                    <Box {...getRootProps({sx: {height: 1}})}>
                        <input {...getInputProps()} />
                        <Stack alignItems="center"
                               justifyContent="center"
                               spacing={2}
                               sx={{height: 1, pb: 2}}>
                            {uploading && <CircularProgress/>}
                            {!uploading &&
                                <>
                                    {
                                        isDragActive ? <ArrowDownwardOutlinedIcon color="primary"/>
                                            : (
                                                <Stack spacing={2} alignItems="center"
                                                       sx={{
                                                           borderStyle: "dashed",
                                                           borderWidth: "1px",
                                                           borderColor: "#888",
                                                           p: 4,
                                                           cursor: "pointer",
                                                       }}>
                                                    <FileUploadOutlinedIcon color="secondary"/>
                                                    <Typography variant="body1" color="#777">
                                                        Drop a CSV file here or click to browse
                                                    </Typography>
                                                </Stack>
                                            )

                                    }
                                </>
                            }
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            {error &&
                <>
                    <Divider/>
                    <Box sx={{p: 1}}>
                        <ErrorMessage error={error}/>
                    </Box>
                </>
            }
        </ModalBase>
    );
}

export default ImportModal;