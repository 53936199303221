import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import {FinetunePrecheckJson} from "../json/FinetunePrecheckJson";
import {AnyFinetuneOptions} from "../json/FinetuneOptionsJson";
import {PagedFinetuneJson} from "../json/PagedResponseJson";
import {AnyFinetuneJson} from "../json/FinetuneJson";
import {ModelPlatform} from "../enum/ModelPlatform"

export interface FinetuneResponse {
    fileId: string
    finetuneId: string
}

export interface FinetuneNameJson {
    name: string
}

export class FinetuneRequests {
    static create(projectId: string, name: string, options: AnyFinetuneOptions ): Promise<FinetuneResponse> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/fine-tunes`)
            .withJson({
                name: name,
                options: options,
            })
            .buildAndSend()
    }

    static list(projectId: string, templateId?: string, platform?: ModelPlatform, completedOnly?: boolean): Promise<PagedFinetuneJson> {
        const queryParams = new URLSearchParams()
        if (templateId) queryParams.append("templateId", templateId)
        if (platform) queryParams.append("platform", platform)
        if (completedOnly && completedOnly) queryParams.append("completed", "true")

        return new RequestBuilder(Method.Get, `projects/${projectId}/fine-tunes?${queryParams.toString()}`)
            .buildAndSend()
    }

    static get(projectId: string, finetuneId: string): Promise<AnyFinetuneJson> {
        return new RequestBuilder(Method.Get, `projects/${projectId}/fine-tunes/${finetuneId}`)
            .buildAndSend()
    }

    static precheck(projectId: string, name: String, options: AnyFinetuneOptions): Promise<FinetunePrecheckJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/fine-tunes/precheck`)
            .withJson({
                name: name,
                options: options,
            })
            .buildAndSend()
    }

    static delete(projectId: string, finetuneId: string, deleteFromPlatform: boolean): Promise<void> {
        return new RequestBuilder(Method.Delete, `projects/${projectId}/fine-tunes/${finetuneId}`)
            .withJson({ deleteFromPlatform: deleteFromPlatform })
            .buildAndSend()
    }

    static getName(): Promise<FinetuneNameJson> {
        return new RequestBuilder(Method.Get, `fine-tunes/names`).buildAndSend()
    }
}