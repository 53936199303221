import React, {useEffect, useState} from 'react';
import {FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import {ExampleSearchParams, ExampleSortProperty, SortDirection} from "../../api/requests/ExampleRequests";
import {ExampleDataset} from "../../api/enum/ExampleDataset";
import {ProjectJson} from "../../api/json/ProjectJson";
import SearchInput from "../form/SearchInput"
import DatasetFilterIconButton from "../dataset/DatasetFilterIconButton"
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import TemplateIconButtonSelect from "../templates/TemplateIconButtonSelect"
import animations from "../../Animations.module.scss"

interface ExampleFeedControlsProps {
    project: ProjectJson
    template?: AnyTemplateJson
    onSearchParamChange: (feedProps: ExampleSearchParams) => void
    onTemplateChange: (newTemplate: AnyTemplateJson) => void
}

function ExampleFeedControls({project, template, onSearchParamChange, onTemplateChange}: ExampleFeedControlsProps) {
    const [searchValue, setSearchValue] = useState<string>()
    const [searchFieldId, setSearchFieldId] = useState<string>()
    const [dataset, setDataset] = useState<ExampleDataset>()
    const [sortBy, setSortBy] = useState<ExampleSortProperty>(ExampleSortProperty.UPDATED)
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC)

    const buildSearchParams = (): ExampleSearchParams => {
        const params: ExampleSearchParams = {
            dataset: dataset,
            sort: {
                by: sortBy,
                direction: sortDirection,
            },
        }

        if (searchValue) {
            params.search = {value: searchValue}
            if (searchFieldId) {
                params.search.fieldId = searchFieldId
            }
        }
        return params
    }

    useEffect(() => onSearchParamChange(buildSearchParams()), [searchValue, searchFieldId, dataset, sortBy, sortDirection])

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            columnSpacing={4}
            rowSpacing={2}
        >
            <Grid item>
                <Stack direction="row" spacing={2}>
                    <Stack direction="row" spacing={1}>
                        {project.templates.length > 0 &&
                            <TemplateIconButtonSelect value={template} templates={project.templates} onChange={onTemplateChange} />
                        }
                        <DatasetFilterIconButton dataset={dataset} onChange={setDataset}/>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                    <SearchInput onThrottledChange={setSearchValue}
                                 sx={{
                                     flex: 1,
                                     minWidth: 280,
                                 }}
                    />
                        {searchValue &&
                            <FormControl className={animations.defaultIn}>
                                <InputLabel id="search-field-select-label" size="small">Specific Field</InputLabel>
                                <Select
                                    labelId="search-field-select-label"
                                    value={searchFieldId ?? ""}
                                    label="Specific Field"
                                    onChange={(event) => {
                                        const newValue = event.target.value
                                        setSearchFieldId(newValue == "all" ? undefined : newValue)
                                    }}
                                    size="small"
                                    sx={{minWidth: 150}}
                                >
                                    <MenuItem key="all" value="">All Fields</MenuItem>
                                    {project.fields.map(field =>
                                        <MenuItem key={field.id} value={field.id}>{field.name}</MenuItem>,
                                    )}
                                </Select>
                            </FormControl>
                        }
                    </Stack>
                </Stack>
            </Grid>

            <Grid item>
                <Stack direction="row" spacing={1}>
                    <FormControl sx={{width: 120}}>
                        <InputLabel id="sort-by-select-label" size="small">Sort by</InputLabel>
                        <Select
                            labelId="sort-by-select-label"
                            value={sortBy}
                            label="Sort by"
                            size="small"
                            onChange={(event) => setSortBy(event.target.value as ExampleSortProperty)}
                        >
                            <MenuItem value={ExampleSortProperty.UPDATED}>Updated</MenuItem>
                            <MenuItem value={ExampleSortProperty.TOKEN_COUNT}># Tokens</MenuItem>
                        </Select>
                    </FormControl>

                    <IconButton onClick={
                        () => {
                            if (sortDirection === SortDirection.ASC)
                                setSortDirection(SortDirection.DESC)
                            else setSortDirection(SortDirection.ASC)
                        }
                    }>
                        <SwapVertOutlinedIcon/>
                    </IconButton>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default ExampleFeedControls;