import {AnyTemplateJson} from "./TemplateJson"
import {ModelPlatform} from "../enum/ModelPlatform"
import {TemplatedModelJson} from "./TemplatedModelJson"

export type AnyFinetuneJson = FinetuneOpenAiJson | FinetuneAi21Json | FinetuneReplicateJson

export interface FinetuneJson extends TemplatedModelJson {
    trainingExampleCount: number | null
    validationExampleCount: number | null
    numberOfEpochs: number
    started: string | null
    completed: string | null
    failed: string | null
    failedReason: string | null
}

export interface FinetuneMinimalJson {
    id: string
    name: string
    platform: ModelPlatform
    template: AnyTemplateJson
}

export interface FinetuneOpenAiJson extends FinetuneJson {
    platform: ModelPlatform.OPEN_AI
    trainingFileId?: string
    validationFileId?: string
    baseModelName: string
    model?: string
    batchSize: number | null
    learningRateMultiplier: number | null
    promptLossWeight: number | null
}

export interface FinetuneAi21Json extends FinetuneJson {
    platform: ModelPlatform.AI21
    modelName: string
    modelType: string
    datasetId?: string
    learningRate: number
}

export interface FinetuneReplicateJson extends FinetuneJson {
    platform: ModelPlatform.REPLICATE
    modelOwner: string
    modelName: string
    modelVersion: string
    baseModelOwner: string
    baseModelName: string
    baseModelVersion: string
    learningRate: number
    trainBatchSize: number | null
    microBatchSize: number | null
    loraRank: number
    loraAlpha: number
    loraDropout: number
}

export function resolveBaseModelName(finetune: AnyFinetuneJson) {
    switch (finetune.platform) {
        case ModelPlatform.OPEN_AI:
            return finetune.baseModelName
        case ModelPlatform.AI21:
            return finetune.modelType
        case ModelPlatform.REPLICATE:
            return `${finetune.modelOwner}/${finetune.modelName}`
    }
}

export function resolveOpenAiPlaygroundUrl(finetune: FinetuneOpenAiJson) {
    const mode = finetune.baseModelName.includes("gpt-3.5") ? "chat" : "complete"
    return `https://beta.openai.com/playground?mode=${mode}&model=${encodeURIComponent(finetune.model ?? "")}`
}

export function resolveFinetuneExternalUrl(finetune: AnyFinetuneJson) {
    switch (finetune.platform) {
        case ModelPlatform.OPEN_AI:
            if (!finetune.completed) {
                return `https://platform.openai.com/finetune/${finetune.platformId}`
            }
            return resolveOpenAiPlaygroundUrl(finetune)
        case ModelPlatform.AI21:
            return "https://studio.ai21.com/models"
        case ModelPlatform.REPLICATE:
            return `https://replicate.com/p/${finetune.platformId}`
    }
}