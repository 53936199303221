import React, {useEffect, useState} from 'react'
import {Box, Stack, TextField} from "@mui/material"
import {MembershipRole} from "../../../api/enum/MembershipRole";
import {InviteRequests} from "../../../api/requests/InviteRequests";
import {InviteJson} from "../../../api/json/InviteJson";
import AsyncButton from "../../button/AsyncButton";
import RoleSelect from "./RoleSelect";
import {validateEmail} from "../../../library/ValidationHelper";
import ApiError from "../../../api/ApiError"
import ErrorMessage from "../../error/ErrorMessage"

interface SendInviteProps {
    handleSent: (invite: InviteJson) => void
}

function SendInviteForm({handleSent}: SendInviteProps) {
    const [email, setEmail] = useState("")
    const [emailInvalid, setEmailInvalid] = useState(true)
    const [showEmailValidation, setShowEmailValidation] = useState(false)
    const [role, setRole] = useState<MembershipRole>()
    const [error, setError] = useState<ApiError>()
    const [saveInProgress, setSaveInProgress] = useState(false)

    useEffect(() => {
        setEmailInvalid(!validateEmail(email))
    }, [email])

    const handleSend = () => {
        if (!role) return

        setSaveInProgress(true)
        InviteRequests.create(email, role)
            .then(handleSent)
            .catch(setError)
            .finally(() => setSaveInProgress(false))
    }

    return (
        <Stack spacing={2}>
            <TextField label="Email"
                       variant="outlined"
                       autoComplete="invite-email"
                       error={emailInvalid && showEmailValidation}
                       onChange={(e) => setEmail(e.target.value)}
                       onBlur={() => setShowEmailValidation(true)}

            />
            <RoleSelect value={role} setValue={setRole}/>
            <Box alignSelf="flex-end">
                <AsyncButton
                    waiting={saveInProgress}
                    disabled={emailInvalid || !role}
                    onClick={handleSend}
                >
                    Send
                </AsyncButton>
            </Box>
            {error && <ErrorMessage error={error}/>}
        </Stack>
    )
}

export default SendInviteForm