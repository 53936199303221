import React, {useEffect, useState} from 'react'
import AdminHeader from "../AdminHeader"
import {UpgradeCodeJson} from "../../../api/json/UpgradeCodeJson"
import UpgradeCodeForm from "./UpgradeCodeForm"
import modalStyles from "../../modal/Modal.module.scss"
import {Box, Fab, Modal, Typography} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ApiError from "../../../api/ApiError"
import UpgradeCodesTable from "./UpgradeCodesTable"
import {UpgradeCodeRequests} from "../../../api/requests/UpgradeCodeRequests"
import ErrorMessage from "../../error/ErrorMessage"
import SpaceBetween from "../../common/SpaceBetween"

function AdminUpgradeCodesPage() {
    const [upgradeCodes, setUpgradeCodes] = useState<UpgradeCodeJson[]>()
    const [createOpen, setCreateOpen] = useState(false)
    const [error, setError] = useState<ApiError>()

    useEffect(() => loadCodes(), [])

    const loadCodes = () => {
        UpgradeCodeRequests.list()
            .then(codes => {
                setUpgradeCodes(codes)
                setError(undefined)
            })
            .catch(setError)
    }

    const deleteCode = (id: string) => {
        UpgradeCodeRequests.delete(id)
            .then(loadCodes)
            .catch(setError)
    }

    return (
        <>
            <AdminHeader/>

            <SpaceBetween>
                <Typography variant="h1">Upgrade Codes</Typography>
                <Fab color="primary" aria-label="create" onClick={() => setCreateOpen(true)}>
                    <AddIcon/>
                </Fab>
            </SpaceBetween>

            {error && <ErrorMessage error={error}/>}

            <UpgradeCodesTable upgradeCodes={upgradeCodes} handleDelete={deleteCode} sx={{mt: 2}}/>

            <Modal open={createOpen} onClose={() => setCreateOpen(false)}>
                <Box className={modalStyles.default}>
                    <Typography variant="h2">Create Upgrade Code</Typography>
                    <UpgradeCodeForm didCreate={() => {
                        loadCodes()
                        setCreateOpen(false)
                    }} sx={{mt: 2}}/>
                </Box>
            </Modal>
        </>
    );
}

export default AdminUpgradeCodesPage;