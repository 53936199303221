import React, {useState} from 'react';
import {Alert, Stack, TextField} from "@mui/material"
import {UpgradeCodeRequests} from "../../api/requests/UpgradeCodeRequests"
import ApiError from "../../api/ApiError"
import AsyncButton from "../button/AsyncButton"
import {UpgradeCodeRedemptionJson} from "../../api/json/UpgradeCodeRedemptionJson"
import {OrganizationJson} from "../../api/json/OrganizationJson"
import ErrorMessage from "../error/ErrorMessage"

function RedeemUpgradeCodeForm({organization, didRedeem}: { organization: OrganizationJson, didRedeem: () => void }) {
    const [code, setCode] = useState("")
    const [redemption, setRedemption] = useState<UpgradeCodeRedemptionJson | null | undefined>(organization.upgradeCodeRedemption)

    const [redeemInProgress, setRedeemInProgress] = useState(false)
    const [error, setError] = useState<ApiError>()

    const redeem = () => {
        setRedeemInProgress(true)
        UpgradeCodeRequests.redeem(code)
            .then(redemption => {
                setRedemption(redemption)
                setError(undefined)
                didRedeem()
            })
            .catch(setError)
            .finally(() => setRedeemInProgress(false))
    }

    return (
        <Stack spacing={2} sx={{mt: 2}}>
            {!redemption &&
                <Stack direction="row" spacing={1} alignItems="center">
                    <TextField label="Upgrade Code" fullWidth onChange={(e) => setCode(e.target.value)}/>
                    <AsyncButton variant="outlined"
                                 waiting={redeemInProgress}
                                 disabled={code.length === 0}
                                 onClick={() => redeem()}>Submit</AsyncButton>
                </Stack>
            }
            {error && <ErrorMessage error={error}/>}
            {redemption && <Alert severity="success">Account upgraded until {new Date(redemption.validUntil).toLocaleDateString()}</Alert>}
        </Stack>
    );
}

export default RedeemUpgradeCodeForm;