import {ReactNode} from "react";
import {Trans} from "react-i18next";

export class TimeUtil {
    static ago(dateString: string): ReactNode {
        const date = Date.parse(dateString)

        const intervals = [
            { i18nKey: 'timeSince.year', seconds: 31536000 },
            { i18nKey: 'timeSince.month', seconds: 2592000 },
            { i18nKey: 'timeSince.day', seconds: 86400 },
            { i18nKey: 'timeSince.hour', seconds: 3600 },
            { i18nKey: 'timeSince.minute', seconds: 60 },
            { i18nKey: 'timeSince.second', seconds: 1 },
            { i18nKey: 'timeSince.justNow', seconds: 0 }
        ];

        const seconds = Math.floor((Date.now() - date) / 1000)
        const interval = intervals.find(interval => interval.seconds <= seconds)

        if (interval) {
            let numberOfIntervals = seconds / interval.seconds

            // Round for anything longer than an hour, otherwise use floor
            numberOfIntervals = interval.seconds > 3600 ? Math.round(numberOfIntervals) : Math.floor(numberOfIntervals)

            return <Trans i18nKey={interval.i18nKey} values={{ count: numberOfIntervals }}></Trans>
        }

        return null
    }
}