import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, IconButton, Stack, SxProps, Tooltip, Typography} from "@mui/material"
import TemplatedModelSelect from "../../models/TemplatedModelSelect"
import AsyncButton from "../../button/AsyncButton"
import {Link as RouterLink, useSearchParams} from "react-router-dom"
import {AnyFinetuneJson} from "../../../api/json/FinetuneJson"
import {ProjectJson} from "../../../api/json/ProjectJson"
import {FinetuneRequests} from "../../../api/requests/FinetuneRequests"
import {useToasts} from "../../../hooks/useToasts"
import ApiError from "../../../api/ApiError"
import InfoAlert from "../../alert/InfoAlert"
import {CompletionEstimateJson} from "../../../api/json/CompletionEstimateJson"
import {CompletionOptionsJson} from "../../../api/json/CompletionOptionsJson"
import CompletionOptionsForm from "../../completions/CompletionOptionsForm"
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined"
import CostTooltip from "../../common/CostTooltip"
import {CompletionRequestJson} from "../../../api/json/CompletionRequestJson"
import ErrorMessage from "../../error/ErrorMessage"
import {TemplatedModelRequests} from "../../../api/requests/TemplatedModelRequests"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import CompletionOptionsFormModal from "../../completions/CompletionOptionsFormModal"
import {AnyModelJson} from "../../../api/json/TemplatedModelJson"

interface PlaygroundModelSectionProps {
    project: ProjectJson
    selected?: AnyModelJson
    fieldValues: Record<string, string>
    disableCompletions: boolean
    updateSelected: (model: AnyModelJson) => void
    onCompletion: (request: CompletionRequestJson) => void
    sx?: SxProps
}

function PlaygroundModelSection({
                                       project,
                                       selected,
                                       fieldValues,
                                       disableCompletions,
                                       updateSelected,
                                       onCompletion,
                                       sx,
                                   }: PlaygroundModelSectionProps) {
    const [models, setModels] = useState<AnyModelJson[]>()
    const [options, setOptions] = useState<CompletionOptionsJson>()

    const [searchParams, setSearchParams] = useSearchParams()

    const [estimate, setEstimate] = useState<CompletionEstimateJson | undefined | null>()
    const [generating, setGenerating] = useState<boolean>(false)

    const [error, setError] = useState<ApiError>()

    const toasts = useToasts()

    useEffect(() => {
        TemplatedModelRequests.list(project.id, undefined, true)
            .then(page => {
                const list = page.content
                setModels(list)

                const modelId = searchParams.get("modelId")
                const modelFromSearchParams = list.find(it => it.id === modelId)
                if (modelFromSearchParams) {
                    updateSelected(modelFromSearchParams)
                } else if (list.length > 0) {
                    updateSelected(list[0])
                }
            })
            .catch(toasts.showError)
    }, [])

    useEffect(() => {
        if (!selected || selected.id === searchParams.get("modelId")) return

        const newParams = new URLSearchParams()
        newParams.set("modelId", selected.id)
        setSearchParams(newParams)
    }, [selected])

    useEffect(() => getEstimate(), [selected, options, fieldValues])

    const getEstimate = () => {
        if (!selected || !project || !options) return

        TemplatedModelRequests.estimate(selected.id, fieldValues, options)
            .then(setEstimate)
            .catch(toasts.showError)
    }

    const getCompletion = () => {
        if (!selected || !project || !options) return

        setError(undefined)
        setGenerating(true)

        TemplatedModelRequests.complete(selected.id, fieldValues, options)
            .then(onCompletion)
            .catch(setError)
            .finally(() => setGenerating(false))
    }

    if (!models)
        return <CircularProgress sx={{mt: 4}}/>

    return (
        <Stack spacing={2} sx={sx}>
            {models.length > 0 ? <>
                    <Typography variant="subtitle1">Model & Settings</Typography>
                    <TemplatedModelSelect models={models}
                                          selected={selected}
                                          onSelect={updateSelected}
                                          sx={{minWidth: 250}}
                    />

                    {selected &&
                        <CompletionOptionsForm platform={selected.platform} onChange={setOptions}/>
                    }

                    <Stack direction="row" alignItems="center" spacing={1}>
                        <AsyncButton
                            variant="contained"
                            waiting={generating}
                            tooltip={disableCompletions ? "Prompt must not be empty. Write one above or press \"Synthesize\" to create one automatically." : ""}
                            disabled={disableCompletions || generating || !selected}
                            onClick={getCompletion}
                            endIcon={<BoltOutlinedIcon/>}
                        >
                            Generate
                        </AsyncButton>

                        {estimate &&
                            <CostTooltip info={`Estimated cost is $${estimate.cost}.`}/>
                        }
                    </Stack>
                </>
                : (
                    <>
                        {project.stats.numberOfTrainingExamples > 10 ?
                            <InfoAlert actionButton={<Button variant="outlined" component={RouterLink}
                                                             to={`/projects/${project.id}/models`}>Go</Button>}>
                                Create your first model to generate completions.
                            </InfoAlert>
                            : <InfoAlert actionButton={<Button variant="outlined" component={RouterLink}
                                                               to={`/projects/${project.id}/examples`}>Go</Button>}>
                                Create training examples and fine-tune a model.
                            </InfoAlert>
                        }
                    </>
                )
            }
            {error && <ErrorMessage error={error}/>}
        </Stack>
    );
}

export default PlaygroundModelSection;