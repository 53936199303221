import FieldValueJson from "../api/json/FieldValueJson"
import {
    AnyTemplateJson,
    AnyTemplateParts,
    TemplateChatParts,
    TemplateStandardParts,
} from "../api/json/TemplateJson"
import {TemplateType} from "../api/enum/TemplateType"
import {AnyFieldJson} from "../api/json/FieldJson"

export type AnyTemplateDataSource = FieldValueJson[] | Record<string, string>

export default class TemplateUtil {
    static inDoubleBrackets(string: string): string {
        return `{{${string}}}`
    }

    static renderChat(chatProperties: TemplateChatParts, dataSource: AnyTemplateDataSource, allFields?: AnyFieldJson[]): {system: string, user: string, assistant: string} {
        return {
            system: this.renderAny(chatProperties.system, dataSource, allFields),
            user: this.renderAny(chatProperties.user, dataSource, allFields),
            assistant: this.renderAny(chatProperties.assistant, dataSource, allFields),
        }
    }

    static renderStandard(standardProperties: TemplateStandardParts, dataSource: AnyTemplateDataSource, allFields?: AnyFieldJson[]): {prompt: string, completion: string} {
        return {
            prompt: this.renderAny(standardProperties.prompt, dataSource, allFields),
            completion: this.renderAny(standardProperties.completion, dataSource, allFields),
        }
    }

    static renderCompletionEquivalent(template: AnyTemplateJson, dataSource: AnyTemplateDataSource, allFields?: AnyFieldJson[]): string {
        if (template.type === TemplateType.CHAT) {
            return this.renderAny(template.assistant, dataSource, allFields)
        }
        return this.renderAny(template.completion, dataSource, allFields)
    }

    static renderAny = (template: string, dataSource: AnyTemplateDataSource, allFields?: AnyFieldJson[]): string => {
        let defaultValues: Record<string, string> = {}
        allFields?.forEach(field => {
            defaultValues[field.reference] = "" // TODO: Could be a default value on the field
        })

        if (Array.isArray(dataSource) && dataSource.length > 0 && 'field' in dataSource[0]) { // Field Values
            (dataSource as FieldValueJson[]).forEach(fieldValue => {
                defaultValues[fieldValue.field.reference] = fieldValue.value
            })
        } else if (TemplateUtil.isRecordStringString(dataSource)) { // Record
            Object.entries(dataSource).forEach(([key, value]) => {
                defaultValues[key] = value
            })
        } else {
            return "Unsupported data source"
        }

        let output = template
        Object.entries(defaultValues).forEach(([key, value]) => {
            output = output.replaceAll(TemplateUtil.inDoubleBrackets(key), value)
        })
        return output
    }

    private static isRecordStringString(obj: any): obj is Record<string, string> {
        if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
            return false
        }

        for (const [, value] of Object.entries(obj)) {
            if (typeof value !== 'string') {
                return false
            }
        }

        return true
    }

    static findPromptReferences = (properties: AnyTemplateParts): string[] => {
        try {
            switch (properties.type) {
                case TemplateType.CHAT:
                    return [
                        ...TemplateUtil.extractReferencesFromString(properties.system),
                        ...TemplateUtil.extractReferencesFromString(properties.user),
                    ]
                case TemplateType.STANDARD:
                    return TemplateUtil.extractReferencesFromString(properties.prompt)
            }
        } catch(error) {
            console.error(error)
        }
        return []
    }

    static findCompletionReferences = (properties: AnyTemplateParts): string[] => {
        try {
            switch (properties.type) {
                case TemplateType.CHAT:
                    return TemplateUtil.extractReferencesFromString(properties.assistant)
                case TemplateType.STANDARD:
                    return TemplateUtil.extractReferencesFromString(properties.completion)
            }
        } catch(error) {
            console.error(error)
        }
        return []
    }

    static findAllReferences = (properties: AnyTemplateParts): string[] => {
        return [
            ...TemplateUtil.findPromptReferences(properties),
            ...TemplateUtil.findCompletionReferences(properties)
        ]
    }

    static isFieldReferenced = (field: AnyFieldJson, template: AnyTemplateJson): boolean => {
        return TemplateUtil.findAllReferences(template).includes(field.reference)
    }

    static extractReferencesFromString = (template: string): string[] => {
        const regex = /{{(.*?)}}/g;
        let matches = [];
        let match;

        while ((match = regex.exec(template)) !== null) {
            matches.push(match[1]);
        }

        return matches;
    }
}