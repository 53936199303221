import React, {useEffect, useState} from 'react';
import ApiError from "../../api/ApiError"
import {Box, Card, CardContent, CircularProgress, IconButton, Stack, SxProps, Typography} from "@mui/material"
import {TimeUtil} from "../../library/TimeUtil"
import {MarketingLeadJson} from "../../api/json/MarketingLeadJson"
import {MarketingLeadRequests} from "../../api/requests/MarketingLeadRequests"
import SpaceBetween from "../common/SpaceBetween"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useToasts} from "../../hooks/useToasts"
import ErrorMessage from "../error/ErrorMessage"

function AdminMarketingLeadsFeed({ sx }: { sx?: SxProps}) {
    const [leads, setLeads] = useState<MarketingLeadJson[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    let toasts = useToasts()

    useEffect(() => {
        if (loading) return

        setLoading(true)
        MarketingLeadRequests.list()
            .then(response => {
                setError(undefined)
                setLeads(response.content)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [])

    const handleDelete = (id: string) => {
        MarketingLeadRequests.delete(id)
            .then(() => {
                let newLeads = [...leads].filter(it => it.id !== id)
                setLeads(newLeads)
            })
            .catch(toasts.showError)
    }

    if (error)
        return <ErrorMessage error={error} sx={sx}/>

    return (
        <Stack spacing={2} sx={sx}>
            {loading && <CircularProgress/>}
            {leads.map(lead =>
                <Card variant="outlined">
                    <CardContent>
                        <SpaceBetween>
                            <Typography variant="caption">{TimeUtil.ago(lead.created)}</Typography>
                            <IconButton color="secondary" size="small">
                                <DeleteOutlineIcon onClick={() => handleDelete(lead.id)}/>
                            </IconButton>
                        </SpaceBetween>
                        <Box>
                            <Typography fontWeight="bold">Name:</Typography> {lead.name}
                        </Box>
                        <Box>
                            <Typography fontWeight="bold">Email:</Typography> {lead.email}
                        </Box>
                        <Box>
                            <Typography fontWeight="bold">Company:</Typography> {lead.company}
                        </Box>
                        <Box>
                            <Typography fontWeight="bold">Message:</Typography> {lead.message}
                        </Box>
                    </CardContent>
                </Card>

            )}
        </Stack>
    );
}

export default AdminMarketingLeadsFeed;