export const downloadArrayBuffer = (arrayBuffer: ArrayBuffer, filename: string) => {
    const blob = new Blob([arrayBuffer])
    let anchor = document.createElement("a")
    let objectUrl = window.URL.createObjectURL(blob)

    anchor.href = objectUrl;
    anchor.download = filename
    anchor.click()

    window.URL.revokeObjectURL(objectUrl)
}