import React, {ReactNode, useState} from 'react';
import {BillingRequests} from "../../api/requests/BillingRequests"
import AsyncButton from "../button/AsyncButton"
import {useToasts} from "../../hooks/useToasts"
import {RocketLaunch} from "@mui/icons-material"
import {usePlanIntent} from "../../hooks/usePlanIntent"
import SelectPlanModal from "./SelectPlanModal"
import {Box} from "@mui/material"

interface CheckoutButtonProps {
    variant?: "contained" | "outlined" | "text"
    children?: ReactNode | string
}

function CheckoutButton({children, variant}: CheckoutButtonProps) {
    const [waiting, setWaiting] = useState(false)
    const [showPlans, setShowPlans] = useState(false)

    const {plan, clear} = usePlanIntent()
    const toasts = useToasts()

    const handleClick = () => {
        if (plan) {
            createCheckout(plan.name, plan.interval)
        } else {
            setShowPlans(true)
        }
    }

    const createCheckout = (planName: string, planInterval: string) => {
        setWaiting(true)
        BillingRequests.createCheckoutUrl(
            planName,
            planInterval,
            `${window.location.origin}/checkouts/success`,
            `${window.location.origin}/organization`,
        )
            .then((response) => {
                clear()
                window.location.href = response.redirectUrl
            })
            .catch(toasts.showError)
            .finally(() => setWaiting(false))
    }

    return (
        <>
            <Box>
                <AsyncButton variant={variant}
                             onClick={handleClick}
                             waiting={waiting}
                             endIcon={<RocketLaunch/>}
                >
                    {children}
                </AsyncButton>
            </Box>
            <SelectPlanModal open={showPlans}
                             handleClose={() => setShowPlans(false)}
                             didSelect={(plan, interval) => createCheckout(plan.tier, interval)}
            />
        </>
    );
}

export default CheckoutButton;