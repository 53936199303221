import React, {useEffect, useState} from 'react';
import {Stack} from "@mui/material"
import animations from "../../Animations.module.scss"
import {ImportJobJson} from "../../api/json/ImportJobJson"
import {ImportJobRequests} from "../../api/requests/ImportJobRequests"
import ImportProgressCard from "./ImportProgressCard"
import {useProjectData} from "../../hooks/useProjectData"

function ImportProgressOverlay() {
    const {id, project} = useProjectData()

    const [trackedImportJobs, setTrackedImportJobs] = useState<ImportJobJson[]>(project?.activeImportJobs ?? [])
    const load = () => {
        if (!id) return

        ImportJobRequests.list(id, true)
            .then(response => {
                // Replace or add active jobs but do not remove them automatically
                response.content.forEach(returned => {
                    let updated = [...trackedImportJobs]
                    let index = updated.findIndex(existing => existing.id === returned.id)
                    if (index === -1) {
                        updated.push(returned)
                    } else {
                        updated[index] = returned
                    }
                })
                setTrackedImportJobs(response.content)
            })
    }

    useEffect(() => load(), [])

    useEffect(() => {
        const interval = setInterval(() => load(), 10000)
        return () => clearInterval(interval)
    }, [])

    if (trackedImportJobs) {
        return (
            <Stack spacing={2}
                   className={animations.defaultIn}
                   sx={{position: "fixed", bottom: 20, left: 0, width: 1, zIndex: 1000}}
            >
                {trackedImportJobs.map(job =>
                    <ImportProgressCard key={job.id}
                                        projectId={id}
                                        job={job}
                                        handleRemove={(toRemove) => {
                                            const updated = [...trackedImportJobs]
                                            const index = updated.findIndex(it => it.id === toRemove.id)
                                            if (index === -1) return
                                            updated.splice(index, 1)
                                            setTrackedImportJobs(updated)
                                        }}/>,
                )}
            </Stack>
        );
    }

    return <></>

}

export default ImportProgressOverlay;