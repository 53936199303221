import React, {createContext, useContext, useEffect, useState} from 'react';
import {useApi} from "./useApi"
import ApiError from "../api/ApiError"
import {ModelSpecRequests} from "../api/requests/ModelSpecRequests"
import {AnyModelSpecJson} from "../api/json/ModelSpecJson"

export type ModelSpecsHook = {
    models?: AnyModelSpecJson[]
    trainableModels?: AnyModelSpecJson[]
    modelsLoading: boolean
    modelsError?: ApiError
    reloadModels: () => void
};

function useModelSpecData(): ModelSpecsHook {
    const [models, setModels] = useState<AnyModelSpecJson[]>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    const {currentUser, token} = useApi()

    useEffect(() => load(),
        [currentUser]
    )

    const load = () => {
        if (!currentUser || !token) return

        setLoading(true)
        ModelSpecRequests.list()
            .then(setModels)
            .catch(setError)
            .finally(() => setLoading(false))
    }

    return {
        models: models,
        trainableModels: models ? models.filter(model => model.trainable) : undefined,
        modelsLoading: loading,
        modelsError: error,
        reloadModels: load
    };
}

const ModelSpecContext = createContext<ModelSpecsHook | null>(null);

type ApiProviderProps = {
    children: React.ReactNode;
};

const ModelSpecProvider: React.FC<ApiProviderProps> = ({ children }) => {
    const data = useModelSpecData();
    return <ModelSpecContext.Provider value={data}>{children}</ModelSpecContext.Provider>;
};

const useModelSpecs = (): ModelSpecsHook => {
    const context = useContext(ModelSpecContext);
    if (!context) {
        throw new Error('useModelSpecs must be used within a ModelSpecProvider context');
    }
    return context;
};

export { ModelSpecProvider, useModelSpecs };