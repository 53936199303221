import React from 'react';
import {IconButton, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material"
import {UpgradeCodeJson} from "../../../api/json/UpgradeCodeJson"
import {TimeUtil} from "../../../library/TimeUtil"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function AdminProjectsTable({ upgradeCodes, handleDelete, sx }: {upgradeCodes?: UpgradeCodeJson[], handleDelete: (id: string) => void, sx?: SxProps}) {
    return (
        <>
            <Table size="medium" sx={sx}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Code</TableCell>
                        <TableCell># of Users</TableCell>
                        <TableCell># of Examples</TableCell>
                        <TableCell>Upgrade duration</TableCell>
                        <TableCell>Redemptions</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {upgradeCodes?.map(it =>
                        <TableRow key={it.id}>
                            <TableCell>
                                <Typography variant="body1">{it.name}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{TimeUtil.ago(it.created)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{it.code}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{it.numberOfUsers.toLocaleString()}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{it.numberOfExamples.toLocaleString()}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{it.upgradeDuration.toLocaleString()}</Typography>
                                <Typography variant="body1">{it.upgradePeriod}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{it.redemptionsCount} / {it.maxRedemptions}</Typography>
                            </TableCell>
                            <TableCell>
                                {it.redemptionsCount === 0 &&
                                    <IconButton color="error" size="small" onClick={() => handleDelete(it.id)}>
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
}

export default AdminProjectsTable;