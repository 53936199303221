import React, {useEffect, useRef, useState} from 'react'
import {Button, CircularProgress, Grid, Link, Stack, Typography} from "@mui/material"
import {useProjectData} from "../../hooks/useProjectData"
import {useToasts} from "../../hooks/useToasts"
import SynthesisResultCard from "./SynthesisResultCard"
import ProjectPageHeader from "../layouts/ProjectPageHeader"
import {Link as RouterLink} from "react-router-dom"
import {useIntegrations} from "../../hooks/useIntegrations"
import SynthesisSettingsForm from "./SynthesisSettings/SynthesisSettingsForm"
import SynthesisSettingsJson from "../../api/json/SynthesisSettingsJson"
import {SynthesisRequests} from "../../api/requests/SynthesisRequests"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AsyncButton from "../button/AsyncButton"
import SynthesisResultJson from "../../api/json/SynthesisResultJson"
import ApiError from "../../api/ApiError"
import {getDefaultTemplate} from "../../library/ProjectUtil"
import ErrorMessage from "../error/ErrorMessage"

function SynthesisPage() {
    const {id, project, reloadProject} = useProjectData()

    const [settingsModalOpen, setSettingsModalOpen] = useState(false)
    const [synthesisResults, setSynthesisResults] = useState<SynthesisResultJson[]>([])
    const [settings, setSettings] = useState<SynthesisSettingsJson>()

    const [loadingResults, setLoadingResults] = useState<boolean>(false)
    const [synthesisInProgress, setSynthesisInProgress] = useState(false)
    const [clearInProgress, setClearInProgress] = useState(false)
    const [blockingError, setBlockingError] = useState<ApiError>()

    const toasts = useToasts()

    const {openAi} = useIntegrations()

    // Reload the project to make sure we have up-to-date templates and fields
    useEffect(() => reloadProject(), [])

    const synthesisInProgressRef = useRef(synthesisInProgress)
    useEffect(() => {
        synthesisInProgressRef.current = synthesisInProgress
    }, [synthesisInProgress]);

    useEffect(() => {
        setLoadingResults(true)
        SynthesisRequests.getResults(id)
            .then(setSynthesisResults)
            .catch(toasts.showError)
            .finally(() => setLoadingResults(false))
    }, [])

    const handleSynthesis = () => {
        if (!settings || blockingError) return

        const batchSizeOrRemainder = Math.min(settings.numberOfExamples - synthesisResults.length, settings.batchSize)
        const isRemainder = batchSizeOrRemainder <= settings.batchSize
        SynthesisRequests.synthesize(id, {...settings, batchSize: batchSizeOrRemainder})
            .then(newResults => {
                if (!synthesisInProgressRef.current) return
                setSynthesisResults([...synthesisResults, ...newResults])
            })
            .catch(error => {
                if (error.httpCode === 404 || error.httpCode === 413 || error.httpCode === 402) {
                    setSynthesisInProgress(false)
                    setBlockingError(error)
                } else {
                    toasts.showError(error)
                    if (isRemainder) {
                        setSynthesisInProgress(false)
                    }
                }
            })
    }

    const handleClear = () => {
        setClearInProgress(true)
        SynthesisRequests.clearResults(id)
            .catch(toasts.showError)
            .finally(() => {
                setSynthesisResults([])
                setClearInProgress(false)
                toasts.clear()
            })
    }

    useEffect(() => {
        if (!settings) return
        if (!synthesisInProgress) return
        if (synthesisResults.length >= settings.numberOfExamples) {
            setSynthesisInProgress(false)
            return
        }

        handleSynthesis()
    }, [synthesisResults])

    useEffect(() => {
        if (!project || project.templates.length === 0) return

        SynthesisRequests.getSettings(project.id)
            .then(setSettings)
            .catch(toasts.showError)
    }, [project])

    if (!project)
        return <CircularProgress/>

    if (project.stats.numberOfTrainingExamples + project.stats.numberOfValidationExamples < 3 || project.templates.length === 0) {
        return (
            <ProjectPageHeader pageTitle="Synthesis">
                Generate examples once you have 3+ examples and a template.
            </ProjectPageHeader>
        )
    }

    if (!openAi) {
        return (
            <ProjectPageHeader pageTitle="Synthesis">
                Please set up your <Link component={RouterLink} to="/integrations/open-ai">OpenAI integration</Link> to
                use this feature.
            </ProjectPageHeader>
        )
    }

    if (!settings) {
        return <CircularProgress/>
    }

    return (
        <>
            <ProjectPageHeader pageTitle="Synthesis" showFab replaceFab={
                <Grid container spacing={2} justifyContent="end" alignItems="center"
                      sx={{minHeight: 40, maxWidth: 400, alignSelf: "center"}}>
                    <Grid item>
                        <Button startIcon={<SettingsOutlinedIcon/>}
                                onClick={() => setSettingsModalOpen(true)}
                        >
                            Settings
                        </Button>
                    </Grid>
                    {synthesisInProgress &&
                        <Grid item>
                            <Button variant="outlined"
                                    endIcon={<PauseCircleOutlineOutlinedIcon/>}
                                    onClick={() => setSynthesisInProgress(false)}>
                                Pause
                            </Button>
                        </Grid>
                    }
                    {synthesisResults.length > 0 && !synthesisInProgress &&
                        <Grid item>
                            <AsyncButton variant="outlined"
                                         waiting={clearInProgress}
                                         color="error"
                                         endIcon={<CloseOutlinedIcon/>}
                                         onClick={() => handleClear()}
                            >
                                Clear
                            </AsyncButton>
                        </Grid>
                    }
                    {(synthesisResults.length < settings.numberOfExamples && !synthesisInProgress) &&
                        <Grid item>
                            <Button
                                variant="contained"
                                endIcon={<PlayArrowIcon/>}
                                onClick={() => {
                                    setSynthesisInProgress(true)
                                    setSettingsModalOpen(false)
                                    toasts.clear()
                                    handleSynthesis()
                                }
                                }>{synthesisResults.length === 0 ? "Start" : "Resume"}</Button>
                        </Grid>
                    }
                </Grid>
            }>
                Generate new examples for your dataset with AI.
            </ProjectPageHeader>

            {synthesisResults.length > 0 &&
                <Stack spacing={2}>
                    <Typography variant="caption">
                        {synthesisResults.length} of {settings.numberOfExamples} examples
                    </Typography>
                    {synthesisResults.map((result, index) => (
                        <SynthesisResultCard key={index}
                                             project={project}
                                             template={project.templates.find(it => it.id === settings.templateId) ?? getDefaultTemplate(project)!}
                                             result={result}
                                             validationRatio={settings.validationRatio}
                                             autoSave={settings?.autoSave}
                        />
                    ))}
                </Stack>
            }

            {((synthesisResults.length < settings.numberOfExamples && synthesisInProgress) || loadingResults) &&
                <CircularProgress sx={{mt: 4}}/>
            }

            {blockingError && <ErrorMessage error={blockingError} onClose={() => setBlockingError(undefined)}/>}

            <SynthesisSettingsForm
                project={project}
                settings={settings}
                open={settingsModalOpen}
                close={() => setSettingsModalOpen(false)}
                didSave={setSettings}
            />
        </>
    )
}

export default SynthesisPage