import React, {useEffect, useState} from 'react';
import {useProjectData} from "../../hooks/useProjectData";
import {
    Alert, Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import ProjectPageHeader from "../layouts/ProjectPageHeader";
import {ProjectRequests} from "../../api/requests/ProjectRequests";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import AsyncButton from "../button/AsyncButton";
import ConfirmDeleteModal from "../modal/ConfirmDeleteModal";
import {useToasts} from "../../hooks/useToasts"
import useSaveShortcut from "../../hooks/useSaveShortcut"
import DangerActionBox from "../form/DangerActionBox"
import DangerZone from "../form/DangerZone"
import ApiError from "../../api/ApiError"
import {ScoringMethod} from "../../api/enum/ScoringMethod"
import {resolveNameForTokenizerAlgorithm, TokenizerAlgorithm} from "../../api/enum/TokenizerAlgorithm"
import ScoringMethodSelect from "../eval/ScoringMethodSelect"
import EvalSettingsForm from "./EvalSettingsForm"
import ProjectSettingsForm from "./ProjectSettingsForm"
import {ProjectJson} from "../../api/json/ProjectJson"

function SettingsPage() {
    const {id, project, setProject} = useProjectData()

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
    const [deleteError, setDeleteError] = useState<ApiError>()

    const navigate = useNavigate()
    const toasts = useToasts()

    const handleUpdatedProject = (updated: ProjectJson) => {
        setProject(updated)
        toasts.showSaved()
    }

    const handleDelete = () => {
        if (!project) return

        ProjectRequests.delete(project.id)
            .then(() => navigate("/"))
            .catch(setDeleteError)
    }

    if (!project) {
        return <CircularProgress/>
    }

    return (
        <>
            <ProjectPageHeader pageTitle="Settings"/>

            <Grid container spacing={4} justifyContent="space-between">
                <Grid item md={6} sm={12}>
                    <Stack spacing={3}>
                        <ProjectSettingsForm project={project}
                                             didUpdate={handleUpdatedProject}/>

                        <EvalSettingsForm project={project}
                                          didUpdate={handleUpdatedProject}/>
                    </Stack>

                    <DangerZone>
                        <DangerActionBox title="Delete this project"
                                         description="All your examples will be deleted."
                                         buttonLabel="Delete" onAction={() => setDeleteConfirmOpen(true)}
                                         actionPending={deleteConfirmOpen}/>
                    </DangerZone>
                </Grid>
            </Grid>

            <ConfirmDeleteModal heading={`Delete "${project.name}" project`}
                                body="This will permanently delete all data associated with the project immediately and cannot be reversed."
                                open={deleteConfirmOpen}
                                setOpen={setDeleteConfirmOpen}
                                confirmedDelete={handleDelete}
                                error={deleteError}
            />
        </>
    );
}

export default SettingsPage;