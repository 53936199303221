import React from 'react';
import {Paper, Stack, SxProps, Typography} from "@mui/material"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"

interface ImportModalOptionProps {
    title: string
    caption: string
    selected: boolean
    onClick: () => void
}

function ImportModalOption({title, caption, selected, onClick}: ImportModalOptionProps) {
    const selectedSx: SxProps = selected ? {
        borderColor: "#4c49c1",
        backgroundColor: "#f5f5ff"
    } : {}

    return (
        <Paper variant="outlined"
               sx={{p: 2, minHeight: 90, cursor: "pointer", ...selectedSx}}
               onClick={onClick}
        >
            <Stack direction="row" spacing={2} alignItems="center">
                <FileUploadOutlinedIcon color={selected ? "primary" : "disabled"}/>
                <Stack>
                    <Typography variant="subtitle2">{title}</Typography>
                    <Typography variant="caption">{caption}</Typography>
                </Stack>
            </Stack>
        </Paper>
    );
}

export default ImportModalOption;