import React, {useState} from 'react';
import {Button, Paper, Stack, SxProps} from "@mui/material"
import {ExampleOrigin} from "../../api/enum/ExampleOrigin"
import ExampleJson from "../../api/json/ExampleJson"
import {useProjectData} from "../../hooks/useProjectData"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import TemplateUtil from "../../library/TemplateUtil"
import TemplateRendererSection from "../templates/TemplateRendererSection"
import ExampleEditorModal from "../examples/ExampleEditorModal"
import {CompletionResultJson} from "../../api/json/CompletionResultJson"
import {GenerativeResultJson} from "../../api/json/GenerativeResultJson"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import {getDefaultTemplate} from "../../library/ProjectUtil"
import {DisplayFormat} from "../../api/enum/DisplayFormat"

interface CompletionCardProps {
    generativeResult: GenerativeResultJson
    template: AnyTemplateJson
    referenceValues: Record<string, string>
    showPrompt?: boolean
    display?: DisplayFormat
    sx?: SxProps
}

function CompletionCard({generativeResult, template, referenceValues, display, sx}: CompletionCardProps) {
    const [exampleEditorOpen, setExampleEditorOpen] = useState(false)
    const [savedExample, setSavedExample] = useState<ExampleJson | null>(generativeResult.example)

    const {project, reloadProject} = useProjectData()

    let completionText: string
    if (savedExample) {
        completionText = TemplateUtil.renderCompletionEquivalent(template, savedExample.fieldValues, project?.fields)
    } else if ("text" in generativeResult) {
        completionText = (generativeResult as CompletionResultJson).text
    } else {
        completionText = TemplateUtil.renderCompletionEquivalent(template, generativeResult.referenceValues, project?.fields)
    }

    return (
        <>
            <Paper variant="outlined" sx={{...sx, p: 2, whiteSpace: "pre-wrap"}}>
                <Stack spacing={2} alignItems="flex-start">
                    <TemplateRendererSection
                        value={completionText}
                        display={display ?? DisplayFormat.PLAIN_TEXT}
                        dynamicSize={false}
                    />
                    {savedExample ?
                        <Button variant="text"
                                size="small"
                                startIcon={<EditIcon/>}
                                onClick={() => setExampleEditorOpen(true)}
                                sx={{minWidth: 90}}
                        >
                            Edit
                        </Button>
                        : <Button variant="outlined"
                                  size="small"
                                  startIcon={<AddIcon/>}
                                  onClick={() => setExampleEditorOpen(true)}
                                  sx={{minWidth: 90}}
                        >
                            Add
                        </Button>
                    }
                </Stack>
            </Paper>

            <ExampleEditorModal
                key={savedExample?.id}
                open={exampleEditorOpen}
                onClose={() => setExampleEditorOpen(false)}
                project={project!}
                example={savedExample ?? undefined}
                template={getDefaultTemplate(project)!}
                origin={ExampleOrigin.PLAYGROUND}
                generativeResultId={generativeResult.id}
                referenceValues={referenceValues}
                didCreate={(_, example) => {
                    setSavedExample(example)
                    setExampleEditorOpen(false)
                    reloadProject() // To update the example count
                }}
                didUpdate={(example) => {
                    setSavedExample(example)
                    setExampleEditorOpen(false)
                }}
                didDelete={() => {
                    setSavedExample(null)
                    setExampleEditorOpen(false)
                }}
            />
        </>
    );
}

export default CompletionCard;