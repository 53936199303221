import React from 'react';
import {Box, Chip, LinearProgress, Stack, StackProps, SxProps, Tooltip, Typography} from "@mui/material"
import {TimeUtil} from "../../library/TimeUtil"
import {AnyFinetuneJson} from "../../api/json/FinetuneJson"
import {resolveFinetuneStatus} from "../../api/json/FinetuneStatus"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RocketOutlinedIcon from '@mui/icons-material/RocketOutlined';
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined"

interface FinetuneStatusInfoProps {
    finetune: AnyFinetuneJson
    horizontalLayout?: boolean
    size?: "small" | "medium"
    useIcon?: boolean
    showTime?: boolean
    sx?: SxProps
}

function FinetuneStatusInfo({finetune, horizontalLayout, size, showTime, useIcon, sx}: FinetuneStatusInfoProps) {
    const {label, muiColor, time, percentProgress} = resolveFinetuneStatus(finetune)

    const stackProps: StackProps = horizontalLayout
        ? {direction: "row", spacing: 1.5, alignItems: "center"}
        : {direction: "column", spacing: 0.5, alignItems: "flex-start"}

    let icon = <CheckCircleOutlineIcon color="success" />
    if (label === "Failed")
        icon = <ErrorOutlineOutlinedIcon color="error" />
    if (label === "Preparing")
        icon = <RocketOutlinedIcon color="secondary" />
    if (label === "Started")
        icon = <RocketLaunchOutlinedIcon color="secondary" />

    return (
        <Stack {...stackProps} sx={sx}>
            {percentProgress !== undefined && percentProgress > 0 ?
                <LinearProgress variant="determinate" value={percentProgress ?? 50} sx={{width: 80}}/>
                :
                <Box>
                    <Tooltip title={finetune.failedReason ?? (useIcon ? label : "")}>
                        {useIcon ? icon :
                            <Chip variant="outlined" color={muiColor} size={size ?? "small"} label={label}/>
                        }
                    </Tooltip><br/>
                </Box>
            }
            {showTime && <Typography variant="caption">{TimeUtil.ago(time)}</Typography>}
        </Stack>
    );
}

export default FinetuneStatusInfo;