import React from 'react'
import AdminUsersTable from "./AdminUsersTable"
import AdminHeader from "./AdminHeader"

function AdminUsersPage() {
    return (
        <>
            <AdminHeader/>
            <AdminUsersTable />
        </>
    );
}

export default AdminUsersPage;