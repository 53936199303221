import React, {ReactNode} from 'react';
import {Box, Stack, Typography} from "@mui/material"
import {RoleCheck} from "../../../library/RoleCheck"

interface SidebarGroupProps {
    atLeastManager?: boolean
    title: string
    children: ReactNode
}

function SidebarGroup({title, atLeastManager, children}: SidebarGroupProps) {
    if (atLeastManager && !RoleCheck.atLeastManager()) {
        return <></>
    }

    return (
        <Box>
            <Typography component={Box} variant="overline">{title}</Typography>
            <Stack spacing={1.5}>
                {children}
            </Stack>
        </Box>
    );
}

export default SidebarGroup;