import React from 'react'
import BusinessIcon from '@mui/icons-material/Business';
import {useApi} from "../../hooks/useApi"
import BreadcrumbsBase, {Breadcrumb} from "./BreadcrumbsBase"

interface BreadcrumbsOrganizationProps {
    breadcrumbs: Breadcrumb[]
}

function BreadcrumbsOrganization({breadcrumbs}: BreadcrumbsOrganizationProps) {
    const {currentOrganization} = useApi()

    if (!currentOrganization) return <></>

    return (
        <BreadcrumbsBase
            homeBase={currentOrganization.name}
            homeBaseUrl={"/organization"}
            homeBaseIcon={<BusinessIcon sx={{mr: 0.5}} fontSize="inherit"/>}
            breadcrumbs={breadcrumbs}
        />
    )
}

export default BreadcrumbsOrganization