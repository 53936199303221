import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import {AnyFieldJson} from "../json/FieldJson";
import {PredefinedOptionJson} from "../json/PredefinedOptionJson";
import {FieldType} from "../enum/FieldType";
import RequestSender from "../RequestSender"

export class FieldRequests {
    static create(projectId: string, name: string, reference: string, type: FieldType, helperText: string | null, predefinedOptions?: PredefinedOptionJson[], numberOfDecimalPlaces?: number): Promise<AnyFieldJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/fields`)
            .withJson(
                {
                    name: name,
                    reference: reference,
                    type: type,
                    helperText: helperText,
                    predefinedOptions: predefinedOptions,
                    numberOfDecimalPlaces: numberOfDecimalPlaces,
                }
            )
            .buildAndSend()
    }

    static get(projectId: string, fieldId: string): Promise<AnyFieldJson> {
        return new RequestBuilder(Method.Get, `projects/${projectId}/fields/${fieldId}`)
            .buildAndSend()
    }

    static list(projectId: string): Promise<AnyFieldJson[]> {
        return new RequestBuilder(Method.Get, `projects/${projectId}/fields`)
            .buildAndSend()
    }

    static update(projectId: string,
                  fieldId: string,
                  name?: string,
                  reference?: string,
                  order?: number,
                  helperText?: string | null,
                  predefinedOptions?: PredefinedOptionJson[], numberOfDecimalPlaces?: number): Promise<AnyFieldJson> {
        return new RequestBuilder(Method.Put, `projects/${projectId}/fields/${fieldId}`)
            .withJson({
                name: name,
                reference: reference,
                order: order,
                helperText: helperText,
                predefinedOptions: predefinedOptions,
                numberOfDecimalPlaces: numberOfDecimalPlaces,
            })
            .buildAndSend()
    }

    static delete(projectId: string, fieldId: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `projects/${projectId}/fields/${fieldId}`)
            .buildAndSend()
    }

    static deletePredefinedOption(projectId: string, fieldId: string, optionId: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `projects/${projectId}/fields/${fieldId}/predefined-options/${optionId}`)
            .buildAndSend()
    }

    static exportCsvTemplate(id: string): Promise<ArrayBuffer> {
        const request = new RequestBuilder(Method.Get, `projects/${id}/fields/csv`)
            .build()
        return RequestSender.sendRequest(request, true)
    }
}