import {useEffect, useCallback, RefObject} from 'react';

function useEnterShortcut(ref: RefObject<HTMLElement>, callback: () => void): void {
    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (document.activeElement?.tagName === "TEXTAREA") {
                return
            }

            if (ref.current && ref.current.contains(document.activeElement) && event.key === "Enter") {
                event.preventDefault();
                callback();
            }
        },
        [callback],
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
}

export default useEnterShortcut;