import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import EvalJson from "../json/EvalJson"

export class EvalRequests {
    static get(evalId: string): Promise<EvalJson> {
        return new RequestBuilder(Method.Get, `evals/${evalId}`)
            .buildAndSend()
    }

    static restart(evalId: string): Promise<EvalJson> {
        return new RequestBuilder(Method.Post, `evals/${evalId}`)
            .buildAndSend()
    }
}