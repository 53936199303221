import React from 'react';
import {ToggleButton, ToggleButtonGroup} from "@mui/material"
import {TemplateType} from "../../api/enum/TemplateType"

interface TemplateTypeSelectProps {
    value: TemplateType
    onChange: (type: TemplateType) => void
}

function TemplateTypeSelect({ value, onChange}: TemplateTypeSelectProps) {
    return (
        <ToggleButtonGroup color="primary"
                           size="small"
                           value={value}
                           exclusive
                           onChange={(_, newValue) => {
                               if (newValue !== null) onChange(newValue)
                           }}
        >
            <ToggleButton value={TemplateType.CHAT} sx={{pl: 2, pr: 2}}>
                Chat
            </ToggleButton>
            <ToggleButton value={TemplateType.STANDARD} sx={{pl: 2, pr: 2}}>
                Standard
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

export default TemplateTypeSelect;