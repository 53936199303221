import React, {useState} from 'react';
import {Button, Divider, Stack} from "@mui/material"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import ExportButton from "./ExportButton"
import {ProjectJson} from "../../../api/json/ProjectJson"
import ImportModal from "../../import/ImportModal"
import {RoleCheck} from "../../../library/RoleCheck"

function SidebarImportExportButtons({project}: {project: ProjectJson}) {
    const [importModalOpen, setImportModalOpen] = useState(false)

    if (!RoleCheck.atLeastManager()) {
        return <></>
    }

    return (
        <>
            <Divider sx={{mt: 3, mb: 3}}/>

            <Stack spacing={2} sx={{maxWidth: 140, width: 1}}>
                <Button variant={project?.fields.length === 0 ? "contained" : "outlined"}
                        color={project?.fields.length === 0 ? "primary" : "secondary"}
                        startIcon={<FileUploadOutlinedIcon/>}
                        onClick={() => setImportModalOpen(true)}
                >
                    Import
                </Button>

                {project && project.stats.numberOfTrainingExamples > 0 &&
                    <ExportButton project={project} sx={{width: 1}}/>
                }
            </Stack>

            <ImportModal open={importModalOpen} setOpen={setImportModalOpen}/>
        </>
    );
}

export default SidebarImportExportButtons;