import React from 'react';
import {Link, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material"
import {TimeUtil} from "../../library/TimeUtil"
import {ProjectJson} from "../../api/json/ProjectJson"
import {resolveNameForProjectType} from "../../api/enum/ProjectType"
import {Link as RouterLink} from "react-router-dom";
import {resolveNameForTokenizerAlgorithm} from "../../api/enum/TokenizerAlgorithm"

function AdminProjectsTable({ projects, total, sx }: {projects?: ProjectJson[], total?: number, sx?: SxProps}) {
    return (
        <>
            {total && <Typography variant="caption" gutterBottom>{total} projects</Typography>}
            <Table size="small" sx={sx}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell>Default Token Algorithm</TableCell>
                        <TableCell># Training Examples</TableCell>
                        <TableCell># Validation Examples</TableCell>
                        <TableCell># Fine-tunes</TableCell>
                        <TableCell>Fields</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects?.map(project =>
                        <TableRow key={project.id}>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    <Link component={RouterLink} to={`/admin/projects/${project.id}`}>{project.name}</Link>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{TimeUtil.ago(project.updated)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{resolveNameForTokenizerAlgorithm(project.defaultTokenizer)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{project.stats.numberOfTrainingExamples}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{project.stats.numberOfValidationExamples}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">{project.stats.numberOfFinetunes}</Typography>
                            </TableCell>
                            <TableCell>
                                <ul>
                                    {project.fields.map(field =>
                                        <li>
                                            <Typography variant="body1">{field.name}</Typography>
                                        </li>,
                                    )}
                                </ul>
                            </TableCell>
                        </TableRow>,
                    )}
                </TableBody>
            </Table>
            </>
    );
}

export default AdminProjectsTable;