import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Grid, Stack, TextField, Tooltip, Typography} from "@mui/material";
import {useProjectData} from "../../hooks/useProjectData";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import PrecheckPanel from "./PrecheckPanel";
import {FinetuneRequests} from "../../api/requests/FinetuneRequests";
import AsyncButton from "../button/AsyncButton";
import PageHeader from "../layouts/PageHeader";
import FinetuneFormOpenAi from "./FinetuneFormOpenAi";
import PlatformSelect from "./PlatformSelect";
import FinetuneFormAi21 from "./FinetuneFormAi21";
import {AnyFinetuneOptions} from "../../api/json/FinetuneOptionsJson";
import LaunchIcon from "@mui/icons-material/Launch";
import {useIntegrations} from "../../hooks/useIntegrations"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ApiError from "../../api/ApiError"
import {useModelSpecs} from "../../hooks/useModelSpecs"
import {ModelPlatform} from "../../api/enum/ModelPlatform"
import FinetuneReplicateForm from "./FinetuneFormReplicate"
import {
    ModelSpecAi21Json,
    ModelSpecGoogleAiJson,
    ModelSpecOpenAiJson,
    ModelSpecReplicateJson,
} from "../../api/json/ModelSpecJson"
import TemplateSelect from "../templates/TemplateSelect"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import animations from "../../Animations.module.scss"
import TemplateRenderer from "../templates/TemplateRenderer"
import ErrorMessage from "../error/ErrorMessage"
import FinetuneFormGoogleAi from "./FinetuneFormGoogleAi"

function StartFinetunePage() {
    const {project, reloadProject} = useProjectData()

    const [name, setName] = useState<string | null>(null)

    const [platform, setPlatform] = useState<ModelPlatform>()
    const [template, setTemplate] = useState<AnyTemplateJson>()
    const [options, setOptions] = useState<AnyFinetuneOptions>()
    const [blocked, setBlocked] = useState(true)
    const [formValid, setFormValid] = useState(false)

    const [saveInProgress, setSaveInProgress] = useState(false)
    const [saveError, setSaveError] = useState<ApiError>()

    const navigate = useNavigate()

    const {integrationsLoading, integrations} = useIntegrations()

    const {trainableModels} = useModelSpecs()

    // Get the unique platform values from the trainable models array
    let finetunePlatforms = Array.from(new Set(trainableModels?.map(modelSpec => modelSpec.platform)))

    // Filter the fine-tune platforms to ones that are set up as integrations
    finetunePlatforms = finetunePlatforms.filter(platform => integrations?.find(integration => integration.platform === platform))

    const [searchParams] = useSearchParams()

    useEffect(() => {
        const templateId = searchParams.get("templateId")
        if (project && templateId) {
            const template = project.templates.find(t => t.id == templateId)
            if (template) {
                setTemplate(template)
            }
        }
    }, [searchParams])

    useEffect(() => {
        if (name && name.length > 0) return
        FinetuneRequests.getName().then(obj => setName(obj.name))
    }, [])

    useEffect(() => reloadProject(), [])

    useEffect(() => {
        if (!finetunePlatforms || !project || platform || finetunePlatforms.length === 0) return

        updatePlatform(finetunePlatforms[0])
    }, [finetunePlatforms, project])

    const updatePlatform = (newPlatform: ModelPlatform) => {
        if (newPlatform !== platform) {
            setPlatform(newPlatform)
            setOptions(undefined)
            setFormValid(newPlatform !== ModelPlatform.REPLICATE)
        }
    }

    const handleCreateFinetune = () => {
        if (!name || !project || !platform || !options) return

        setSaveInProgress(true)
        FinetuneRequests.create(project.id, name, options)
            .then(() => {
                reloadProject()
                navigate(`/projects/${project.id}/models`)
            })
            .catch(setSaveError)
            .finally(() => setSaveInProgress(false))
    }

    if (!project) {
        return <></>
    }

    if (integrationsLoading || name === null) {
        return <CircularProgress/>
    }

    if (!finetunePlatforms || finetunePlatforms.length === 0 || platform === undefined) {
        return <PageHeader pageTitle="Start a Fine-tune" sx={{pb: 0}}>Set up your first integration in order to
            fine-tune.<Button component={RouterLink} endIcon={<LaunchIcon/>}
                              to="/integrations">Integrations</Button></PageHeader>
    }

    const startDisabled = !platform || platform !== options?.platform || blocked || !formValid || saveInProgress || !name || !template
    let startDisabledReason: string | null = null
    if (!platform) startDisabledReason = "No platform selected"
    else if (platform !== options?.platform) startDisabledReason = "Platform mismatch"
    else if (!template) startDisabledReason = "No template selected"
    else if (!name) startDisabledReason = "Name required"
    else if (blocked) startDisabledReason = "Blocked by precheck"
    else if (!formValid) startDisabledReason = "Please complete the form"

    return (
        <>
            <PageHeader pageTitle="Start a Fine-tune" sx={{pb: 0}}/>

            {!trainableModels && <CircularProgress/>}
            {trainableModels &&
                <Grid container spacing={4} sx={{pt: 2}} className={animations.defaultIn}>
                    <Grid item lg={6} md={6} xs={12}>
                        <Stack spacing={2}>
                            <TextField label="Name"
                                       aria-label="fine-tune name"
                                       autoComplete="fine-tune-name"
                                       value={name}
                                       onChange={e => setName(e.target.value)}
                            />

                            <TemplateSelect templates={project.templates} value={template} onChange={setTemplate}/>

                            {template &&
                                <Box sx={{pl: 2, pr: 2, maxHeight: 320, overflowY: "auto"}}>
                                    <TemplateRenderer templateParts={template} staticSystemPromptTreatment="show"/>
                                </Box>
                            }

                            <Typography variant="subtitle1">Platform & Model</Typography>
                            <PlatformSelect platforms={finetunePlatforms}
                                            value={platform}
                                            onChange={updatePlatform}
                            />

                            {template &&
                                <>
                                    {platform === ModelPlatform.OPEN_AI &&
                                        <FinetuneFormOpenAi
                                            baseModels={trainableModels.filter(it => it.platform === ModelPlatform.OPEN_AI) as ModelSpecOpenAiJson[]}
                                            template={template}
                                            setOptions={setOptions}
                                            setFormValid={setFormValid}
                                        />
                                    }
                                    {platform === ModelPlatform.AI21 &&
                                        <FinetuneFormAi21
                                            baseModels={trainableModels.filter(it => it.platform === ModelPlatform.AI21) as ModelSpecAi21Json[]}
                                            template={template}
                                            setOptions={setOptions}
                                            setFormValid={setFormValid}
                                        />
                                    }
                                    {platform === ModelPlatform.REPLICATE &&
                                        <FinetuneReplicateForm
                                            numberOfTrainingExamples={project.stats.numberOfTrainingExamples}
                                            baseModels={trainableModels.filter(it => it.platform === ModelPlatform.REPLICATE) as ModelSpecReplicateJson[]}
                                            template={template}
                                            setOptions={setOptions}
                                            setFormValid={setFormValid}
                                        />
                                    }
                                    {platform === ModelPlatform.GOOGLE_AI &&
                                        <FinetuneFormGoogleAi
                                            template={template}
                                            baseModels={trainableModels.filter(it => it.platform === ModelPlatform.GOOGLE_AI) as ModelSpecGoogleAiJson[]}
                                            numberOfTrainingExamples={project.stats.numberOfTrainingExamples}
                                            setOptions={setOptions}
                                            setFormValid={setFormValid}
                                        />
                                    }
                                </>
                            }

                            <Tooltip title={startDisabledReason}>
                                <Box sx={{width: 125}}>
                                    <AsyncButton waiting={saveInProgress}
                                                 disabled={startDisabled}
                                                 endIcon={<PlayArrowIcon/>}
                                                 onClick={handleCreateFinetune}
                                    >
                                        Start
                                    </AsyncButton>
                                </Box>
                            </Tooltip>

                            {saveError && <ErrorMessage error={saveError}/>}
                        </Stack>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12}>
                        {options && platform && template &&
                            <PrecheckPanel key={platform}
                                           project={project}
                                           platform={platform}
                                           template={template}
                                           options={options}
                                           setBlocked={setBlocked}
                                           sx={{position: "sticky", top: 90}}
                            />
                        }
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default StartFinetunePage;