export enum TemplateType {
    STANDARD = "STANDARD",
    CHAT = "CHAT",
    TEXT = "TEXT",
}

export function resolveNameForTemplateType(templateType: TemplateType) {
    switch (templateType) {
        case TemplateType.STANDARD:
            return "Standard"
        case TemplateType.CHAT:
            return "Chat"
        case TemplateType.TEXT:
            return "Text"
    }
}