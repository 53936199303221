import React, {useEffect, useState} from 'react';
import {SxProps, TextField} from "@mui/material";

interface NumberInputProps {
    label: string
    ariaLabel?: string
    size?: "small" | "medium"
    helperText?: string
    value?: number | null
    min: number
    max: number
    nullable?: boolean
    disabled?: boolean
    endAdornment?: React.ReactNode
    onChange?: (newValue: number) => void
    onNullableChange?: (newValue: number | null) => void
    onBlur?: (newValue: number) => void
    onNullableBlur?: (newValue: number | null) => void
    fullWidth?: boolean
    sx?: SxProps
}

function NumberInput({ label, ariaLabel, helperText, value, min, max, nullable, disabled, onChange, onNullableChange, onBlur, onNullableBlur, endAdornment, size, fullWidth, sx }: NumberInputProps) {
    const [stringValue, setStringValue] = useState(value != undefined ? value.toString() : "")

    useEffect(() => {
        if (value === 0 && validateNumber(stringValue) === 0) return

        setStringValue(value?.toString() ?? "")
    }, [value])

    const handleChange = (value: string) => {
        setStringValue(value)

        let finalValue = resolveFinalValue(value)

        if (nullable) {
            onNullableChange && onNullableChange(finalValue)
        } else {
            onChange && onChange(finalValue as number)
        }
    }

    const handleBlur = () => {
        let finalValue = resolveFinalValue(stringValue)
        if (finalValue) {
            setStringValue(finalValue.toString())
        }

        if (nullable) {
            onNullableBlur && onNullableBlur(finalValue)
        } else {
            onBlur && onBlur(finalValue as number)
        }
    }

    const resolveFinalValue = (value: string): number | null => {
        let finalValue: number | null
        if (value === "" && nullable) {
            finalValue = null
        } else {
            finalValue = validateNumber(value)
        }

        return finalValue
    }

    const validateNumber = (input: string): number => {
        let numberValue = parseFloat(input)
        if (isNaN(numberValue)) numberValue = 0
        numberValue = Math.max(Math.min(numberValue, max), min)
        return numberValue
    }

    return (
        <TextField
            label={label}
            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
            aria-label={ariaLabel ?? "number-input"}
            size={size}
            autoComplete="number"
            helperText={helperText}
            disabled={disabled}
            value={stringValue}
            fullWidth={fullWidth}
            onChange={(event) => handleChange(event.target.value)}
            InputProps={{
              endAdornment: endAdornment
            }}
            onBlur={() => handleBlur() }
            sx={sx}
        ></TextField>
    );
}

export default NumberInput;