import React, {useEffect, useState} from 'react';
import ApiError from "../../api/ApiError"
import {Alert, CircularProgress, Stack, SxProps} from "@mui/material"
import {AdminRequests} from "../../api/requests/AdminRequests"
import ActivityCard from "../activities/ActivityCard/ActivityCard"
import {AnyActivityJson} from "../../api/json/AnyActivityJson"
import ErrorMessage from "../error/ErrorMessage"

function AdminActivitiesFeed({ sx }: { sx?: SxProps}) {
    const [activities, setActivities] = useState<(AnyActivityJson)[]>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    useEffect(() => {
        if (loading) return

        setLoading(true)
        AdminRequests.listActivities()
            .then((response) => {
                setError(undefined)
                setActivities(response.content)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [])

    if (error)
        return <ErrorMessage error={error} sx={sx}/>

    return (
        <Stack spacing={2} sx={sx}>
            {loading && <CircularProgress/>}
            {activities?.map(activity =>
                <ActivityCard key={activity.id} activity={activity} adminContext/>,
            )}
        </Stack>
    );
}

export default AdminActivitiesFeed;