import React, {useEffect, useState} from 'react';
import {Box, Chip, LinearProgress, Paper, Stack, Tooltip, Typography} from "@mui/material"
import {ProjectJson} from "../../api/json/ProjectJson"
import AsyncButton from "../button/AsyncButton"
import TransformJobJson from "../../api/json/TransformJobJson"
import {useToasts} from "../../hooks/useToasts"
import {TransformJobRequests} from "../../api/requests/TransformJobRequests"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import RocketOutlinedIcon from "@mui/icons-material/RocketOutlined"
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import styles from "../../Card.module.scss"
import animations from "../../Animations.module.scss"
import ApiError from "../../api/ApiError"
import ErrorMessage from "../error/ErrorMessage"

interface TransformJobCardProps {
    project: ProjectJson
    job: TransformJobJson
    didCancel: (canceled: TransformJobJson) => void
    didReload: (job: TransformJobJson) => void
}

function TransformJobCard({project, job, didCancel, didReload}: TransformJobCardProps) {
    const [canceling, setCanceling] = useState(false)

    const [error, setError] = useState<ApiError>()
    const toasts = useToasts()

    const reload = () => {
        if (job.completed || job.canceled)
            return

        TransformJobRequests.get(project.id, job.id)
            .then(updated => {
                setError(undefined)
                didReload(updated)
            })
            .catch(setError)
    }

    const handleCancel = () => {
        setCanceling(true)
        TransformJobRequests.cancel(project.id, job.id)
            .then(didCancel)
            .catch(toasts.showError)
            .finally(() => setCanceling(false))
    }

    useEffect(() => {
        const interval = setInterval(() => reload(), 3000)
        return () => clearInterval(interval)
    }, [job])

    return (
        <Stack spacing={2} alignItems="flex-start" sx={{p: 2}} className={`${styles.cardContainer} ${animations.defaultIn}`}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" width="100%">
                {!job.started &&
                    <Tooltip title="Preparing">
                        <RocketOutlinedIcon color="secondary"/>
                    </Tooltip>
                }
                {job.completed &&
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Tooltip title={`${job.succeededCount} succeeded`}>
                            <CheckCircleOutlineIcon color="success"/>
                        </Tooltip>
                        <Typography variant="body2">Completed</Typography>
                    </Stack>
                }
                {job.canceled &&
                    <Tooltip title="Canceled">
                        <DoNotDisturbAltOutlinedIcon color="error"/>
                    </Tooltip>
                }
                {job.totalTasks && job.active &&
                    <Tooltip title={`${job.succeededCount + job.failedCount} of ${job.totalTasks}`}>
                        <LinearProgress variant="determinate"
                                        value={(job.succeededCount + job.failedCount) / job.totalTasks * 100}
                                        sx={{flex:"1", width: "100%"}}
                        />
                    </Tooltip>
                }

                {job.failedCount > 0 &&
                    <Chip color="error" variant="outlined" size="small" label={`${job.failedCount} failed`}/>
                }
            </Stack>

            <Stack component={Paper}>
                <Typography variant="caption">Destination Field</Typography>
                <Typography variant="body1">{job.destination.name}</Typography>
            </Stack>

            {job.active &&
                <AsyncButton waiting={canceling} variant="outlined" color="error"
                             onClick={() => handleCancel()}>Cancel</AsyncButton>
            }

            <ErrorMessage error={error}/>
        </Stack>
    );
}

export default TransformJobCard;