import React, {useEffect, useState} from 'react';
import {Stack, TextField, Typography} from "@mui/material"
import {TemplateStandardParts} from "../../api/json/TemplateJson"
import {AnyFieldJson} from "../../api/json/FieldJson"
import TemplateUtil from "../../library/TemplateUtil"
import TemplateRenderer from "./TemplateRenderer"
import FieldReferenceTextField from "./FieldReferenceTextField"
import {TemplateType} from "../../api/enum/TemplateType"

interface TemplateEditorStandardProps {
    fields: AnyFieldJson[]
    templateParts: TemplateStandardParts
    readonly?: boolean
    onChange: (properties: TemplateStandardParts) => void
}

function TemplateEditorStandard({fields, templateParts, readonly, onChange}: TemplateEditorStandardProps) {
    const [prompt, setPrompt] = useState<string>(templateParts.prompt)
    const [completion, setCompletion] = useState<string>(templateParts.completion)
    const [activeSection, setActiveSection] = useState<"prompt" | "completion" | null>(null)

    const [separator, setSeparator] = useState<string>(templateParts.separator)
    const [stopSequence, setStopSequence] = useState<string>(templateParts.stopSequence)

    const currentProperties: TemplateStandardParts = {
        type: TemplateType.STANDARD,
        prompt: prompt,
        completion: completion,
        separator: separator,
        stopSequence: stopSequence,
    }

    useEffect(() => {
        onChange(currentProperties)
    }, [prompt, completion, separator, stopSequence])

    const usedReferences = TemplateUtil.findAllReferences(currentProperties)
    const unreferencedFields = fields.filter(f => usedReferences.indexOf(f.reference) === -1)

    if (readonly) {
        return (
            <TemplateRenderer templateParts={templateParts}
                              showLabels
                              staticSystemPromptTreatment="show"
            />
        )
    }

    return (
        <>
            <Stack spacing={2}>
                <Typography variant="h3" gutterBottom>Prompt</Typography>

                <FieldReferenceTextField
                    label="Prompt"
                    value={prompt}
                    setValue={setPrompt}
                    unreferencedFields={unreferencedFields}
                    showInsertButtons={activeSection === "prompt"}
                    didFocus={() => setActiveSection("prompt")}
                />

                <TextField
                    label="Separator"
                    helperText="Separates each prompt from its completion."
                    value={separator}
                    multiline
                    onChange={e => setSeparator(e.target.value)}
                />

                <Typography variant="h3" gutterBottom>Completion</Typography>

                <FieldReferenceTextField
                    label="Completion"
                    value={completion}
                    setValue={setCompletion}
                    unreferencedFields={unreferencedFields}
                    showInsertButtons={activeSection === "completion"}
                    didFocus={() => setActiveSection("completion")}
                />

                <TextField
                    label="Stop Sequence"
                    helperText="Signal to stop generating."
                    value={stopSequence}
                    multiline
                    onChange={e => setStopSequence(e.target.value)}
                />
            </Stack>
        </>
    );
}

export default TemplateEditorStandard;