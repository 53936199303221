import React, {useState} from 'react';
import {AnyModelSpecJson} from "../../api/json/ModelSpecJson"
import PlatformSelect from "../finetunes/PlatformSelect"
import ModelSpecSelect from "./ModelSpecSelect"
import {ModelPlatform} from "../../api/enum/ModelPlatform"
import {useIntegrations} from "../../hooks/useIntegrations"
import {useModelSpecs} from "../../hooks/useModelSpecs"

interface ModelFinderProps {
    value?: AnyModelSpecJson
    instructModelsOnly?: boolean
    onChange: (newModel?: AnyModelSpecJson) => void
}

function ModelFinder({value, onChange, instructModelsOnly}: ModelFinderProps) {
    const {modelPlatforms} = useIntegrations()

    const [platform, setPlatform] = useState<ModelPlatform | undefined>(value?.platform)

    const {models} = useModelSpecs()

    if (!models) return <></>

    const filteredModels = instructModelsOnly ? models.filter(it => it.instructTuned) : models
    const availableModels = filteredModels.filter(it => it.platform === platform && !it.deprecated) ?? []

    return (
        <>
            {modelPlatforms &&
                <PlatformSelect platforms={modelPlatforms}
                                value={platform ?? value?.platform ?? ""}
                                onChange={(newPlatform) => {
                    if (newPlatform !== platform) {
                        onChange(undefined)
                    }
                    setPlatform(newPlatform)
                }
                }/>
            }

            {platform &&
                <ModelSpecSelect label="Model"
                                 models={availableModels}
                                 value={value?.name ?? ""}
                                 onChange={(newModelName) => {
                                     const model = availableModels.find(it => it.platform === platform && it.name === newModelName)
                                     onChange(model)
                                 }}/>
            }
        </>
    );
}

export default ModelFinder;