import React from 'react';
import {FieldType} from "../../api/enum/FieldType";
import {SxProps, TextField, TextFieldProps} from "@mui/material";
import PredefinedOptionSelect from "../fields/PredefinedOptionSelect";
import {AnyFieldJson} from "../../api/json/FieldJson";

export interface FieldValueInputProps {
    field: AnyFieldJson
    value: string
    onChange: (newValue: string) => void
    sx?: SxProps
}

function FieldValueInput({field, value, onChange, sx}: FieldValueInputProps) {
    const validateNumber = () => {
        let numberValue = parseFloat(value)
        if (isNaN(numberValue)) numberValue = 0
        onChange(numberValue.toString())
    }

    const validateUrl = () => {
        if (value.startsWith("https://", 0) || value.startsWith("http://", 0))
            return

        onChange(`https://${value}`)
    }

    const props: TextFieldProps = {
        variant: "outlined",
        label: field.name,
        value: value,
        onChange: (e) => onChange(e.target.value),
        sx: sx,
    }

    switch (field.type) {
        case FieldType.NUMBER:
            return (
                <TextField {...props}
                           placeholder="Number"
                           inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                           onBlur={validateNumber}
                />
            )
        case FieldType.URL:
            return (
                <TextField {...props}
                           placeholder="URL"
                           onBlur={validateUrl}
                />
            )
        case FieldType.TEXT:
            return (
                <TextField {...props}
                           placeholder="Text"
                           multiline
                />
            )
        case FieldType.PREDEFINED_OPTIONS:
            return (
                <PredefinedOptionSelect
                    label={field.name}
                    value={value}
                    choices={field.predefinedOptions ?? []}
                    onChange={(newValue) => onChange(newValue)}
                />
            )
    }
}

export default FieldValueInput;