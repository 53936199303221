import React, {useState} from 'react';
import {ScoringMethod} from "../../api/enum/ScoringMethod"
import {ModelPlatform} from "../../api/enum/ModelPlatform"
import {Box, Chip, FormControl, FormHelperText, Stack, TextField, Typography} from "@mui/material"
import ScoringMethodSelect from "../eval/ScoringMethodSelect"
import ModelFinder from "../models/ModelFinder"
import {useModelSpecs} from "../../hooks/useModelSpecs"
import AsyncButton from "../button/AsyncButton"
import {ProjectJson} from "../../api/json/ProjectJson"
import {ProjectRequests} from "../../api/requests/ProjectRequests"
import useSaveShortcut from "../../hooks/useSaveShortcut"
import EvalSettingsJson from "../../api/json/EvalSettingsJson"
import {useToasts} from "../../hooks/useToasts"

interface EvalSettingsFormProps {
    project: ProjectJson
    didUpdate: (project: ProjectJson) => void
}

function EvalSettingsForm({project, didUpdate}: EvalSettingsFormProps) {
    const evalSettings = project.evalSettings
    const criteriaOptions = ["Helpful", "Accurate", "Relevant", "Complete", "Safe"]

    const [scoringMethod, setScoringMethod] = useState<ScoringMethod>(evalSettings.scoringMethod)
    const [platform, setPlatform] = useState<ModelPlatform | null>(evalSettings.platform)
    const [modelName, setModelName] = useState<string | null>(evalSettings.modelName)
    const [criteria, setCriteria] = useState<string[]>(evalSettings.criteria?.split(", ") ?? [])
    const [additionalInstructions, setAdditionalInstructions] = useState<string | null>(evalSettings.additionalInstructions)

    const [saveInProgress, setSaveInProgress] = useState(false)
    const toasts = useToasts()

    const {models} = useModelSpecs()
    const currentModelSpec = models?.find(m => m.platform === platform && m.name === modelName)

    const unsavedChanges = (
        evalSettings.scoringMethod !== scoringMethod
        || evalSettings.platform !== platform
        || evalSettings.modelName !== modelName
        || evalSettings.criteria !== criteria.join(", ")
        || evalSettings.additionalInstructions !== additionalInstructions
    )

    const disableSave = !unsavedChanges || (scoringMethod === ScoringMethod.PREDICTIVE && (platform === null || modelName === null))

    const handleSave = () => {
        if (disableSave) return

        setSaveInProgress(true)
        const newEvalSettings: EvalSettingsJson = {
            scoringMethod: scoringMethod,
            platform: platform,
            modelName: modelName,
            criteria: criteria.join(", "),
            additionalInstructions: additionalInstructions,
        }
        ProjectRequests.update(project.id, undefined, undefined, undefined, newEvalSettings)
            .then(updated => didUpdate(updated))
            .catch(toasts.showError)
            .finally(() => setSaveInProgress(false))
    }
    useSaveShortcut(handleSave)

    return (
        <Stack spacing={2}>
            <Typography variant="h2">Evaluation</Typography>
            <Typography paragraph>Evals run when you apply a template to a model or fine-tune a new model.</Typography>

            <ScoringMethodSelect value={scoringMethod}
                                 onChange={setScoringMethod}
            />

            {scoringMethod === ScoringMethod.PREDICTIVE &&
                <>
                    <ModelFinder instructModelsOnly
                                 value={currentModelSpec}
                                 onChange={model => {
                                     if (model === undefined) return

                                     setPlatform(model.platform)
                                     setModelName(model.name)

                                 }}/>
                    {modelName &&
                        <>
                            <Typography variant="subtitle2">Criteria</Typography>
                            <Box sx={{float: "left"}}>
                                {criteriaOptions.map(value => {
                                        const selected = criteria.includes(value)
                                        return (
                                            <Chip variant={selected ? "outlined" : "filled"}
                                                  color={selected ? "primary" : "default"}
                                                  label={value}
                                                  sx={{
                                                      mr: 0.5,
                                                      border: 1,
                                                      borderColor: selected ? undefined : "transparent",
                                                  }}
                                                  onClick={() => {
                                                      const newCriteria = [...criteria]
                                                      if (selected) {
                                                          const index = newCriteria.indexOf(value)
                                                          newCriteria.splice(index, 1)
                                                      } else {
                                                          newCriteria.push(value)
                                                      }
                                                      setCriteria(newCriteria)
                                                  }}
                                            />
                                        )
                                    },
                                )}
                            </Box>

                            <FormControl>
                                <TextField
                                    type="text"
                                    label="Additional Instructions"
                                    variant="outlined"
                                    value={additionalInstructions}
                                    onChange={(e) => setAdditionalInstructions(e.target.value)}
                                />
                                <FormHelperText>Specific instructions to include in the evaluation prompt
                                    (optional).</FormHelperText>
                            </FormControl>
                        </>
                    }
                </>
            }

            <AsyncButton waiting={saveInProgress}
                         disabled={disableSave}
                         onClick={handleSave}>
                Save
            </AsyncButton>
        </Stack>
    );
}

export default EvalSettingsForm;