import React, {ReactNode, useEffect, useState} from 'react';
import SpaceBetween from "../common/SpaceBetween"
import {Box, IconButton, SxProps, Typography} from "@mui/material"
import ShuffleIcon from "@mui/icons-material/Shuffle"
import InfoAlert from "../alert/InfoAlert"
import ErrorMessage from "../error/ErrorMessage"
import {ExampleRequests} from "../../api/requests/ExampleRequests"
import ExampleJson from "../../api/json/ExampleJson"
import ApiError from "../../api/ApiError"
import {ProjectJson} from "../../api/json/ProjectJson"

interface ExamplePreviewProps {
    project: ProjectJson
    example?: ExampleJson
    children: ReactNode
    handleExample: (example: ExampleJson) => void
    sx?: SxProps
}

function RandomExampleLoader({project, example, children, handleExample, sx}: ExamplePreviewProps) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    const loadExample = () => {
        if (loading) return

        setLoading(true)
        ExampleRequests.getRandom(project.id, 1)
            .then(page => {
                handleExample(page.content[0])
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }
    useEffect(() => loadExample(), [])

    return (
        <Box sx={sx}>
            <SpaceBetween>
                <Typography variant="h2">Preview</Typography>
                <IconButton onClick={loadExample}>
                    <ShuffleIcon/>
                </IconButton>
            </SpaceBetween>

            <Box key={example?.id}>
                <Box sx={{maxHeight: 640, overflowY: "auto", mt: 2}}>
                    {example && children}
                </Box>
                {project.stats.numberOfTrainingExamples + project.stats.numberOfValidationExamples === 0 &&
                    <InfoAlert>Preview will be shown here once there are examples.</InfoAlert>
                }
                {error && <ErrorMessage error={error} sx={{mt: 2}}/>}
            </Box>
        </Box>
    );
}

export default RandomExampleLoader;