import React from 'react';
import {CompletionRequestJson} from "../../api/json/CompletionRequestJson"
import {Avatar, Chip, Stack, Tooltip, Typography} from "@mui/material"
import {TimeUtil} from "../../library/TimeUtil"
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined"
import {useProjectData} from "../../hooks/useProjectData"
import {useNavigate} from "react-router-dom"
import TemperatureChip from "./TemperatureChip"

function CompletionRequestHead({request}: {request: CompletionRequestJson}) {
    const {id} = useProjectData()
    const navigate = useNavigate()

    let modelDetailPathComponent = "completion" in request.model ? "fine-tunes" : "models"
    return (
        <>
            <Typography variant="caption">{TimeUtil.ago(request.created)}</Typography>
            <Stack direction="row" spacing={1} sx={{mt: 1}}>
                {request.user &&
                    <Tooltip title={request.user.email}>
                        <Avatar src={request.user.avatar?.sizes.thumbnail} sx={{width: 32, height: 32}}/>
                    </Tooltip>
                }
                <Chip variant="outlined" label={request.model.name} clickable onClick={() => navigate(`/projects/${id}/${modelDetailPathComponent}/${request.model.id}`)}/>
                <TemperatureChip value={request.temperature}/>
            </Stack>
        </>
    );
}

export default CompletionRequestHead;