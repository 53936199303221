import {ProjectJson} from "../../../api/json/ProjectJson";
import React, {useState} from 'react';
import {FileDownloadOutlined} from "@mui/icons-material";
import AsyncButton from "../../button/AsyncButton";
import ExportModal from "../../modal/ExportModal";
import {SxProps} from "@mui/material"

function ExportButton({project, sx}: { project: ProjectJson, sx?: SxProps }) {
    const [modalOpen, setModalOpen] = useState(false)

    return (
        <>
            <AsyncButton
                id="export-button"
                waiting={modalOpen}
                variant="outlined"
                color="secondary"
                startIcon={<FileDownloadOutlined/>}
                onClick={() => setModalOpen(true)}
                sx={sx}
            >
                Export
            </AsyncButton>
            <ExportModal project={project} open={modalOpen} setOpen={setModalOpen}/>
        </>
    );
}

export default ExportButton;