import React, {useCallback, useEffect, useState} from 'react';
import ProjectPageHeader from "../layouts/ProjectPageHeader"
import {CompletionRequests} from "../../api/requests/CompletionRequests"
import {useProjectData} from "../../hooks/useProjectData"
import ApiError from "../../api/ApiError"
import {Alert, CircularProgress, Stack} from "@mui/material"
import CompletionRequestSection from "./CompletionRequestSection"
import ScrollPaginator from "../misc/ScrollPaginator"
import {PagedCompletionsJson} from "../../api/json/PagedResponseJson"
import ErrorMessage from "../error/ErrorMessage"

function CompletionsPage() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    const [pages, setPages] = useState<PagedCompletionsJson[]>([])

    const {id, project} = useProjectData()

    const loadPage = useCallback((pageNumber: number) => {
        if (loading) return

        setLoading(true)
        CompletionRequests.listByProject(id, pageNumber)
            .then(response => {
                setPages( [...pages, response])
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [pages, loading])

    if (!project) {
        return <>No project</>
    }

    const completionsRequests = pages.flatMap(page => page.content)
    return (
        <>
            <ProjectPageHeader pageTitle="Completions">
                View past completions from the playground and shared fine-tunes.
            </ProjectPageHeader>

            <Stack spacing={2}>
                <ScrollPaginator pages={pages} loadNextPage={(pageNumber) => loadPage(pageNumber)} error={error}>
                    {completionsRequests.map(request => (
                        <CompletionRequestSection key={request.id} request={request}/>
                    ))}
                </ScrollPaginator>
            </Stack>
        </>
    );
}

export default CompletionsPage;