import React, {ReactNode, useState} from 'react';
import {BillingRequests} from "../../api/requests/BillingRequests"
import AsyncButton from "../button/AsyncButton"
import {useToasts} from "../../hooks/useToasts"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {ButtonProps} from "@mui/material"

interface BillingPortalButtonProps extends ButtonProps {
    children?: ReactNode | string
    component?: any
}

function BillingPortalButton({children, component, ...buttonProps}: BillingPortalButtonProps) {
    const [waiting, setWaiting] = useState(false)

    const toasts = useToasts()

    const createBillingPortalUrl = () => {
        setWaiting(true)
        BillingRequests.createBillingPortalUrl(`${window.location.origin}/organization`)
            .then((response) => {
                window.open(response.redirectUrl)
            })
            .catch(toasts.showError)
            .finally(() => setWaiting(false))
    }

    return (
        <AsyncButton onClick={createBillingPortalUrl}
                     waiting={waiting}
                     endIcon={<OpenInNewIcon/>}
                     component={component}
                     {...buttonProps}>
            {children}
        </AsyncButton>
    );
}

export default BillingPortalButton;