import React from 'react'
import WebIcon from "@mui/icons-material/Web"
import BreadcrumbsBase, {Breadcrumb} from "./BreadcrumbsBase"

function BreadcrumbsProject({projectName, breadcrumbs}: { projectName: string, breadcrumbs: Breadcrumb[] }) {
    return <BreadcrumbsBase
        homeBase="Projects"
        homeBaseUrl="/"
        homeBaseIcon={<WebIcon sx={{mr: 0.5}} fontSize="inherit"/>}
        breadcrumbs={[projectName, ...breadcrumbs]}
    />
}

export default BreadcrumbsProject