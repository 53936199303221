import React, {MouseEventHandler, ReactComponentElement, ReactElement, ReactNode} from 'react';
import {Box, Button, ButtonProps, CircularProgress, SxProps, Theme, Tooltip} from "@mui/material";

interface AsyncButtonProps extends ButtonProps {
    children: ReactNode
    waiting: boolean
    tooltip?: string
    component?: any
}

function AsyncButton({children, waiting, tooltip, component, ...buttonProps}: AsyncButtonProps) {
    const {disabled, variant, color, endIcon, ...otherButtonProps} = buttonProps

    return (
        <Tooltip title={tooltip}>
            <Box>
                <Button variant={variant ?? "contained"}
                        color={color ?? "primary"}
                        disabled={waiting || disabled}
                        endIcon={waiting ? <CircularProgress color="inherit" size={18}/> : (endIcon ?? undefined)}
                        component={component}
                        {...otherButtonProps}
                >
                    {children}
                </Button>
            </Box>
        </Tooltip>
    );
}

export default AsyncButton;