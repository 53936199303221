import React, {useEffect, useState} from 'react'
import {TextField} from "@mui/material"
import SecretKeyTextField from "../SecretKeyTextField"
import {OpenAiJson} from "../../../api/json/IntegrationJson"
import {IntegrationSettingsFormProps} from "./IntegrationSettingsFormProps"
import {ModelPlatform} from "../../../api/enum/ModelPlatform"

interface OpenAiSettingsFormProps extends IntegrationSettingsFormProps {
    openAi: OpenAiJson | null
}

function OpenAiSettingsForm({openAi, onChange}: OpenAiSettingsFormProps) {
    const [secretKey, setSecretKey] = useState<string>(openAi?.secretKey ?? "")
    const [isValidSecretKey, setIsValidSecretKey] = useState(true)

    const [organizationId, setOrganizationId] = useState<string | null>(openAi?.organizationId ?? "")
    const [isValidOrganizationId, setIsValidOrganizationId] = useState(true)

    useEffect(() => {
        if (secretKey === undefined) return

        const secretKeyIsValid = (secretKey?.startsWith("sk-") && secretKey.length >= 51) ?? false
        const orgIdIsValid = organizationId?.startsWith("org-") || organizationId === ""

        setIsValidSecretKey(secretKeyIsValid)
        setIsValidOrganizationId(orgIdIsValid)

        const unsavedChanges = !openAi || (secretKey !== "" && secretKey !== openAi.secretKey) || (organizationId !== "" && organizationId !== openAi.organizationId)

        onChange({
            platform: ModelPlatform.OPEN_AI,
            id: openAi?.id ?? null,
            secretKey: secretKey !== "" ? secretKey : null,
            organizationId: organizationId !== "" ? organizationId : null,
        }, secretKeyIsValid && orgIdIsValid, unsavedChanges)
    }, [secretKey, organizationId])

    return (
        <>
            <SecretKeyTextField helperText={"Starts with \"sk-\""}
                                value={secretKey}
                                setValue={setSecretKey}
                                valid={isValidSecretKey}/>
            <TextField label="Organization ID"
                       aria-label="organization id"
                       variant="outlined"
                       autoComplete="organization-id"
                       fullWidth
                       value={organizationId}
                       helperText={"Optional. Starts with \"org-\""}
                       error={!isValidOrganizationId}
                       onChange={(event) => setOrganizationId(event.target.value)}
            />
        </>
    )
}

export default OpenAiSettingsForm