import RequestBuilder from "../RequestBuilder"
import CurrentUserJson from "../json/CurrentUserJson"
import {Method} from "../enum/Method"
import {PagedResponseJson} from "../json/PagedResponseJson";
import {ImageJson} from "../json/ImageJson";
import MembershipJson from "../json/MembershipJson"

export class UserRequests {
    static list(): Promise<PagedResponseJson<MembershipJson>> {
        return new RequestBuilder(Method.Get, `users`)
            .buildAndSend()
    }

    static getCurrentUser(): Promise<CurrentUserJson> {
        return new RequestBuilder(Method.Get, `current-user`)
            .withoutOrganization()
            .buildAndSend()
    }

    static updateUserDetails(firstName: string, lastName: string, marketingOptIn: boolean): Promise<CurrentUserJson> {
        return new RequestBuilder(Method.Put, `current-user`)
            .withJson({
                firstName: firstName,
                lastName: lastName,
                marketingOptIn: marketingOptIn,
            })
            .withoutOrganization()
            .buildAndSend()
    }

    static changePassword(currentPassword: string, newPassword: string): Promise<CurrentUserJson> {
        return new RequestBuilder(Method.Put, `current-user`)
            .withJson({
                currentPassword: currentPassword ?? null,
                newPassword: newPassword ?? null,
            })
            .withoutOrganization()
            .buildAndSend()
    }
    static updateCurrentUser(firstName: string, lastNane: string, currentPassword?: string, newPassword?: string): Promise<CurrentUserJson> {
        return new RequestBuilder(Method.Put, `current-user`)
            .withJson({
                firstName: firstName,
                lastName: lastNane,
                currentPassword: currentPassword ?? null,
                newPassword: newPassword ?? null,
            })
            .withoutOrganization()
            .buildAndSend()
    }

    static updateCurrentUserAvatar(image: File): Promise<ImageJson> {
        const formData = new FormData()
        formData.append("image", image)

        return new RequestBuilder(Method.Post, `current-user/avatar`)
            .withFormData(formData)
            .withoutOrganization()
            .buildAndSend()
    }
}