import React, {useEffect, useState} from 'react'
import SecretKeyTextField from "../SecretKeyTextField"
import {AnthropicJson} from "../../../api/json/IntegrationJson"
import {IntegrationSettingsFormProps} from "./IntegrationSettingsFormProps"
import {ModelPlatform} from "../../../api/enum/ModelPlatform"

interface AnthropicSettingsFormProps extends IntegrationSettingsFormProps {
    anthropic: AnthropicJson | null
}

function AnthropicSettingsForm({ anthropic, onChange}: AnthropicSettingsFormProps) {
    const [secretKey, setSecretKey] = useState<string>(anthropic?.secretKey ?? "")
    const [isValidSecretKey, setIsValidSecretKey] = useState(true)

    useEffect(() => {
        if (secretKey === "") return

        const isValid = secretKey.startsWith("sk-ant-api") && secretKey.length >= 108
        setIsValidSecretKey(isValid)

        const unsavedChanges = !anthropic || (secretKey !== "" && secretKey !== anthropic.secretKey)

        onChange({
            platform: ModelPlatform.ANTHROPIC,
            id: anthropic?.id ?? null,
            secretKey: secretKey,
        }, isValid, unsavedChanges)
    }, [secretKey])

    return (
        <SecretKeyTextField helperText={"Starts with \"sk-ant-api\""}
                            value={secretKey}
                            setValue={setSecretKey}
                            valid={isValidSecretKey}
        />
    )
}

export default AnthropicSettingsForm