import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {DemoDatasetJson} from "../json/DemoDatasetJson"
import {PagedResponseJson} from "../json/PagedResponseJson"

export class DemoDatasetRequests {
    static create(name: string, description: string, validationPercent: number, publicValue: boolean, file: File): Promise<DemoDatasetJson> {
        const formData = new FormData()
        formData.append("csv", file)
        formData.append("name", name)
        formData.append("validationPercent", validationPercent.toFixed(6))
        formData.append("description", description)
        formData.append("public", publicValue ? "true" : "false")

        return new RequestBuilder(Method.Post, `admin/demo-datasets`)
            .withFormData(formData)
            .withoutOrganization()
            .buildAndSend()
    }

    static update(id: string, name?: string | null, description?: string | null, validationPercent?: number | null, publicValue?: boolean | null): Promise<DemoDatasetJson> {
        return new RequestBuilder(Method.Put, `admin/demo-datasets/${id}`)
            .withJson({
                name: name,
                description: description,
                validationPercent: validationPercent,
                public: publicValue,
            })
            .withoutOrganization()
            .buildAndSend()
    }

    static list(page: number = 0): Promise<PagedResponseJson<DemoDatasetJson>> {
        const queryParams = new URLSearchParams()
        queryParams.append("page", page.toString())

        return new RequestBuilder(Method.Get, `admin/demo-datasets?${queryParams.toString()}`)
            .withoutOrganization()
            .buildAndSend()
    }

    static delete(id: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `admin/demo-datasets/${id}`)
            .withoutOrganization()
            .buildAndSend()
    }
}