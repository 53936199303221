import React, {ReactNode} from 'react';
import {Box} from "@mui/material"

function CenteredContent({children}: { children: ReactNode }) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            {children}
        </Box>
    );
}

export default CenteredContent;