import React, {useEffect, useState} from 'react';
import {Alert, Box, Button, CircularProgress, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material"
import {CsvColumnIntentJson} from "../../../api/json/CsvColumnIntentJson"
import CsvImportColumnCard from "./CsvImportColumnCard"
import {ImportJobColumnAnalysisJson} from "../../../api/json/ImportJobColumnAnalysisJson"
import {allFieldTypes, FieldType} from "../../../api/enum/FieldType"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import ReplayIcon from '@mui/icons-material/Replay';
import {useProjectData} from "../../../hooks/useProjectData"

interface ValidationError {
    message: string
    highlight?: FieldType | string
}

interface CsvImportReviewColumnsStepProps {
    columns: ImportJobColumnAnalysisJson[]
    columnIntents: CsvColumnIntentJson[]
    updatedColumnIntents: (intents: CsvColumnIntentJson[]) => void
    finishedReview: () => void
}

function CsvImportReviewColumnsStep({
                                        columns,
                                        columnIntents,
                                        updatedColumnIntents,
                                        finishedReview,
                                    }: CsvImportReviewColumnsStepProps) {
    const {project, reloadProject} = useProjectData()
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])

    useEffect(() => {
        setValidationErrors(
            validateColumnIntents(columnIntents),
        )
    }, [columnIntents])

    const handleFieldChange = (newValue: string | null, name: string, index: number) => {
        let newColumnIntents = [...columnIntents]

        let columnIntent = newColumnIntents[index]
        if (allFieldTypes.includes(newValue as FieldType)) {
            columnIntent.newField = {
                name: name,
                type: newValue as FieldType
            }
            columnIntent.fieldReference = null
        } else {
            columnIntent.newField = null
            columnIntent.fieldReference = newValue
        }

        newColumnIntents[index] = columnIntent
        updatedColumnIntents(newColumnIntents)
        setValidationErrors(validateColumnIntents(newColumnIntents))
    }

    const mappableFields = project?.fields ?? []
    const unmappedFields = project?.fields.filter(field => !columnIntents.map(intent => intent.fieldReference).includes(field.reference))
    const validateColumnIntents = (intents: CsvColumnIntentJson[]): ValidationError[] => {
        const errors = []

        mappableFields.forEach(field => {
                const mappedToFieldCount = intents.filter(i => i.fieldReference === field.reference).length
                if (mappedToFieldCount > 1) {
                    errors.push({
                        message: `Only one column can map to the "${field.name}" field.`,
                        highlight: field.id,
                    })
                }
            },
        )

        const allNewFieldNames = intents.filter(it => it.newField !== null).map(it => it.newField?.name)
        if (new Set(allNewFieldNames).size != allNewFieldNames.length) {
            errors.push({message: "Duplicate new field name found."})
        }

        const allFieldReferences = intents.filter(it => it.newField !== null || it.fieldReference !== null).map(it => it.newField?.name ?? it.fieldReference)
        if (new Set(allFieldReferences).size != allFieldReferences.length) {
            errors.push({message: "Duplicate field reference found."})
        }

        return errors
    }

    const continueButton = (
        <Stack direction="row" spacing={1} alignItems="center">
            {validationErrors.length > 0 &&
                <Tooltip title={validationErrors.map(it => it.message).join("\n")}>
                    <ErrorOutlineIcon color="error"/>
                </Tooltip>
            }
            <Button variant="contained"
                    onClick={() => finishedReview()}
                    disabled={validationErrors.length > 0}
            >
                Continue
            </Button>
        </Stack>
    )

    const header = (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h1" gutterBottom>Review Columns</Typography>
                {continueButton}
            </Stack>
            <Typography variant="body1">Let's map each column to a field.</Typography>
        </Box>
    )

    if (!project) {
        return (
            <>
                {header}
                <CircularProgress />
            </>
        )
    }

    return (
        <>
            {header}

            <Box sx={{mt: 4}}>
                <Grid container spacing={6}>
                    <Grid item xs={12} lg={project.fields.length > 0 ? 9 : 12}>
                        <Stack spacing={{ md: 2, xs: 6}}>
                            {columnIntents.map((intent, index) => {
                                let cardFields = unmappedFields ?? []
                                let currentField = project?.fields.find(field => field.reference === intent.fieldReference)
                                if (currentField) {
                                    cardFields = [currentField, ...cardFields]
                                }
                                return (
                                    <CsvImportColumnCard
                                        key={index}
                                        intent={intent}
                                        sampleValues={columns[index].sampleValues}
                                        fields={cardFields}
                                        error={validationErrors.filter(e => e.highlight === intent.fieldReference ?? intent.newField?.type).length > 0}
                                        onChange={(newTypeOrField, name) => handleFieldChange(newTypeOrField, name, index)}
                                    />
                                )
                            })}
                        </Stack>
                    </Grid>

                    {project.fields.length > 0 &&
                        <Grid item lg={3} md={12}>
                            <Box sx={{position: "sticky", top: "90px"}}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h2">Existing Fields</Typography>
                                    <IconButton color="secondary" size="small" onClick={reloadProject}>
                                        <ReplayIcon/>
                                    </IconButton>
                                </Stack>
                                <Stack spacing={1} sx={{mt: 1}}>
                                    {project.fields.map(field => {
                                        const valid = columnIntents.filter(it => it.fieldReference === field.reference).length === 1
                                        return (
                                            <Tooltip title={!valid && `No column is mapped to ${field.name} yet.`}>
                                                <Alert
                                                    severity={valid ? "success" : "warning"}>
                                                    {field.name}
                                                </Alert>
                                            </Tooltip>
                                        )
                                    })}
                                    <Button href={`/projects/${project.id}/fields`}
                                            target="_blank"
                                            variant="outlined"
                                            color="secondary"
                                            endIcon={<OpenInNewIcon/>}>
                                        Edit Fields
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
            {columnIntents.length > 3 &&
                <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{mt: 3}}>
                    {continueButton}
                </Stack>
            }
        </>
    )
}

export default CsvImportReviewColumnsStep;