import React, {useEffect, useState} from 'react'
import SecretKeyTextField from "../SecretKeyTextField"
import {ReplicateJson} from "../../../api/json/IntegrationJson"
import {IntegrationSettingsFormProps} from "./IntegrationSettingsFormProps"
import {ModelPlatform} from "../../../api/enum/ModelPlatform"

interface ReplicateSettingsFormProps extends IntegrationSettingsFormProps {
    replicate: ReplicateJson | null
}

function Ai21SettingsForm({replicate, onChange}: ReplicateSettingsFormProps) {
    const [secretKey, setSecretKey] = useState<string>(replicate?.secretKey ?? "")
    const [isValidSecretKey, setIsValidSecretKey] = useState(true)

    useEffect(() => {
        if (secretKey === undefined) return

        const secretKeyIsValid = secretKey.startsWith("r8_") && secretKey.length >= 40
        setIsValidSecretKey(secretKeyIsValid)

        const unsavedChanges = !replicate || (secretKey !== "" && secretKey !== replicate.secretKey)

        onChange({
            platform: ModelPlatform.REPLICATE,
            id: replicate?.id ?? null,
            secretKey: secretKey !== "" ? secretKey : null,
        }, secretKeyIsValid, unsavedChanges)
    }, [secretKey])

    return (
        <>
            <SecretKeyTextField
                value={secretKey}
                setValue={setSecretKey}
                valid={isValidSecretKey}
                helperText={"Also called an API token. Starts with \"r8_\""}
            />
        </>
    )
}

export default Ai21SettingsForm