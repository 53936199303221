import React from 'react';
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import {FormControl, InputLabel, MenuItem, Select, Stack, SxProps, Typography} from "@mui/material"
import {resolveNameForTemplateType} from "../../api/enum/TemplateType"

interface TemplateSelectProps {
    templates: AnyTemplateJson[]
    label?: string
    value?: AnyTemplateJson
    onChange: (newValue: AnyTemplateJson) => void
    size?: "small" | "medium"
    sx?: SxProps
    hideDetails?: boolean
}

function TemplateSelect({templates, label, value, onChange, size, sx, hideDetails}: TemplateSelectProps) {
    const finalLabel = label ?? "Template"
    return (
        <FormControl fullWidth size={size ?? "medium"} sx={sx}>
            <InputLabel id="template-label">{finalLabel}</InputLabel>
            <Select
                labelId="template-label"
                value={value?.id ?? ""}
                label={finalLabel}
                size={size ?? "medium"}
                onChange={(e) => {
                    const newValue = templates.find(template => template.id === e.target.value) as AnyTemplateJson
                    onChange(newValue)
                }}
            >
                {templates.map(template => {
                        if (template.id) {
                            return (
                                <MenuItem value={template.id} key={template.id}>
                                    <Stack direction="row" spacing={1} alignItems="baseline">
                                        <Typography variant="body1">{template.name}</Typography>
                                        {!hideDetails &&
                                            <>
                                                <Typography variant="caption">{resolveNameForTemplateType(template.type)}</Typography>
                                            </>
                                        }
                                    </Stack>
                                </MenuItem>
                            )
                        }
                    },
                )}
            </Select>
        </FormControl>
    );
}

export default TemplateSelect;