import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {PagedCompletionsJson} from "../json/PagedResponseJson"

export class CompletionRequests {

    static listByProject(projectId: string, page: number = 0): Promise<PagedCompletionsJson> {
        const queryParams = new URLSearchParams()
        queryParams.append("page", page.toString())

        return new RequestBuilder(Method.Get, `projects/${projectId}/completions?${queryParams.toString()}`)
            .buildAndSend()
    }
}