import React from 'react';
import {TemplatedModelJson} from "../../api/json/TemplatedModelJson"
import {Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import animations from "../../Animations.module.scss"
import {TimeUtil} from "../../library/TimeUtil"
import {resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"
import {Link as RouterLink} from "react-router-dom"
import {ProjectJson} from "../../api/json/ProjectJson"
import TemplatedModelActionsMenu from "./TemplatedModelActionsMenu"
import InlineCode from "../common/InlineCode"
import ModelScoreCell from "./ModelScoreCell"

interface TemplatedModelsTableProps {
    project: ProjectJson
    models: TemplatedModelJson[]
    reload: () => void
}

function TemplatedModelsTable({project, models, reload}: TemplatedModelsTableProps) {
    return (
        <TableContainer component={Paper} className={animations.defaultIn}>
            <Table sx={{minWidth: 650}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell>Platform</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Template</TableCell>
                        <TableCell>Added</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {models.map(model => (
                        <TableRow key={model.id}>
                            <TableCell>
                                <Link component={RouterLink}
                                      to={`/projects/${project.id}/models/${model.id}`}>
                                    {model.name}
                                </Link>
                            </TableCell>
                            <TableCell>
                                <ModelScoreCell project={project} model={model}/>
                            </TableCell>
                            <TableCell>{resolveNameForModelPlatform(model.platform)}</TableCell>
                            <TableCell>
                                <InlineCode>
                                    {model.platformId}
                                </InlineCode>
                            </TableCell>
                            <TableCell>
                                <Link component={RouterLink}
                                      to={`/projects/${project.id}/templates/${model.template.id}`}>
                                    {model.template.name}
                                </Link>
                            </TableCell>
                            <TableCell>{TimeUtil.ago(model.created)}</TableCell>
                            <TableCell>
                                <TemplatedModelActionsMenu model={model} didDelete={() => reload()} didRename={() => reload()}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TemplatedModelsTable;