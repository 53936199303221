import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import {OrganizationWithRoleJson} from "../json/OrganizationWithRoleJson";
import {ImageJson} from "../json/ImageJson";
import {MembershipRole} from "../enum/MembershipRole";
import {OrganizationStatsJson} from "../json/OrganizationStatsJson"

export class OrganizationRequests {
    static create(name: string): Promise<OrganizationWithRoleJson> {
        return new RequestBuilder(Method.Post, `organizations`)
            .withJson({ name: name })
            .withoutOrganization()
            .buildAndSend()
    }

    static update(id: string, name: string): Promise<OrganizationWithRoleJson> {
        return new RequestBuilder(Method.Put, `organizations/${id}`)
            .withJson({ name: name })
            .buildAndSend()
    }

    static replaceLogo(id: string, image: File): Promise<ImageJson> {
        const formData = new FormData()
        formData.append("image", image)

        return new RequestBuilder(Method.Post, `organizations/${id}/logo`)
            .withFormData(formData)
            .withoutOrganization()
            .buildAndSend()
    }

    static get(id: string): Promise<OrganizationWithRoleJson> {
        return new RequestBuilder(Method.Get, `organizations/${id}`)
            .buildAndSend()
    }

    static getStats(id: string): Promise<OrganizationStatsJson> {
        return new RequestBuilder(Method.Get, `organizations/${id}/stats`)
            .buildAndSend()
    }

    static delete(id: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `organizations/${id}`)
            .buildAndSend()
    }

    static removeMembership(organizationId: string, userId: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `organizations/${organizationId}/memberships/${userId}`)
            .buildAndSend()
    }

    static updateMembership(organizationId: string, userId: string, role: MembershipRole): Promise<void> {
        return new RequestBuilder(Method.Put, `organizations/${organizationId}/memberships/${userId}`)
            .withJson({ role: role })
            .buildAndSend()
    }
}