export enum TemplateSyntax {
    CHAT_ML = "CHAT_ML",
    LLAMA_CHAT = "LLAMA_CHAT",
    MISTRAL_CHAT = "MISTRAL_CHAT",
    GEMINI_CHAT = "GEMINI_CHAT",
}

export function resolveNameForSyntax(syntax: TemplateSyntax) {
    switch (syntax) {
        case TemplateSyntax.CHAT_ML:
            return "ChatML"
        case TemplateSyntax.LLAMA_CHAT:
            return "LLaMA Chat"
        case TemplateSyntax.MISTRAL_CHAT:
            return "Mistral Chat"
        case TemplateSyntax.GEMINI_CHAT:
            return "Gemini Chat"
    }
}

export const allTemplateSyntax = [
    TemplateSyntax.CHAT_ML,
    TemplateSyntax.LLAMA_CHAT,
    TemplateSyntax.MISTRAL_CHAT,
    TemplateSyntax.GEMINI_CHAT
]