import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material"
import {useProjectData} from "../../../hooks/useProjectData"
import CompletionCard from "../../completions/CompletionCard"
import PlaygroundModelSection from "./PlaygroundModelSection"
import ProjectPageHeader from "../../layouts/ProjectPageHeader"
import InferencePromptForm from "../../inference/InferencePromptForm"
import SynthesizeButton from "./SynthesizeButton"
import SynthesisResultJson from "../../../api/json/SynthesisResultJson"
import {CompletionRequestJson} from "../../../api/json/CompletionRequestJson"
import SpaceBetween from "../../common/SpaceBetween"
import PlaygroundCompletionRequestCard from "./PlaygroundCompletionRequestCard"
import {AnyModelJson} from "../../../api/json/TemplatedModelJson"
import {DisplayFormat} from "../../../api/enum/DisplayFormat"

function PlaygroundPage() {
    const {project, reloadProject} = useProjectData()

    const [display, setDisplay] = useState<DisplayFormat>(DisplayFormat.PLAIN_TEXT)

    const [templatedModel, setTemplatedModel] = useState<AnyModelJson>()
    const [promptInputs, setPromptInputs] = useState<Record<string, string>>({})

    const [completionRequests, setCompletionRequests] = useState<CompletionRequestJson[]>([])
    const [synthesisResult, setSynthesisResult] = useState<SynthesisResultJson>()

    // Reload the project to make sure we have up-to-date templates and fields
    useEffect(() => reloadProject(), [])

    if (!project) {
        return <CircularProgress/>
    }

    const atLeastOnePromptCharacter = !!Object.values(promptInputs).find(value => value.length > 0)

    const disableSynthesis = !!synthesisResult || completionRequests.length > 0

    const synthesisButton = templatedModel ? (
        <Tooltip title={disableSynthesis ? "Clear completions to enable synthesis." : undefined}>
            <Box>
                <SynthesizeButton project={project}
                                  template={templatedModel.template}
                                  disabled={disableSynthesis}
                                  handleResult={result => {
                                      setPromptInputs(result.referenceValues)
                                      setSynthesisResult(result)
                                  }}
                />
            </Box>
        </Tooltip>
    ) : undefined

    return (
        <>
            <ProjectPageHeader pageTitle="Playground"
                               replaceFab={synthesisButton}/>

            <Grid container spacing={6}>
                <Grid item md={5} sm={12}>
                    <Box sx={{position: "sticky", top: 90}}>

                        <SpaceBetween sx={{minHeight: 35}}>
                            <Typography variant="h2">Prompt</Typography>

                            {Object.keys(promptInputs).length > 0 &&
                                <Button
                                    onClick={() => {
                                        setPromptInputs({})
                                    }}>Clear</Button>
                            }
                        </SpaceBetween>

                        {templatedModel &&
                            <InferencePromptForm fields={project.fields}
                                                 template={templatedModel.template}
                                                 fieldValues={promptInputs}
                                                 updateFieldValues={setPromptInputs}
                                                 sx={{mt: 2}}
                            />
                        }

                        <PlaygroundModelSection
                            project={project}
                            selected={templatedModel}
                            fieldValues={promptInputs}
                            disableCompletions={!atLeastOnePromptCharacter}
                            updateSelected={setTemplatedModel}
                            onCompletion={request => {
                                setCompletionRequests(prevState => [request, ...prevState])
                            }}
                            sx={{mt: 4}}
                        />
                    </Box>
                </Grid>

                <Grid item md={7} sm={12} xs={12}>
                    <SpaceBetween sx={{minHeight: 35}}>
                        <Typography variant="h2">Completions</Typography>
                        {(completionRequests.length > 0 || synthesisResult) &&
                            <Button variant="text"
                                    size="small"
                                    onClick={() => {
                                        setCompletionRequests([])
                                        setSynthesisResult(undefined)
                                    }
                                    }>
                                Clear
                            </Button>
                        }
                    </SpaceBetween>

                    <FormGroup>
                        <FormControlLabel
                            label="Render markdown"
                            control={
                                <Checkbox checked={display === DisplayFormat.MARKDOWN}
                                          onChange={(_, checked) => setDisplay(checked ? DisplayFormat.MARKDOWN : DisplayFormat.PLAIN_TEXT)}/>
                            }
                        />
                    </FormGroup>

                    <Stack spacing={4} sx={{mt: 2}}>
                        {/* TODO: Should the template be tied to the synthesis result?*/}
                        {templatedModel && synthesisResult &&
                            <Box>
                                <Typography variant="caption">Synthesis</Typography>
                                <CompletionCard
                                    key={synthesisResult.id}
                                    template={templatedModel.template}
                                    generativeResult={synthesisResult}
                                    referenceValues={synthesisResult.referenceValues}
                                    sx={{mt: 1}}
                                />
                            </Box>
                        }

                        {completionRequests.map(request => (
                            <PlaygroundCompletionRequestCard key={request.id} request={request} display={display}/>
                        ))}
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default PlaygroundPage