import React, {useEffect, useState} from 'react';
import {Button, Stack, Typography} from "@mui/material"
import {ModelPlatform} from "../../api/enum/ModelPlatform"
import NumberInput from "../form/NumberInput"
import SpaceBetween from "../common/SpaceBetween"
import {CompletionOptionsJson, DEFAULT_COMPLETION_OPTIONS} from "../../api/json/CompletionOptionsJson"

interface CompletionOptionsFormProps {
    platform: ModelPlatform
    value?: CompletionOptionsJson
    defaultMaxTokens?: number | null
    hideNumberOfCompletions?: boolean
    onChange: (options: CompletionOptionsJson) => void
}

function CompletionOptionsForm({ defaultMaxTokens, platform, value, hideNumberOfCompletions, onChange }: CompletionOptionsFormProps) {
    const [showAdvanced, setShowAdvanced] = useState(false)

    const [temperature, setTemperature] = useState(value?.temperature ?? DEFAULT_COMPLETION_OPTIONS.temperature)
    const [topP, setTopP] = useState(value?.topP ?? DEFAULT_COMPLETION_OPTIONS.topP)
    const [presencePenalty, setPresencePenalty] = useState<number | null>(value?.presencePenalty ?? DEFAULT_COMPLETION_OPTIONS.presencePenalty)
    const [frequencyPenalty, setFrequencyPenalty] = useState<number | null>(value?.frequencyPenalty ?? DEFAULT_COMPLETION_OPTIONS.frequencyPenalty)
    const [maxTokens, setMaxTokens] = useState<number | null>(value?.maxTokens ?? defaultMaxTokens ?? DEFAULT_COMPLETION_OPTIONS.maxTokens)
    const [numberOfCompletions, setNumberOfCompletions] = useState(value?.numberOfCompletions ?? DEFAULT_COMPLETION_OPTIONS.numberOfCompletions)

    const platformsThatSupportNumberOfCompletions = [ModelPlatform.OPEN_AI, ModelPlatform.AI21, ModelPlatform.REPLICATE, ModelPlatform.GOOGLE_AI]

    useEffect(() => {
        setTemperature(Math.min(temperature, platform === ModelPlatform.OPEN_AI ? 2 : 1))
    }, [platform])

    useEffect(() => {
        onChange({
            temperature: temperature,
            maxTokens: maxTokens,
            numberOfCompletions: temperature > 0 ? numberOfCompletions : 1,
            topP: topP,
            presencePenalty: presencePenalty,
            frequencyPenalty: frequencyPenalty,
        })
    }, [temperature, topP, presencePenalty, frequencyPenalty, maxTokens, numberOfCompletions])

    return (
        <Stack spacing={2}>
            <Stack direction={{lg: "row", xs: "column"}} spacing={2}>
                <NumberInput
                    label="Temperature"
                    value={temperature}
                    min={0}
                    max={platform === ModelPlatform.OPEN_AI ? 2 : 1}
                    fullWidth
                    onChange={setTemperature}
                />

                <NumberInput
                    label="Max Tokens"
                    value={maxTokens}
                    min={1}
                    max={4096 /*Need to set this on a per-model basis*/}
                    fullWidth
                    nullable
                    onNullableChange={setMaxTokens}
                />

                {!hideNumberOfCompletions && temperature > 0 && platformsThatSupportNumberOfCompletions.includes(platform) &&
                    <NumberInput
                        label="Quantity"
                        value={numberOfCompletions}
                        min={1}
                        max={10}
                        fullWidth
                        onChange={setNumberOfCompletions}
                    />
                }
            </Stack>
            {!showAdvanced ?
                <Button sx={{alignSelf: "flex-start"}} onClick={() => setShowAdvanced(true)}>Show advanced</Button>
            :
                <>
                    <SpaceBetween>
                        <Typography variant="subtitle1">Advanced</Typography>
                        <Button size="small" onClick={() => setShowAdvanced(false)}>Hide</Button>
                    </SpaceBetween>

                    <NumberInput
                        label="Top P"
                        helperText="The top percentiles of highest probability tokens to select from."
                        value={topP}
                        min={0}
                        max={1}
                        onChange={setTopP}
                    />

                    {platform === ModelPlatform.OPEN_AI &&
                        <>
                            <NumberInput
                                label="Presence Penalty"
                                helperText="Value from -2 to 2 to encourage (negative) or penalize (positive) repetition."
                                value={presencePenalty}
                                min={-2}
                                max={2}
                                nullable
                                onNullableChange={setPresencePenalty}
                            />
                            <NumberInput
                                label="Frequency Penalty"
                                helperText="Like presence penalty except relative to the frequency."
                                value={frequencyPenalty}
                                min={-2}
                                max={2}
                                nullable
                                onNullableChange={setFrequencyPenalty}
                            />
                        </>
                    }

                    {platform === ModelPlatform.AI21 &&
                        <>
                            <NumberInput
                                label="Presence Penalty"
                                helperText="Value from 0 to 5. Higher values reduce the chance of tokens reappearing."
                                value={presencePenalty}
                                min={0}
                                max={5}
                                nullable
                                onNullableChange={setPresencePenalty}
                            />
                            <NumberInput
                                label="Frequency Penalty"
                                helperText="Value from 1 to 500. Higher values reduce the chance of tokens reappearing based on their frequency."
                                value={frequencyPenalty}
                                min={1}
                                max={500}
                                nullable
                                onNullableChange={setFrequencyPenalty}
                            />
                        </>
                    }
                </>
            }
        </Stack>
    );
}

export default CompletionOptionsForm;