import React, {useState} from 'react'
import ExampleJson from "../../../api/json/ExampleJson"
import {Box, Paper, Stack, Typography} from "@mui/material"
import {ProjectJson} from "../../../api/json/ProjectJson";
import styles from "./ExampleCard.module.scss";
import {TimeUtil} from "../../../library/TimeUtil";
import {ExampleDataset} from "../../../api/enum/ExampleDataset";
import {ExampleOrigin} from "../../../api/enum/ExampleOrigin"
import animations from "../../../Animations.module.scss"
import TemplateRenderer from "../../templates/TemplateRenderer"
import ExampleEditorModal from "../ExampleEditorModal"
import SpaceBetween from "../../common/SpaceBetween"
import {AnyTemplateJson} from "../../../api/json/TemplateJson"
import {calculateTokenCount} from "../../../library/TokenCountUtil"

interface ExampleCardProps {
    example: ExampleJson
    project: ProjectJson
    template?: AnyTemplateJson
    didUpdate?: (example: ExampleJson) => void
    didDelete?: (example: ExampleJson) => void
}

function ExampleCard({example, project, template, didUpdate, didDelete}: ExampleCardProps) {
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false)

    const tokenCount = template ? calculateTokenCount(example, template, project.defaultTokenizer) : null

    return (
        <>
            <Box
                className={`${styles.cardContainer} ${example.dataset === ExampleDataset.TRAINING ? styles.training : styles.validation} ${animations.defaultIn}`}
                onClick={() => setEditModalOpen(true)}
                sx={{position: "relative", p: 1.8}}>

                <Stack component={Paper} spacing={1}>
                    <Box className={styles.renderedExample}>
                        {template ?
                            <TemplateRenderer dataSource={example.fieldValues}
                                              templateParts={template}
                                              staticSystemPromptTreatment="hide"/>
                            : <>
                                {example.fieldValues.map(fieldValue =>
                                    <Typography paragraph sx={{whiteSpace: "pre-wrap"}}>
                                        {fieldValue.value}
                                    </Typography>,
                                )}
                            </>
                        }
                    </Box>

                    <SpaceBetween>
                        <Typography variant="caption">{TimeUtil.ago(example.updated)}</Typography><br/>
                        {tokenCount && <Typography variant="caption">{tokenCount?.toLocaleString()} tokens</Typography>}
                    </SpaceBetween>
                </Stack>
            </Box>

            {didUpdate && didDelete &&
                <ExampleEditorModal
                    open={editModalOpen}
                    onClose={() => {
                        setEditModalOpen(false)
                    }}
                    project={project}
                    origin={ExampleOrigin.MANUAL}
                    example={example}
                    template={template}
                    didUpdate={example => {
                        setEditModalOpen(false)
                        didUpdate(example)
                    }}
                    didDelete={() => {
                        setEditModalOpen(false)
                        didDelete(example)
                    }}
                />
            }
        </>
    )
}

export default ExampleCard