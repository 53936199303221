import {Shadows, ThemeOptions} from "@mui/material";

export const themeOptions: ThemeOptions = {
    palette: {
        text: {
            primary: "#2d3341",
            secondary: "#666",
            disabled: "#92A5D4",
        },
        primary: {
            main: "#4c49c1",
            contrastText: "#fff",
        },
        secondary: {
            main: "#666",
            contrastText: "#fff",
        },
        info: {
            main: "#ececf1",
            contrastText: "#353740",
        },
        error: {
            main: "#b02323",
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                    textTransform: "unset",
                    fontSize: "0.9rem"
                },
                outlinedPrimary: {
                    backgroundColor: '#fff',
                },
                outlinedSecondary: {
                    backgroundColor: '#fff',
                    borderColor: "#555",
                    color: "#555",
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    }
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: "none"
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'h3',
                    subtitle2: 'h3',
                    body1: 'span',
                    body2: 'span',
                },
            },
        },
        MuiInputBase: {
             styleOverrides: {
                 root: {
                     backgroundColor: "#fff"
                 }
             }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '&.MuiMenu-list': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    borderRadius: "6px",
                    boxShadow: "0px 0.5px 2.5px rgba(0, 0, 0, 0.25)",
                    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
                        borderBottom: 'none',
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    ':first-of-type': {
                        paddingLeft: 24
                    },
                    ' :last-child': {
                        paddingRight: 24
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    backgroundColor: "#fff"
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    border: "1px solid #709772"
                },
                standardError: {
                    border: "1px solid #d6b8b8"
                },
                standardWarning: {
                    border: "1px solid #d6c9b8"
                },
            }
        }
    },
    typography: {
        h1: {
            fontSize: "2.2rem",
            fontWeight: 400,
            color: "#2d3341"
        },
        h2: {
            fontSize: "1.5rem",
            fontWeight: 400,
            color: "#2d3341"
        },
        h3: {
            fontSize: "1.1rem",
            fontWeight: 500,
            color: "#2d3341"
        },
        h4: {
            fontSize: "1.0rem",
            fontWeight: 500,
            color: "#2d3341"
        },
        subtitle1: {
            fontSize: "1.0rem",
            fontWeight: 500,
            color: "#2d3341"
        },
        subtitle2: {
            fontSize: "0.9rem",
            fontWeight: 500,
            color: "#2d3341"
        },
        body1: {
            fontSize: "0.95rem",
        },
        caption: {
            color: "#5d6987",
        }
    },
    shadows: Array(25).fill("none") as Shadows,
}