export enum PlanTier {
    PRO = "PRO",
    GROWTH = "GROWTH",
    STARTER = "STARTER",
}

export function resolveNameForPlanTier(planTier: PlanTier) {
    switch (planTier) {
        case PlanTier.PRO:
            return "Pro"
        case PlanTier.GROWTH:
            return "Growth"
        case PlanTier.STARTER:
            return "Starter"
    }
}
