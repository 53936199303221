import React, {useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react"
import {useNavigate} from "react-router-dom"
import {UserRequests} from "../../api/requests/UserRequests"
import {useToasts} from "../../hooks/useToasts"
import CurrentUserJson from "../../api/json/CurrentUserJson"
import {useApi} from "../../hooks/useApi"

function AuthProvider({redirectPath}: { redirectPath: string }) {
    const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0()
    const {token, setCurrentUser, setToken, clearCurrentOrganization} = useApi()
    const navigate = useNavigate()

    const toasts = useToasts()

    useEffect(() => {
        if (!isAuthenticated) return

        getAccessTokenSilently().then(newToken => {
            if (newToken !== token) {
                setToken(newToken)
                clearCurrentOrganization()
            }

            UserRequests.getCurrentUser()
                .then(currentUser => {
                    setCurrentUser(currentUser)
                    navigate(redirectPath ?? "/organizations/select")
                })
                .catch(toasts.showError)
        }).catch(toasts.showError)
    }, [isAuthenticated, isLoading])

    return (<></>);
}

export default AuthProvider;