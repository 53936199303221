import React, {useState} from 'react';
import {AppState, Auth0Provider} from "@auth0/auth0-react"
import AuthRedirectHandler from "./AuthRedirectHandler"

function AuthProvider({children}: { children: React.ReactNode }) {
    const [redirectPath, setRedirectPath] = useState<string>()

    const handleCallback = (appState?: AppState) => {
        setRedirectPath(appState?.returnTo)
    }

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN!}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
            authorizationParams={{
                redirect_uri: `${window.location.origin}/auth0-callback`,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            }}
            onRedirectCallback={appState => handleCallback(appState)}
        >
            {redirectPath && <AuthRedirectHandler redirectPath={redirectPath}/>}
            {children}
        </Auth0Provider>
    );
}

export default AuthProvider;