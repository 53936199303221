import React, {useEffect, useState} from 'react';
import ProjectPageHeader from "../layouts/ProjectPageHeader";
import {AnyFinetuneJson, resolveBaseModelName} from "../../api/json/FinetuneJson";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {FinetuneRequests} from "../../api/requests/FinetuneRequests";
import {useProjectData} from "../../hooks/useProjectData";
import {Button, Card, CardContent, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import ApiError from "../../api/ApiError"
import EvalSection from "../eval/EvalSection"
import TemplatedModelShareSettings from "../models/TemplatedModelShareSettings"
import FinetuneDetailGridItemsOpenAi from "./FinetuneDetailGridItemsOpenAi"
import FinetuneDetailGridItemsAi21 from "./FinetuneDetailGridItemsAi21"
import FinetuneStatusInfo from "./FinetuneStatusInfo"
import GridItem from "../models/GridItem"
import {ModelPlatform, resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"
import FinetuneDetailGridItemsReplicate from "./FinetuneDetailGridItemsReplicate"
import TemplatedModelActionsMenu from "../models/TemplatedModelActionsMenu"
import SpaceBetween from "../common/SpaceBetween"
import ErrorMessage from "../error/ErrorMessage"
import TemplateRenderer from "../templates/TemplateRenderer"
import ModelPlaygroundButton from "../models/ModelPlaygroundButton"

function FinetuneDetailPage() {
    const {id, project} = useProjectData()
    const {finetuneId} = useParams()

    const [finetune, setFinetune] = useState<AnyFinetuneJson>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    const navigate = useNavigate()

    useEffect(() => load(), [project])

    const load = () => {
        if (!finetuneId) return

        setLoading(true)
        FinetuneRequests.get(id, finetuneId)
            .then(setFinetune)
            .catch(setError)
            .finally(() => setLoading(false))
    }

    if (error) {
        return <ErrorMessage error={error}/>
    }

    if (loading || !project) {
        return <CircularProgress/>
    }

    if (!finetune) return <>Unexpected behavior</>

    const createdDate = new Date(finetune.created).toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    let trainingTimeLabel = null
    if (finetune.completed) {
        const trainingMinutes = Math.round((Date.parse(finetune.completed!) - Date.parse(finetune.created)) / (1000 * 60))
        trainingTimeLabel = `${trainingMinutes} minutes`
        if (trainingMinutes >= 90) {
            trainingTimeLabel = `${Math.round(trainingMinutes / (60 * 2)) * 2} hours`
        }
    }

    return (
        <>
            <ProjectPageHeader
                pageTitle={finetune.name}
                breadcrumbs={[
                    {
                        label: "Fine-tunes",
                        url: `/projects/${id}/models`,
                    },
                    finetune.name,
                ]}
                replaceFab={finetune.completed && <ModelPlaygroundButton projectId={id} modelId={finetune.id} sx={{justifySelf: "center"}}/>}
                nextToTitle={
                    <TemplatedModelActionsMenu model={finetune}
                                               didRename={() => load()}
                                               didDelete={() => navigate(`/projects/${project.id}/models`)}
                    />
                }
            />

            <Grid spacing={2} container>
                <Grid item lg={6} sm={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Typography variant="h2">Details</Typography>
                                <FinetuneStatusInfo finetune={finetune} horizontalLayout showTime size="small" />
                            </Stack>
                            <Grid container spacing={1} sx={{mt:1}}>
                                {(finetune.completed || finetune.failed) &&
                                    <>
                                        <GridItem label="Platform" value={resolveNameForModelPlatform(finetune.platform)}/>
                                        <GridItem label="Created" value={createdDate}/>
                                        <GridItem label="# of training examples"
                                                  value={finetune.trainingExampleCount?.toLocaleString() ?? ""}/>
                                        <GridItem label="Training time" value={trainingTimeLabel}/>
                                    </>
                                }

                                <GridItem label="Base model" value={resolveBaseModelName(finetune)} codeValue/>

                                {finetune.platform === ModelPlatform.AI21 &&
                                    <FinetuneDetailGridItemsAi21 finetune={finetune}/>
                                }
                                {finetune.platform === ModelPlatform.OPEN_AI &&
                                    <FinetuneDetailGridItemsOpenAi finetune={finetune}/>
                                }
                                {finetune.platform === ModelPlatform.REPLICATE &&
                                    <FinetuneDetailGridItemsReplicate finetune={finetune}/>
                                }
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card variant="outlined" sx={{mt: 3}}>
                        <CardContent>
                            <Typography variant="h2" gutterBottom>Hyperparams</Typography>
                            <Grid container spacing={1}>
                                <GridItem label="# of epochs" value={finetune.numberOfEpochs}/>

                                {finetune.platform === ModelPlatform.OPEN_AI &&
                                    <>
                                        {finetune.promptLossWeight &&
                                            <GridItem label="Prompt loss weight" value={finetune.promptLossWeight}/>
                                        }
                                        {finetune.learningRateMultiplier &&
                                            <GridItem label="Learning Rate Multiplier" value={finetune.learningRateMultiplier}/>
                                        }
                                        {finetune.batchSize &&
                                            <GridItem label="Batch Size" value={finetune.batchSize}/>
                                        }
                                    </>
                                }
                                {finetune.platform === ModelPlatform.AI21 &&
                                    <>
                                        {finetune.learningRate &&
                                            <GridItem label="Learning rate" value={finetune.learningRate} />
                                        }
                                    </>
                                }
                                {finetune.platform === ModelPlatform.REPLICATE &&
                                    <>
                                        <GridItem label="Learning rate" value={finetune.learningRate}/>
                                        {finetune.trainBatchSize && <GridItem label="Train batch size" value={finetune.trainBatchSize}/>}
                                        {finetune.microBatchSize && <GridItem label="Micro batch size" value={finetune.microBatchSize}/>}
                                        <GridItem label="LoRA Rank" value={finetune.loraRank}/>
                                        <GridItem label="LoRA Alpha" value={finetune.loraAlpha}/>
                                        <GridItem label="LoRA Dropout" value={finetune.loraDropout}/>
                                    </>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={6} sm={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Stack spacing={1}>
                                <SpaceBetween>
                                    <Typography variant="h2" gutterBottom>Template</Typography>
                                    <Button component={RouterLink} to={`/projects/${project.id}/templates/${finetune.template.id}`}>{finetune.template.name}</Button>
                                </SpaceBetween>
                                {/*Add option to show separator & stop sequence to template renderer?*/}
                                <TemplateRenderer templateParts={finetune.template} showLabels staticSystemPromptTreatment="show"/>
                            </Stack>
                        </CardContent>
                    </Card>

                    {finetune.completed &&
                        <Card variant="outlined" sx={{mt: 3}}>
                            <CardContent>
                                <TemplatedModelShareSettings model={finetune}
                                                             updateModel={model => setFinetune(model as AnyFinetuneJson)}/>
                            </CardContent>
                        </Card>
                    }
                </Grid>
            </Grid>

            {finetune.completed && project.stats.numberOfValidationExamples > 0 &&
                <EvalSection
                    project={project}
                    model={finetune}
                    updateEval={newEval => {
                        const newFinetune = {...finetune}
                        newFinetune.eval = newEval
                        setFinetune(newFinetune)
                    }}
                />
            }
        </>
    );
}

export default FinetuneDetailPage;