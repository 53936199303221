import React, {useEffect, useState} from 'react';
import {AnyFieldJson} from "../../api/json/FieldJson"
import {DragDropContext, Draggable, Droppable, DropResult} from "@hello-pangea/dnd"
import {Box, Stack, SxProps} from "@mui/material"
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

function FieldOrderList({fields, startsAt, onChange, sx}: { fields: AnyFieldJson[], startsAt?: number, onChange: (updated: AnyFieldJson[]) => void, sx?: SxProps }) {
    const [ordered, setOrdered] = useState<AnyFieldJson[]>(fields)

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return

        let list = [...ordered]
        const [removed] = list.splice(result.source.index, 1);
        list.splice(result.destination?.index, 0, removed);

        // Set the order for each field
        list = list.map((field, index) => {
            return {
                ...field,
                order: index + (startsAt ?? 0)
            };
        });

        setOrdered(list)
    }

    useEffect(() => {
        // Call onChange if the order has changed
        if (ordered.some((item, index) => item.id !== fields[index].id)) {
            onChange(ordered);
        }
    }, [ordered])

    return (
        <Box sx={sx}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                    {(provided, snapshot) => (
                        <Stack spacing={1} ref={provided.innerRef} {...provided.droppableProps} sx={{cursor: "grab"}}>
                            {ordered.map((field, index) => (
                                <Draggable key={field.id} draggableId={field.id!} index={index}>
                                    {(provided, snapshot) => (
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={provided.draggableProps.style}
                                        >
                                            <DragIndicatorIcon/>
                                            <Box>
                                                {field.name}
                                            </Box>
                                        </Stack>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    );
}

export default FieldOrderList;