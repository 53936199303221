import React from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography} from "@mui/material";
import {ExampleDataset} from "../../api/enum/ExampleDataset"
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

interface DatasetFilterIconButtonProps {
    dataset?: ExampleDataset
    onChange: (exampleSet?: ExampleDataset) => void
}

function DatasetFilterIconButton({dataset, onChange}: DatasetFilterIconButtonProps) {
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>()

    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <FilterAltOutlinedIcon/>
            </IconButton>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            onChange(undefined)
                        }}
                        >
                            <ListItemText>
                                <Typography fontWeight={dataset === undefined ? 600 : 400}>All</Typography>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            onChange(ExampleDataset.TRAINING)
                        }}
                        >
                            <ListItemText>
                                <Typography fontWeight={dataset === ExampleDataset.TRAINING ? 600 : 400}>Training</Typography>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            onChange(ExampleDataset.VALIDATION)
                        }}
                        >
                            <ListItemText>
                                <Typography fontWeight={dataset === ExampleDataset.VALIDATION ? 600 : 400}>Validation</Typography>
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>
        </>
    )
}

export default DatasetFilterIconButton;