import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import RequestSender from "../RequestSender"

export class MarketingEventRegistrationRequests {
    static exportCsv(marketingEventId: string): Promise<ArrayBuffer> {
        const request = new RequestBuilder(Method.Get, `marketing-events/${marketingEventId}/registrations/csv`)
            .build()
        return RequestSender.sendRequest(request, true)
    }
}