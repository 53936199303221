import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography} from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {useToasts} from "../../hooks/useToasts"
import {RoleCheck} from "../../library/RoleCheck"
import {DeleteOutline} from "@mui/icons-material"
import {TemplateRequests} from "../../api/requests/TemplateRequests"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import {ProjectJson} from "../../api/json/ProjectJson"
import EditIcon from "@mui/icons-material/Edit"
import RenameModal from "../modal/RenameModal"
import ApiError from "../../api/ApiError"
import {useNavigate} from "react-router-dom"
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

interface TemplateActionMenuProps {
    project: ProjectJson
    template: AnyTemplateJson
    didRename?: (template: AnyTemplateJson) => void
    didCopy?: (copy: AnyTemplateJson) => void
    didDelete: () => void
}

function TemplateActionsMenu({project, template, didRename, didCopy, didDelete}: TemplateActionMenuProps) {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement>()

    const [renameModalOpen, setRenameModalOpen] = useState(false)
    const [renameInProgress, setRenameInProgress] = useState(false)
    const [renameError, setRenameError] = useState<ApiError>()

    const [deleteInProgress, setDeleteInProgress] = useState(false)

    const [copyInProgress, setCopyInProgress] = useState(false)

    const toasts = useToasts()
    const navigate = useNavigate()

    const handleRename = (newName: string) => {
        setRenameInProgress(true)
        TemplateRequests.update(project.id, template.id, newName, null)
            .then(template => {
                setRenameError(undefined)
                setRenameModalOpen(false)
                didRename && didRename(template)
            })
            .catch(setRenameError)
            .finally(() => setRenameInProgress(false))
    }

    const handleCopy = () => {
        setCopyInProgress(true)
        TemplateRequests.copy(project.id, template.id)
            .then(template => didCopy && didCopy(template))
            .catch(toasts.showError)
            .finally(() => setCopyInProgress(false))
    }

    const handleDelete = () => {
        setDeleteInProgress(true)
        TemplateRequests.delete(project.id, template.id)
            .then(() => didDelete && didDelete())
            .catch(toasts.showError)
            .finally(() => setDeleteInProgress(false))
    }

    if (!RoleCheck.atLeastManager()) {
        return <></>
    }

    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem disabled={renameInProgress}
                                  onClick={() => {
                                      setMenuAnchor(undefined)
                                      setRenameModalOpen(true)
                                  }}
                        >
                            <ListItemIcon>
                                <EditIcon/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Rename</Typography>
                            </ListItemText>
                        </MenuItem>

                        <MenuItem disabled={copyInProgress} onClick={() => handleCopy()}>
                            <ListItemIcon>
                                <ContentCopyIcon sx={{ width: 22}} />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Make a copy</Typography>
                            </ListItemText>
                        </MenuItem>

                        {project.stats.numberOfTrainingExamples > 10 &&
                            <MenuItem onClick={() => {
                                navigate(`/projects/${project.id}/fine-tunes/start?templateId=${template.id}`)
                            }}>
                                <ListItemIcon>
                                    <RocketLaunchOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Start a fine-tune
                                </ListItemText>
                            </MenuItem>
                        }

                        {template && template.stats && template.stats.numberOfFinetunes === 0 &&
                            <MenuItem disabled={deleteInProgress}
                                      onClick={() => {
                                          setMenuAnchor(undefined)
                                          handleDelete()
                                      }}
                            >
                                <ListItemIcon>
                                    <DeleteOutline color="error"/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography color="error">Delete</Typography>
                                </ListItemText>
                            </MenuItem>
                        }
                    </MenuList>
                </Paper>
            </Menu>

            <RenameModal heading="Rename template"
                         currentName={template.name}
                         open={renameModalOpen}
                         setOpen={setRenameModalOpen}
                         handleRename={handleRename}
                         renameInProgress={renameInProgress}
                         error={renameError}
            />
        </>
    );
}

export default TemplateActionsMenu;