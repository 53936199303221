import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom"
import {useIntegrations} from "../../hooks/useIntegrations"
import BreadcrumbsOrganization from "../layouts/BreadcrumbsOrganization"
import PageHeader from "../layouts/PageHeader"
import {Button, CircularProgress, Stack} from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import ErrorMessage from "../error/ErrorMessage"
import ApiError from "../../api/ApiError"
import {useToasts} from "../../hooks/useToasts"
import {AnyIntegrationJson} from "../../api/json/IntegrationJson"
import {IntegrationRequests} from "../../api/requests/IntegrationRequests"
import useSaveShortcut from "../../hooks/useSaveShortcut"
import FormContainer from "../layouts/FormContainer"
import {integrationDetailsList} from "./IntegrationDetailsList"
import OpenAiSettingsForm from "./settingsforms/OpenAiSettingsForm"
import AnthropicSettingsForm from "./settingsforms/AnthropicSettingsForm"
import AsyncButton from "../button/AsyncButton"
import Ai21SettingsForm from "./settingsforms/Ai21SettingsForm"
import ReplicateSettingsForm from "./settingsforms/ReplicateSettingsForm"
import DangerZone from "../form/DangerZone"
import DangerActionBox from "../form/DangerActionBox"
import ConfirmDeleteModal from "../modal/ConfirmDeleteModal"
import GoogleAiSettingsForm from "./settingsforms/GoogleAiSettingsForm"
import GroqCloudSettingsForm from "./settingsforms/GroqCloudSettingsForm"
import {ModelPlatform, resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"

function IntegrationDetailPage() {
    const {path} = useParams()

    const [saveInProgress, setSaveInProgress] = useState(false)
    const [saveError, setSaveError] = useState<ApiError>()

    const [integration, setIntegration] = useState<AnyIntegrationJson>()
    const [formValid, setFormValid] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
    const [deleteError, setDeleteError] = useState<ApiError>()

    const toasts = useToasts()
    const navigate = useNavigate()

    const pageDetails = integrationDetailsList.find(it => it.path == path)!
    const pageTitle = resolveNameForModelPlatform(pageDetails.platform)

    const {
        openAi,
        ai21,
        replicate,
        anthropic,
        googleAi,
        groqCloud,
        integrationsLoading,
        integrationsError,
        reloadIntegrations,
    } = useIntegrations()

    useEffect(() => reloadIntegrations(), [])

    const handleSave = () => {
        if (!integration || !formValid) return

        setSaveInProgress(true)

        const request = integration?.id
            ? IntegrationRequests.update(integration)
            : IntegrationRequests.create(integration)

        request.then(() => {
            toasts.showSaved()
            setSaveError(undefined)
            reloadIntegrations()
        })
            .catch(setSaveError)
            .finally(() => setSaveInProgress(false))
    }
    useSaveShortcut(handleSave)

    const handleChange = (integration: AnyIntegrationJson, formValid: boolean, unsavedChanges: boolean) => {
        setIntegration(integration)
        setFormValid(formValid)
        setUnsavedChanges(unsavedChanges)
    }

    return (
        <>
            <Stack spacing={3}>
                <BreadcrumbsOrganization breadcrumbs={[{label: "Integrations", url: "/integrations"}, pageTitle]}/>
                <PageHeader pageTitle={pageTitle}
                            fab={pageDetails.apiKeysUrl && (
                                <Button variant="outlined"
                                        color="secondary"
                                        href={pageDetails.apiKeysUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        endIcon={<OpenInNewIcon/>}
                                >
                                    Get API Key
                                </Button>
                            )
                            }
                            sx={{mt: 3, pb: 0}}/>

                {integrationsError && <ErrorMessage error={integrationsError}/>}

                {integrationsLoading ? <CircularProgress/> :
                    <>
                        <FormContainer>
                            {pageDetails.platform === ModelPlatform.OPEN_AI &&
                                <OpenAiSettingsForm openAi={openAi} onChange={handleChange}/>
                            }
                            {pageDetails.platform === ModelPlatform.AI21 &&
                                <Ai21SettingsForm ai21={ai21} onChange={handleChange}/>
                            }
                            {pageDetails.platform === ModelPlatform.REPLICATE &&
                                <ReplicateSettingsForm replicate={replicate} onChange={handleChange}/>
                            }
                            {pageDetails.platform === ModelPlatform.ANTHROPIC &&
                                <AnthropicSettingsForm anthropic={anthropic} onChange={handleChange}/>
                            }
                            {pageDetails.platform === ModelPlatform.GOOGLE_AI &&
                                <GoogleAiSettingsForm googleAi={googleAi} onChange={handleChange}/>
                            }
                            {pageDetails.platform === ModelPlatform.GROQ_CLOUD &&
                                <GroqCloudSettingsForm groqCloud={groqCloud} onChange={handleChange}/>
                            }
                        </FormContainer>

                        <AsyncButton
                            waiting={saveInProgress}
                            disabled={!formValid || !unsavedChanges}
                            onClick={handleSave}
                        >
                            Save
                        </AsyncButton>
                        <ErrorMessage error={saveError} sx={{mt: 2}}/>
                    </>
                }
            </Stack>

            {integration && integration?.id !== null &&
                <>
                    <DangerZone>
                        <DangerActionBox title="Disconnect this integration"
                                         description="Models will become inaccessible."
                                         buttonLabel="Disconnect" onAction={() => setDeleteConfirmOpen(true)}
                                         actionPending={deleteConfirmOpen}/>
                    </DangerZone>

                    <ConfirmDeleteModal heading={`Disconnect ${resolveNameForModelPlatform(integration.platform)}`}
                                        body={`Are you sure you want to disconnect ${resolveNameForModelPlatform(integration.platform)}?`}
                                        deleteButtonTitle="Disconnect"
                                        open={deleteConfirmOpen}
                                        setOpen={setDeleteConfirmOpen}
                                        error={deleteError}
                                        confirmedDelete={() => {
                                            if (!integration?.id) return

                                            IntegrationRequests.delete(integration.id)
                                                .then(() => navigate("/integrations"))
                                                .catch(setDeleteError)
                                        }}
                    />
                </>
            }
        </>
    );
}

export default IntegrationDetailPage;