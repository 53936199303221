import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import {PagedResponseJson} from "../json/PagedResponseJson";
import {TestRunJson} from "../json/TestRunJson"
import EvalJson from "../json/EvalJson"

export class TestRunRequests {
    static run(evalId: string, exampleId: number): Promise<TestRunJson> {
        return new RequestBuilder(Method.Post, `evals/${evalId}/test-runs/${exampleId}`)
            .buildAndSend()
    }

    static list(evalId: string, page: number = 0, unscoredOnly = false): Promise<PagedResponseJson<TestRunJson>> {
        return new RequestBuilder(Method.Get, `evals/${evalId}/test-runs?page=${page}&unscoredOnly=${unscoredOnly ? "true" : "false"}`)
            .buildAndSend()
    }

    static updateScore(evalId: string, exampleId: number, score: number): Promise<TestRunJson> {
        return new RequestBuilder(Method.Put, `evals/${evalId}/test-runs/${exampleId}`)
            .withJson({score: score})
            .buildAndSend()
    }

    static delete(evalId: string, exampleId: number): Promise<EvalJson> {
        return new RequestBuilder(Method.Delete, `evals/${evalId}/test-runs/${exampleId}`)
            .buildAndSend()
    }

    static deleteAll(evalId: string): Promise<EvalJson> {
        return new RequestBuilder(Method.Delete, `evals/${evalId}/test-runs`)
            .buildAndSend()
    }
}