export enum DisplayFormat {
    PLAIN_TEXT = "PLAIN_TEXT",
    MARKDOWN = "MARKDOWN",
    CODE = "CODE",
}

export function resolveNameForDisplayFormat(displayFormat: DisplayFormat) {
    switch (displayFormat) {
        case DisplayFormat.PLAIN_TEXT:
            return "Plain Text"
        case DisplayFormat.MARKDOWN:
            return "Markdown"
        case DisplayFormat.CODE:
            return "Code"
    }
}
