import React, {useEffect, useState} from 'react';
import AdminHeader from "../AdminHeader"
import {Fab, Grid, Typography} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ApiError from "../../../api/ApiError"
import ModalBase from "../../modal/ModalBase"
import DemoDatasetForm from "./DemoDatasetForm"
import {PagedResponseJson} from "../../../api/json/PagedResponseJson"
import {DemoDatasetJson} from "../../../api/json/DemoDatasetJson"
import {DemoDatasetRequests} from "../../../api/requests/DemoDatasetRequests"
import ScrollPaginator from "../../misc/ScrollPaginator"
import SpaceBetween from "../../common/SpaceBetween"
import AdminDemoDatasetCard from "./AdminDemoDatasetCard"

function AdminDemoDatasetsPage() {
    const [createOpen, setCreateOpen] = useState(false)
    const [pages, setPages] = useState<PagedResponseJson<DemoDatasetJson>[]>([])
    const [error, setError] = useState<ApiError>()

    const loadPage = (pageNumber: number) => {
        DemoDatasetRequests.list(pageNumber)
            .then(response => setPages([...pages, response]))
            .catch(setError)
    }

    const reload = () => {
        setPages([])
        loadPage(0)
    }

    useEffect(() => reload(), [])

    const datasets = pages.flatMap(it => it.content)

    return (
        <>
            <AdminHeader/>

            <SpaceBetween>
                <Typography variant="h1">Demo Datasets</Typography>
                <Fab color="primary" aria-label="create" onClick={() => setCreateOpen(true)}>
                    <AddIcon/>
                </Fab>
            </SpaceBetween>

            <ScrollPaginator pages={pages} loadNextPage={loadPage} error={error}>
                <Grid container spacing={2} sx={{mt: 2}}>
                    {datasets.map(dataset => (
                        <Grid item xs={12} sm={6} key={dataset.id}>
                            <AdminDemoDatasetCard
                                dataset={dataset}
                                didUpdate={updated => {
                                    setPages(pages =>
                                        pages.map(page => ({
                                            ...page,
                                            content: page.content.map(dataset =>
                                                dataset.id === updated.id ? { ...updated } : dataset
                                            ),
                                        }))
                                    );
                                }}
                                didDelete={(deleteId => {
                                        setPages(pages =>
                                            pages.map(page => ({
                                                ...page,
                                                content: page.content.filter(project => project.id !== deleteId),
                                            })),
                                        )
                                    }
                                )}
                            />
                        </Grid>
                    ))}
                </Grid>
            </ScrollPaginator>

            <ModalBase title="New Demo Dataset" open={createOpen} setOpen={setCreateOpen}>
                <DemoDatasetForm didCreate={created => {
                    setCreateOpen(false)

                    // This will trigger the paginator to load the first page
                    setPages([])
                }}/>
            </ModalBase>
        </>
    );
}

export default AdminDemoDatasetsPage;