import React, {useState} from 'react';
import {Alert, Box, FormHelperText, Grid, Stack, Typography} from "@mui/material";
import TemplateEditorStandard from "./TemplateEditorStandard"
import {TemplateType} from "../../api/enum/TemplateType"
import {
    AnyTemplateJson,
    AnyTemplateParts,
    TemplateChatParts,
    TemplateStandardParts,
} from "../../api/json/TemplateJson"
import TemplateEditorChat from "./TemplateEditorChat"
import {ProjectJson} from "../../api/json/ProjectJson"
import ApiError from "../../api/ApiError"
import useSaveShortcut from "../../hooks/useSaveShortcut"
import AsyncButton from "../button/AsyncButton"
import TemplateRenderer from "./TemplateRenderer"
import ExampleJson from "../../api/json/ExampleJson"
import TemplateUtil from "../../library/TemplateUtil"
import {TemplateRequests} from "../../api/requests/TemplateRequests"
import ErrorMessage from "../error/ErrorMessage"
import RandomExampleLoader from "../examples/RandomExampleLoader"
import TemplateTypeSelect from "./TemplateTypeSelect"

interface TemplateEditorProps {
    project: ProjectJson
    readonly?: boolean
    template?: AnyTemplateJson
    didSave: (template: AnyTemplateJson) => void
}

function TemplateEditor({project, readonly, template, didSave}: TemplateEditorProps) {
    const [type, setType] = useState<TemplateType>(template?.type ?? TemplateType.CHAT)

    const [savedParts, setSavedParts] = useState<AnyTemplateParts | undefined>(template)

    const [chatEditingParts, setChatEditingParts] = useState<TemplateChatParts>(template && template.type === TemplateType.CHAT ? template : {
        type: TemplateType.CHAT,
        system: "",
        user: "",
        assistant: "",
    })

    const [standardEditingParts, setStandardEditingParts] = useState<TemplateStandardParts>(template && template.type === TemplateType.STANDARD ? template : {
        type: TemplateType.STANDARD,
        prompt: "",
        completion: "",
        separator: "###",
        stopSequence: "\n\n###\n\n",
    })

    const [example, setExample] = useState<ExampleJson>()

    const [saving, setSaving] = useState(false)
    const [saveError, setSaveError] = useState<ApiError>()

    const resolveEditingParts = (): AnyTemplateParts => {
        return type === TemplateType.CHAT ? chatEditingParts : standardEditingParts
    }

    const handleSave = () => {
        if (!savedParts) {
            setSaving(true)
            TemplateRequests.create(project.id, null, resolveEditingParts())
                .then(finishHandleSave)
                .catch(setSaveError)
                .finally(() => setSaving(false))
        } else {
            if (readonly || !template) return

            let updatedParts = resolveEditingParts()

            setSaving(true)
            TemplateRequests.update(project.id, template.id, null, updatedParts)
                .then(finishHandleSave)
                .catch(setSaveError)
                .finally(() => setSaving(false))
        }
    }
    useSaveShortcut(() => handleSave())

    const finishHandleSave = (saved: AnyTemplateJson) => {
        setSavedParts(saved)
        if (saved.type === TemplateType.CHAT) {
            setChatEditingParts(saved)
        } else {
            setStandardEditingParts(saved)
        }
        didSave(saved)
    }

    const extraneousFields = template ? TemplateUtil.findAllReferences(template)
        .filter(reference => project.fields.map(field => field.reference)
            .indexOf(reference) === -1) : null

    let unsavedChanges = false
    if (savedParts) {
        if (type === TemplateType.CHAT && savedParts.type === TemplateType.CHAT) {
            if (chatEditingParts.system !== savedParts.system
                || chatEditingParts.user !== savedParts.user
                || chatEditingParts.assistant !== savedParts.assistant
            ) {
                unsavedChanges = true
            }
        } else if (type === TemplateType.STANDARD && savedParts.type === TemplateType.STANDARD) {
            if (standardEditingParts.prompt !== savedParts.prompt
                || standardEditingParts.completion !== savedParts.completion
                || standardEditingParts.separator !== savedParts.separator
                || standardEditingParts.stopSequence !== savedParts.stopSequence
            ) {
                unsavedChanges = true
            }
        }
    } else {
        unsavedChanges = true
    }

    return (
        <>
            {!template && (
                <Stack spacing={2} sx={{mb: 2}}>
                    <Typography variant="subtitle1">Type</Typography>
                    <TemplateTypeSelect value={type} onChange={setType}/>
                    <FormHelperText>Chat is recommended for most use cases.</FormHelperText>
                </Stack>
            )}
            {type === TemplateType.STANDARD && (
                <TemplateEditorStandard fields={project.fields}
                                        templateParts={standardEditingParts}
                                        readonly={readonly}
                                        onChange={setStandardEditingParts}/>
            )}
            {type === TemplateType.CHAT && (
                <TemplateEditorChat fields={project.fields}
                                    templateParts={chatEditingParts}
                                    readonly={readonly}
                                    onChange={setChatEditingParts}/>
            )}
            {!readonly &&
                <AsyncButton waiting={saving}
                             sx={{mt: 2}}
                             disabled={!unsavedChanges}
                             onClick={() => handleSave()}>Save</AsyncButton>
            }

            <Stack spacing={1} sx={{mt: 2}}>
                {extraneousFields && extraneousFields.length > 0 &&
                    <Alert severity="warning">Extraneous references found: {extraneousFields.join(", ")}</Alert>}
                {saveError && <ErrorMessage error={saveError}/>}
            </Stack>

            <Grid item xs={12} lg={6} sx={{display: {xs: "none", sm: "block"}}}>
                <RandomExampleLoader project={project} example={example} handleExample={setExample}>
                    {example &&
                        <TemplateRenderer templateParts={resolveEditingParts()}
                                          dataSource={example.fieldValues}
                                          staticSystemPromptTreatment="show"
                        />
                    }
                </RandomExampleLoader>
            </Grid>
        </>
    )
}

export default TemplateEditor
