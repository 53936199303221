import {PagedExamplesJson, PagedResponseJson} from "../json/PagedResponseJson";
import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import ExampleJson from "../json/ExampleJson";
import FieldValueJson from "../json/FieldValueJson";
import {ExampleDataset} from "../enum/ExampleDataset";
import {ExampleOrigin} from "../enum/ExampleOrigin"
import {ProjectJson} from "../json/ProjectJson"
import {AnyTemplateJson} from "../json/TemplateJson"

export interface ExampleSearchParams {
    dataset?: ExampleDataset
    search?: {
        value: string
        fieldId?: string
    }
    sort: {
        by: ExampleSortProperty
        direction: SortDirection
    }
}

export enum ExampleSortProperty {
    UPDATED = "UPDATED",
    TOKEN_COUNT = "TOKEN_COUNT"
}

export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export class ExampleRequests {
    static list(
        projectId: string,
        searchParams: ExampleSearchParams,
        page: number,
    ): Promise<PagedExamplesJson> {
        const queryParams = new URLSearchParams()
        queryParams.append("page", `${page}`)
        queryParams.append("sortBy", searchParams.sort.by)
        queryParams.append("sortDirection", searchParams.sort.direction)

        if (searchParams.dataset)
            queryParams.append("dataset", searchParams.dataset.toString())

        if (searchParams.search) {
            queryParams.append("search", searchParams.search.value)

            if (searchParams.search.fieldId) {
                queryParams.append("searchFieldId", searchParams.search.fieldId)
            }
        }

        return new RequestBuilder(Method.Get, `projects/${projectId}/examples?${queryParams.toString()}`)
            .buildAndSend()
    }

    static create(
        projectId: string,
        dataset: ExampleDataset,
        origin: ExampleOrigin,
        fieldValues: FieldValueJson[],
        generativeResultId: string | null,
    ): Promise<ExampleJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/examples`)
            .withJson(
                {
                    dataset: dataset,
                    origin: origin,
                    fieldValues: fieldValues,
                    generativeResultId: generativeResultId,
                },
            )
            .buildAndSend()
    }

    static update(exampleId: number, dataset: ExampleDataset, fieldValues: FieldValueJson[]): Promise<ExampleJson> {
        return new RequestBuilder(Method.Put, `examples/${exampleId}`)
            .withJson(
                {
                    dataset: dataset,
                    fieldValues: fieldValues,
                },
            )
            .buildAndSend()
    }

    static updateAll(
        projectId: string,
        validationPercent: number,
    ): Promise<ProjectJson> {
        return new RequestBuilder(Method.Put, `projects/${projectId}/examples`)
            .withJson({ validationPercent: validationPercent })
            .buildAndSend()
    }

    static get(exampleId: number): Promise<ExampleJson> {
        return new RequestBuilder(Method.Get, `examples/${exampleId}`)
            .buildAndSend()
    }

    static getRandom(projectId: string, number?: number, dataset?: ExampleDataset): Promise<PagedExamplesJson> {
        const queryParams = new URLSearchParams()
        if (number) queryParams.append("number", number.toString())
        if (dataset) queryParams.append("dataset", dataset)

        return new RequestBuilder(Method.Get, `projects/${projectId}/examples/random?${queryParams.toString()}`)
            .buildAndSend()
    }

    static delete(exampleId: number): Promise<void> {
        return new RequestBuilder(Method.Delete, `examples/${exampleId}`)
            .buildAndSend()
    }
}