import React, {useEffect, useState} from 'react';
import {Alert, Box, Link, Stack} from "@mui/material";
import {VerificationRequests} from "../../api/requests/VerificationRequests";
import {useAuth0} from "@auth0/auth0-react"
import ApiError from "../../api/ApiError"
import {UserRequests} from "../../api/requests/UserRequests"
import {useApi} from "../../hooks/useApi"

function EmailVerificationAlert() {
    const [resent, setResent] = useState(false)
    const [error, setError] = useState<ApiError>()

    const {setCurrentUser} = useApi()

    useEffect(() => {
        const interval = setInterval(() => checkEmailVerifiedStatus(), 3000)
        return () => clearInterval(interval)
    }, [])

    const handleResend = () => {
        VerificationRequests.resendEmailConfirmation()
            .then(() => setResent(true))
            .catch(setError)
    }

    const checkEmailVerifiedStatus = () => {
        UserRequests.getCurrentUser()
            .then(user => {
                if (user.emailVerified) {
                    setCurrentUser(user)
                    window.location.reload()
                }
            })
    }

    return <>
        <Alert severity="warning" sx={{mb: 2}}>
            <Stack direction="row" spacing={0.5}>
            <Box>Please verify your email address.</Box>
            <Box>{resent ? "Sent" : <Link onClick={handleResend} underline="none">Resend email</Link>}</Box>
            {error && <Box>{error.resolveUserMessage()} error</Box>}
            </Stack>
        </Alert>
    </>
}

export default EmailVerificationAlert;