import React, {useState} from 'react';
import {ProjectJson} from "../../../api/json/ProjectJson"
import {
    Checkbox,
    FormControlLabel,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Typography,
} from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {RoleCheck} from "../../../library/RoleCheck"
import {DeleteOutline, EditOutlined} from "@mui/icons-material"
import ConfirmDeleteModal from "../../modal/ConfirmDeleteModal"
import {ProjectRequests} from "../../../api/requests/ProjectRequests"
import ApiError from "../../../api/ApiError"
import RenameModal from "../../modal/RenameModal"
import CopyModal from "../../modal/CopyModal"

interface ProjectActionsMenuProps {
    project: ProjectJson
    didRename: (updated: ProjectJson) => void
    didCopy: (copy: ProjectJson) => void
    didDelete: (deletedId: string) => void
}

function ProjectActionsMenu({project, didCopy, didRename, didDelete}: ProjectActionsMenuProps) {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement>()

    const [copyModalOpen, setCopyModalOpen] = useState(false)
    const [copyExamples, setCopyExamples] = useState(false)
    const [copyInProgress, setCopyInProgress] = useState(false)
    const [copyError, setCopyError] = useState<ApiError>()

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteError, setDeleteError] = useState<ApiError>()

    const [renameModalOpen, setRenameModalOpen] = useState(false)

    const [renameInProgress, setRenameInProgress] = useState(false)
    const [renameError, setRenameError] = useState<ApiError>()

    const handleRename = (newName: string) => {
        setRenameInProgress(true)
        ProjectRequests.update(project.id, newName)
            .then(updated => {
                setRenameModalOpen(false)
                didRename(updated)
                setMenuAnchor(undefined)
            })
            .catch(setRenameError)
            .finally(() => setRenameInProgress(false))
    }

    const handleCopy = () => {
        setCopyInProgress(true)
        ProjectRequests.copy(project.id, copyExamples)
            .then(copy => {
                setCopyModalOpen(false)
                didCopy(copy)
                setMenuAnchor(undefined)
            })
            .catch(setCopyError)
            .finally(() => setCopyInProgress(false))
    }

    const handleDelete = () => {
        ProjectRequests.delete(project.id)
            .then(() => {
                setDeleteModalOpen(false)
                didDelete(project.id)
                setMenuAnchor(undefined)
            })
            .catch(setDeleteError)
    }

    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <MoreVertIcon/>
            </IconButton>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            setRenameModalOpen(true)
                        }}>
                            <ListItemIcon>
                                <EditOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Rename</Typography>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            setCopyModalOpen(true)
                        }}>
                            <ListItemIcon>
                                <ContentCopyIcon sx={{ width: 22}} />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Make a copy</Typography>
                            </ListItemText>
                        </MenuItem>
                        {RoleCheck.isAdmin() &&
                            <MenuItem onClick={() => {
                                setMenuAnchor(undefined)
                                setDeleteModalOpen(true)
                            }}>
                                <ListItemIcon>
                                    <DeleteOutline color="error"/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography color="error">Delete</Typography>
                                </ListItemText>
                            </MenuItem>
                        }
                    </MenuList>
                </Paper>
            </Menu>

            <RenameModal
                heading="Rename project"
                currentName={project.name}
                open={renameModalOpen}
                setOpen={setRenameModalOpen}
                handleRename={handleRename}
                renameInProgress={renameInProgress}
                error={renameError}
            />

            <CopyModal heading={`Copy "${project.name}" project`}
                       open={copyModalOpen}
                       setOpen={setCopyModalOpen}
                       handleCopy={handleCopy}
                       copyInProgress={copyInProgress}
            >
                <Typography paragraph>This will create a new project with the same structure.</Typography>
                {project.stats.totalExamples > 0 &&
                    <FormControlLabel
                        control={<Checkbox checked={copyExamples} onChange={(e) => setCopyExamples(e.target.checked)}/>}
                        label={`Copy all ${project.stats.totalExamples} examples too`}/>
                }
            </CopyModal>

            <ConfirmDeleteModal
                heading={`Delete "${project.name}" project`}
                body={`This will permanently remove the project.`}
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                confirmedDelete={handleDelete}
                error={deleteError}
            />
        </>
    )
}

export default ProjectActionsMenu;