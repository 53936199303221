import React from 'react'
import {Grid} from "@mui/material"
import AdminHeader from "./AdminHeader"
import AdminOrganizationsTable from "./AdminOrganizationsTable"

function AdminOrganizationsPage() {
    return (
        <>
            <AdminHeader/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AdminOrganizationsTable/>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminOrganizationsPage;