import React, {useEffect, useState} from 'react';
import {Alert, Skeleton, Stack} from "@mui/material";
import {ActivityRequests} from "../../api/requests/ActivityRequests";
import {AnyActivityJson} from "../../api/json/AnyActivityJson";
import ActivityCard from "./ActivityCard/ActivityCard";
import animations from "../../Animations.module.scss";
import ApiError from "../../api/ApiError"
import ErrorMessage from "../error/ErrorMessage"

function ActivitiesList() {
    const [activities, setActivities] = useState<(AnyActivityJson)[]>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    useEffect(() => {
        setLoading(true)
        ActivityRequests.list()
            .then(page => {
                setActivities(page.content)
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [])

    if (loading) {
        return <></>
    }

    return (
        <>
            {activities &&
                <Stack spacing={3} sx={{mt: 4}} className={animations.defaultIn}>
                    {activities.map(activity => <ActivityCard key={activity.id} activity={activity}/>)}
                </Stack>
            }
            {error && <ErrorMessage error={error} sx={{mt: 2}}/>}
        </>
    )
}

export default ActivitiesList;