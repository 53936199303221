import React, {useRef, useState} from 'react';
import {Box, Stack, TextField, Typography} from "@mui/material"
import AsyncButton from "../button/AsyncButton"
import ApiError from "../../api/ApiError"
import {useNavigate} from "react-router-dom"
import useEnterShortcut from "../../hooks/useEnterShortcut"
import ErrorMessage from "../error/ErrorMessage"
import {ProjectRequests} from "../../api/requests/ProjectRequests"
import {ModalOpenProps} from "../modal/ModalOpenProps"
import ModalBase from "../modal/ModalBase"

function NewProjectModal({open, setOpen}: ModalOpenProps) {
    const [description, setDescription] = useState("")
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<ApiError>()

    const navigate = useNavigate()

    const disabled = description.length === 0 || description.length > 300

    const contentRef = useRef<HTMLDivElement>(null)

    useEnterShortcut(contentRef, () => {
        if (open && !disabled) {
            handleCreate()
        }
    })

    const handleCreate = () => {
        setSaving(true)
        ProjectRequests.create(description)
            .then(project => {
                navigate(`/projects/${project.id}/overview`)
            })
            .catch(setError)
            .finally(() => setSaving(false))
    }

    return (
        <ModalBase
            title="New Project"
            size="small"
            open={open}
            setOpen={setOpen}
            buttons={
                <AsyncButton variant="contained"
                             disabled={disabled}
                             waiting={saving}
                             onClick={() => handleCreate()}
                >
                    Create
                </AsyncButton>
            }
            error={error}
        >
            <Stack spacing={2} ref={contentRef}>
                <Typography variant="body1">Write a brief description to help us set up your project.</Typography>
                <TextField label="Description"
                           autoComplete="project-description"
                           multiline
                           value={description}
                           onChange={e => setDescription(e.target.value.slice(0, 300))}
                           helperText={"Describe what your model will do, like \"Generate Y from X.\""}
                />
            </Stack>
        </ModalBase>
    );
}

export default NewProjectModal;