import React from 'react';
import {
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import styles from "./ProjectsPage.module.scss"
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import {Link as RouterLink} from "react-router-dom";
import {ProjectJson} from "../../../api/json/ProjectJson";
import ProjectActionsMenu from "./ProjectActionsMenu"
import {RoleCheck} from "../../../library/RoleCheck"
import animations from "../../../Animations.module.scss"

interface ProjectsTableProps {
    projects: ProjectJson[]
    didRename: (updated: ProjectJson) => void
    didCopy: (copy: ProjectJson) => void
    didDelete: (deletedId: string) => void
}

function ProjectsTable({projects, didRename, didCopy, didDelete}: ProjectsTableProps) {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="projects table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="center">Examples</TableCell>
                        <TableCell align="center">Fields</TableCell>
                        <TableCell align="center">Templates</TableCell>
                        <TableCell align="center">Models</TableCell>
                        {RoleCheck.atLeastManager() && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects.map(project => (
                        <TableRow
                            key={project.id}
                            className={animations.defaultIn}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>
                                <Link
                                    component={RouterLink}
                                    className={styles.projectLink}
                                    fontWeight={500}
                                    to={`/projects/${project.id}/overview`}
                                    sx={{pl: 0}}
                                >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <span>{project.name}</span>
                                        <ArrowCircleRightOutlinedIcon/>
                                    </Stack>
                                </Link>
                                {project.description && <Typography variant="caption">{project.description}</Typography>}
                            </TableCell>
                            <TableCell align="center">{project.stats.totalExamples > 0 && project.stats.totalExamples.toLocaleString()}</TableCell>
                            <TableCell align="center">{project.fields.length > 0 && project.fields.length.toLocaleString()}</TableCell>
                            <TableCell align="center">{project.templates.length > 0 && project.templates.length.toLocaleString()}</TableCell>
                            <TableCell align="center">{project.stats.numberOfModels > 0 && project.stats.numberOfModels.toLocaleString()}</TableCell>
                            {RoleCheck.atLeastManager() &&
                                <TableCell>
                                    <ProjectActionsMenu project={project} didCopy={didCopy} didRename={didRename} didDelete={didDelete}/>
                                </TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ProjectsTable;