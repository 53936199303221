import React, {useState} from 'react';
import {Box, Button, FormControl, InputLabel, Link, MenuItem, Modal, Select, Stack, Typography} from "@mui/material"
import modalStyles from "../modal/Modal.module.scss"
import LaunchIcon from "@mui/icons-material/Launch"
import {PlanInterval, resolveNameForPlanInterval} from "../../api/enum/Plan"
import PlanJson from "../../api/json/PlanJson"
import {PlanFactory} from "../../library/PlanFactory"
import {resolveNameForPlanTier} from "../../api/enum/PlanTier"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

interface SelectPlanModalProps {
    open: boolean
    handleClose: () => void
    didSelect: (plan: PlanJson, interval: PlanInterval) => void
}

function PlanMenuItem({plan}: { plan: PlanJson }) {
    return (
        <Stack>
            <Typography variant="body1" fontWeight={500}>{resolveNameForPlanTier(plan.tier)}</Typography>
            <Typography variant="body2">{plan.exampleLimit.toLocaleString("en-US")} examples, {plan.userLimit.toLocaleString("en-US")} users</Typography>
        </Stack>
    )
}

function IntervalMenuItem({interval, monthlyPrice}: { interval: PlanInterval, monthlyPrice: number }) {
    return (
        <Stack>
            <Typography variant="body2" fontWeight={500}>{resolveNameForPlanInterval(interval)}</Typography>
            <Typography variant="body2">$
                {interval === PlanInterval.MONTHLY && `${monthlyPrice.toLocaleString("en-US")} per month`}
                {interval === PlanInterval.YEARLY && `${(monthlyPrice * 10).toLocaleString("en-US")} per year`}
            </Typography>
        </Stack>
    )
}

function SelectPlanModal({open, handleClose, didSelect}: SelectPlanModalProps) {
    const [plan, setPlan] = useState<PlanJson>()
    const [interval, setInterval] = useState<PlanInterval>()

    const plans = PlanFactory.ALL

    return (
        <Modal open={open} onClose={handleClose}>
            <Stack spacing={2} className={modalStyles.default}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h2">Select Plan</Typography>
                    <Button component={Link} variant="text" href="https://www.entrypointai.com/pricing" target="_blank"
                            endIcon={<LaunchIcon/>}>
                        Full comparison chart
                    </Button>
                </Stack>

                <FormControl fullWidth>
                    <InputLabel id="plan-label">Plan</InputLabel>
                    <Select
                        labelId="plan-label"
                        value={plan?.tier ?? ""}
                        label="Plan"
                        onChange={(e) => {
                            const newPlan = plans.find(p => p.tier === e.target.value) as PlanJson
                            setPlan(newPlan)
                        }}
                    >
                        {plans.map(p =>
                            <MenuItem value={p.tier} key={p.tier}>
                                <PlanMenuItem plan={p}/>
                            </MenuItem>,
                        )}
                    </Select>
                </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="interval-label">Interval</InputLabel>
                        <Select
                            labelId="interval-label"
                            value={interval ?? ""}
                            label="Interval"
                            disabled={!plan}
                            onChange={(e) => setInterval(e.target.value as PlanInterval)}
                        >
                            <MenuItem value={PlanInterval.MONTHLY}>
                                <IntervalMenuItem interval={PlanInterval.MONTHLY} monthlyPrice={plan?.monthlyPrice ?? NaN}/>
                            </MenuItem>
                            <MenuItem value={PlanInterval.YEARLY}>
                                <IntervalMenuItem interval={PlanInterval.YEARLY} monthlyPrice={plan?.monthlyPrice ?? NaN}/>
                            </MenuItem>
                        </Select>
                    </FormControl>

                <Stack direction="row" justifyContent="flex-end">
                    <Button variant="contained"
                            endIcon={<ArrowRightAltIcon />}
                            disabled={!plan || !interval}
                            onClick={() => didSelect(plan!, interval!)}
                    >
                        Continue to checkout
                    </Button>
                </Stack>
            </Stack>
        </Modal>
    );
}

export default SelectPlanModal;