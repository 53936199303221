import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {AnyModelSpecJson} from "../../api/json/ModelSpecJson"

interface ModelSpecSelectProps {
    label?: string
    models: AnyModelSpecJson[]
    value: string | ""
    onChange: (newValue: string) => void
}

function ModelSpecSelect({label, models, value, onChange}: ModelSpecSelectProps) {
    const defaultLabel = "Model to use"

    return (
        <FormControl>
            <InputLabel id="chat-model-select">{label ?? defaultLabel}</InputLabel>
            <Select
                labelId="chat-model-select"
                value={value}
                label={label ?? defaultLabel}
                fullWidth
                onChange={(e) => onChange(e.target.value)}
            >
                {models.map(model => (
                    <MenuItem key={model.name} value={model.name}>{model.displayName}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default ModelSpecSelect;