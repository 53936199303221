import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Stack,
    SxProps,
    TextField,
} from "@mui/material";
import {UserRequests} from "../../api/requests/UserRequests";
import CurrentUserJson from "../../api/json/CurrentUserJson";
import AsyncButton from "../button/AsyncButton";
import {useToasts} from "../../hooks/useToasts"
import useSaveShortcut from "../../hooks/useSaveShortcut"

interface UserDetailsFormProps {
    buttonLabel?: string
    buttonAlignment?: "left" | "right"
    user: CurrentUserJson
    didSave: (user: CurrentUserJson) => void
}

function UserDetailsForm({buttonLabel, buttonAlignment, user, didSave}: UserDetailsFormProps) {
    const [firstName, setFirstName] = useState<string>(user.firstName ?? "")
    const [lastName, setLastName] = useState<string>(user.lastName ?? "")
    const [avatarFile, setAvatarFile] = useState<File>()
    const [marketingOptIn, setMarketingOptIn] = useState(user.marketingOptIn ?? true)

    const [saveInProgress, setSaveInProgress] = useState(false)

    const toasts = useToasts()

    useEffect(() => {
        if (!avatarFile || !user) return
        UserRequests.updateCurrentUserAvatar(avatarFile)
            .then(image => {
                const newUser = user
                newUser.avatar = image
                didSave(newUser)
                toasts.showSaved()
            })
            .catch(toasts.showError)
            .finally(() => setAvatarFile(undefined))
    }, [avatarFile, user])

    const disableSave = firstName.length === 0 || lastName.length === 0
    const handleSave = () => {
        if (disableSave) return
        setSaveInProgress(true)
        UserRequests.updateUserDetails(firstName, lastName, marketingOptIn)
            .then(modifiedUser => {
                didSave(modifiedUser)
                toasts.showSaved()
            })
            .catch(toasts.showError)
            .finally(() => setSaveInProgress(false))
    }
    useSaveShortcut(handleSave)

    return (
        <>
            <Box sx={{ pb: 1 }}>
                <input
                    accept="image/jpg, image/jpeg, image/png"
                    hidden
                    id="choose-avatar"
                    type="file"
                    onChange={event => {
                        if (!event.target.files?.length) return
                        setAvatarFile(event.target.files[0])
                    }}
                />

                <label htmlFor="choose-avatar"> <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={user.avatar?.sizes.thumbnail} sx={{cursor: "pointer"}}/>
                    <Button variant="outlined" component="span">
                        Choose image
                    </Button>
                    {avatarFile && <CircularProgress size={30}/>}
                </Stack>
                </label>
            </Box>
            <TextField
                label="First Name"
                multiline
                value={firstName}
                fullWidth
                onChange={(event) => setFirstName(event.target.value)}
            />
            <TextField
                label="Last Name"
                multiline
                value={lastName}
                fullWidth
                onChange={(event) => setLastName(event.target.value)}
            />
            <FormControlLabel control={<Checkbox checked={marketingOptIn} onChange={(_, checked) => setMarketingOptIn(checked)} />}
                              label="Receive emails about product updates and promotions" />
            <Stack direction="row" spacing={2} justifyContent={buttonAlignment === "right" ? "flex-end" : "flex-start"}>
                <AsyncButton
                    waiting={saveInProgress}
                    onClick={handleSave}
                    disabled={disableSave}
                >
                    {buttonLabel ?? "Save"}
                </AsyncButton>
            </Stack>
        </>
    )
}

export default UserDetailsForm;