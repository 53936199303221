import React, {useEffect, useState} from 'react';
import ExampleJson from "../../api/json/ExampleJson"
import {Box, Grid, useMediaQuery, useTheme} from "@mui/material"
import ExampleCard from "./ExampleCard/ExampleCard"
import {ProjectJson} from "../../api/json/ProjectJson"
import {AnyTemplateJson} from "../../api/json/TemplateJson"

interface ExampleMulticolumnLayoutProps {
    examples: ExampleJson[]
    project: ProjectJson
    template?: AnyTemplateJson
}

function ExampleFeedMulticolumnLayout({examples, project, template}: ExampleMulticolumnLayoutProps) {
    const [numberOfColumns, setNumberOfColumns] = useState<number>(1)
    const [columns, setColumns] = useState<ExampleJson[][]>([])

    useEffect(() => {
        if (!examples) return

        const newColumns: ExampleJson[][] = Array.from({ length: numberOfColumns }, () => []);
        examples.forEach((example, index) => {
            newColumns[index % numberOfColumns].push(example);
        });

        setColumns(newColumns);
    }, [examples, numberOfColumns])

    const updateExample = (updated: ExampleJson) => {
        // TODO: This doesn't actually change it on the parent list of examples, so it can revert
        const newColumns = [...columns]
        newColumns.forEach(col => {
            const index = col.findIndex(it => it.id === updated.id)
            if (index !== -1) {
                col[index] = updated
            }
        })
        setColumns(newColumns)
    }

    const removeExample = (deleted: ExampleJson) => {
        // TODO: This doesn't actually remove it from the parent list of examples, so it can reappaear
        const newColumns = [...columns]
        newColumns.forEach(col => {
            const index = col.findIndex(it => it.id === deleted.id)
            if (index !== -1) {
                col.splice(index, 1); // Remove the example
            }
        })
        setColumns(newColumns)
    }

    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))

    useEffect(() => {
        if (isLg) {
            setNumberOfColumns(3)
        } else if (isMd) {
            setNumberOfColumns(2)
        } else {
            setNumberOfColumns(1)
        }
    }, [isMd, isLg])

    return (
        <Grid spacing={2} container>
            {columns?.map((column, index) =>
                <Grid item xs={12 / numberOfColumns} key={index}>
                    {column.map(example =>
                        <Box sx={{mb: 2}} key={example.id}>
                            <ExampleCard
                                project={project}
                                template={template}
                                example={example}
                                didUpdate={updateExample}
                                didDelete={() => removeExample(example)}
                            />
                        </Box>,
                    )}
                </Grid>,
            )
            }
        </Grid>
    );
}

export default ExampleFeedMulticolumnLayout;