import ExampleJson from "../api/json/ExampleJson"
import {AnyTemplateJson} from "../api/json/TemplateJson"
import {TokenizerAlgorithm} from "../api/enum/TokenizerAlgorithm"
import TemplateUtil from "./TemplateUtil"

export const calculateTokenCount = (example: ExampleJson, template: AnyTemplateJson, algorithm: TokenizerAlgorithm): number | null => {
    const templateCount = getTemplateTokenCount(template, algorithm)

    const references = TemplateUtil.findAllReferences(template)
    const referencedFieldValues = example.fieldValues.filter(it => references.includes(it.field.reference))

    let referencedFieldValuesCount = 0
    referencedFieldValues.forEach(fieldValue => {
        let tokenCount = fieldValue.tokenCounts?.find(tokenCount => tokenCount.algorithm === algorithm)
        if (tokenCount) {
            referencedFieldValuesCount += tokenCount.count
        }
    })

    if (referencedFieldValuesCount)
        return (templateCount ?? 0) + referencedFieldValuesCount

    return null
}


export const getTemplateTokenCount = (template: AnyTemplateJson, algorithm: TokenizerAlgorithm): number | null => {
    return template.tokenCounts.find(tokenCount => tokenCount.algorithm === algorithm)?.count ?? null
}