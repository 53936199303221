import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, Stack, SxProps, Typography} from "@mui/material"
import {ModelPlatform} from "../../api/enum/ModelPlatform"
import {AnyModelJson, isFinetune} from "../../api/json/TemplatedModelJson"

interface TemplatedModelSelectProps {
    models: AnyModelJson[]
    selected?: AnyModelJson
    onSelect: (finetune: AnyModelJson) => void
    sx: SxProps
}

function TemplatedModelSelect({models, selected, onSelect, sx}: TemplatedModelSelectProps) {
    return (
        <FormControl sx={sx}>
            <InputLabel id="fine-tune-select-label">Fine-tuned Model</InputLabel>
            <Select
                labelId="fine-tune-select-label"
                value={selected?.id ?? ""}
                label="Fine-tuned Model"
                onChange={(e) => {
                    const model = models.find(finetune => finetune.id === e.target.value)!
                    onSelect(model)
                }}
            >
                {models.map(model =>
                    <MenuItem key={model.id} value={model.id}>
                        <Stack>
                            <Typography>{model.name}</Typography>
                            <Typography variant="caption">
                                {!isFinetune(model) ?
                                    <>{model.platformId}</> :
                                    <>
                                        {model.platform === ModelPlatform.OPEN_AI && model.model}
                                        {model.platform === ModelPlatform.AI21 && `${model.modelName}/ ${model.modelType}`}
                                        {model.platform === ModelPlatform.REPLICATE && model.baseModelName}
                                    </>
                                }
                            </Typography>
                        </Stack>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}

export default TemplatedModelSelect;