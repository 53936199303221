import React from 'react';
import {AnyTemplateJson, AnyTemplateParts} from "../../api/json/TemplateJson"
import {TemplateType} from "../../api/enum/TemplateType"
import TemplateRendererChat from "./TemplateRendererChat"
import TemplateRendererStandard from "./TemplateRendererStandard"
import {Stack, SxProps} from "@mui/material"
import templateStyles from "./Template.module.scss"
import {AnyTemplateDataSource} from "../../library/TemplateUtil"
import {DisplayFormat} from "../../api/enum/DisplayFormat"

interface AnyTemplateRendererProps extends TemplateRendererProps {
    templateParts: AnyTemplateParts
}

export interface TemplateRendererProps {
    dataSource?: AnyTemplateDataSource
    section?: "prompt" | "completion"
    showLabels?: boolean
    promptDisplay?: DisplayFormat
    completionDisplay?: DisplayFormat
    staticSystemPromptTreatment?: "hide" | "collapse" | "show"
    sx?: SxProps
}

function TemplateRenderer({dataSource, templateParts, ...props}: AnyTemplateRendererProps) {
    let content = <>Template not found</>
    if (templateParts.type === TemplateType.STANDARD) {
        content = <TemplateRendererStandard dataSource={dataSource} templateParts={templateParts} {...props} />
    } else if (templateParts.type === TemplateType.CHAT) {
        content = <TemplateRendererChat dataSource={dataSource} templateParts={templateParts} {...props} />
    }

    return (
        <Stack spacing={1} alignItems="flex-start" className={templateStyles.template} sx={props.sx}>
            {content}
        </Stack>
    )
}

export default TemplateRenderer;