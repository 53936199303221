import React, {useCallback, useState} from 'react';
import {useProjectData} from "../../hooks/useProjectData"
import ApiError from "../../api/ApiError"
import {PagedTransformJson} from "../../api/json/PagedResponseJson"
import ScrollPaginator from "../misc/ScrollPaginator"
import {TransformRequests} from "../../api/requests/TransformRequests"
import TransformsFeed from "./TransformsFeed"
import ProjectPageHeader from "../layouts/ProjectPageHeader"
import {useNavigate} from "react-router-dom"

function TransformsPage() {
    const {id, project} = useProjectData()

    const [pages, setPages] = useState<PagedTransformJson[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    const navigate = useNavigate()

    const loadPage = useCallback((pageNumber: number) => {
        if (loading) return

        setLoading(true)
        TransformRequests.list(id, pageNumber)
            .then(response => {
                setPages([...pages, response])
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [pages, loading])

    if (!project) {
        return <></>
    }

    const transforms = pages.flatMap(page => page.content)
    return (
        <>
            <ProjectPageHeader pageTitle="Transforms" showFab onFabClick={() => navigate(`/projects/${project.id}/transforms/new`)}/>
            <ScrollPaginator pages={pages}
                             loadNextPage={pageNumber => loadPage(pageNumber)}
                             error={error}
            >
                <TransformsFeed transforms={transforms}/>
            </ScrollPaginator>
        </>
    );
}

export default TransformsPage;