import React from 'react';
import {Container} from "@mui/material"
import {Outlet} from "react-router-dom"
import {useApi} from "../../hooks/useApi"
import {SystemRole} from "../../api/enum/SystemRole"

function AdminContainer() {
    const { currentUser } = useApi()

    return (
        <Container sx={{mt: 4, pt: "60px", pb: "80px", minHeight: "70vh"}}>
            {currentUser?.systemRole === SystemRole.ADMIN
                ? <Outlet/>
                : <>Nothing to see here.</>
            }
        </Container>
    )
}

export default AdminContainer;