import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {VerificationJson} from "../json/VerificationJson"
import CurrentUserJson from "../json/CurrentUserJson"

export class VerificationRequests {
    static get(code: string): Promise<VerificationJson> {
        return new RequestBuilder(Method.Get, `verifications/by-code/${code}`)
            .unauthenticated()
            .withoutOrganization()
            .buildAndSend()
    }

    static verify(code: string): Promise<CurrentUserJson> {
        return new RequestBuilder(Method.Post, `verifications/by-code/${code}`)
            .unauthenticated()
            .withoutOrganization()
            .buildAndSend()
    }

    static resendEmailConfirmation(): Promise<void> {
        return new RequestBuilder(Method.Post, `verifications/resend-email-confirmation`)
            .withoutOrganization()
            .buildAndSend()
    }
}