import React from 'react';
import {Container} from "@mui/material"
import EmailVerificationAlert from "./EmailVerificationAlert"
import {Outlet} from "react-router-dom"
import {useApi} from "../../hooks/useApi"

function FullwidthContainer() {
    const {currentUser} = useApi()

    return (
        <Container sx={{mt: 4, pt: "60px", pb: "80px", minHeight: "70vh"}}>
            {currentUser && currentUser.emailVerified == null &&
                <EmailVerificationAlert/>
            }
            <Outlet/>
        </Container>
    );
}

export default FullwidthContainer;