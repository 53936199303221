import React, {useEffect, useState} from 'react';
import {Button, Card, CardContent, Grid, Stack, Typography} from "@mui/material"
import {ProjectJson} from "../../api/json/ProjectJson"
import {ExampleOrigin} from "../../api/enum/ExampleOrigin"
import ExampleJson from "../../api/json/ExampleJson"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import {useProjectData} from "../../hooks/useProjectData"
import animations from "../../Animations.module.scss"
import {ExampleRequests} from "../../api/requests/ExampleRequests"
import {ExampleDataset} from "../../api/enum/ExampleDataset"
import SynthesisResultJson from "../../api/json/SynthesisResultJson"
import ApiError from "../../api/ApiError"
import TemplateRenderer from "../templates/TemplateRenderer"
import ExampleEditorModal from "../examples/ExampleEditorModal"
import AsyncButton from "../button/AsyncButton"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import ErrorMessage from "../error/ErrorMessage"
import FieldValueJson from "../../api/json/FieldValueJson"

interface SynthesisResultCardProps {
    project: ProjectJson
    template: AnyTemplateJson
    result: SynthesisResultJson
    validationRatio: number
    autoSave?: boolean
}

function SynthesisResultCard({project, template, result, validationRatio, autoSave}: SynthesisResultCardProps) {
    const {reloadProject} = useProjectData()
    const [saveDrawerOpen, setSaveDrawerOpen] = useState(false)
    const [savedExample, setSavedExample] = useState<ExampleJson | null>(result.example)
    const [deleted, setDeleted] = useState<boolean>()

    const [saveInProgress, setSaveInProgress] = useState<boolean>(false)

    const [error, setError] = useState<ApiError>()

    useEffect(() => {
        if (!autoSave || savedExample || deleted || saveInProgress) return

        setSaveInProgress(true)
        saveExample()
    }, [autoSave])

    const saveExample = () => {
        const fieldValues: FieldValueJson[] = project.fields.map(field => ({
            field: field,
            value: result.referenceValues[field.reference] ?? "",
            tokenCounts: null,
        }))

        const dataset = Math.random() < validationRatio ? ExampleDataset.VALIDATION : ExampleDataset.TRAINING

        ExampleRequests.create(project.id, dataset, ExampleOrigin.SYNTHESIS, fieldValues, result.id)
            .then((example) => {
                setSavedExample(example)
                reloadProject() // To update the example count
            })
            .catch(setError)
            .finally(() => setSaveInProgress(false))
    }

    return (
        <>
            <Card variant="outlined" className={animations.defaultIn}>
                <CardContent sx={{pb: 0, whiteSpace: "pre-wrap"}}>
                    <Grid container spacing={4}>
                        <Grid item lg={5} md={6} sm={12}>
                            <Typography variant="subtitle1">Prompt</Typography>
                            <TemplateRenderer dataSource={result.referenceValues} templateParts={template} section="prompt" staticSystemPromptTreatment="hide"/>
                        </Grid>
                        <Grid item lg={5} md={6} sm={12}>
                            <Typography variant="subtitle1">Completion</Typography>
                            <TemplateRenderer dataSource={result.referenceValues} templateParts={template} section="completion"/>
                        </Grid>
                        <Grid item lg={2} md={12} sm={12}>
                            <Stack justifyContent="center" alignItems="end" sx={{height: "100%"}}>
                                {savedExample ?
                                    <Button variant="text"
                                            startIcon={<EditIcon/>}
                                            onClick={() => setSaveDrawerOpen(true)}
                                            sx={{minWidth: 90}}
                                    >
                                        Edit
                                    </Button>
                                    : <AsyncButton variant="outlined"
                                                   startIcon={<AddIcon/>}
                                                   onClick={() => saveExample()}
                                                   waiting={saveInProgress}
                                                   sx={{minWidth: 90}}
                                    >
                                        Add
                                    </AsyncButton>
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                    {error && <ErrorMessage error={error}/>}
                </CardContent>
            </Card>

            {savedExample &&
                <ExampleEditorModal
                    open={saveDrawerOpen}
                    onClose={() => setSaveDrawerOpen(false)}
                    project={project}
                    template={template}
                    example={savedExample}
                    didUpdate={(example) => {
                        setSavedExample(example)
                        setSaveDrawerOpen(false)
                    }}
                    didDelete={() => {
                        setSavedExample(null)
                        setDeleted(true)
                        setSaveDrawerOpen(false)
                    }}
                />
            }
        </>
    );
}

export default SynthesisResultCard;