import React, {ReactNode} from 'react'
import {Breadcrumbs, Link, Typography} from "@mui/material"
import {Link as RouterLink} from "react-router-dom";

export interface BreadcrumbLink {
    label: string
    url: string
}

export type Breadcrumb = string | BreadcrumbLink

interface BreadcrumbsBaseProps {
    homeBase: string
    homeBaseUrl: string
    homeBaseIcon: ReactNode
    breadcrumbs: Breadcrumb[]
}

function BreadcrumbsBase({homeBase, homeBaseUrl, homeBaseIcon, breadcrumbs}: BreadcrumbsBaseProps) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink}
                  color="secondary"
                  sx={{display: 'flex', alignItems: 'center'}}
                  to={homeBaseUrl}>
                {homeBaseIcon}
                {homeBase}
            </Link>

            {breadcrumbs.map((breadcrumb, index) => {
                if (typeof breadcrumb  === "string") {
                    return <Typography color="text.primary" key={index}>{breadcrumb}</Typography>
                }
                return <Link component={RouterLink} color="secondary" to={breadcrumb.url} key={index}>{breadcrumb.label}</Link>
            })}
        </Breadcrumbs>
    )
}

export default BreadcrumbsBase