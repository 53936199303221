import React from 'react';
import {FormControlLabel, Radio, RadioGroup, SxProps, Theme} from "@mui/material"
import {ExampleDataset} from "../../api/enum/ExampleDataset"

interface DatasetRadio {
    dataset?: ExampleDataset
    setDataset: (dataset: ExampleDataset) => void
    size?: "small" | "medium" | "large"
    sx?: SxProps<Theme>
}

function DatasetRadio({dataset, setDataset, size, sx}: DatasetRadio) {
    return (
        <RadioGroup
            row
            value={dataset}
            onChange={(_, value) => setDataset(value as ExampleDataset)}
        >
            <FormControlLabel value={ExampleDataset.TRAINING} control={<Radio/>} label="Training"/>
            <FormControlLabel value={ExampleDataset.VALIDATION} control={<Radio/>} label="Validation"/>
        </RadioGroup>
    );
}

export default DatasetRadio;