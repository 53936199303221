import React, {ReactNode} from 'react';
import {createTheme, ThemeProvider} from "@mui/material"
import {ModelSpecProvider} from "./hooks/useModelSpecs"
import {IntegrationsProvider} from "./hooks/useIntegrations"
import {ApiProvider} from "./hooks/useApi"
import {themeOptions} from "./library/ThemeOptions"
import {PlanIntentProvider} from "./hooks/usePlanIntent"
import AuthProvider from "./components/accounts/AuthProvider"
import {ToastProvider} from "./hooks/useToasts"
import {BrowserRouter} from "react-router-dom"
import {BackdropProvider} from "./hooks/useBackdrop"

function DataProviders({children}: { children: ReactNode }) {
    const theme = createTheme(themeOptions)

    return (
        <ApiProvider>
            <IntegrationsProvider>
                <ModelSpecProvider>
                    <ThemeProvider theme={theme}>
                        <ToastProvider>
                            <BackdropProvider>
                                <PlanIntentProvider>
                                    <BrowserRouter>{/* Components that require routing or navigation come after this */}
                                        <AuthProvider>
                                            {children}
                                        </AuthProvider>
                                    </BrowserRouter>
                                </PlanIntentProvider>
                            </BackdropProvider>
                        </ToastProvider>
                    </ThemeProvider>
                </ModelSpecProvider>
            </IntegrationsProvider>
        </ApiProvider>
    );
}

export default DataProviders;