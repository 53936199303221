import React, {useEffect, useState} from 'react';
import {Button, FormControl, FormHelperText, InputLabel, Link, MenuItem, Select, Typography} from "@mui/material";
import {FinetuneOptionsAi21} from "../../api/json/FinetuneOptionsJson";
import LaunchIcon from "@mui/icons-material/Launch";
import {ModelSpecAi21Json} from "../../api/json/ModelSpecJson"
import {ModelPlatform} from "../../api/enum/ModelPlatform"
import SpaceBetween from "../common/SpaceBetween"
import NumberInput from "../form/NumberInput"
import {AnyTemplateJson} from "../../api/json/TemplateJson"

interface FineTuneAi21FormProps {
    template: AnyTemplateJson
    baseModels: ModelSpecAi21Json[]
    setOptions: (options: FinetuneOptionsAi21) => void
    setFormValid: (valid: boolean) => void
}

function FinetuneFormAi21({template, baseModels, setOptions, setFormValid}: FineTuneAi21FormProps) {
    const nonDeprecatedBaseModels = baseModels.filter(it => !it.deprecated)
    const [modelType, setModelType] = useState<string>(nonDeprecatedBaseModels[0].name)
    const [showAdvanced, setShowAdvanced] = useState<boolean>(false)

    const [numberOfEpochs, setNumberOfEpochs] = useState<number>(20)
    const [learningRate, setLearningRate] = useState<number | null>(null)

    useEffect(() => setOptions({
        platform: ModelPlatform.AI21,
        templateId: template.id,
        baseModel: baseModels.find(it => it.name === modelType),
        modelType: modelType,
        numberOfEpochs: numberOfEpochs,
        learningRate: learningRate,
    }), [modelType, numberOfEpochs, learningRate])

    useEffect(() => {
        setFormValid(true)
    }, [])

    return (
        <>
            <FormControl>
                <InputLabel id="base-model-select-label">Model Type</InputLabel>
                <Select
                    labelId="base-model-select-label"
                    value={modelType}
                    label="Base Model"
                    fullWidth
                    onChange={(e) => setModelType(e.target.value)}
                >
                    {nonDeprecatedBaseModels.map(model =>
                        <MenuItem key={model.name} value={model.name}>{model.displayName}</MenuItem>
                    )}
                </Select>
                <FormHelperText>Each Jurassic-2 model has <Link
                    href="https://docs.ai21.com/docs/complete-api" target="_blank"
                    rel="noopener noreferrer">different capabilities</Link> and costs.</FormHelperText>
            </FormControl>
            {showAdvanced ?
                <>
                    <SpaceBetween>
                        <Typography variant="subtitle1">Advanced</Typography>
                        <Button endIcon={<LaunchIcon />}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://docs.ai21.com/reference/create-custom-model">
                            Docs
                        </Button>
                    </SpaceBetween>
                    <NumberInput label="Number of Epochs"
                                 helperText="The number of full cycles to go through the training dataset. Multiplies pricing."
                                 min={1}
                                 max={100}
                                 value={numberOfEpochs}
                                 onChange={setNumberOfEpochs}
                    />
                    <NumberInput label="Learning Rate"
                                 helperText="Default varies by model. Set automatically when left blank."
                                 min={0.01}
                                 max={1}
                                 nullable
                                 onNullableChange={setLearningRate}/>
                </> :
                <Button sx={{width: 150}} onClick={() => setShowAdvanced(true)}>Show Advanced</Button>
            }
        </>
    );
}

export default FinetuneFormAi21;