import React, {useState, useEffect, createContext, useContext} from "react";
import {getCookie, removeCookie, setCookie} from "typescript-cookie"

export interface PlanIntent {
    name: "pro" | "growth" | "starter"
    interval: "monthly" | "yearly"
}

export interface PlanIntentContextData {
    plan?: PlanIntent;
    clear: () => void;
}

export const PlanIntentContext = createContext<PlanIntentContextData | undefined>(undefined);

export function PlanIntentProvider({ children }: { children: React.ReactNode }) {
    const planNameKey = "planName"
    const intervalKey = "interval"
    const cookieKey = "plan_intent"

    const [plan, setPlan] = useState<PlanIntent>()

    useEffect(() => {
        setPlan(getStoredPlanIntent())
    }, [])

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        if (!searchParams.has(planNameKey) || !searchParams.has(intervalKey)) {
            return;
        }

        const name = searchParams.get(planNameKey);
        const interval = searchParams.get(intervalKey);

        if (!isValidName(name) || !isValidInterval(interval)) {
            return;
        }

        const plan: PlanIntent = {
            name: name,
            interval: interval,
        };

        const jsonString = JSON.stringify(plan);
        setCookie(cookieKey, jsonString);
        setPlan(plan);
    }, []);

    function getStoredPlanIntent(): PlanIntent | undefined {
        const stringValue = getCookie(cookieKey)
        if (!stringValue) return undefined

        return JSON.parse(stringValue) as PlanIntent
    }

    function clear() {
        removeCookie(cookieKey);
        setPlan(undefined);
    }

    function isValidName(name: string | null): name is "pro" | "growth" | "starter" {
        return name === "pro" || name === "growth" || name === "starter";
    }

    function isValidInterval(interval: string | null): interval is "monthly" | "yearly" {
        return interval === "monthly" || interval === "yearly";
    }

    const value = { plan, clear };

    return (
        <PlanIntentContext.Provider value={value}>
            {children}
        </PlanIntentContext.Provider>
    );
}

export function usePlanIntent(): PlanIntentContextData {
    const context = useContext(PlanIntentContext);
    if (!context) {
        throw new Error(`usePlanIntent must be used within a PlanIntentProvider`);
    }
    return context;
}