import React, {useEffect} from 'react'
import {Box, CircularProgress, Grid, Typography} from "@mui/material"
import IntegrationCard from "./IntegrationCard";
import PageHeader from "../layouts/PageHeader";
import {useIntegrations} from "../../hooks/useIntegrations"
import ErrorMessage from "../error/ErrorMessage"
import {integrationDetailsList} from "./IntegrationDetailsList"

function IntegrationsPage() {
    const {integrations, integrationsLoading, integrationsError, reloadIntegrations} = useIntegrations()

    useEffect(() => reloadIntegrations(), [])

    const header = <PageHeader pageTitle="Integrations"/>

    const exploreList = integrationDetailsList.map(p => p.platform)
        .filter(platform => integrations?.filter(integration => integration.platform === platform).length === 0)

    return (
        <>
            {header}
            {!integrations && integrationsLoading && <CircularProgress/>}
            {integrationsError && <ErrorMessage error={integrationsError}/>}

            <Box>
                {integrations && integrations.length > 0 &&
                    <Typography variant="subtitle1" gutterBottom>Connected</Typography>
                }
                <Grid container spacing={2}>
                    {integrations?.map(integration => {
                        const properties = integrationDetailsList.filter(p => p.platform === integration.platform)[0]
                        if (!properties) return <></>
                        return (
                            <Grid item xs={8} sm={5} lg={4} key={properties.platform} sx={{maxWidth: 360}}>
                                <IntegrationCard key={properties.platform} {...properties}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>

            <Box sx={{mt: 3}}>
                {exploreList.length > 0 && <Typography variant="subtitle1" gutterBottom>Not Connected</Typography>}
                <Grid container spacing={2}>
                    {exploreList.map(platform => {
                        const properties = integrationDetailsList.filter(p => p.platform === platform)[0]
                        return (
                            <Grid item xs={8} sm={5} lg={4} key={properties.platform}>
                                <IntegrationCard key={properties.platform} {...properties}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </>
    )
}

export default IntegrationsPage