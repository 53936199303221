import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import {AnyModelJson, TemplatedModelJson} from "../json/TemplatedModelJson"
import {PagedModelJson} from "../json/PagedResponseJson"
import {AnyFinetuneJson} from "../json/FinetuneJson"
import {CompletionOptionsJson} from "../json/CompletionOptionsJson"
import {CompletionRequestJson} from "../json/CompletionRequestJson"
import {CompletionEstimateJson} from "../json/CompletionEstimateJson"
import {ModelPlatform} from "../enum/ModelPlatform"

export class TemplatedModelRequests {
    static create(projectId: string, baseModelName: string, name: string, templateId: string ): Promise<TemplatedModelJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/models`)
            .withJson({
                name: name,
                baseModelName: baseModelName,
                templateId: templateId,
            })
            .buildAndSend()
    }

    static list(projectId: string, templateId?: string, includeFinetunes?: boolean, platform?: ModelPlatform): Promise<PagedModelJson> {
        const queryParams = new URLSearchParams()
        if (includeFinetunes) queryParams.append("includeFinetunes", includeFinetunes.toString())
        if (platform) queryParams.append("platform", platform)
        if (templateId) queryParams.append("templateId", templateId)

        return new RequestBuilder(Method.Get, `projects/${projectId}/models?${queryParams.toString()}`)
            .buildAndSend()
    }

    static get(modelId: string): Promise<AnyModelJson> {
        return new RequestBuilder(Method.Get, `models/${modelId}`)
            .buildAndSend()
    }

    static getByAccessCode(modelId: string, accessCode: string): Promise<AnyFinetuneJson> {
        return new RequestBuilder(Method.Get, `models/${modelId}?accessCode=${accessCode}`)
            .unauthenticated()
            .withoutOrganization()
            .buildAndSend()
    }

    static update(modelId: string, name: string | null, allowAccess?: boolean): Promise<AnyModelJson> {
        return new RequestBuilder(Method.Put, `models/${modelId}`)
            .withJson({ name: name, allowAccess: allowAccess })
            .buildAndSend()
    }

    static complete(modelId: string, inputs: Record<string, string>, options: CompletionOptionsJson): Promise<CompletionRequestJson> {
        return new RequestBuilder(Method.Post, `models/${modelId}/completions`)
            .withJson({
                inputs: inputs,
                options: options,
            })
            .buildAndSend()
    }

    static completeByAccessCode(modelId: string, inputs: Record<string, string>, options: CompletionOptionsJson, accessCode: string): Promise<CompletionRequestJson> {
        return new RequestBuilder(Method.Post, `models/${modelId}/completions`)
            .withJson({
                accessCode: accessCode,
                inputs: inputs,
                options: options,
            })
            .unauthenticated()
            .withoutOrganization()
            .buildAndSend()
    }

    static estimate(modelId: string, inputs: Record<string, string>, options: CompletionOptionsJson): Promise<CompletionEstimateJson | null> {
        return new RequestBuilder(Method.Post, `models/${modelId}/completions/estimates`)
            .withJson({
                inputs: inputs,
                options: options,
            })
            .buildAndSend()
    }

    static delete(projectId: string, modelId: string, deleteFromPlatform: boolean): Promise<void> {
        return new RequestBuilder(Method.Delete, `projects/${projectId}/models/${modelId}`)
            .withJson({ deleteFromPlatform: deleteFromPlatform })
            .buildAndSend()
    }
}