import React, {useEffect, useState} from 'react'
import SecretKeyTextField from "../SecretKeyTextField"
import {GoogleAiJson} from "../../../api/json/IntegrationJson"
import {IntegrationSettingsFormProps} from "./IntegrationSettingsFormProps"
import {ModelPlatform} from "../../../api/enum/ModelPlatform"

interface GoogleAiSettingsFormProps extends IntegrationSettingsFormProps {
    googleAi: GoogleAiJson | null
}

function GoogleAiSettingsForm({googleAi, onChange}: GoogleAiSettingsFormProps) {
    const [secretKey, setSecretKey] = useState<string>(googleAi?.secretKey ?? "")
    const [isValidSecretKey, setIsValidSecretKey] = useState(true)

    useEffect(() => {
        if (secretKey === "") return

        const isValidSecretKey = secretKey.length >= 39
        setIsValidSecretKey(isValidSecretKey)

        const isValid = isValidSecretKey

        const unsavedChanges = !googleAi || (secretKey !== "" && secretKey !== googleAi.secretKey)

        onChange({
            platform: ModelPlatform.GOOGLE_AI,
            id: googleAi?.id ?? null,
            secretKey: secretKey,
        }, isValid, unsavedChanges)
    }, [secretKey])

    return (
        <>
            <SecretKeyTextField label="API key"
                                value={secretKey}
                                setValue={setSecretKey}
                                valid={isValidSecretKey}
            />
        </>
    )
}

export default GoogleAiSettingsForm