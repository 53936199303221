import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography} from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {useToasts} from "../../hooks/useToasts"
import {RoleCheck} from "../../library/RoleCheck"
import {DeleteOutline} from "@mui/icons-material"
import {ProjectJson} from "../../api/json/ProjectJson"
import EditIcon from "@mui/icons-material/Edit"
import RenameModal from "../modal/RenameModal"
import ApiError from "../../api/ApiError"
import {TransformRequests} from "../../api/requests/TransformRequests"
import TransformJson from "../../api/json/TransformJson"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

interface TransformActionsMenuProps {
    project: ProjectJson
    transform: TransformJson
    didRename?: (template: TransformJson) => void
    didCopy?: (copy: TransformJson) => void
    didDelete: () => void
}

function TransformActionsMenu({project, transform, didRename, didCopy, didDelete}: TransformActionsMenuProps) {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement>()

    const [renameModalOpen, setRenameModalOpen] = useState(false)
    const [renaming, setRenaming] = useState(false)
    const [renameError, setRenameError] = useState<ApiError>()

    const [copying, setCopying] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const toasts = useToasts()

    const handleRename = (newName: string) => {
        setRenaming(true)
        TransformRequests.update(project.id, transform.id, newName)
            .then(transform => {
                setRenameError(undefined)
                setRenameModalOpen(false)
                didRename && didRename(transform)
            })
            .catch(setRenameError)
            .finally(() => setRenaming(false))
    }

    const handleCopy = () => {
        setCopying(true)
        TransformRequests.copy(project.id, transform.id)
            .then(template => didCopy && didCopy(template))
            .catch(toasts.showError)
            .finally(() => {
                setCopying(false)
                setMenuAnchor(undefined)
            })
    }

    const handleDelete = () => {
        setDeleting(true)
        TransformRequests.delete(project.id, transform.id)
            .then(() => didDelete && didDelete())
            .catch(toasts.showError)
            .finally(() => {
                setDeleting(false)
                setMenuAnchor(undefined)
            })
    }

    if (!RoleCheck.atLeastManager()) {
        return <></>
    }

    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem disabled={renaming}
                                  onClick={() => {
                                      setMenuAnchor(undefined)
                                      setRenameModalOpen(true)
                                  }}
                        >
                            <ListItemIcon>
                                <EditIcon/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Rename</Typography>
                            </ListItemText>
                        </MenuItem>

                        <MenuItem disabled={copying} onClick={() => handleCopy()}>
                            <ListItemIcon>
                                <ContentCopyIcon sx={{ width: 22}} />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Make a copy</Typography>
                            </ListItemText>
                        </MenuItem>

                        {!transform.hasActiveJob &&
                            <MenuItem disabled={deleting}
                                      onClick={() => handleDelete()}
                            >
                                <ListItemIcon>
                                    <DeleteOutline color="error"/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography color="error">Delete</Typography>
                                </ListItemText>
                            </MenuItem>
                        }
                    </MenuList>
                </Paper>
            </Menu>

            <RenameModal heading="Rename template"
                         currentName={transform.name}
                         open={renameModalOpen}
                         setOpen={setRenameModalOpen}
                         handleRename={handleRename}
                         renameInProgress={renaming}
                         error={renameError}
            />
        </>
    );
}

export default TransformActionsMenu;