import React, {useEffect, useState} from 'react';
import {TextField} from "@mui/material"
import {ProjectJson} from "../../api/json/ProjectJson"
import {AnyFieldJson} from "../../api/json/FieldJson"

interface FieldEditCommonFieldsProps {
    project: ProjectJson
    field?: AnyFieldJson
    name: string
    reference: string
    setName: (newValue: string) => void
    setReference: (newValue: string) => void
    setFieldValid?: (isValid: boolean) => void
}

function FieldNameAndReferenceInput({ project, field, name, reference, setName, setReference, setFieldValid}: FieldEditCommonFieldsProps) {
    const [nameShort, setNameShort] = useState(false)
    const [displayNameError, setDisplayNameError] = useState(false)
    const [referenceTaken, setReferenceTaken] = useState(false)
    const [referenceShort, setReferenceShort] = useState(false)

    const handleSetName = (name: string) => {
        setName(name)

        if (!field)
            setReference(name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "_"))
    }

    useEffect(() => {
        let referenceTakenValue = false
        if (!field) {
            referenceTakenValue = project.fields.find(it => it.reference === reference) !== undefined
        }

        setReferenceTaken(referenceTakenValue)

        let referenceShortValue = reference.length === 0
        setReferenceShort(referenceShortValue)

        let nameShortValue = name.length === 0
        setNameShort(name.length === 0)

        setFieldValid && setFieldValid(!nameShortValue && !referenceShort && !referenceTakenValue)

    }, [name, reference])

    return (
        <>
            <TextField label="Name"
                       variant="outlined"
                       error={nameShort && displayNameError}
                       value={name}
                       onBlur={() => setDisplayNameError(true)}
                       onChange={(e) => handleSetName(e.target.value)}/>
            <TextField label="Reference"
                       variant="outlined"
                       value={reference}
                       error={referenceTaken}
                       helperText={referenceTaken ? "This reference is already used in the project." : "Unique reference to use in templates."}
                       onChange={(e) => setReference(e.target.value)}/>
        </>
    );
}

export default FieldNameAndReferenceInput;