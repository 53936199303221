import React, {useState} from 'react';
import SpaceBetween from "../common/SpaceBetween"
import {Chip, Paper, Stack, Typography} from "@mui/material"
import AsyncButton from "../button/AsyncButton"
import {DeleteOutline, SportsScoreOutlined} from "@mui/icons-material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import EvalJson from "../../api/json/EvalJson"
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import {EvalRequests} from "../../api/requests/EvalRequests"
import {useToasts} from "../../hooks/useToasts"
import {TestRunRequests} from "../../api/requests/TestRunRequests"
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import EvalSettingsGrid from "./EvalSettingsGrid"
import EvalProgressBar from "./EvalProgressBar"
import {ProjectJson} from "../../api/json/ProjectJson"

interface EvalSectionHeaderProps {
    project: ProjectJson
    evalJson: EvalJson
    totalValidationExamples: number
    updateEval: (evalJson: EvalJson) => void
}

function EvalSectionHeader({project, evalJson, totalValidationExamples, updateEval}: EvalSectionHeaderProps) {
    const [running, setRunning] = useState(false)
    const [starting, setStarting] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const toasts = useToasts()

    const handleDeleteAll = () => {
        setDeleting(true)
        TestRunRequests.deleteAll(evalJson.id)
            .then(evalJson => {
                updateEval(evalJson)
                setRunning(false)

                // Start new test runs
                handleRestart()
            })
            .catch(toasts.showError)
            .finally(() => setDeleting(false))
    }

    const handleRestart = () => {
        setStarting(true)
        EvalRequests.restart(evalJson.id)
            .then(evalJson => {
                toasts.showMessage("Eval started successfully.", "success")
                updateEval(evalJson)
                setRunning(true)
            })
            .catch(toasts.showError)
            .finally(() => setStarting(false))
    }

    return (
        <Stack spacing={2}>
            <SpaceBetween sx={{mt: 4}}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <ScienceOutlinedIcon/>
                    <Typography variant="h2">Evaluation</Typography>
                </Stack>

                <Stack direction="row" spacing={2}>
                    {evalJson.stats.completedTestRuns > 0 &&
                        <AsyncButton variant="text"
                                     color="error"
                                     waiting={deleting}
                                     endIcon={<DeleteOutline/>}
                                     onClick={handleDeleteAll}>Delete & Restart</AsyncButton>
                    }
                    {evalJson.completed && evalJson.stats.completedTestRuns < totalValidationExamples && !running &&
                        <AsyncButton variant="outlined"
                                     waiting={starting}
                                     endIcon={<PlayArrowIcon/>}
                                     onClick={handleRestart}>Run Missing Tests</AsyncButton>
                    }
                </Stack>
            </SpaceBetween>

            {!evalJson.completed &&
                <EvalProgressBar evalJson={evalJson}
                                 totalValidationExamples={project.stats.numberOfValidationExamples}/>
            }

            {evalJson.stats.completedTestRuns > 0 &&
                <Stack direction="row" spacing={1}>
                    <Chip variant="outlined"
                          color="secondary"
                          label={`${evalJson.stats.completedTestRuns} test runs`}
                          sx={{fontWeight: 600}}
                    />
                    {evalJson.stats.scoredTestRuns === evalJson.stats.completedTestRuns &&
                        <Chip variant="outlined"
                              color="secondary"
                              icon={<SportsScoreOutlined/>}
                              label={`${evalJson.stats.score}% avg score`}
                              sx={{fontWeight: 600}}
                        />
                    }
                    {evalJson.stats.averageLatency !== null &&
                        <Chip variant="outlined"
                              color="secondary"
                              icon={<ScheduleOutlinedIcon/>}
                              label={`${evalJson.stats.averageLatency} ms`}
                              sx={{fontWeight: 600}}
                        />
                    }
                </Stack>
            }

            <Paper variant="outlined" sx={{p: 2}}>
                <EvalSettingsGrid evalJson={evalJson}/>
            </Paper>
        </Stack>
    )
}

export default EvalSectionHeader;