import React, {useEffect, useState} from 'react';
import {Box, Grid, Stack, SxProps, Typography} from "@mui/material"
import DemoDatasetCard from "./DemoDatasetCard"
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import {DemoDatasetJson} from "../../../api/json/DemoDatasetJson"
import {DemoDatasetRequests} from "../../../api/requests/DemoDatasetRequests"
import ApiError from "../../../api/ApiError"
import ErrorMessage from "../../error/ErrorMessage"
import animations from "../../../Animations.module.scss"
import {ProjectRequests} from "../../../api/requests/ProjectRequests"
import {useNavigate} from "react-router-dom"
import {useBackdrop} from "../../../hooks/useBackdrop"

interface DemoDatasetPickerProps {
    sx?: SxProps
}

function DemoDatasetPicker({sx}: DemoDatasetPickerProps) {
    const [demoDatasets, setDemoDatasets] = useState<DemoDatasetJson[]>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()
    const [creating, setCreating] = useState(false)

    const {setBackdropOpen} = useBackdrop()

    const navigate = useNavigate()

    useEffect(() => {
        if (loading) return

        setLoading(true)
        DemoDatasetRequests.list(0)
            .then(response => {
                setError(undefined)
                setDemoDatasets(response.content)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [])

    const handleCreate = (dataset: DemoDatasetJson) => {
        if (creating) return

        setBackdropOpen(true)
        setCreating(true)
        ProjectRequests.create(dataset.description, dataset.name, dataset.id)
            .then(project => {
                setBackdropOpen(false)
                navigate(`/projects/${project.id}/overview`)
            })
            .catch(setError)
            .finally(() => setCreating(false))
    }

    if (loading) {
        return <></>
    }

    if (!demoDatasets || demoDatasets.length === 0) {
        return <></>
    }

    return (
        <Stack sx={sx} spacing={2} className={animations.defaultIn}>
            <Box sx={{maxWidth: 720, margin: "0 auto", textAlign: "center"}}>
                <DataObjectOutlinedIcon color="secondary"/>
                <Typography variant="h2" gutterBottom>Demo datasets</Typography>
                <Typography paragraph>Populate a new project with one of our curated datasets for
                    educational and research purposes.</Typography>
            </Box>

            <Grid container spacing={2}>
                {demoDatasets?.map((dataset, index) => (
                    <Grid item xs={12} md={6} lg={4} key={dataset.id}>
                        <DemoDatasetCard dataset={dataset} onClick={handleCreate}/>
                    </Grid>
                ))}
            </Grid>

            <ErrorMessage error={error} sx={{mt:2}}/>
        </Stack>
    );
}

export default DemoDatasetPicker;