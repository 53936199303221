import React, {useEffect, useState} from 'react';
import {Alert, Box, Button, Fade, FormControl, Grid, Modal, Stack, Typography} from "@mui/material"
import styles from "./ExampleEditor.module.scss"
import ExampleActionsMenu from "./ExampleActionsMenu"
import AsyncButton from "../button/AsyncButton"
import DatasetRadio from "../dataset/DatasetRadio"
import FieldValueInputGroup from "./FieldValueInputGroup"
import SpaceBetween from "../common/SpaceBetween"
import TemplateRenderer from "../templates/TemplateRenderer"
import {ExampleDataset} from "../../api/enum/ExampleDataset"
import FieldValueJson from "../../api/json/FieldValueJson"
import ApiError from "../../api/ApiError"
import {FieldType} from "../../api/enum/FieldType"
import {ExampleRequests} from "../../api/requests/ExampleRequests"
import {ExampleOrigin} from "../../api/enum/ExampleOrigin"
import {ProjectJson} from "../../api/json/ProjectJson"
import ExampleJson from "../../api/json/ExampleJson"
import {calculateTokenCount} from "../../library/TokenCountUtil"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import TemplateSelect from "../templates/TemplateSelect"

interface ExampleEditorModalProps {
    open: boolean
    onClose: () => void
    keepMounted?: boolean
    project: ProjectJson
    origin?: ExampleOrigin
    generativeResultId?: string
    example?: ExampleJson
    template?: AnyTemplateJson
    referenceValues?: Record<string, string>
    defaultDataset?: ExampleDataset
    allowSaveAndNext?: boolean
    didCreate?: (finished: boolean, example: ExampleJson) => void
    didDelete?: () => void
    didUpdate?: (example: ExampleJson) => void
}

function ExampleEditorModal({
                                open,
                                keepMounted,
                                onClose,
                                project,
                                origin,
                                generativeResultId,
                                example,
                                template,
                                referenceValues,
                                defaultDataset,
                                allowSaveAndNext,
                                didCreate,
                                didDelete,
                                didUpdate,
                            }: ExampleEditorModalProps) {
    const [fieldValues, setFieldValues] = useState<FieldValueJson[]>(example?.fieldValues ?? [])
    const [dataset, setDataset] = useState<ExampleDataset>(example?.dataset ?? defaultDataset ?? ExampleDataset.TRAINING)

    const [previewTemplate, setPreviewTemplate] = useState<AnyTemplateJson | undefined>(template)

    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<ApiError>()

    useEffect(() => {
        if (!referenceValues) return

        const defaultFieldValues: FieldValueJson[] = []
        Object.entries(referenceValues).forEach(([key, value]) => {
            defaultFieldValues.push({
                field: project.fields.find(it => it.reference === key)!,
                value: value,
                tokenCounts: null,
            })
        })
        setFieldValues(defaultFieldValues)
    }, [referenceValues])

    const reset = () => {
        const resetFieldValues = [...fieldValues]
        // Reset everything to empty except the dropdowns
        fieldValues.forEach(fieldValue => {
            fieldValue.value = fieldValue.field.type === FieldType.PREDEFINED_OPTIONS ? fieldValue.value : ""
        })
        setFieldValues(resetFieldValues)
    }

    const handleSave = (finished: boolean) => {
        setSaving(true)
        if (!example) {
            ExampleRequests.create(project.id, dataset, origin ?? ExampleOrigin.MANUAL, fieldValues, generativeResultId ?? null)
                .then(saved => {
                    setError(undefined)
                    didCreate && didCreate(finished, saved)

                    if (!finished) reset()
                })
                .catch(setError)
                .finally(() => setSaving(false))
        } else {
            ExampleRequests.update(example.id, dataset, fieldValues)
                .then(updated => {
                    setError(undefined)
                    didUpdate && didUpdate(updated)
                    onClose()
                })
                .catch(setError)
                .finally(() => setSaving(false))
        }
    }

    useEffect(() => {
        // TODO: This doesn't work 100%, if you revert your changes it doesn't return to false. Haven't figured it out.
        if (!example) {
            setUnsavedChanges(true)
        } else {
            setUnsavedChanges(example.fieldValues !== fieldValues || example.dataset !== dataset)
        }
    }, [example, fieldValues, dataset])

    const tokenCount = (example && template) ? calculateTokenCount(example, template, project.defaultTokenizer) : null
    const saveAndNextMode = example === undefined && allowSaveAndNext

    return (
        <Modal
            open={open}
            keepMounted={keepMounted}
            onClose={(_, reason) => {
                if (reason === "escapeKeyDown" || !unsavedChanges)
                    onClose()
            }}
        >
            <Fade in={open}>
                <Stack className={styles.modal}>
                    <Stack direction="row"
                           justifyContent="space-between"
                           alignItems="center"
                           className={styles.header}
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography variant="h2">{!example ? "Create" : "Edit"} Example</Typography>
                            <ExampleActionsMenu example={example}
                                                didDelete={() => {
                                                    didDelete && didDelete()
                                                    onClose()
                                                }}/>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Button onClick={() => {
                                if (example)
                                    setFieldValues(example.fieldValues)
                                onClose()
                            }}
                                    variant="outlined"
                                    color="secondary"
                            >
                                {unsavedChanges ? "Cancel" : "Close"}
                            </Button>
                            <AsyncButton waiting={saving}
                                         disabled={!unsavedChanges}
                                         onClick={() => handleSave(!saveAndNextMode)}>
                                {saveAndNextMode ? "Save & next" : "Save & close"}
                            </AsyncButton>
                        </Stack>
                    </Stack>

                    <Box className={styles.container}>
                        {error &&
                            <Alert sx={{zIndex: 100, mb: 3}}
                                   severity="error">
                                {error.resolveUserMessage()}
                            </Alert>
                        }

                        <Grid container spacing={6} className={styles.content}>
                            <Grid item xs={12} lg={6}>
                                <Stack spacing={3}>
                                    <FormControl>
                                        <Typography variant="subtitle1">Dataset</Typography>
                                        <DatasetRadio dataset={dataset} setDataset={setDataset}/>
                                    </FormControl>

                                    <FieldValueInputGroup title="Field Values"
                                                          fieldValues={fieldValues}
                                                          fields={project.fields}
                                                          onChange={(newValue, reference) => {
                                                              const newFieldValues = [...fieldValues]
                                                              const index = newFieldValues.findIndex(fieldValue => fieldValue.field.reference === reference)
                                                              if (index == -1) {
                                                                  let field = project.fields.find(field => field.reference === reference)!
                                                                  setFieldValues([
                                                                      {
                                                                          field: field,
                                                                          value: newValue,
                                                                          tokenCounts: null,
                                                                      },
                                                                      ...newFieldValues,
                                                                  ])
                                                              } else {
                                                                  newFieldValues[index].value = newValue
                                                                  setFieldValues(newFieldValues)
                                                              }
                                                          }
                                                          }/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Stack spacing={2}>
                                    <SpaceBetween sx={{mt: 2}}>
                                        <Typography variant="subtitle1">Preview</Typography>
                                        {/** TODO: Update token count as you edit field values ? **/}
                                        {tokenCount &&
                                            <Typography
                                                variant="caption">{tokenCount.toLocaleString()} tokens</Typography>
                                        }
                                    </SpaceBetween>

                                    <TemplateSelect templates={project.templates} value={previewTemplate}
                                                    onChange={setPreviewTemplate}/>
                                    {previewTemplate && fieldValues.length > 0 &&
                                        <Box>
                                            <TemplateRenderer dataSource={fieldValues} templateParts={previewTemplate}/>
                                        </Box>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            </Fade>
        </Modal>
    );
}

export default ExampleEditorModal;