import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Stack, TextField} from "@mui/material"
import styles from "./Accounts.module.scss"
import {OrganizationRequests} from "../../api/requests/OrganizationRequests"
import useSaveShortcut from "../../hooks/useSaveShortcut"
import {OrganizationJson} from "../../api/json/OrganizationJson"
import {useToasts} from "../../hooks/useToasts"
import AsyncButton from "../button/AsyncButton"
import {useApi} from "../../hooks/useApi"
import {OrganizationWithRoleJson} from "../../api/json/OrganizationWithRoleJson"

interface OrganizeDetailsFormProps {
    organization: OrganizationWithRoleJson
    didSaveDetails: (organization: OrganizationWithRoleJson) => void
    didAddLogo: (organization: OrganizationWithRoleJson) => void

    buttonLabel?: string
    buttonAlignment?: "left" | "right"
}

function OrganizationDetailsForm({
                                     organization,
                                     didSaveDetails,
                                     didAddLogo,
                                     buttonLabel,
                                     buttonAlignment,
                                 }: OrganizeDetailsFormProps) {
    const [name, setName] = useState<string>("")

    const [saveInProgress, setSaveInProgress] = useState(false)

    const [replacingLogo, setReplacingLogo] = useState(false)

    const toasts = useToasts()
    const {setCurrentOrganization} = useApi()

    useEffect(() => {
        if (organization) setName(organization.name)
    }, [organization])

    const disableSave = name.length === 0
    const handleSave = () => {
        if (disableSave) return

        setSaveInProgress(true)
        OrganizationRequests.update(organization!.id, name)
            .then((value) => {
                setCurrentOrganization(value)
                didSaveDetails(value)
                toasts.showSaved()
            })
            .catch(toasts.showError)
            .finally(() => setSaveInProgress(false))
    }
    useSaveShortcut(handleSave)

    const handleLogo = ((logoFile: File) => {
        if (!logoFile || !organization) return

        setReplacingLogo(true)
        OrganizationRequests.replaceLogo(organization?.id, logoFile)
            .then(image => {
                setReplacingLogo(false)
                const newOrganization = {...organization, name: name, logo: image, role: organization!.role}
                setCurrentOrganization(newOrganization)
                toasts.showSaved()
                didAddLogo(newOrganization)
            })
            .catch(toasts.showError)
    })

    return (
        <>
            <Box>
                <input
                    accept="image/jpg, image/jpeg, image/png"
                    hidden
                    id="choose-avatar"
                    type="file"
                    onChange={event => {
                        if (!event.target.files?.length) return
                        handleLogo(event.target.files[0])
                    }}
                />
                <Stack direction="row" spacing={1} alignItems="center" sx={{pb: 2}}>
                    {organization.logo &&
                        <img src={organization.logo.sizes.thumbnail}
                             className={styles.organizationLogo}
                             alt="Logo"/>}
                    <label htmlFor="choose-avatar">
                        <Button variant="text" component="span">
                            Choose logo
                        </Button>
                    </label>
                    {replacingLogo && <CircularProgress size={30}/>}
                </Stack>
            </Box>

            <TextField
                label="Name"
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
            />

            <Stack direction="row" spacing={2} justifyContent={buttonAlignment === "right" ? "flex-end" : "flex-start"}
                   sx={{mt: 2}}>
                <AsyncButton
                    waiting={saveInProgress}
                    onClick={handleSave}
                    disabled={disableSave}
                >
                    {buttonLabel ?? "Save"}
                </AsyncButton>
            </Stack>
        </>
    );
}

export default OrganizationDetailsForm;