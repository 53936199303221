import {PagedActivityJson, PagedProjectsJson} from "../json/PagedResponseJson"
import RequestBuilder from "../RequestBuilder"
import {ProjectJson} from "../json/ProjectJson"
import {Method} from "../enum/Method"
import RequestSender from "../RequestSender"
import {TokenizerAlgorithm} from "../enum/TokenizerAlgorithm"
import {TemplateSyntax} from "../enum/TemplateSyntax"
import {JsonlStructure} from "../enum/JsonlStructure"
import EvalSettingsJson from "../json/EvalSettingsJson"

export class ProjectRequests {
    static list(page: number = 0): Promise<PagedProjectsJson> {
        const queryParams = new URLSearchParams()
        queryParams.append("page", page.toString())

        return new RequestBuilder(Method.Get, `projects?${queryParams.toString()}`).buildAndSend()
    }

    static get(id: string): Promise<ProjectJson> {
        return new RequestBuilder(Method.Get, `projects/${id}`).buildAndSend()
    }

    static create(description: string, name?: string, demoDatasetId?: string): Promise<ProjectJson> {
        return new RequestBuilder(Method.Post, "projects")
            .withJson({
                name: name,
                description: description,
                demoDatasetId: demoDatasetId,
            })
            .buildAndSend()
    }

    static copy(projectId: string, copyExamples: boolean): Promise<ProjectJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/copies`)
            .withJson({copyExamples: copyExamples})
            .buildAndSend()
    }

    static update(
        projectId: string,
        name?: string,
        description?: string,
        defaultTokenizer?: TokenizerAlgorithm,
        evalSettings?: EvalSettingsJson,
    ): Promise<ProjectJson> {
        return new RequestBuilder(Method.Put, `projects/${projectId}`)
            .withJson({
                name: name,
                description: description,
                defaultTokenAlgorithm: defaultTokenizer,
                evalSettings: evalSettings,
            })
            .buildAndSend()
    }

    static delete(id: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `projects/${id}`).buildAndSend()
    }

    static listActivities(id: string): Promise<PagedActivityJson> {
        return new RequestBuilder(Method.Get, `projects/${id}/activities`).buildAndSend()
    }

    static exportJsonl(id: string, templateId: string, syntax: TemplateSyntax | null, jsonlStructure: JsonlStructure | null): Promise<ArrayBuffer> {
        const queryParams = new URLSearchParams()
        queryParams.append("templateId", templateId)

        if (syntax) {
            queryParams.append("syntax", syntax)
        }
        if (jsonlStructure) {
            queryParams.append("jsonlStructure", jsonlStructure)
        }

        let endpoint = `projects/${id}/exports/jsonl?${queryParams.toString()}`
        const request = new RequestBuilder(Method.Get, endpoint)
            .build()
        return RequestSender.sendRequest(request, true)
    }

    static exportCsv(id: string): Promise<ArrayBuffer> {
        const request = new RequestBuilder(Method.Get, `projects/${id}/exports/csv`)
            .build()
        return RequestSender.sendRequest(request, true)
    }
}