import React, {useEffect, useState} from 'react';
import {Skeleton, Stack} from "@mui/material";
import CurrentUserJson from "../../api/json/CurrentUserJson";
import {UserRequests} from "../../api/requests/UserRequests";
import PageHeader from "../layouts/PageHeader";
import UserDetailsForm from "./UserDetailsForm";
import {useApi} from "../../hooks/useApi"
import FormContainer from "../layouts/FormContainer"
import ApiError from "../../api/ApiError"
import ErrorMessage from "../error/ErrorMessage"

function CurrentUserPage() {
    const [user, setUser] = useState<CurrentUserJson>()
    const [error, setError] = useState<ApiError>()

    const {setCurrentUser} = useApi()

    useEffect(() => {
        UserRequests.getCurrentUser()
            .then(setUser)
            .catch(error => setError(error))
    }, [])

    if (!user) {
        return (
            <Stack spacing={2} sx={{maxWidth: 450}}>
                <Skeleton height={60}/>
                <Skeleton height={50}/>
                <Skeleton height={50}/>
                <Skeleton height={30}/>
                <Skeleton height={50}/>
                <Skeleton height={50}/>
                <Skeleton width={120} height={40}/>
            </Stack>
        )
    }

    return (
        <>
            <PageHeader pageTitle="My Account">
                Enter your details and upload your avatar for a personalized experience.
            </PageHeader>

            {error && <ErrorMessage error={error}/>}

            <FormContainer>
                <UserDetailsForm user={user} didSave={savedUser => {
                    setUser(savedUser)
                    setCurrentUser(savedUser)
                }}/>
            </FormContainer>
        </>
    );
}

export default CurrentUserPage;