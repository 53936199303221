export type AnyError = Error | ApiError

export default class ApiError extends Error {
    httpCode: number

    constructor(message: string, httpCode: number) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiError.prototype);

        this.httpCode = httpCode
    }

    resolveUserMessage(): string {
        if (this.message === "") {
            return `An error occurred (${this.httpCode})`
        }

        return this.message
    }
}