import {ProjectJson} from "../api/json/ProjectJson"
import {AnyTemplateJson} from "../api/json/TemplateJson"

export const getDefaultTemplate = (project?: ProjectJson): AnyTemplateJson | undefined => {
    if (!project) return undefined

    if (project.templates.length > 0)
        return project.templates[0]

    return undefined
}