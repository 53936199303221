import React, {useEffect, useState} from 'react';
import {TestRunJson} from "../../api/json/TestRunJson"
import ApiError from "../../api/ApiError"
import {ProjectJson} from "../../api/json/ProjectJson"
import {AnyModelJson} from "../../api/json/TemplatedModelJson"
import {PagedResponseJson} from "../../api/json/PagedResponseJson"
import {Box, CircularProgress} from "@mui/material"
import ErrorMessage from "../error/ErrorMessage"
import {TestRunRequests} from "../../api/requests/TestRunRequests"
import TestRunCard from "./TestRunCard"

interface TestRunScoringPanelProps {
    model: AnyModelJson
    reloadEvalStats: () => void
}

function TestRunScoringPanel({model, reloadEvalStats}: TestRunScoringPanelProps) {
    const [page, setPage] = useState<PagedResponseJson<TestRunJson>>()
    const [error, setError] = useState<ApiError>()

    useEffect(() => {
        loadPage()
    }, [])

    const loadPage = () => {
        TestRunRequests.list(model.eval.id, 0, true)
            .then(response => setPage(response))
            .catch(setError)
    }

    if (!page) {
        return <CircularProgress/>
    }

    if (error) {
        return <ErrorMessage error={error}/>
    }

    if (page.content.length === 0) {
        return <Box sx={{mt: 2}}>All done!</Box>
    }

    const testRun = page.content[0]

    return (
        <Box sx={{marginBottom: "420px", mt: 2}}>
            <TestRunCard
                key={testRun.example.id}
                evalJson={model.eval}
                template={model.template}
                testRun={testRun}
                didUpdate={(updated) => {
                    loadPage()
                    reloadEvalStats()
                }}
                didDelete={() => loadPage()}
            />
        </Box>
    )
}

export default TestRunScoringPanel;