import React from 'react';
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography} from "@mui/material"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

interface TemplateIconButtonSelectProps {
    templates: AnyTemplateJson[]
    value?: AnyTemplateJson
    onChange: (newValue: AnyTemplateJson) => void
}

function TemplateIconButtonSelect({templates, value, onChange}: TemplateIconButtonSelectProps) {
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>()
    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <TextSnippetOutlinedIcon/>
            </IconButton>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>

                        {templates.map(template => (
                            <MenuItem onClick={() => {
                                setMenuAnchor(undefined)
                                onChange(template)
                            }}
                                      key={template.id}
                            >
                                <ListItemText>
                                    <Typography
                                        fontWeight={value?.id === template.id ? 600 : 400}>{template.name}</Typography>
                                </ListItemText>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Paper>
            </Menu>
        </>
    )

}

export default TemplateIconButtonSelect;