import React, {useRef, useState} from 'react';
import {FormControlLabel, Stack, Switch, SxProps, TextField} from "@mui/material"
import AsyncButton from "../../button/AsyncButton"
import ApiError from "../../../api/ApiError"
import ErrorMessage from "../../error/ErrorMessage"
import NumberInput from "../../form/NumberInput"
import {MarketingEventJson, MarketingEventProperties} from "../../../api/json/MarketingEventJson"
import {MarketingEventRequests} from "../../../api/requests/MarketingEventRequests"
import useEnterShortcut from "../../../hooks/useEnterShortcut"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface MarketingEventFormProps {
    sx?: SxProps
    existing?: MarketingEventJson
    didUpdate?: (dataset: MarketingEventJson) => void
    didCreate?: (dataset: MarketingEventJson) => void
}

function MarketingEventForm({didCreate, didUpdate, existing, sx}: MarketingEventFormProps) {
    const formRef = useRef<HTMLElement>(null)

    const [name, setName] = useState(existing?.name ?? "")
    const [description, setDescription] = useState(existing?.description ?? "")
    const [meetingUrl, setMeetingUrl] = useState(existing?.meetingUrl ?? "")
    const [start, setStart] = useState<Date>(existing ? new Date(existing.start) : new Date())
    const [duration, setDuration] = useState<number>(existing?.duration ?? 60)
    const [occurrences, setOccurrences] = useState<number>(existing?.occurrences ?? 1)
    const [published, setPublished] = useState<boolean>(existing?.published ?? false)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<ApiError>()

    useEnterShortcut(formRef, () => {
        existing ? handleUpdate() : handleCreate()
    })

    const resolveProperties = (): MarketingEventProperties => {
        return {
            name: name,
            description: description,
            meetingUrl: meetingUrl,
            start: start?.toISOString(),
            duration: duration,
            occurrences: occurrences,
            published: published,
        }
    }

    const handleUpdate = () => {
        if (!existing)
            return

        setSaving(true)
        MarketingEventRequests.update(existing.id, resolveProperties())
            .then(updated => {
                didUpdate && didUpdate(updated)
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setSaving(false))
    }

    const handleCreate = () => {
        if (!name)
            return

        setSaving(true)
        MarketingEventRequests.create(resolveProperties())
            .then(created => {
                setError(undefined)
                didCreate && didCreate(created)
            })
            .catch(setError)
            .finally(() => setSaving(false))
    }

    const disabled = !name

    return (
        <Stack spacing={2} sx={sx} ref={formRef}>
            <TextField label="Name"
                       value={name}
                       onChange={(e) => {
                           if (e.target.value.length > 60) return
                           setName(e.target.value)
                       }}
            />

            <TextField label="Description"
                       multiline
                       value={description}
                       onChange={(e) => {
                           if (e.target.value.length > 1500) return
                           setDescription(e.target.value)
                       }}
            />

            <TextField label="Meeting URL"
                       value={meetingUrl}
                       helperText="Must be a valid URL starting with https://"
                       onChange={(e) => {
                           if (e.target.value.length > 255) return
                           setMeetingUrl(e.target.value)
                       }}
            />

            <DatePicker
                selected={start}
                onChange={date => date && setStart(date)}
                showTimeSelect
                customInput={
                    <TextField fullWidth label="Start Date and Time"
                               helperText={Intl.DateTimeFormat().resolvedOptions().timeZone}/>}
                dateFormat="Pp"
            />

            <NumberInput label="Duration"
                         value={duration}
                         min={1}
                         max={60 * 12}
                         onChange={setDuration}
                         helperText="How many minutes long the event is"
            />

            <NumberInput label="Occurrences"
                         value={occurrences}
                         min={1}
                         max={50}
                         onChange={setOccurrences}
                         helperText="Number of days in a row (1 by default)"
            />

            <FormControlLabel
                control={
                    <Switch checked={published} onChange={e => setPublished(e.target.checked)}/>
                }
                label="Published"
            />

            <Stack direction="row" justifyContent="flex-end">
                {existing ?
                    <AsyncButton waiting={saving} disabled={disabled} onClick={() => handleUpdate()}>
                        Update
                    </AsyncButton>
                    :
                    <AsyncButton waiting={saving} disabled={disabled} onClick={() => handleCreate()}>
                        Create
                    </AsyncButton>
                }
            </Stack>

            <ErrorMessage error={error}/>
        </Stack>
    );
}

export default MarketingEventForm;