import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Divider, Paper, Stack, Typography} from "@mui/material"
import {ProjectJson} from "../../api/json/ProjectJson"
import AsyncButton from "../button/AsyncButton"
import ApiError from "../../api/ApiError"
import useEnterShortcut from "../../hooks/useEnterShortcut"
import ErrorMessage from "../error/ErrorMessage"
import FieldNameAndReferenceInput from "../fields/FieldNameAndReferenceInput"
import CompletionOptionsForm from "../completions/CompletionOptionsForm"
import ModelFinder from "../models/ModelFinder"
import {AnyModelSpecJson} from "../../api/json/ModelSpecJson"
import {CompletionOptionsJson} from "../../api/json/CompletionOptionsJson"
import TransformJson from "../../api/json/TransformJson"
import SpaceBetween from "../common/SpaceBetween"
import {TransformJobRequests} from "../../api/requests/TransformJobRequests"
import TransformJobJson from "../../api/json/TransformJobJson"
import ModalBase from "../modal/ModalBase"
import {TransformJobEstimateJson} from "../../api/json/TransformJobEstimateJson"

interface StartTransformJobModalProps {
    project: ProjectJson
    transform: TransformJson
    open: boolean
    setOpen: (open: boolean) => void
    didStart: (newJob: TransformJobJson) => void
}

function NewTransformJobModal({project, transform, open, setOpen, didStart}: StartTransformJobModalProps) {
    const [step, setStep] = useState(0)

    const [fieldName, setFieldName] = useState<string>("")
    const [fieldReference, setFieldReference] = useState<string>("")
    const [fieldValid, setFieldValid] = useState(false)

    const [model, setModel] = useState<AnyModelSpecJson>()
    const [completionOptions, setCompletionOptions] = useState<CompletionOptionsJson>()

    const [estimate, setEstimate] = useState<TransformJobEstimateJson>()
    const [estimateError, setEstimateError] = useState<ApiError>()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<ApiError>()

    const modalRef = useRef<HTMLDivElement>(null)

    useEnterShortcut(modalRef, () => {
        if (open && !disabled) {
            handleCreate()
        }
    })

    const handleCreate = () => {
        if (!model || !completionOptions) return

        setSaving(true)
        TransformJobRequests.create(project.id, transform.id, fieldName, fieldReference, model, completionOptions)
            .then(transformJob => {
                setOpen(false)
                didStart(transformJob)
            })
            .catch(setError)
            .finally(() => setSaving(false))
    }

    useEffect(() => {
        if (!model || !completionOptions) return

        TransformJobRequests.estimate(project.id, transform.id, model, completionOptions)
            .then(setEstimate)
            .catch(setEstimateError)
    }, [model, completionOptions])

    const disabled = fieldName.length === 0 || fieldReference.length === 0 || !model || !completionOptions
    return (
        <ModalBase
            open={open}
            setOpen={setOpen}
            innerRef={modalRef}
            size="large"
            title="New Transform Job"
        >
            <Stack spacing={2}>
                <Typography paragraph>This will create a new field and populate it with completions from your prompt.</Typography>
                {step === 0 &&
                    <>
                        <Typography variant="subtitle1">New Destination Field</Typography>
                        <FieldNameAndReferenceInput project={project}
                                                    name={fieldName}
                                                    reference={fieldReference}
                                                    setName={setFieldName}
                                                    setReference={setFieldReference}
                                                    setFieldValid={setFieldValid}
                        />

                        <Button variant="contained" disabled={!fieldValid} onClick={() => setStep(1)}
                                sx={{alignSelf: "flex-end"}}>Next</Button>
                    </>
                }
                {step === 1 &&
                    <>
                        <Typography variant="subtitle1">Model to Use</Typography>
                        <ModelFinder value={model} onChange={setModel}/>

                        <SpaceBetween>
                            <Button onClick={() => setStep(0)}>Back</Button>
                            <Button variant="contained" disabled={!model} onClick={() => setStep(2)}>Next</Button>
                        </SpaceBetween>
                    </>
                }
                {step === 2 &&
                    <>
                        {model &&
                            <>
                                {estimateError && <ErrorMessage error={error}/>}
                                <Typography variant="subtitle1">Completion Options</Typography>
                                <CompletionOptionsForm platform={model.platform}
                                                       hideNumberOfCompletions
                                                       onChange={setCompletionOptions}/>

                                {estimate &&
                                    <Box component={Paper} variant="outlined" sx={{p:2, alignSelf: "flex-end"}}>
                                        <Stack spacing={1}>
                                            <Typography variant="subtitle1">Estimate</Typography>
                                            <Typography variant="body1">Cost: ${estimate.estimatedCost}</Typography>

                                            <Divider sx={{width: 60}}/>
                                            <Typography variant="caption">{estimate.totalPromptTokens.toLocaleString()} prompt tokens, {estimate.maxCompletionTokens.toLocaleString()} max completion tokens</Typography>
                                            {completionOptions?.maxTokens === null &&
                                                <Typography variant="caption">Set max tokens for more accurate estimate.</Typography>
                                            }
                                        </Stack>
                                    </Box>
                                }

                                <SpaceBetween>
                                    <Button onClick={() => setStep(1)}>Back</Button>
                                    <AsyncButton variant="contained"
                                                 disabled={disabled}
                                                 waiting={saving}
                                                 onClick={() => handleCreate()}
                                    >
                                        Start
                                    </AsyncButton>
                                </SpaceBetween>
                            </>
                        }
                    </>
                }

                {error && <ErrorMessage error={error}/>}
            </Stack>
        </ModalBase>
    );
}

export default NewTransformJobModal;