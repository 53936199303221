import {PagedResponseJson} from "../json/PagedResponseJson"
import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {InviteJson} from "../json/InviteJson"
import {MembershipRole} from "../enum/MembershipRole";
import {InviteAcceptJson} from "../json/InviteAcceptJson";
import MembershipJson from "../json/MembershipJson";

export class InviteRequests {
    static list(): Promise<PagedResponseJson<InviteJson>> {
        return new RequestBuilder(Method.Get, "invites").buildAndSend()
    }

    static get(code: string): Promise<InviteAcceptJson> {
        return new RequestBuilder(Method.Get, `invites/by-code/${code}`)
            .unauthenticated()
            .withoutOrganization()
            .buildAndSend()
    }

    static create(email: string, role: MembershipRole): Promise<InviteJson> {
        return new RequestBuilder(Method.Post, "invites")
            .withJson({
                email: email,
                role: role
            })
            .buildAndSend()
    }

    static accept(code: string): Promise<MembershipJson> {
        return new RequestBuilder(Method.Post, `invites/by-code/${code}/accept`)
            .withoutOrganization()
            .buildAndSend()
    }

    static delete(id: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `invites/${id}`).buildAndSend()
    }
}