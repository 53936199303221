import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import throttle from "lodash.throttle"
import {CircularProgress} from "@mui/material"
import {PagedResponseJson} from "../../api/json/PagedResponseJson"
import {AnyError} from "../../api/ApiError"
import ErrorMessage from "../error/ErrorMessage"

interface ScrollPaginatorProps {
    children: ReactNode
    pages: PagedResponseJson<any>[]
    loadNextPage: (pageNumber: number) => void
    error?: AnyError
}

function ScrollPaginator({children, pages, loadNextPage, error}: ScrollPaginatorProps) {
    const [loadingPageNumber, setLoadingPageNumber] = useState<number | null>(null)
    const lastPage = pages.length > 0 ? pages[pages.length - 1] : null

    const loadPage = (nextPageNumber: number) => {
        if (loadingPageNumber !== null) return
        if (lastPage && nextPageNumber > lastPage.totalPages - 1) return

        if (!pages.find(p => p.number === nextPageNumber)) {
            setLoadingPageNumber(nextPageNumber)
            loadNextPage(nextPageNumber)
        }
    }

    useEffect(() => {
        if (pages.find(p => p.number === loadingPageNumber)) {
            setLoadingPageNumber(null)
        }
        if (pages.length === 0) {
            loadPage(0)
        }
    }, [pages])

    const pixelsFromBottomToLoadMore = 1200
    const handleScroll = () => {
        if (!lastPage) return
        if (loadingPageNumber !== null) return

        if (window.scrollY + window.innerHeight >= document.body.offsetHeight - pixelsFromBottomToLoadMore) {
            loadPage(lastPage.number + 1)
        }
    }

    const throttledHandleScroll = useMemo(() => throttle(handleScroll, 300), [loadingPageNumber])

    useEffect(() => {
        window.addEventListener("scroll", throttledHandleScroll)
        return () => {
            window.removeEventListener("scroll", throttledHandleScroll)
            throttledHandleScroll.cancel()
        }
    })

    return (
        <>
            {children}
            {!error && loadingPageNumber !== null && <CircularProgress sx={{mt: 2}}/>}
            {error && <ErrorMessage error={error} sx={{mt:2}}/>}
        </>
    );
}

export default ScrollPaginator;