import React, {useCallback, useEffect, useState} from 'react'
import {Fab, Grid, Typography} from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import {ProjectRequests} from "../../../api/requests/ProjectRequests"
import {RoleCheck} from "../../../library/RoleCheck";
import ProjectsTable from "./ProjectsTable";
import ActivitiesList from "../../activities/ActivitiesList";
import PageHeader from "../../layouts/PageHeader";
import {useApi} from "../../../hooks/useApi"
import {useNavigate} from "react-router-dom"
import ApiError from "../../../api/ApiError"
import {PagedProjectsJson} from "../../../api/json/PagedResponseJson"
import ScrollPaginator from "../../misc/ScrollPaginator"
import NewProjectModal from "../NewProjectModal"
import DemoDatasetPicker from "./DemoDatasetPicker"

function ProjectsPage() {
    const [error, setError] = useState<ApiError>()

    const [loading, setLoading] = useState(false)
    const [showNewProjectModal, setShowNewProjectModal] = useState(false)
    const [pages, setPages] = useState<PagedProjectsJson[]>([])

    const {token, currentUser} = useApi()

    const navigate = useNavigate()

    const loadPage = useCallback((pageNumber: number) => {
        if (loading) return

        setLoading(true)
        ProjectRequests.list(pageNumber)
            .then(response => {
                setPages([...pages, response])
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [pages, loading])

    useEffect(() => {
        if (!token || !currentUser) {
            navigate("/login")
        }
    }, [token, currentUser])

    const fab = (
        RoleCheck.atLeastManager()
            ? <Fab color="primary" aria-label="add" onClick={() => setShowNewProjectModal(true)}>
                <AddIcon/>
            </Fab>
            : undefined
    )

    const projects = pages.flatMap(page => page.content)
    return (
        <>
            <PageHeader pageTitle="Projects" fab={fab}/>

            <Grid container spacing={6}>
                <Grid item lg={8} md={9} sm={12}>
                    <ScrollPaginator pages={pages}
                                     loadNextPage={pageNumber => loadPage(pageNumber)}
                                     error={error}
                    >
                        {projects.length > 0 &&
                            <ProjectsTable projects={projects}
                                           didCopy={copy => {
                                               if (pages.length > 0) {
                                                   setPages(pages => [
                                                       {
                                                           ...pages[0],
                                                           content: [copy, ...pages[0].content],
                                                       },
                                                       ...pages.slice(1),
                                                   ]);
                                               }
                                           }}
                                           didRename={updated => {
                                               setPages(pages =>
                                                   pages.map(page => ({
                                                       ...page,
                                                       content: page.content.map(project =>
                                                           project.id === updated.id ? { ...project, name: updated.name } : project
                                                       ),
                                                   }))
                                               );
                                           }}
                                           didDelete={deletedId => {
                                               setPages(pages =>
                                                   pages.map(page => ({
                                                       ...page,
                                                       content: page.content.filter(project => project.id !== deletedId),
                                                   }))
                                               )
                                           }}
                            />
                        }
                    </ScrollPaginator>
                </Grid>
                <Grid item lg={4} md={3} sm={12}>
                    {projects.length > 0 &&
                        <>
                            <Typography variant="h2">Latest Activity</Typography>
                            <ActivitiesList/>
                        </>
                    }
                </Grid>
            </Grid>

            <DemoDatasetPicker sx={{mt:8}}/>

            <NewProjectModal open={showNewProjectModal} setOpen={setShowNewProjectModal}/>
        </>
    )
}

export default ProjectsPage