import { useEffect, useCallback } from 'react';

function useSaveShortcut(saveMethod: () => void, includeEnter: boolean = false): void {
    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            const isCmdOrCtrl = event.metaKey || event.ctrlKey

            if ((isCmdOrCtrl && event.key.toLowerCase() === "s") || includeEnter && event.key.toLowerCase() === "enter") {
                event.preventDefault()
                saveMethod()
            }
        },
        [saveMethod]
    )

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        };
    }, [handleKeyDown])
}

export default useSaveShortcut;