import React from 'react';
import {AnyError} from "../../api/ApiError"
import {Alert, SxProps} from "@mui/material"

interface ErrorMessageProps {
    error?: AnyError
    onClose?: () => void
    sx?: SxProps
}

function ErrorMessage({error, onClose, sx}: ErrorMessageProps) {
    if (!error)
        return <></>

    let message = ""
    if ("httpCode" in error) {
        message = error.resolveUserMessage()
    } else {
        message = error.message
    }

    return (
        <Alert severity="error" onClose={onClose} sx={sx}>{message}</Alert>
    );
}

export default ErrorMessage;