import React, {useEffect, useState} from 'react';
import {Card, LinearProgress, Typography} from "@mui/material"
import SpaceBetween from "../common/SpaceBetween"
import {ImportJobJson} from "../../api/json/ImportJobJson"
import {ImportJobRequests} from "../../api/requests/ImportJobRequests"
import styles from "./ImportProgressCard.module.scss"

interface ImportProgressCardProps {
    projectId: string
    job: ImportJobJson
    handleRemove: (job: ImportJobJson) => void
}

function ImportProgressCard({projectId, job, handleRemove}: ImportProgressCardProps) {
    const [latest, setLatest] = useState(job)
    const [removing, setRemoving] = useState(false)

    const load = () => {
        if (removing) return

        ImportJobRequests.get(projectId, job.id)
            .then(response => {
                setLatest(response)
                if (response.completed || response.failed) {
                    setRemoving(true)
                    setTimeout(() => handleRemove(job), 3000)
                }
            })
    }

    useEffect(() => {
        const interval = setInterval(() => load(), 1000)
        return () => clearInterval(interval)
    }, [])

    const progress = (latest.processedCount / latest.totalRowCount) * 100

    return (
        <Card variant="outlined"
              className={removing ? styles.out : styles.in}
              sx={{
                  p: 3,
                  margin: "0 auto",
                  width: 1,
                  maxWidth: 520,
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.08)",
              }}
              id={job.id}>
            <SpaceBetween>
                <Typography variant="subtitle1">Import in progress</Typography>
                <Typography variant="caption">{latest.totalRowCount} examples</Typography>
            </SpaceBetween>
            <LinearProgress variant="determinate" value={progress} sx={{mt: 1}}/>
        </Card>
    );
}

export default ImportProgressCard;