import React, {useEffect, useState} from 'react';
import {CircularProgress} from "@mui/material"
import {ProjectJson} from "../../api/json/ProjectJson"
import ApiError from "../../api/ApiError"
import {AdminRequests} from "../../api/requests/AdminRequests"
import AdminProjectsTable from "./AdminProjectsTable"
import ErrorMessage from "../error/ErrorMessage"

function AdminAllProjectsTable() {
    const [projects, setProjects] = useState<(ProjectJson)[]>()
    const [total, setTotal] = useState<number>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    useEffect(() => {
        if (loading) return

        setLoading(true)
        AdminRequests.listProjects()
            .then((response) => {
                setError(undefined)
                setProjects(response.content)
                setTotal(response.totalElements)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [])

    if (error)
        return <ErrorMessage error={error}/>

    if (loading)
        return <CircularProgress/>

    return (
        <AdminProjectsTable projects={projects} total={total}/>
    )
}

export default AdminAllProjectsTable;