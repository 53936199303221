import React, {useEffect, useState} from 'react';
import SynthesisEstimateJson from "../../../api/json/SynthesisEstimateJson"
import ApiError from "../../../api/ApiError"
import {SynthesisRequests} from "../../../api/requests/SynthesisRequests"
import {ProjectJson} from "../../../api/json/ProjectJson"
import {useToasts} from "../../../hooks/useToasts"
import {Box, SxProps} from "@mui/material"
import {useIntegrations} from "../../../hooks/useIntegrations"
import AsyncButton from "../../button/AsyncButton"
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import PriceCheckInfo from "../../common/PriceCheckInfo"
import SynthesisResultJson from "../../../api/json/SynthesisResultJson"
import {AnyTemplateJson} from "../../../api/json/TemplateJson"

interface GenerateButtonProps {
    project: ProjectJson
    template: AnyTemplateJson
    disabled?: boolean
    handleResult: (result: SynthesisResultJson) => void
    sx?: SxProps
}

function SynthesizeButton({project, template, disabled, handleResult, sx}: GenerateButtonProps) {
    const [generateInProgress, setGenerateInProgress] = useState(false)
    const [estimate, setEstimate] = useState<SynthesisEstimateJson>()
    const [generateEstimateError, setGenerateEstimateError] = useState<ApiError>()

    const toasts = useToasts()
    const {openAi} = useIntegrations()

    const singleSynthesisSettings = {
        templateId: template.id!,
        numberOfExamples: 1,
        batchSize: 1,
        alignmentText: "",
        chatModel: "gpt-3.5-turbo",
        validationRatio: 0,
        autoSave: false,
    }

    useEffect(() => {
        SynthesisRequests.estimate(project.id, singleSynthesisSettings)
            .then(setEstimate)
            .catch(setGenerateEstimateError)
    }, [])

    const generateExample = () => {
        setGenerateInProgress(true)
        SynthesisRequests.synthesize(project.id, singleSynthesisSettings)
            .then((results) => {
                const result = results[0]
                handleResult(result)
            })
            .catch(toasts.showError)
            .finally(() => setGenerateInProgress(false))
    }

    const generateButtonDisabled = disabled || generateEstimateError !== undefined

    if (!openAi || !estimate) return <></>

    return (
        <PriceCheckInfo flipped info={disabled ? null : `$${estimate.cost} estimated cost`} size="small">
            <Box sx={sx}>
                <AsyncButton waiting={generateInProgress} onClick={generateExample}
                             disabled={generateButtonDisabled}
                             variant="contained"
                             endIcon={<AutoFixHighOutlinedIcon/>}
                >
                    Synthesize
                </AsyncButton>
            </Box>
        </PriceCheckInfo>
    );
}

export default SynthesizeButton;