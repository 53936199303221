import React from 'react'
import AdminHeader from "./AdminHeader"
import AdminAllProjectsTable from "./AdminAllProjectsTable"

function AdminProjectsPage() {

    return (
        <>
            <AdminHeader/>
            <AdminAllProjectsTable/>
        </>
    );
}

export default AdminProjectsPage;