import React from 'react';
import {TemplateStandardParts} from "../../api/json/TemplateJson"
import TemplateUtil from "../../library/TemplateUtil"
import {Divider, Typography} from "@mui/material"
import TemplateRendererSection from "./TemplateRendererSection"
import {TemplateRendererProps} from "./TemplateRenderer"
import {useProjectData} from "../../hooks/useProjectData"

interface TemplateRendererStandardProps extends TemplateRendererProps {
    templateParts: TemplateStandardParts
}

function TemplateRendererStandard({dataSource, templateParts, section, showLabels, promptDisplay, completionDisplay}: TemplateRendererStandardProps) {
    const {project} = useProjectData()

    const {prompt, completion} = dataSource ? TemplateUtil.renderStandard(templateParts, dataSource, project?.fields) : {...templateParts}

    return (
        <>
            {section !== "completion" &&
                <>
                    {showLabels && <Typography variant="subtitle1">Prompt</Typography>}
                    <TemplateRendererSection value={prompt} display={promptDisplay}/>
                </>
            }
            {section === undefined && <Divider sx={{width: 60}}/>}
            {section !== "prompt" &&
                <>
                    {showLabels && <Typography variant="subtitle1">Completion</Typography>}
                    <TemplateRendererSection value={completion} display={completionDisplay}/>
                </>
            }
        </>
    )
}

export default TemplateRendererStandard;