import React from 'react';
import {ImageJson} from "../../api/json/ImageJson"
import accountStyles from "./Accounts.module.scss"

function Logo({image}: { image?: ImageJson }) {
    if (!image) return <></>

    return <img src={image.sizes.thumbnail}
                className={accountStyles.organizationLogo}
                alt="logo"/>
}

export default Logo;