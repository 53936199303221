import React, {useState} from 'react';
import MembershipJson from "../../../api/json/MembershipJson";
import {Avatar, Button, Chip, IconButton, Stack, TableCell, TableRow, Typography} from "@mui/material";
import {MembershipRole, resolveNameForRole} from "../../../api/enum/MembershipRole";
import {OrganizationRequests} from "../../../api/requests/OrganizationRequests";
import ConfirmDeleteModal from "../../modal/ConfirmDeleteModal";
import RoleSelect from "./RoleSelect";
import EditIcon from "@mui/icons-material/Edit";
import {RoleCheck} from "../../../library/RoleCheck";
import {useApi} from "../../../hooks/useApi"
import ApiError from "../../../api/ApiError"

interface UsersPageMembershipRowProps {
    membership: MembershipJson
    lastUser: boolean
    didDelete: () => void
    didUpdate: () => void
}

function MembersPageMembershipRow({ membership, lastUser, didUpdate, didDelete }: UsersPageMembershipRowProps) {
    const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false)
    const [removeError, setRemoveError] = useState<ApiError>()
    const [editingRole, setEditingRole] = useState(false)
    const [updateError, setUpdateError] = useState<ApiError>()

    const {currentUser, currentOrganization} = useApi()

    const handleRemoveMembership = () => {
        if (!currentOrganization) return

        OrganizationRequests.removeMembership(currentOrganization.id, membership.user.id)
            .then(() => {
                setRemoveConfirmOpen(false)
                didDelete()
            })
            .catch(setRemoveError)
    }

    const changeRole = (newRole: MembershipRole) => {
        if (!currentOrganization) return

        OrganizationRequests.updateMembership(currentOrganization.id, membership.user.id, newRole)
            .then(() => {
                setEditingRole(false)
                didUpdate()
            })
            .catch(setUpdateError)
    }

    const user = membership.user
    const userIsSelf = user.id === currentUser?.id
    return (
        <>
            <TableRow>
                <TableCell width={60}><Avatar src={user.avatar?.sizes.thumbnail}/></TableCell>
                <TableCell align="left">
                    <Stack direction="row" alignItems="center">
                        <Typography fontSize="inherit">{user.fullName}</Typography>
                        {userIsSelf &&
                            <Chip label="You" size="small" color="primary" sx={{ml: 1}}/>}
                    </Stack>
                    <Typography variant="caption">{user.email}</Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                    {editingRole ? <RoleSelect value={membership.role} setValue={changeRole} error={updateError} size="small"/>
                        :
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography>{resolveNameForRole(membership.role)}</Typography>
                            {(RoleCheck.isAdmin() && !userIsSelf) &&
                                <IconButton size="small" onClick={() => setEditingRole(true)}><EditIcon
                                    fontSize="small"/></IconButton>
                            }
                        </Stack>
                    }
                </TableCell>
                <TableCell align="right">
                    {!lastUser &&
                        <Button variant="contained" color="info"
                                onClick={() => setRemoveConfirmOpen(true)}>{userIsSelf ? "Leave" : "Remove"}</Button>
                    }
                </TableCell>
            </TableRow>

            <ConfirmDeleteModal
                heading={userIsSelf ? `Leave ${membership.organization.name}` : `Remove ${user.fullName} from ${membership.organization.name}`}
                body={userIsSelf ? "This will completely remove you from the organization" : "This will completely remove the user from the organization."}
                open={removeConfirmOpen}
                setOpen={setRemoveConfirmOpen}
                confirmedDelete={handleRemoveMembership}
                error={removeError}
            />
        </>
    );
}

export default MembersPageMembershipRow;