import React, {useEffect, useState} from 'react';
import {IconButton, InputAdornment, SxProps, TextField} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"

interface SearchInputProps {
    onThrottledChange: (newValue: string | undefined) => void
    sx?: SxProps
}

function SearchInput({onThrottledChange, sx}: SearchInputProps) {
    const [value, setValue] = useState<string>()

    // Throttle loading from changes in the search string
    let timer: NodeJS.Timeout | null = null
    useEffect(() => {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => onThrottledChange(value), 250)
        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [value])

    return (
        <TextField
            id="outlined-search"
            label="Search"
            value={value ?? ""}
            sx={sx}
            InputProps={{
                startAdornment:
                    <InputAdornment position="start">
                        <SearchIcon/>
                    </InputAdornment>
                ,
                endAdornment: value &&
                    <InputAdornment position="end">
                        <IconButton size="small" edge="end" onClick={() => setValue(undefined)}>
                            <ClearIcon/>
                        </IconButton>
                    </InputAdornment>,
            }}
            size="small"
            onChange={(e) => setValue(e.target.value)}
        />
    );
}

export default SearchInput;