import React, {ReactNode, useEffect, useState} from 'react';
import {AnyFinetuneJson} from "../../api/json/FinetuneJson";
import {Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import FinetunesTableRow from "./FinetunesTableRow";
import animations from "../../Animations.module.scss"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export type FinetuneTableColumn = "Created" |  "Score" | "Platform" | "Model" | "Epochs" | "Training Examples" | "Template" | "Status"

interface FinetunesTableProps {
    finetunes: AnyFinetuneJson[]
    columns: FinetuneTableColumn[]
    reload: () => void
}

type SortProperty = "numberOfEpochs" | "trainingExampleCount" | "score" | "created" | null

function FinetunesTable({finetunes, columns, reload}: FinetunesTableProps) {
    const [sortedFinetunes, setSortedFinetunes] = useState<AnyFinetuneJson[]>(finetunes)

    const [sortProperty, setSortProperty] = useState<SortProperty>(null)
    const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC")

    useEffect(() => {
        if (sortProperty === null) {
            setSortedFinetunes(finetunes)
            return
        }

        const sorted = [...finetunes].sort((a, b) => {
            let first = 0
            let second = 0
            switch (sortProperty) {
                case "numberOfEpochs":
                    first = a.numberOfEpochs
                    second = b.numberOfEpochs
                    break
                case "trainingExampleCount":
                    first = a.trainingExampleCount ?? 0
                    second = b.trainingExampleCount ?? 0
                    break
                case "score":
                    first = a.eval.stats?.score ?? 0
                    second = b.eval.stats?.score ?? 0
                    break
                case "created":
                    first = Date.parse(a.created)
                    second = Date.parse(b.created)
            }

            if (sortOrder === "ASC") {
                return first - second
            }
            return second - first
        })
        setSortedFinetunes(sorted)
    }, [sortProperty, sortOrder, finetunes])

    const toggleSortBy = (property: SortProperty) => {
        if (sortProperty === property) {
            toggleSortOrder()
        } else {
            setSortProperty(property)
            setSortOrder("DESC")
        }
    }

    const toggleSortOrder = () => {
        if (sortOrder === "DESC") {
            setSortOrder("ASC")
        } else {
            setSortOrder("DESC")
        }
    }

    const SortableTableCell = ({property, minWidth, children}: { property: SortProperty, minWidth?: number, children: ReactNode }) => {
        return <TableCell onClick={() => toggleSortBy(property)} sx={{cursor: "pointer"}}>
            <Stack direction="row" alignItems="center" sx={{minWidth: minWidth ?? "auto"}}>
                {children}
                {sortProperty === property && (
                    <>
                        {sortOrder === "ASC" && <KeyboardArrowUpIcon fontSize="small"/>}
                        {sortOrder === "DESC" && <KeyboardArrowDownIcon fontSize="small"/>}
                    </>
                )
                }
            </Stack>
        </TableCell>
    }

    return (
        <TableContainer component={Paper} className={animations.defaultIn}>
            <Table sx={{minWidth: 650}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        {columns.includes("Score") && <SortableTableCell property="score">Score</SortableTableCell>}
                        {columns.includes("Platform") && <TableCell>Platform</TableCell>}
                        {columns.includes("Model") && <TableCell>Base Model</TableCell>}
                        {columns.includes("Epochs") && <SortableTableCell property="numberOfEpochs">Epochs</SortableTableCell>}
                        {columns.includes("Training Examples") && <SortableTableCell property="trainingExampleCount">Training Examples</SortableTableCell>}
                        {columns.includes("Template") && <TableCell>Template</TableCell>}
                        {columns.includes("Created") && <TableCell>Created</TableCell>}
                        {columns.includes("Status") && <SortableTableCell property="created">Status</SortableTableCell>}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedFinetunes.map(finetune =>
                        <FinetunesTableRow key={finetune.id} finetune={finetune} columns={columns} reload={reload} />)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default FinetunesTable;