import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {MembershipRole, resolveNameForRole} from "../../../api/enum/MembershipRole";

interface RoleSelectProps {
    value?: MembershipRole
    setValue: (role: MembershipRole) => void
    size?: "small" | "medium"
    error?: Error
}

function RoleSelect({ value, setValue, size, error }: RoleSelectProps) {
    return (
        <FormControl>
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
                labelId="role-select-label"
                value={value ?? ""}
                label="Role"
                size={size}
                error={error !== undefined}
                onChange={(e) => setValue(e.target.value as MembershipRole)}
            >
                <MenuItem value={MembershipRole.ADMIN}>{resolveNameForRole(MembershipRole.ADMIN)}</MenuItem>
                <MenuItem value={MembershipRole.MANAGER}>{resolveNameForRole(MembershipRole.MANAGER)}</MenuItem>
                <MenuItem value={MembershipRole.LABELER}>{resolveNameForRole(MembershipRole.LABELER)}</MenuItem>
            </Select>
        </FormControl>
    );
}

export default RoleSelect;