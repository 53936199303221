import React, {useEffect, useState} from 'react'
import {Alert, CircularProgress} from "@mui/material"
import {useProjectData} from "../../../hooks/useProjectData"
import CsvImportFinalizeStep from "./CsvImportFinalizeStep"
import CsvImportConfirmationStep from "./CsvImportConfirmationStep"
import CsvImportReviewColumnsStep from "./CsvImportReviewColumnsStep"
import {CsvColumnIntentJson} from "../../../api/json/CsvColumnIntentJson"
import {useParams} from "react-router-dom"
import {ImportJobRequests} from "../../../api/requests/ImportJobRequests"
import ApiError from "../../../api/ApiError"
import ErrorMessage from "../../error/ErrorMessage"
import {ImportJobFileAnalysisJson} from "../../../api/json/ImportJobColumnAnalysisJson"

function CsvImportPage() {
    const {id, project} = useProjectData()
    const {importJobId} = useParams()
    const [error, setError] = useState<ApiError>()

    const [fileAnalysis, setFileAnalysis] = useState<ImportJobFileAnalysisJson>()
    const [columnIntents, setColumnIntents] = useState<CsvColumnIntentJson[]>([])

    const [needsReview, setNeedsReview] = useState(true)
    const [needsFinalization, setNeedsFinalization] = useState(true)

    useEffect(() => {
        if (!importJobId) return

        ImportJobRequests.getFileAnalysis(id, importJobId)
            .then(response => {
                setFileAnalysis(response)

                // This is helpful when you use the import modal while you are already on this page
                setNeedsReview(true)
                setNeedsFinalization(true)
            })
            .catch(setError)

    }, [importJobId])

    useEffect(() => {
        if (!fileAnalysis) return

        const startingColumnIntents: CsvColumnIntentJson[] = fileAnalysis.columns.map(column => {
            if (column.existingFieldReference) {
                return {
                    index: column.index,
                    columnHeader: column.name,
                    fieldReference: column.existingFieldReference,
                    newField: null
                }
            }
            return {
                index: column.index,
                columnHeader: column.name,
                fieldReference: null,
                newField: column.suggestedNewField,
            }
        })

        setColumnIntents(startingColumnIntents)
    }, [fileAnalysis])

    if (!importJobId) {
        return <Alert severity="error">Missing import job ID</Alert>
    }

    if (!project || !fileAnalysis) {
        return <CircularProgress/>
    }

    if (error)
        return <ErrorMessage error={error}/>

    // Review columns step
    if (needsReview) {
        return <CsvImportReviewColumnsStep columns={fileAnalysis.columns}
                                           columnIntents={columnIntents}
                                           updatedColumnIntents={setColumnIntents}
                                           finishedReview={() => setNeedsReview(false)} />
    }

    // Finalize step
    if (needsFinalization) {
        return <CsvImportFinalizeStep project={project}
                                      jobId={importJobId}
                                      totalRows={fileAnalysis.totalRows}
                                      columnIntents={columnIntents}
                                      handleBack={() => setNeedsReview(true)}
                                      didFinalize={() => setNeedsFinalization(false)} />
    }

    // Confirmation step
    return <CsvImportConfirmationStep project={project} columnIntents={columnIntents} />

}

export default CsvImportPage