import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {PagedActivityJson, PagedProjectsJson, PagedResponseJson, PagedUserJson} from "../json/PagedResponseJson"
import {AdminUserDetailJson} from "../json/AdminUserDetailJson"
import {AdminOrganizationDetailJson} from "../json/AdminOrganizationDetailJson"
import {AdminProjectDetailJson} from "../json/AdminProjectDetailJson"
import {OrganizationAdminJson} from "../json/OrganizationAdminJson"

export class AdminRequests {
    static listActivities(): Promise<PagedActivityJson> {
        return new RequestBuilder(Method.Get, `admin/activities`)
            .withoutOrganization()
            .buildAndSend()
    }

    static listUsers(search?: string): Promise<PagedUserJson> {
        let queryParams = new URLSearchParams()
        if (search) {
            queryParams.append("search", search)
        }
        return new RequestBuilder(Method.Get, `admin/users?${queryParams.toString()}`)
            .withoutOrganization()
            .buildAndSend()
    }

    static getUserDetails(userId: string): Promise<AdminUserDetailJson> {
        return new RequestBuilder(Method.Get, `admin/users/${userId}`)
            .withoutOrganization()
            .buildAndSend()
    }

    static listOrganizations(): Promise<PagedResponseJson<OrganizationAdminJson>> {
        return new RequestBuilder(Method.Get, `admin/organizations`)
            .withoutOrganization()
            .buildAndSend()
    }

    static getOrganizationDetails(organizationId: string): Promise<AdminOrganizationDetailJson> {
        return new RequestBuilder(Method.Get, `admin/organizations/${organizationId}`)
            .withoutOrganization()
            .buildAndSend()
    }

    static listProjects(): Promise<PagedProjectsJson> {
        return new RequestBuilder(Method.Get, `admin/projects`)
            .withoutOrganization()
            .buildAndSend()
    }

    static getProjectDetails(projectId: string): Promise<AdminProjectDetailJson> {
        return new RequestBuilder(Method.Get, `admin/projects/${projectId}`)
            .withoutOrganization()
            .buildAndSend()
    }
}