import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {RoleCheck} from "../../library/RoleCheck";
import {useProjectData} from "../../hooks/useProjectData"
import DatasetAllocationModal from "./DatasetAllocationModal"
import ShapeLineOutlinedIcon from '@mui/icons-material/ShapeLineOutlined';

function ExamplesPageActionsMenu() {
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>()

    const [showAllocate, setShowAllocate] = useState(false)

    const {project} = useProjectData()

    if (!RoleCheck.isAdmin() || !project || project.stats.totalExamples === 0) {
        return <></>
    }

    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <MoreVertIcon/>
            </IconButton>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            setShowAllocate(true)
                        }}>
                            <ListItemIcon>
                                <ShapeLineOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Allocate</Typography>
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>

            <DatasetAllocationModal project={project} open={showAllocate} onClose={() => setShowAllocate(false)} />
        </>
    )
}

export default ExamplesPageActionsMenu;