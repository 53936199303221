import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";

export interface RedirectUrlResponse {
    redirectUrl: string
}

export class BillingRequests {
    static createCheckoutUrl(planName: string, interval: string, successUrl: string, cancelUrl: string): Promise<RedirectUrlResponse> {
        return new RequestBuilder(Method.Post, `billing/stripe/checkouts`)
            .withJson({
                planName: planName,
                interval: interval,
                successUrl: successUrl,
                cancelUrl: cancelUrl,
            })
            .buildAndSend()
    }

    static createBillingPortalUrl(returnUrl: string): Promise<RedirectUrlResponse> {
        return new RequestBuilder(Method.Post, `billing/stripe/portal-links`)
            .withJson({returnUrl: returnUrl})
            .buildAndSend()
    }
}