export const validateNumber = (input: string, min: number, max: number, integer: boolean = false, allowEmpty: boolean = false): string => {
    let numberValue = integer ? parseInt(input) : parseFloat(input)
    if (isNaN(numberValue)) {
        if (allowEmpty) {
            return ""
        } else {
            numberValue = 0
        }
    }
    numberValue = Math.max(Math.min(numberValue, max), min)
    return `${numberValue}`
}

export const validateEmail = (email: string): boolean => {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return expression.test(email) && email.length <= 48 // Database size limit is 48
}