import React, {useEffect, useState} from 'react'
import {Avatar, Chip, CircularProgress, Grid, Link, Stack, Table, TableCell, TableRow, Typography} from "@mui/material"
import AdminHeader from "./AdminHeader"
import {Link as RouterLink, useParams} from "react-router-dom"
import {AdminUserDetailJson} from "../../api/json/AdminUserDetailJson"
import {AdminRequests} from "../../api/requests/AdminRequests"
import ApiError from "../../api/ApiError"
import ActivityCard from "../activities/ActivityCard/ActivityCard"
import Logo from "../accounts/Logo"
import {resolveNameForRole} from "../../api/enum/MembershipRole"
import ErrorMessage from "../error/ErrorMessage"

function AdminUserDetailPage() {
    const [userDetails, setUserDetails] = useState<AdminUserDetailJson>()
    const [error, setError] = useState<ApiError>()

    const {userId} = useParams()

    useEffect(() => {
        if (!userId) return

        AdminRequests.getUserDetails(userId)
            .then(details => {
                setUserDetails(details)
                setError(undefined)
            })
            .catch(setError)
    }, [userId])

    if (error) {
        return (
            <>
                <AdminHeader/>
                <ErrorMessage error={error}/>
            </>
        )
    }

    if (!userDetails) {
        return (
            <>
                <AdminHeader/>
                <CircularProgress/>
            </>
        )
    }

    return (
        <>
            <AdminHeader/>
            <Typography variant="h1">{userDetails.user.email}</Typography>

            <Stack direction="row" spacing={2} alignItems="center" sx={{mt:2}}>
                <Avatar src={userDetails.user.avatar?.sizes.thumbnail}/>
                <Typography fontWeight={500}>{userDetails.user.fullName}</Typography>
                <Chip variant="outlined" label={`Joined ${new Date(userDetails.user.created).toLocaleDateString()} `}/>
                <Chip variant="outlined" label={userDetails.user.emailVerified ? "Verified" : "Unverified"}/>
            </Stack>
            <Grid container columnSpacing={3} sx={{mt: 4}}>
                <Grid item sm={6} xs={12}>
                    <Typography variant="h2">Memberships</Typography>
                    <Table size="small" sx={{mt: 2}}>
                        {userDetails.memberships.map(membership => (
                            <TableRow>
                                <TableCell>
                                    <Logo image={membership.organization.logo}/>
                                </TableCell>
                                <TableCell>
                                    <Link component={RouterLink} to={`/admin/organizations/${membership.organization.id}`} fontWeight={500}>{membership.organization.name}</Link>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">{resolveNameForRole(membership.role)}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Typography variant="h3">Activities</Typography>
                    <Stack spacing={3} sx={{ mt: 4 }}>
                        {userDetails.activities.map(activity => (
                            <ActivityCard key={activity.id} activity={activity} adminContext/>
                        ))}
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminUserDetailPage;