export const calculateDefaultValidationPercent = (totalRows: number): number => {
    let ratio

    if(totalRows < 100) {
        ratio = 0.0
    } else if(totalRows > 3000) {
        ratio = 0.05
    } else {
        ratio = 0.2 - ((totalRows - 100) * (0.15 / (3000 - 100)))
    }

    return Number(ratio.toFixed(2))
}