import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material"
import {ScoringMethod} from "../../api/enum/ScoringMethod"

interface ScoringMethodSelectProps {
    value: ScoringMethod | null
    helperText?: string
    onChange: (newValue: ScoringMethod) => void
}

function ScoringMethodSelect({ value, helperText, onChange}: ScoringMethodSelectProps) {
    return (
        <FormControl>
            <InputLabel id="evaluation-method-select-label">Scoring Method</InputLabel>
            <Select
                labelId="evaluation-method-select-label"
                value={value}
                label="Scoring Method"
                fullWidth
                onChange={(e) => onChange(e.target.value as ScoringMethod)}
            >
                <MenuItem value={ScoringMethod.EXACT_MATCH}>Exact Match</MenuItem>
                <MenuItem value={ScoringMethod.MANUAL}>Manual</MenuItem>
                <MenuItem value={ScoringMethod.PREDICTIVE}>Predictive</MenuItem>
            </Select>
            {helperText &&
                <FormHelperText>{helperText}</FormHelperText>
            }

        </FormControl>
    );
}

export default ScoringMethodSelect;