import React from 'react';
import {Box, CircularProgress} from "@mui/material"

// The purpose of this page is to be a placeholder while AuthRedirectHandler gets the Auth0 token and handles the redirect
function Auth0CallbackPage() {
    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        </>
    )
}

export default Auth0CallbackPage;