import {getCookie, removeCookie, setCookie} from "typescript-cookie"
import CurrentUserJson from "./json/CurrentUserJson";
import {OrganizationWithRoleJson} from "./json/OrganizationWithRoleJson";

export default class ApiConfig {
    static persistenceKeys = {
        token: 'ep.token',
        currentUser: 'ep.currentUser',
        currentOrganization: 'ep.organization',
    };

    static baseUrl = process.env.NODE_ENV === "development" ? "http://localhost:8080" : ""

    static getBaseUrl(): string {
        return ApiConfig.baseUrl
    }

    static getToken(): string | undefined {
        return getCookie(ApiConfig.persistenceKeys.token) ?? undefined
    }

    static setToken(token: string) {
        setCookie(ApiConfig.persistenceKeys.token, token, { expires: 30 })
    }

    static clearToken() {
        // Set an expired cookie
        setCookie(ApiConfig.persistenceKeys.token, "",{ expires: -1 })
        removeCookie(ApiConfig.persistenceKeys.token)
    }

    static getCurrentUser(): CurrentUserJson | undefined {
        const storedCurrentUser = localStorage.getItem(ApiConfig.persistenceKeys.currentUser)
        return storedCurrentUser ? JSON.parse(storedCurrentUser) as CurrentUserJson : undefined
    }

    static setCurrentUser(currentUser: CurrentUserJson) {
        localStorage.setItem(ApiConfig.persistenceKeys.currentUser, JSON.stringify(currentUser))
    }

    static clearCurrentUser() {
        localStorage.removeItem(ApiConfig.persistenceKeys.currentUser)
    }

    static getCurrentOrganization(): OrganizationWithRoleJson | undefined {
        const storedOrganization = localStorage.getItem(ApiConfig.persistenceKeys.currentOrganization)
        return storedOrganization ? JSON.parse(storedOrganization) as OrganizationWithRoleJson : undefined
    }

    static setCurrentOrganization(organization: OrganizationWithRoleJson) {
        localStorage.setItem(ApiConfig.persistenceKeys.currentOrganization, JSON.stringify(organization))
    }

    static clearCurrentOrganization() {
        localStorage.removeItem(ApiConfig.persistenceKeys.currentOrganization)
    }

    static clearAll() {
        this.clearCurrentUser()
        this.clearCurrentOrganization()
        this.clearToken()
    }
}
