import React from 'react';
import {Button, Container, Typography} from "@mui/material"

function NotFound404Page() {
    return (
        <Container sx={{mt: 10}}>
            <Typography variant="h1">Page not found</Typography>
            <Button variant="contained" href="/" sx={{mt: 2}}>Home</Button>
        </Container>
    );
}

export default NotFound404Page;