import React, {useRef, useState} from 'react';
import {Button, Stack, TextField} from "@mui/material"
import AsyncButton from "../button/AsyncButton"
import ApiError from "../../api/ApiError"
import useEnterShortcut from "../../hooks/useEnterShortcut"
import ModalBase from "./ModalBase"

interface RenameModalProps {
    heading: string
    currentName: string
    open: boolean
    setOpen: (open: boolean) => void
    handleRename: (newName: string) => void
    renameInProgress: boolean
    error?: ApiError
    maxCharacters?: number
}

function RenameModal({heading, currentName, open, setOpen, handleRename, renameInProgress, error, maxCharacters}: RenameModalProps) {
    const [name, setName] = useState(currentName)

    const modalRef = useRef<HTMLDivElement>(null)

    const unsavedChanges = name !== currentName

    useEnterShortcut(modalRef, () => {
        if (open && name.length > 0 && unsavedChanges) {
            handleRename(name)
        }
    })

    return (
        <ModalBase
            open={open}
            setOpen={setOpen}
            title={heading}
            error={error}
            innerRef={modalRef}
            buttons={
                <>
                    <Button variant="outlined" color="secondary" onClick={() => setOpen(false)}>Cancel</Button>
                    <AsyncButton
                        variant="contained"
                        disabled={name.length < 1 || !unsavedChanges}
                        waiting={renameInProgress}
                        onClick={() => handleRename(name)}
                    >
                        Save & close
                    </AsyncButton>
                </>
            }
        >
            <Stack spacing={2}>
                <TextField
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(event) => setName(event.target.value.slice(0, maxCharacters ?? 60))}
                />
            </Stack>
        </ModalBase>
    );
}

export default RenameModal;