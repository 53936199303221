import React, {createContext, useContext, useState} from "react"
import {Backdrop, CircularProgress} from "@mui/material"

export interface UseBackdropState {
    backdropOpen: boolean
    setBackdropOpen: (value: boolean) => void
}

const Context = createContext<UseBackdropState>({
    backdropOpen: false,
    setBackdropOpen: () => {},
})

export const useBackdrop = (): UseBackdropState => {
    const context = useContext(Context)
    if (context === null) {
        throw new Error('useBackdrop must be used within a BackdropContext tag')
    }
    return context
}

export const BackdropProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
    const [open, setOpen] = useState(false)

    return (
        <Context.Provider value={{
            backdropOpen: open,
            setBackdropOpen: setOpen,
        }
        }>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {props.children}
        </Context.Provider>
    )
}
