import React from 'react';
import {AnyFinetuneJson} from "../../api/json/FinetuneJson";
import {Button, Link, TableCell, TableRow, Typography} from "@mui/material";
import TemplatedModelActionsMenu from "../models/TemplatedModelActionsMenu";
import {Link as RouterLink} from "react-router-dom"
import FinetuneStatusInfo from "./FinetuneStatusInfo"
import {useProjectData} from "../../hooks/useProjectData"
import {ScoringMethod} from "../../api/enum/ScoringMethod"
import {ModelPlatform, resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"
import {FinetuneTableColumn} from "./FinetunesTable"
import {TimeUtil} from "../../library/TimeUtil"
import InlineCode from "../common/InlineCode"
import ModelScoreCell from "../models/ModelScoreCell"

interface FinetunesTableRowProps {
    finetune: AnyFinetuneJson
    columns: FinetuneTableColumn[]
    reload: () => void
}

function FinetunesTableRow({finetune, columns, reload}: FinetunesTableRowProps) {
    const {project} = useProjectData()

    if (!project) return <></>

    return (
        <>
            <TableRow key={finetune.id}>
                <TableCell>
                    <Link component={RouterLink}
                          to={`/projects/${project.id}/fine-tunes/${finetune.id}`}
                    >
                        {finetune.name}
                    </Link>
                </TableCell>

                {columns.includes("Score") &&
                    <TableCell>
                        <ModelScoreCell project={project} model={finetune}/>
                    </TableCell>
                }

                {columns.includes("Platform") &&
                    <TableCell>
                        <Typography variant="body1">{resolveNameForModelPlatform(finetune.platform)}</Typography><br/>
                    </TableCell>
                }

                {columns.includes("Model") &&
                    <TableCell>
                        <InlineCode>
                            {finetune.platform === ModelPlatform.OPEN_AI &&
                                <>{finetune.baseModelName}</>
                            }
                            {finetune.platform === ModelPlatform.AI21 &&
                                <>{finetune.modelType}/{finetune.modelName}</>
                            }
                            {finetune.platform === ModelPlatform.REPLICATE &&
                                <>{finetune.baseModelOwner}/{finetune.baseModelName}</>
                            }
                        </InlineCode>
                    </TableCell>
                }

                {columns.includes("Epochs") &&
                    <TableCell>{finetune.numberOfEpochs.toLocaleString()}</TableCell>
                }

                {columns.includes("Training Examples") &&
                    <TableCell>{finetune.trainingExampleCount?.toLocaleString()} </TableCell>
                }

                {columns.includes("Template") &&
                    <TableCell>
                        <Link component={RouterLink}
                              to={`/projects/${project?.id}/templates/${finetune.template.id}`}>
                            {finetune.template.name}
                        </Link>
                    </TableCell>
                }

                {/*<TableCell>*/}
                {/*    {finetune.platform === FinetunePlatform.REPLICATE &&*/}
                {/*        <>*/}
                {/*            <Typography variant="caption">Learning Rate: {finetune.learningRate}</Typography><br/>*/}
                {/*            <Typography variant="caption">LoRA Rank: {finetune.loraRank}</Typography>*/}
                {/*        </>*/}
                {/*    }*/}
                {/*</TableCell>*/}

                {columns.includes("Created") &&
                    <TableCell>
                        {TimeUtil.ago(finetune.created)}
                    </TableCell>
                }

                {columns.includes("Status") &&
                    <TableCell>
                        <FinetuneStatusInfo useIcon finetune={finetune} />
                    </TableCell>
                }

                <TableCell>
                    <TemplatedModelActionsMenu model={finetune} didDelete={reload} didRename={reload}/>
                </TableCell>
            </TableRow>
        </>
    )
}

export default FinetunesTableRow;