import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {ModelPlatform, resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"

interface PlatformSelectProps {
    label?: string
    helperText?: string
    platforms: ModelPlatform[]
    value: ModelPlatform | ""
    onChange: (platform: ModelPlatform) => void
}

function PlatformSelect({ label, helperText, platforms, value, onChange }: PlatformSelectProps) {
    return (
        <FormControl>
            <InputLabel id="platform-select-label">{label ?? "Platform"}</InputLabel>
            <Select
                labelId="platform-select-label"
                value={value}
                label={label ?? "Platform"}
                fullWidth
                onChange={(e) => onChange(e.target.value as ModelPlatform)}
            >
                {platforms.map((platform, index) =>
                    <MenuItem key={index}
                              value={platform}>{resolveNameForModelPlatform(platform)}</MenuItem>
                )}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

export default PlatformSelect;