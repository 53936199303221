import React from 'react';
import {Button, SxProps} from "@mui/material"
import {Link as RouterLink} from "react-router-dom"
import {PlayCircle} from "@mui/icons-material"

function ModelPlaygroundButton({projectId, modelId, sx}: { projectId: string, modelId: string, sx?: SxProps }) {
    return (
        <Button variant="outlined" component={RouterLink}
                to={`/projects/${projectId}/playground?modelId=${modelId}`}
                endIcon={<PlayCircle/>}
                sx={sx}
        >
            Playground
        </Button>
    );
}

export default ModelPlaygroundButton;