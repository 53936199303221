import React from 'react';
import {FormControl, FormHelperText} from "@mui/material";
import FieldValueInput, {FieldValueInputProps} from "./FieldValueInput"

function FieldValueInputWithHelperText({field, value, onChange, sx}: FieldValueInputProps) {
    return (
        <FormControl>
            <FieldValueInput field={field} value={value} onChange={onChange} sx={sx}/>
            {field.helperText && <FormHelperText>{field.helperText}</FormHelperText>}
        </FormControl>
    )
}

export default FieldValueInputWithHelperText;