import React, {ReactNode} from 'react';
import {Stack, SxProps} from "@mui/material"

function FormContainer({children, sx}: { children: ReactNode, sx?: SxProps }) {
    return (
        <Stack spacing={2} alignItems="start" sx={{width: "100%", maxWidth: 575, ...sx}}>
            {children}
        </Stack>
    );
}

export default FormContainer;