import React, {useEffect, useState} from 'react'
import {Avatar, Chip, CircularProgress, Grid, Link, Stack, Table, TableCell, TableRow, Typography} from "@mui/material"
import AdminHeader from "./AdminHeader"
import {Link as RouterLink, useParams} from "react-router-dom"
import {AdminRequests} from "../../api/requests/AdminRequests"
import ApiError from "../../api/ApiError"
import ActivityCard from "../activities/ActivityCard/ActivityCard"
import Logo from "../accounts/Logo"
import {resolveNameForRole} from "../../api/enum/MembershipRole"
import {AdminOrganizationDetailJson} from "../../api/json/AdminOrganizationDetailJson"
import AdminProjectsTable from "./AdminProjectsTable"
import ErrorMessage from "../error/ErrorMessage"

function AdminOrganizationDetailPage() {
    const [details, setDetails] = useState<AdminOrganizationDetailJson>()
    const [error, setError] = useState<ApiError>()

    const {organizationId} = useParams()

    useEffect(() => {
        if (!organizationId) return

        AdminRequests.getOrganizationDetails(organizationId)
            .then(setDetails)
            .catch(setError)
    }, [organizationId])

    if (error) {
        return (
            <>
                <AdminHeader/>
                <ErrorMessage error={error}/>
            </>
        )
    }

    if (!details) {
        return (
            <>
                <AdminHeader/>
                <CircularProgress/>
            </>
        )
    }

    const organization = details.organization
    return (
        <>
            <AdminHeader/>
            <Typography variant="h1">{organization.name}</Typography>

            <Stack direction={{md: "row", sm:"column"}} spacing={1} alignItems="center" sx={{mt:2}}>
                <Logo image={organization.logo}/>
                <Chip variant="outlined" label="Organization"/>
                <Chip variant="outlined" label={`Created ${new Date(organization.created).toLocaleDateString()} `}/>
            </Stack>

            <Grid container spacing={4} sx={{mt: 1}}>
                <Grid item sm={12}>
                    <Stack spacing={0.5}>
                        <Typography variant="h2">Limits</Typography>
                        <Typography variant="body1"># of examples: {organization.usageLimits?.numberOfExamples?.toLocaleString() ?? "Unlimited"}</Typography>
                        <Typography variant="body1"># of users: {organization.usageLimits?.numberOfUsers?.toLocaleString() ?? "Unlimited"}</Typography>
                    </Stack>
                </Grid>

                <Grid item sm={12}>
                    <Typography variant="h2">Projects</Typography>
                    <AdminProjectsTable projects={details.projects} sx={{ mt: 4}}/>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Typography variant="h2">Memberships</Typography>
                    <Table size="small" sx={{mt: 2}}>
                        {details.memberships.map(membership => (
                            <TableRow>
                                <TableCell>
                                    <Avatar src={membership.user.avatar?.sizes.thumbnail} />
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">{membership.user.fullName}</Typography>
                                    <Link component={RouterLink} to={`/admin/users/${membership.user.id}`} fontWeight={500}>{membership.user.email}</Link>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">{resolveNameForRole(membership.role)}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Typography variant="h2">Activities</Typography>
                    <Stack spacing={3} sx={{mt: 4}}>
                        {details.activities.map(activity => (
                            <ActivityCard key={activity.id} activity={activity} adminContext/>
                        ))}
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminOrganizationDetailPage;