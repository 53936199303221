import React from 'react';
import {Grid} from "@mui/material"
import GridItem from "../models/GridItem"
import {resolveNameForScoringMethod, ScoringMethod} from "../../api/enum/ScoringMethod"
import {resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"
import EvalJson from "../../api/json/EvalJson"

function EvalSettingsGrid({evalJson}: { evalJson: EvalJson }) {
    return (
        <Grid container rowSpacing={2}>
            <GridItem label="Scoring Method"
                      value={resolveNameForScoringMethod(evalJson.scoringMethod)}
                      gridSize={3}/>
            {evalJson.scoringMethod === ScoringMethod.PREDICTIVE &&
                <>
                    <GridItem label="Criteria"
                              value={evalJson.criteria}
                              gridSize={3}/>
                    {evalJson.platform &&
                        <GridItem label="Platform"
                                  value={resolveNameForModelPlatform(evalJson.platform)}
                                  gridSize={3}/>
                    }
                    <GridItem codeValue label="Model Name" value={evalJson.modelName}
                              gridSize={3}/>
                    {evalJson.additionalInstructions &&
                        <GridItem fullWidth
                                  label="Additional Instructions"
                                  value={evalJson.additionalInstructions}/>
                    }
                </>
            }
        </Grid>
    );
}

export default EvalSettingsGrid;