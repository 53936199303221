import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, LinearProgress, Stack, Typography} from "@mui/material";
import UserDetailsForm from "./UserDetailsForm";
import CurrentUserJson from "../../api/json/CurrentUserJson";
import {UserRequests} from "../../api/requests/UserRequests";
import {useNavigate} from "react-router-dom";
import {useToasts} from "../../hooks/useToasts"
import Person2Icon from '@mui/icons-material/Person2';

function OnboardingUserPage() {
    const [user, setUser] = useState<CurrentUserJson>()

    const navigate = useNavigate()
    const toasts = useToasts()

    useEffect(() => {
        UserRequests.getCurrentUser()
            .then(setUser)
            .catch(toasts.showError)
    }, [])

    if (!user) return <CircularProgress />

    return (
        <Stack spacing={2}>
            <Box>
                <LinearProgress variant="determinate" value={0}/>
                <Stack spacing={1} sx={{mt: 4}}>
                    <Typography variant="h1">Set up your account</Typography>
                </Stack>
            </Box>
            <UserDetailsForm
                user={user}
                buttonLabel="Save & continue"
                buttonAlignment="right"
                didSave={savedUser => {
                    setUser(savedUser)

                    // Check if first name has value so that we don't jump ahead when avatar is set
                    if (savedUser.firstName.length === 0) return

                    if (savedUser.emailVerified) {
                        navigate("/onboarding/organization")
                    } else {
                        navigate("/onboarding/verify-email")
                    }
                }
                }/>
        </Stack>
    );
}

export default OnboardingUserPage;