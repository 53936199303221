import React, {useEffect, useState} from 'react';
import {Box, LinearProgress, Link, Stack, Typography} from "@mui/material";
import {UserRequests} from "../../api/requests/UserRequests";
import {useNavigate} from "react-router-dom";
import {useToasts} from "../../hooks/useToasts"
import EmailIcon from '@mui/icons-material/Email';
import {VerificationRequests} from "../../api/requests/VerificationRequests"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import {useApi} from "../../hooks/useApi"

function OnboardingVerifyEmail() {
    const [resent, setResent] = useState(false)

    const {setCurrentUser, currentUser} = useApi()
    const navigate = useNavigate()
    const toasts = useToasts()

    useEffect(() => {
        const interval = setInterval(() => checkEmailVerifiedStatus(), 3000)
        return () => clearInterval(interval)
    }, [])

    const checkEmailVerifiedStatus = () => {
        UserRequests.getCurrentUser()
            .then(user => {
                if (user.emailVerified) {
                    setCurrentUser(user)
                    navigate("/onboarding/organization")
                }
            })
            .catch(toasts.showError)
    }

    const handleResend = () => {
        VerificationRequests.resendEmailConfirmation()
            .then(() => setResent(true))
            .catch(toasts.showError)
    }

    return (
        <Stack spacing={2}>
            <Box>
                <LinearProgress variant="determinate" value={33}/>
                <Stack spacing={1} sx={{mt: 4}}>
                    <EmailIcon color="secondary" sx={{fontSize: 30}}/>
                    <Typography variant="h1">Verify your email address</Typography>
                </Stack>
            </Box>
            <Typography>Please check your email ({currentUser?.email}) for the verification link—this page will update
                automatically when it's complete.</Typography>
            <Box>
                {resent
                    ? <Stack direction="row" spacing={0.5} alignItems="center">
                        <CheckCircleOutlineIcon color="secondary"/><Typography>Sent</Typography>
                    </Stack>
                    : <Link onClick={handleResend} underline="none">Resend email</Link>}
            </Box>
        </Stack>
    );
}

export default OnboardingVerifyEmail;