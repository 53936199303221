import API from "./API"
import {Method} from "./enum/Method"
import RequestSender from "./RequestSender"
import ApiError from "./ApiError"

export default class RequestBuilder {
    url: string
    method: Method
    authenticated: boolean = true
    useOrganization: boolean = true

    headers: Headers = new Headers()
    body: string | FormData | null = null

    constructor(method: Method, endpoint: string) {
        this.method = method
        this.url = `${API.getBaseUrl()}/api/${endpoint}`
    }

    unauthenticated(): RequestBuilder {
        this.authenticated = false
        return this
    }

    withoutOrganization(): RequestBuilder {
        this.useOrganization = false
        return this
    }

    withJson(json: any): RequestBuilder {
        this.headers.append("Content-Type", "application/json")
        this.body = JSON.stringify(json)
        return this
    }

    withFormData(formData: FormData): RequestBuilder {
        this.body = formData
        return this
    }

    withHeaders(headers: Headers): RequestBuilder {
        this.headers = headers
        return this
    }

    build(): Request {
        if (this.authenticated) {
            const token = API.getToken()

            if (!token) {
                throw new ApiError("Missing token for authenticated request.", 0)
            }

            this.headers.append("Authorization", `Bearer ${token}`)
        }

        if (this.useOrganization) {
            const organization = API.getCurrentOrganization()

            if (!organization) {
                window.location.href = `${window.location.origin}/organizations/select`
                throw new ApiError("Organization ID is required but not set", 0)
            }

            this.headers.append("Organization-Id", organization.id)
        }

        try {
            return new Request(this.url, {
                method: this.method,
                headers: this.headers,
                body: this.body,
            })
        } catch (e: any) {
            throw new ApiError(e.message, 0)
        }
    }

    async buildAndSend(): Promise<any> {
        return RequestSender.sendRequest(this.build())
    }
}