import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardContent,
    Divider,
    FormControl,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import {FieldType} from "../../../api/enum/FieldType";
import {AnyFieldJson} from "../../../api/json/FieldJson";
import {CsvColumnIntentJson} from "../../../api/json/CsvColumnIntentJson";
import styles from "./CsvImportColumnCard.module.scss";
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {useProjectData} from "../../../hooks/useProjectData"

interface ImportColumnCardProps {
    intent: CsvColumnIntentJson
    sampleValues: string[]
    fields: AnyFieldJson[]
    error: boolean
    onChange: (newTypeOrField: string | null, name: string) => void
}

function CsvImportColumnCard({intent, sampleValues, fields, error, onChange}: ImportColumnCardProps) {
    const {project} = useProjectData()

    let initialMappingStrategy: "existing" | "new" | "skip" = intent.fieldReference ? "existing" : "skip"
    if (initialMappingStrategy === "skip" && project && project.stats.totalExamples === 0) {
        initialMappingStrategy = "new"
    }

    const [mappingStrategy, setMappingStrategy] = useState<"existing" | "new" | "skip">(initialMappingStrategy)
    const [existingFieldRef, setExistingFieldRef] = useState(intent.fieldReference)
    const [newFieldType, setNewFieldType] = useState(intent.newField?.type ?? FieldType.TEXT)
    const [newFieldName, setNewFieldName] = useState(intent.newField?.name ?? intent.columnHeader)

    useEffect(() => onChange(resolveNewTypeOrField(), newFieldName),
        [mappingStrategy, existingFieldRef, newFieldType, newFieldName],
    )

    const resolveNewTypeOrField = (): string | null => {
        switch (mappingStrategy) {
            case "new":
                return newFieldType
            case "existing":
                return existingFieldRef ?? null
            case "skip":
                return null
        }
    }

    return (
        <Stack spacing={{xs: 1, sm: 2, md: 4}}
               direction={{xs: 'column', md: 'row'}}
               justifyContent="flex-start"
               alignItems="center"
               divider={
                   <>
                       <ChevronRightIcon color="secondary" sx={{display: {md: "block", xs: "none"}}}/>
                       <KeyboardArrowDownIcon color="secondary" sx={{display: {md: "none", xs: "block"}}}/>
                   </>
               }
        >
            <Card variant="outlined" className={styles.cardContainer} sx={{width: 1}}>
                <CardContent>
                    <Typography variant="overline">Column</Typography>
                    <Typography variant="h2" component="div">{intent.columnHeader.substring(0, 60)}</Typography>
                    <List sx={{p: 0, mt: 1}}>
                        {sampleValues.map((sampleValue, index) =>
                            <Box key={index}>
                                <ListItem sx={{pl: 0, pr: 0}}>
                                    <Typography variant="body2"
                                                sx={{whiteSpace: "pre-wrap"}}>{sampleValue.substring(0, 255)}</Typography>
                                </ListItem>
                                {index < sampleValues.length - 1 && <Divider/>}
                            </Box>,
                        )}
                    </List>
                </CardContent>
            </Card>

            <Card variant="outlined" className={styles.cardContainer} sx={{width: 1}}>
                <CardContent>
                    <Typography variant="overline" gutterBottom>Field</Typography>
                    <ToggleButtonGroup
                        value={mappingStrategy}
                        exclusive
                        onChange={(_, newValue) => setMappingStrategy(newValue)}
                        fullWidth
                        aria-label="text alignment"
                        size="small"
                    >
                        {project && project.fields.length > 0 &&
                            <ToggleButton value="existing">Existing</ToggleButton>
                        }
                        <ToggleButton value="new">New</ToggleButton>
                        <ToggleButton value="skip">Skip</ToggleButton>
                    </ToggleButtonGroup>

                    {mappingStrategy === "existing" &&
                        <FormControl sx={{mt: 3, width: 1}}>
                            <InputLabel id="existing-field-select-label">Field</InputLabel>
                            <Select
                                labelId="existing-field-select-label"
                                label="Field"
                                value={existingFieldRef}
                                error={error}
                                onChange={(event) => setExistingFieldRef(event.target.value)}
                                fullWidth
                            >
                                {fields.map(field => (
                                    <MenuItem key={field.id} value={field.reference}>{field.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }

                    {mappingStrategy === "new" &&
                        <Stack spacing={2} sx={{mt: 2}}>
                            <Typography variant="subtitle1">Define this field</Typography>
                            <TextField label="New Field Name"
                                       value={newFieldName}
                                       onChange={(event) => setNewFieldName(event.target.value)}
                            />
                            <FormControl>
                                <InputLabel id="field-type-select-label">Field Type</InputLabel>
                                <Select
                                    labelId="field-type-select-label"
                                    label="Field Type"
                                    value={newFieldType}
                                    error={error}
                                    onChange={(event) => setNewFieldType(event.target.value as FieldType)}
                                    fullWidth
                                >
                                    {/*<MenuItem value={FieldType.URL}>URL</MenuItem>*/}
                                    <MenuItem value={FieldType.TEXT}>Text</MenuItem>
                                    <MenuItem value={FieldType.NUMBER}>Number</MenuItem>
                                    <MenuItem value={FieldType.PREDEFINED_OPTIONS}>Predefined Options</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    }
                </CardContent>
            </Card>
        </Stack>

    )
}

export default CsvImportColumnCard;