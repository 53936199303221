import React, {useEffect, useState} from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {ProjectJson} from "../../api/json/ProjectJson";
import {FinetuneOptionsGoogleAi, FinetuneOptionsOpenAi} from "../../api/json/FinetuneOptionsJson";
import LaunchIcon from "@mui/icons-material/Launch";
import {ModelSpecGoogleAiJson, ModelSpecOpenAiJson} from "../../api/json/ModelSpecJson"
import {ModelPlatform} from "../../api/enum/ModelPlatform"
import SpaceBetween from "../common/SpaceBetween"
import NumberInput from "../form/NumberInput"
import {ProjectType} from "../../api/enum/ProjectType"
import {AnyTemplateJson} from "../../api/json/TemplateJson"

interface FinetuneFormGoogleAiProps {
    template: AnyTemplateJson
    baseModels: ModelSpecGoogleAiJson[]
    numberOfTrainingExamples: number
    setOptions: (options: FinetuneOptionsGoogleAi) => void
    setFormValid: (valid: boolean) => void
}

function FinetuneFormGoogleAi({template, baseModels, numberOfTrainingExamples, setOptions, setFormValid}: FinetuneFormGoogleAiProps) {
    const [baseModel, setBaseModel] = useState<ModelSpecGoogleAiJson>(baseModels[0])
    const [showAdvanced, setShowAdvanced] = useState<boolean>(false)

    const [numberOfEpochs, setNumberOfEpochs] = useState<number>(2)
    const [learningRate, setLearningRate] = useState<number>(0.0002)

    let defaultBatchSize = Math.max(Math.min(Math.round(numberOfTrainingExamples * 0.02), 256), 1)
    const [batchSize, setBatchSize] = useState<number>(defaultBatchSize)

    useEffect(() => setOptions({
        platform: ModelPlatform.GOOGLE_AI,
        templateId: template.id,
        baseModel: baseModel,
        baseModelName: baseModel.name,
        numberOfEpochs: numberOfEpochs,
        learningRate: learningRate,
        batchSize: batchSize,
    }), [baseModel, numberOfEpochs, learningRate, batchSize])

    useEffect(() => {
        setFormValid(true)
    }, [])

    return (
        <>
            <FormControl>
                <InputLabel id="base-model-select-label">Base Model</InputLabel>
                <Select
                    labelId="base-model-select-label"
                    value={baseModel.name}
                    label="Base Model"
                    fullWidth
                    onChange={(e) => {
                        const model = baseModels.find(it => it.name === e.target.value)
                        if (model) setBaseModel(model)
                    }}
                >
                    {baseModels.filter(model => !model.deprecated).map(model =>
                        <MenuItem key={model.name} value={model.name}>{model.displayName}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {showAdvanced ?
                <>
                    <SpaceBetween>
                        <Typography variant="subtitle1">Advanced</Typography>
                        <Button endIcon={<LaunchIcon/>}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://platform.openai.com/docs/api-reference/fine-tunes/create">
                            Docs
                        </Button>
                    </SpaceBetween>
                    <NumberInput label="Number of Epochs"
                                 helperText="The number of full cycles to go through the training dataset."
                                 min={1}
                                 max={100}
                                 value={numberOfEpochs}
                                 onChange={setNumberOfEpochs}
                    />
                    <>
                        <NumberInput label="Learning Rate"
                                     helperText="How much the model learns from each batch."
                                     min={0.0000001}
                                     max={1}
                                     value={learningRate}
                                     onChange={setLearningRate}
                        />
                        <NumberInput label="Batch Size"
                                     helperText="Number of examples processed at a time."
                                     min={1}
                                     max={256}
                                     value={batchSize}
                                     onChange={setBatchSize}
                        />
                    </>
                </> :
                <Button sx={{width: 150}} onClick={() => setShowAdvanced(true)}>Show Advanced</Button>
            }
        </>
    );
}

export default FinetuneFormGoogleAi;