import React, {useEffect, useState} from 'react';
import {CircularProgress, Stack, Tooltip, Typography} from "@mui/material";
import {useProjectData} from "../../hooks/useProjectData";
import TemplateEditor from "./TemplateEditor"
import ProjectPageHeader from "../layouts/ProjectPageHeader"
import {useNavigate, useParams} from "react-router-dom"
import ApiError from "../../api/ApiError"
import {TemplateRequests} from "../../api/requests/TemplateRequests"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import {Breadcrumb} from "../layouts/BreadcrumbsBase"
import TemplateActionsMenu from "./TemplateActionsMenu"
import {LockOutlined} from "@mui/icons-material"
import {useToasts} from "../../hooks/useToasts"
import ErrorMessage from "../error/ErrorMessage"
import TemplatedModelsTable from "../models/TemplatedModelsTable"
import {TemplatedModelRequests} from "../../api/requests/TemplatedModelRequests"
import {AnyModelJson} from "../../api/json/TemplatedModelJson"

function TemplateDetailPage() {
    const {id, project, reloadProject} = useProjectData()
    const {templateId} = useParams()

    const [savedTemplate, setSavedTemplate] = useState<AnyTemplateJson>()
    const [templateLoading, setTemplateLoading] = useState(false)
    const [templateError, setTemplateError] = useState<ApiError>()

    const [models, setModels] = useState<AnyModelJson[]>()
    const [modelsLoading, setModelsLoading] = useState(false)
    const [modelsError, setModelsError] = useState<ApiError>()

    const toasts = useToasts()

    useEffect(() => {
        load()
        loadTemplatedModels()
        reloadProject()
    }, [])

    const load = () => {
        if (!templateId) return

        setTemplateLoading(true)
        TemplateRequests.get(id, templateId)
            .then(template => {
                setSavedTemplate(template)
                setTemplateError(undefined)
            })
            .catch(setTemplateError)
            .finally(() => setTemplateLoading(false))
    }

    const loadTemplatedModels = () => {
        if (modelsLoading) return

        setModelsLoading(true)
        TemplatedModelRequests.list(id, templateId, true)
            .then(response => {
                setModels(response.content)
                setModelsError(undefined)
            })
            .catch(setModelsError)
            .finally(() => setModelsLoading(false))

    }

    const navigate = useNavigate()

    if (templateError) {
        return <ErrorMessage error={templateError}/>
    }

    if (!project) {
        return <CircularProgress/>
    }

    const name = savedTemplate?.name ?? "Untitled"
    let breadcrumbs: Breadcrumb[] = [
        {
            label: "Templates",
            url: `/projects/${id}/templates`,
        },
    ]
    breadcrumbs.push(name)

    let readonly = false
    if (savedTemplate !== undefined && savedTemplate.stats && savedTemplate.stats.numberOfTemplatedModels >= 1) {
        readonly = true
    }

    return <>
        <ProjectPageHeader pageTitle={name}
                           breadcrumbs={breadcrumbs}
                           nextToTitle={
                               <Stack direction="row" alignItems="center">
                                   {readonly && (
                                       <Tooltip title="Read-only because it's used in a model.">
                                           <LockOutlined color="secondary" fontSize="small"/>
                                       </Tooltip>
                                   )
                                   }
                                   {savedTemplate &&
                                       <TemplateActionsMenu project={project}
                                                            template={savedTemplate}
                                                            didRename={renamed => {
                                                                const updated: AnyTemplateJson = {...savedTemplate}
                                                                updated.name = renamed.name
                                                                setSavedTemplate(updated)
                                                            }}
                                                            didCopy={() => toasts.showMessage("A copy of this template has been added to the project.", "success")}
                                                            didDelete={() => navigate(`/projects/${project?.id}/templates`)}
                                       />
                                   }
                               </Stack>
                           }
        />

        {!templateLoading &&
            <TemplateEditor project={project}
                            readonly={readonly}
                            template={savedTemplate}
                            didSave={template => {
                                setSavedTemplate(template)
                                reloadProject() // To get accurate sidebar count
                            }}
            />
        }

        {models && models.length > 0 &&
            <>
                <Stack direction="row" spacing={1}>
                    <Typography variant="h2" sx={{mt: 2, mb: 2}}>Models</Typography>
                    {modelsLoading && <CircularProgress size={24}/>}
                </Stack>

                <TemplatedModelsTable project={project} models={models} reload={() => loadTemplatedModels()}/>
            </>
        }

        {modelsError && <ErrorMessage error={modelsError}/>}
    </>
}

export default TemplateDetailPage
