import React, {useEffect} from 'react';
import {Box, CircularProgress, LinearProgress, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {OrganizationRequests} from "../../api/requests/OrganizationRequests"
import OrganizationDetailsForm from "./OrganizationDetailsForm"
import {useToasts} from "../../hooks/useToasts"
import {useApi} from "../../hooks/useApi"
import {PlanIntent, usePlanIntent} from "../../hooks/usePlanIntent"
import {BillingRequests} from "../../api/requests/BillingRequests"

function OnboardingOrganizationPage() {
    const navigate = useNavigate()
    const toasts = useToasts()
    const {plan, clear} = usePlanIntent()
    const {currentOrganization, setCurrentOrganization, currentUser} = useApi()

    useEffect(() => {
        if (!currentUser) return

        let selectedOrganizationId = currentOrganization?.id
        if (!selectedOrganizationId && currentUser.organizations.length > 0) {
            setCurrentOrganization(currentUser.organizations[0])
            selectedOrganizationId = currentUser.organizations[0].id
        }

        if (!selectedOrganizationId) {
            return navigate("/organizations/select")
        }

        OrganizationRequests.get(selectedOrganizationId)
            .then(setCurrentOrganization)
            .catch(toasts.showError)
    }, [])

    const redirectToCheckout = (plan: PlanIntent) => {
        BillingRequests.createCheckoutUrl(
            plan.name,
            plan.interval,
            `${window.location.origin}/checkouts/success`,
            `${window.location.origin}/organization`
        ).then(response => {
            clear()
            window.location.href = response.redirectUrl
        })
            .catch(toasts.showError)
    }

    if (!currentOrganization) return <CircularProgress/>

    return (
        <Stack spacing={2}>
            <Box>
                <LinearProgress variant="determinate" value={75}/>
                <Stack spacing={1} sx={{mt: 4}}>
                    <Typography variant="h1">Set up your organization</Typography>
                </Stack>
            </Box>
            <OrganizationDetailsForm
                buttonLabel="Save & finish"
                buttonAlignment="right"
                organization={currentOrganization}
                didAddLogo={setCurrentOrganization}
                didSaveDetails={(organization) => {
                    setCurrentOrganization(organization)

                    if (plan) {
                        redirectToCheckout(plan)
                    } else {
                        navigate("/")
                    }
                }}/>
        </Stack>
    );
}

export default OnboardingOrganizationPage;