import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {ProjectJson} from "../../api/json/ProjectJson"
import {RoleCheck} from "../../library/RoleCheck"
import FieldOrderModal from "./FieldOrderModal"
import ReorderIcon from '@mui/icons-material/Reorder';
import {AnyFieldJson} from "../../api/json/FieldJson"

function FieldsPageActionsMenu({project, fields, reload}: { project: ProjectJson, fields: AnyFieldJson[], reload: () => void }) {
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>()

    const [showOrderModal, setShowOrderModal] = useState(false)

    if (!RoleCheck.isAdmin() || !project) {
        return <></>
    }

    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <MoreVertIcon/>
            </IconButton>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            setShowOrderModal(true)
                        }}>
                            <ListItemIcon>
                                <ReorderIcon/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Change Order</Typography>
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>

            <FieldOrderModal project={project}
                             fields={fields}
                             open={showOrderModal}
                             onClose={() => {
                                 setShowOrderModal(false)
                                 reload()
                             }}
            />
        </>
    )
}

export default FieldsPageActionsMenu;