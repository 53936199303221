import React, {useEffect, useState} from 'react';
import ProjectPageHeader from "../layouts/ProjectPageHeader";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {useProjectData} from "../../hooks/useProjectData";
import {Button, Card, CardContent, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import ApiError from "../../api/ApiError"
import EvalSection from "../eval/EvalSection"
import TemplatedModelShareSettings from "./TemplatedModelShareSettings"
import GridItem from "./GridItem"
import {resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"
import SpaceBetween from "../common/SpaceBetween"
import ErrorMessage from "../error/ErrorMessage"
import {TemplatedModelJson} from "../../api/json/TemplatedModelJson"
import {TemplatedModelRequests} from "../../api/requests/TemplatedModelRequests"
import TemplateRenderer from "../templates/TemplateRenderer"
import ModelPlaygroundButton from "./ModelPlaygroundButton"
import TemplatedModelActionsMenu from "./TemplatedModelActionsMenu"

function TemplatedModelDetailPage() {
    const {id, project} = useProjectData()
    const {modelId} = useParams()

    const [model, setModel] = useState<TemplatedModelJson>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    useEffect(() => load(), [project])

    const navigate = useNavigate()

    const load = () => {
        if (!modelId) return

        setLoading(true)
        TemplatedModelRequests.get(modelId)
            .then(setModel)
            .catch(setError)
            .finally(() => setLoading(false))
    }

    if (error) {
        return <ErrorMessage error={error}/>
    }

    if (loading || !project) {
        return <CircularProgress/>
    }

    if (!model) return <>Unexpected behavior</>

    return (
        <>
            <ProjectPageHeader
                pageTitle={model.name}
                breadcrumbs={[
                    {
                        label: "Models",
                        url: `/projects/${id}/models`,
                    },
                    model.name,
                ]}
                replaceFab={<ModelPlaygroundButton projectId={id} modelId={model.id} sx={{alignSelf: "center"}}/>}
                nextToTitle={
                    <TemplatedModelActionsMenu model={model}
                                               didRename={() => load()}
                                               didDelete={() => navigate(`/projects/${project.id}/models`)}
                    />
                }
            />

            <Grid spacing={2} container>
                <Grid item lg={6} sm={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h2" gutterBottom>Details</Typography>
                            <Grid container spacing={1}>
                                <GridItem label="Platform" value={resolveNameForModelPlatform(model.platform)}/>
                                <GridItem label="Base model" value={model.platformId} codeValue/>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card variant="outlined" sx={{mt: 3}}>
                        <CardContent>
                            <TemplatedModelShareSettings model={model} updateModel={setModel}/>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={6} sm={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Stack spacing={1}>
                                <SpaceBetween>
                                    <Typography variant="h2" gutterBottom>Template</Typography>
                                    <Button component={RouterLink}
                                            to={`/projects/${project.id}/templates/${model.template.id}`}>{model.template.name}</Button>
                                </SpaceBetween>
                                <TemplateRenderer templateParts={model.template} showLabels
                                                  staticSystemPromptTreatment="show"/>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {project.stats.numberOfValidationExamples > 0 &&
                <EvalSection
                    project={project}
                    model={model}
                    updateEval={newEval => {
                        const newModel = {...model}
                        newModel.eval = newEval
                        setModel(newModel)
                    }}
                />
            }
        </>
    );
}

export default TemplatedModelDetailPage;