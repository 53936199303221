import React, {useState} from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography} from "@mui/material"
import {resolveNameForTokenizerAlgorithm, TokenizerAlgorithm} from "../../api/enum/TokenizerAlgorithm"
import {ProjectJson} from "../../api/json/ProjectJson"
import AsyncButton from "../button/AsyncButton"
import {ProjectRequests} from "../../api/requests/ProjectRequests"
import useSaveShortcut from "../../hooks/useSaveShortcut"
import {useProjectData} from "../../hooks/useProjectData"
import {useToasts} from "../../hooks/useToasts"

interface ProjectSettingsFormProps {
    project: ProjectJson
    didUpdate: (project: ProjectJson) => void
}

function ProjectSettingsForm({project, didUpdate}: ProjectSettingsFormProps) {
    const {setProject} = useProjectData()

    const [name, setName] = useState(project.name)
    const [description, setDescription] = useState(project.description)
    const [defaultTokenizer, setDefaultTokenizer] = useState<TokenizerAlgorithm>(project.defaultTokenizer)

    const [saveInProgress, setSaveInProgress] = useState(false)
    const toasts = useToasts()

    const unsavedChanges = (
        project.name !== name
        || project.description !== description
        || project.defaultTokenizer !== defaultTokenizer
    )

    const disableSave = !unsavedChanges || name.length === 0 || description.length === 0

    const handleSave = () => {
        if (disableSave) return

        setSaveInProgress(true)
        ProjectRequests.update(project.id, name, description, defaultTokenizer)
            .then(updated => didUpdate(updated))
            .catch(toasts.showError)
            .finally(() => setSaveInProgress(false))
    }
    useSaveShortcut(handleSave)

    return (

        <Stack spacing={2}>
            <Typography variant="h2">Project</Typography>
            <TextField label="Name"
                       value={name}
                       onChange={e => {
                           setName(e.target.value.slice(0, 60))
                       }}
            />
            <TextField label="Description"
                       value={description}
                       multiline
                       helperText="This provides important context for various features."
                       onChange={e => setDescription(e.target.value.slice(0, 300))}
            />

            <FormControl>
                <InputLabel id="token-count-select-label">Default Tokenizer</InputLabel>
                <Select
                    labelId="token-count-select-label"
                    value={defaultTokenizer}
                    label="Default Tokenizer"
                    fullWidth
                    onChange={(e) => setDefaultTokenizer(e.target.value as TokenizerAlgorithm)}
                >
                    {[TokenizerAlgorithm.GPT, TokenizerAlgorithm.JURASSIC, TokenizerAlgorithm.LLAMA, TokenizerAlgorithm.MISTRAL].map((algorithm, index) =>
                        <MenuItem key={index} value={algorithm}>
                            {resolveNameForTokenizerAlgorithm(algorithm)}
                        </MenuItem>,
                    )}
                </Select>
                <FormHelperText>Which token count to display.</FormHelperText>
            </FormControl>

            <AsyncButton waiting={saveInProgress}
                         disabled={disableSave}
                         onClick={handleSave}>
                Save
            </AsyncButton>
        </Stack>
    );
}

export default ProjectSettingsForm;