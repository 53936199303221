import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {DeleteOutline, EditOutlined} from "@mui/icons-material";
import {RoleCheck} from "../../library/RoleCheck";
import ConfirmDeleteModal from "../modal/ConfirmDeleteModal";
import {useProjectData} from "../../hooks/useProjectData";
import ApiError from "../../api/ApiError"
import {ModelPlatform, resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"
import RenameModal from "../modal/RenameModal"
import {TemplatedModelRequests} from "../../api/requests/TemplatedModelRequests"
import {AnyModelJson, isFinetune} from "../../api/json/TemplatedModelJson"

interface TemplatedModelActionsMenuProps {
    model: AnyModelJson
    didDelete: () => void
    didRename: (name: string) => void
}

function TemplatedModelActionsMenu({model, didDelete, didRename}: TemplatedModelActionsMenuProps) {
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>()

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteError, setDeleteError] = useState<ApiError>()

    const [renameModalOpen, setRenameModalOpen] = useState(false)
    const [renameInProgress, setRenameInProgress] = useState(false)
    const [renameError, setRenameError] = useState<ApiError>()

    const {id} = useProjectData()

    const handleDelete = (deleteFromPlatform: boolean) => {
        TemplatedModelRequests.delete(id, model.id, deleteFromPlatform)
            .then(() => {
                setDeleteModalOpen(false)
                didDelete()
            })
            .catch(setDeleteError)
    }

    const handleRename = (newName: string) => {
        setRenameInProgress(true)
        TemplatedModelRequests.update(model.id, newName)
            .then(() => {
                setRenameError(undefined)
                setRenameModalOpen(false)
                didRename(newName)
            })
            .catch(setRenameError)
            .finally(() => setRenameInProgress(false))
    }

    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <MoreVertIcon/>
            </IconButton>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            setRenameModalOpen(true)
                        }}>
                            <ListItemIcon>
                                <EditOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Rename</Typography>
                            </ListItemText>
                        </MenuItem>
                        {RoleCheck.isAdmin() &&
                            <MenuItem onClick={() => {
                                setMenuAnchor(undefined)
                                setDeleteModalOpen(true)
                            }}>
                                <ListItemIcon>
                                    <DeleteOutline color="error"/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography color="error">Delete</Typography>
                                </ListItemText>
                            </MenuItem>
                        }
                    </MenuList>
                </Paper>
            </Menu>

            <ConfirmDeleteModal
                heading={`Delete "${model.name}" fine-tune`}
                body={`This will permanently remove the fine-tune from Entry Point.${model.platform === ModelPlatform.AI21 ? " It will not be removed from AI21." : ""}`}
                checkboxLabel={
                    isFinetune(model) && model.platform !== ModelPlatform.AI21 ? `Delete from ${resolveNameForModelPlatform(model.platform)}` : undefined
                }
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                confirmedDelete={(checkboxValue) => handleDelete(checkboxValue === true)}
                error={deleteError}
            />

            <RenameModal heading="Rename Model"
                         currentName={model.name}
                         open={renameModalOpen}
                         setOpen={setRenameModalOpen}
                         handleRename={handleRename}
                         renameInProgress={renameInProgress}
                         error={renameError}
            />
        </>
    )
}

export default TemplatedModelActionsMenu;