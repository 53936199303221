import React, {useEffect, useState} from 'react';
import AdminHeader from "../AdminHeader"
import {Fab, Grid, Typography} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ApiError from "../../../api/ApiError"
import ModalBase from "../../modal/ModalBase"
import {PagedResponseJson} from "../../../api/json/PagedResponseJson"
import ScrollPaginator from "../../misc/ScrollPaginator"
import SpaceBetween from "../../common/SpaceBetween"
import {MarketingEventJson} from "../../../api/json/MarketingEventJson"
import {MarketingEventRequests} from "../../../api/requests/MarketingEventRequests"
import MarketingEventForm from "./MarketingEventForm"
import MarketingEventCard from "./MarketingEventCard"

function AdminMarketingEventsPage() {
    const [createOpen, setCreateOpen] = useState(false)
    const [pages, setPages] = useState<PagedResponseJson<MarketingEventJson>[]>([])
    const [error, setError] = useState<ApiError>()

    const loadPage = (pageNumber: number) => {
        MarketingEventRequests.list(pageNumber)
            .then(response => setPages([...pages, response]))
            .catch(setError)
    }

    const reload = () => {
        setPages([])
        loadPage(0)
    }

    useEffect(() => reload(), [])

    const events = pages.flatMap(it => it.content)

    return (
        <>
            <AdminHeader/>

            <SpaceBetween>
                <Typography variant="h1">Marketing Events</Typography>
                <Fab color="primary" aria-label="create" onClick={() => setCreateOpen(true)}>
                    <AddIcon/>
                </Fab>
            </SpaceBetween>

            <ScrollPaginator pages={pages} loadNextPage={loadPage} error={error}>
                <Grid container spacing={2} sx={{mt: 2}}>
                    {events.map(event => (
                        <Grid item xs={12} sm={12} key={event.id}>
                            <MarketingEventCard marketingEvent={event}
                                                didUpdate={updated =>
                                                    setPages(pages =>
                                                        pages.map(page => ({
                                                            ...page,
                                                            content: page.content.map(dataset =>
                                                                dataset.id === updated.id ? {...updated} : dataset,
                                                            ),
                                                        })),
                                                    )
                                                }
                                                didCopy={() => reload()}
                                                didDelete={deleteId =>
                                                    setPages(pages =>
                                                        pages.map(page => ({
                                                            ...page,
                                                            content: page.content.filter(project => project.id !== deleteId),
                                                        })),
                                                    )
                                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            </ScrollPaginator>

            <ModalBase title="New Marketing Event" size="large" open={createOpen} setOpen={setCreateOpen}>
                <MarketingEventForm didCreate={() => {
                    setCreateOpen(false)
                    reload()
                }}/>
            </ModalBase>
        </>
    );
}

export default AdminMarketingEventsPage;