import React from 'react';
import {Stack, SxProps} from "@mui/material"
import CompletionCard from "./CompletionCard"
import {CompletionRequestJson} from "../../api/json/CompletionRequestJson"
import {DisplayFormat} from "../../api/enum/DisplayFormat"

function CompletionCardStack({request, display, sx}: { request: CompletionRequestJson, display?: DisplayFormat, sx?: SxProps}) {
    return (
        <Stack spacing={0.5} sx={sx}>
            {request.results.map(result => (
                <CompletionCard
                    key={result.id}
                    template={request.model.template}
                    display={display}
                    generativeResult={result}
                    referenceValues={{...request.inputs, ...result.referenceValues}}
                    showPrompt
                />))}
        </Stack>
    );
}

export default CompletionCardStack;