import {Grid, Typography} from "@mui/material"
import React, {ReactNode} from "react"
import InlineCode from "../common/InlineCode"

interface GridItemProps {
    label: string
    value: ReactNode | string | number
    fullWidth?: boolean
    gridSize?: number
    extra?: ReactNode
    codeValue?: boolean
}

const GridItem = ({label, value, fullWidth, gridSize, extra, codeValue}: GridItemProps) => {
    return (
        <>
            <Grid item sm={fullWidth ? 12 : (gridSize ?? 6)} xs={12}>
                <Typography variant="subtitle2">{label}</Typography>
                <Typography variant="body1">
                    {codeValue ? <InlineCode>{value}</InlineCode> : value}
                </Typography><br/>
                {extra && extra}
            </Grid>
        </>
    )
}

export default GridItem;