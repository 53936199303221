import React, {useState} from 'react';
import {DemoDatasetJson} from "../../../api/json/DemoDatasetJson"
import {Button, Card, Chip, Stack, Typography} from "@mui/material"
import SpaceBetween from "../../common/SpaceBetween"
import {DemoDatasetRequests} from "../../../api/requests/DemoDatasetRequests"
import ApiError from "../../../api/ApiError"
import ErrorMessage from "../../error/ErrorMessage"
import AsyncButton from "../../button/AsyncButton"
import ModalBase from "../../modal/ModalBase"
import EditIcon from "@mui/icons-material/Edit"
import ConfirmDeleteModal from "../../modal/ConfirmDeleteModal"
import DemoDatasetForm from "./DemoDatasetForm"

interface AdminDemoDatasetCardProps {
    dataset: DemoDatasetJson
    didUpdate: (updated: DemoDatasetJson) => void
    didDelete: (deleteId: string) => void
}

function AdminDemoDatasetCard({dataset, didUpdate, didDelete}: AdminDemoDatasetCardProps) {
    const [editOpen, setEditOpen] = useState(false)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

    const [deleting, setDeleting] = useState(false)
    const [deleteError, setDeleteError] = useState<ApiError>()

    const handleDelete = () => {
        setDeleting(true)
        DemoDatasetRequests.delete(dataset.id)
            .then(() => {
                setDeleteError(undefined)
                setConfirmDeleteOpen(false)
                didDelete(dataset.id)
            })
            .catch(setDeleteError)
            .finally(() => setDeleting(false))
    }

    return (
        <Card variant="outlined" sx={{p: 2}}>
            <SpaceBetween>
                <Typography variant="subtitle1">{dataset.name}</Typography>
                <Stack direction="row" spacing={2}>
                    <AsyncButton variant="text"
                                 color="error"
                                 waiting={deleting}
                                 onClick={() => setConfirmDeleteOpen(true)}>Delete</AsyncButton>
                    <Button variant="outlined" startIcon={<EditIcon/>} onClick={() => setEditOpen(true)}>Edit</Button>
                </Stack>
            </SpaceBetween>
            <Typography paragraph sx={{mt: 2}}>{dataset.description}</Typography>

            <Stack direction="row" spacing={1}>
                {dataset.public ? <Chip color="primary" label="Public"/>
                    : <Chip color="secondary" label="Private"/>}
                <Chip variant="outlined" label={`${dataset.numberOfRecords} records`}/>
            </Stack>

            <ErrorMessage error={deleteError}/>

            <ConfirmDeleteModal heading={`Delete ${dataset.name} dataset`}
                                body="Sure you wanna do that?"
                                open={confirmDeleteOpen}
                                setOpen={setConfirmDeleteOpen}
                                confirmedDelete={() => handleDelete()}
            />

            <ModalBase title="Edit Demo Dataset" open={editOpen} setOpen={setEditOpen}>
                <DemoDatasetForm existing={dataset} didUpdate={(updated) => {
                    setEditOpen(false)
                    didUpdate(updated)
                }}/>
            </ModalBase>
        </Card>
    );
}

export default AdminDemoDatasetCard;