export enum ModelPlatform {
    OPEN_AI = "OPEN_AI",
    AI21 = "AI21",
    REPLICATE = "REPLICATE",
    ANTHROPIC = "ANTHROPIC",
    GOOGLE_AI = "GOOGLE_AI",
    GROQ_CLOUD = "GROQ_CLOUD",
}

export function resolveNameForModelPlatform(platform: ModelPlatform) {
    switch (platform) {
        case ModelPlatform.OPEN_AI:
            return "OpenAI"
        case ModelPlatform.AI21:
            return "AI21"
        case ModelPlatform.REPLICATE:
            return "Replicate"
        case ModelPlatform.ANTHROPIC:
            return "Anthropic"
        case ModelPlatform.GOOGLE_AI:
            return "Google AI"
        case ModelPlatform.GROQ_CLOUD:
            return "Groq Cloud"
    }
}