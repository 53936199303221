import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {DeleteOutline} from "@mui/icons-material";
import {RoleCheck} from "../../library/RoleCheck";
import {ExampleRequests} from "../../api/requests/ExampleRequests"
import ExampleJson from "../../api/json/ExampleJson"
import {useToasts} from "../../hooks/useToasts"

interface ExampleActionsMenuProps {
    example?: ExampleJson
    didDelete: () => void
}

function ExampleActionsMenu({example, didDelete}: ExampleActionsMenuProps) {
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>()

    const [deleteInProgress, setDeleteInProgress] = useState(false)

    const toasts = useToasts()

    const handleDelete = () => {
        if (!example) return

        setDeleteInProgress(true)
        ExampleRequests.delete(example.id)
            .then(() => didDelete && didDelete())
            .catch(toasts.showError)
            .finally(() => setDeleteInProgress(false))
    }

    if (!RoleCheck.isAdmin() || !example) {
        return <></>
    }

    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <MoreVertIcon/>
            </IconButton>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        {RoleCheck.isAdmin() && example &&
                            <MenuItem disabled={deleteInProgress}
                                      onClick={() => {
                                          setMenuAnchor(undefined)
                                          handleDelete()
                                      }}
                            >
                                <ListItemIcon>
                                    <DeleteOutline color="error"/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography color="error">Delete</Typography>
                                </ListItemText>
                            </MenuItem>
                        }
                    </MenuList>
                </Paper>
            </Menu>
        </>
    )
}

export default ExampleActionsMenu;