import React, {ReactNode, useRef, useState} from 'react';
import {Button, Stack, TextField} from "@mui/material"
import AsyncButton from "../button/AsyncButton"
import ApiError from "../../api/ApiError"
import useEnterShortcut from "../../hooks/useEnterShortcut"
import ModalBase from "./ModalBase"

interface CopyModalProps {
    heading: string
    open: boolean
    setOpen: (open: boolean) => void
    handleCopy: () => void
    copyInProgress: boolean
    error?: ApiError
    children?: ReactNode
}

function RenameModal({heading, open, setOpen, handleCopy, copyInProgress, error, children}: CopyModalProps) {
    return (
        <ModalBase
            open={open}
            setOpen={setOpen}
            title={heading}
            error={error}
            buttons={
                <>
                    <Button variant="outlined" color="secondary" onClick={() => setOpen(false)}>Cancel</Button>
                    <AsyncButton
                        variant="contained"
                        waiting={copyInProgress}
                        onClick={() => handleCopy()}
                    >
                        Make copy
                    </AsyncButton>
                </>
            }
        >
            {children && children}
        </ModalBase>
    );
}

export default RenameModal;