export enum MembershipRole {
    ADMIN = "ADMIN",
    MANAGER = "MANAGER",
    LABELER = "LABELER",
}

export const resolveNameForRole = (role: MembershipRole) => {
    switch (role) {
        case MembershipRole.ADMIN:
            return "Admin"
        case MembershipRole.MANAGER:
            return "Manager"
        case MembershipRole.LABELER:
            return "Labeler"
    }
}