import React, {useState} from 'react';
import {InviteJson} from "../../../api/json/InviteJson";
import {Avatar, Chip, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import {TimeUtil} from "../../../library/TimeUtil";
import {resolveNameForRole} from "../../../api/enum/MembershipRole";
import {InviteRequests} from "../../../api/requests/InviteRequests";
import AsyncButton from "../../button/AsyncButton";
import {useToasts} from "../../../hooks/useToasts"

function MembersPageInviteRow({invite, didDelete}: { invite: InviteJson, didDelete: () => void }) {
    const [deleteInProgress, setDeleteInProgress] = useState(false)
    const toasts = useToasts()

    const handleDeleteInvite = (invite: InviteJson) => {
        setDeleteInProgress(true)
        InviteRequests.delete(invite.id)
            .then(didDelete)
            .catch(toasts.showError)
            .finally(() => setDeleteInProgress(false))
    }

    return (
        <>
            <TableRow>
                <TableCell width={60}><Avatar/></TableCell>
                <TableCell align="left">
                    {invite.email}
                    <Typography sx={{ml: 1}}>
                        {invite.expired ?
                            <Chip size="small" label="Expired" color="warning"/>
                            : <Tooltip title={TimeUtil.ago(invite.created)}><Chip size="small" label="Invited"/></Tooltip>
                        }
                    </Typography>
                </TableCell>
                <TableCell>{resolveNameForRole(invite.role)}</TableCell>
                <TableCell align="right">
                    <AsyncButton waiting={deleteInProgress} variant="contained" color="info" onClick={() => handleDeleteInvite(invite)}>Uninvite</AsyncButton>
                </TableCell>
            </TableRow>
        </>
    );
}

export default MembersPageInviteRow;