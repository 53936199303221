import React, {useEffect, useState} from 'react';
import {PredefinedOptionJson} from "../../api/json/PredefinedOptionJson";
import {Stack, TextField, Typography} from "@mui/material";
import FieldPredefinedOptionsEditorRow from "./FieldPredefinedOptionsEditorRow";
import {FieldRequests} from "../../api/requests/FieldRequests";
import {ProjectJson} from "../../api/json/ProjectJson";
import {FieldPredefinedOptionsJson} from "../../api/json/FieldJson";

interface FieldPredefinedOptionsEditorProps {
    project: ProjectJson
    fieldId?: string
    options: PredefinedOptionJson[]
    updateOptions: (options: PredefinedOptionJson[], saveChanges: boolean) => void
}

function FieldPredefinedOptionsEditor({project, fieldId, options, updateOptions}: FieldPredefinedOptionsEditorProps) {
    const [newOption, setNewOption] = useState("")
    const [valueAlreadyExists, setValueAlreadyExists] = useState(false)

    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                addNewOption()
            }
        }
        window.addEventListener('keyup', listener);
        return () => window.removeEventListener('keyup', listener)
    }, [newOption])

    const addNewOption = () => {
        if (newOption.length === 0)
            return

        if (valueAlreadyExists)
            return

        setNewOption("")
        addOption(newOption)
    }

    useEffect(() => {
        setValueAlreadyExists(newOption.length > 0 && options.find(option => option.value === newOption) !== undefined)
    }, [newOption])

    useEffect(() => {
        if (!fieldId) return

        // Get example counts and update the options
        FieldRequests.get(project.id, fieldId)
            .then(field => {
                const optionsWithExampleCounts = (field as FieldPredefinedOptionsJson).predefinedOptions
                if (optionsWithExampleCounts)
                    updateOptions(optionsWithExampleCounts, false)
            })
    }, [fieldId])

    const addOption = (newValue: string) => {
        const newOptions = [...options]
        const newOption = {
            value: newValue,
            numberOfExamples: 0,
        }
        newOptions.push(newOption)
        updateOptions(newOptions, true)
    }

    const handleUpdate = (newValue: string, index: number) => {
        const newOptions = [...options]
        newOptions[index].value = newValue
        updateOptions(newOptions, false)
    }

    const handleRemoveDeleted = (option: PredefinedOptionJson, index: number) => {
        const updated = [...options]
        updated.splice(index, 1)
        return updateOptions(updated, true)
    }

    return (
        <>
            <Stack spacing={0.5}>
                <Typography variant="subtitle2">Predefined Options:</Typography>
                {options?.map((option, index) =>
                    <FieldPredefinedOptionsEditorRow
                        key={index}
                        project={project}
                        fieldId={fieldId}
                        option={option}
                        didDelete={() => handleRemoveDeleted(option, index)}
                        didUpdate={(newValue) => handleUpdate(newValue, index)}
                    />
                )}
                <TextField
                    value={newOption}
                    helperText={valueAlreadyExists ? "This value is already in the list." : "Type and press enter to add new option."} size="small"
                    error={valueAlreadyExists}
                    onChange={(e) => setNewOption(e.target.value)}
                    onBlur={addNewOption}
                />
            </Stack>
        </>
    );
}

export default FieldPredefinedOptionsEditor;