import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {PagedResponseJson} from "../json/PagedResponseJson"
import {MarketingEventJson, MarketingEventProperties} from "../json/MarketingEventJson"

export class MarketingEventRequests {
    static create(properties: MarketingEventProperties): Promise<MarketingEventJson> {
        return new RequestBuilder(Method.Post, `marketing-events`)
            .withJson(properties)
            .withoutOrganization()
            .buildAndSend()
    }

    static update(id: string, properties: MarketingEventProperties): Promise<MarketingEventJson> {
        return new RequestBuilder(Method.Put, `marketing-events/${id}`)
            .withJson(properties)
            .withoutOrganization()
            .buildAndSend()
    }

    static copy(id: string): Promise<MarketingEventJson> {
        return new RequestBuilder(Method.Post, `marketing-events/${id}/copies`)
            .withoutOrganization()
            .buildAndSend()
    }

    static list(page: number = 0): Promise<PagedResponseJson<MarketingEventJson>> {
        const queryParams = new URLSearchParams()
        queryParams.append("page", page.toString())

        return new RequestBuilder(Method.Get, `marketing-events?${queryParams.toString()}`)
            .withoutOrganization()
            .buildAndSend()
    }

    static listUpcoming(page: number = 0): Promise<PagedResponseJson<MarketingEventJson>> {
        const queryParams = new URLSearchParams()
        queryParams.append("page", page.toString())

        return new RequestBuilder(Method.Get, `marketing-events/upcoming?${queryParams.toString()}`)
            .withoutOrganization()
            .buildAndSend()
    }

    static delete(id: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `marketing-events/${id}`)
            .withoutOrganization()
            .buildAndSend()
    }
}