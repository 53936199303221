import React, {ReactNode} from 'react';
import {Stack, SxProps} from "@mui/material"
import CostTooltip from "./CostTooltip"

interface InfoHelperTextProps {
    info?: string | null
    flipped?: boolean
    children: ReactNode, size?: "small" | "medium" | "large" | "inherit"
    sx?: SxProps
}

function PriceCheckInfo({info, flipped, size, sx, children}: InfoHelperTextProps) {
    return (
        <Stack direction={flipped ? "row-reverse" : "row"} spacing={1} alignItems="center" sx={sx}>
            {children}
            <CostTooltip info={info} size={size} />
        </Stack>
    );
}

export default PriceCheckInfo;