import React from 'react';
import NumberInput from "../form/NumberInput"

interface AllocationInputProps {
    currentNumberOfTrainingExamples: number
    currentNumberOfValidationExamples: number
    validationPercent: number | null
    onChange: (newValue: number) => void
}

function DatasetAllocationInput({ validationPercent, currentNumberOfTrainingExamples, currentNumberOfValidationExamples, onChange }: AllocationInputProps ) {
    const total = currentNumberOfTrainingExamples + currentNumberOfValidationExamples

    const newNumberOfValidationExamples = Math.round((validationPercent ?? 0) * total)
    const newNumberOfTrainingExamples = Math.round(total - newNumberOfValidationExamples)

    const handleNewValue = (newValue: number) => {
        let percent = Math.round(newValue) / 100
        onChange(percent)
    }

    return (
        <NumberInput
            label="For Validation"
            value={validationPercent ? Math.round(validationPercent * 100) : null}
            helperText={`${newNumberOfTrainingExamples} training, ${newNumberOfValidationExamples} validation`}
            min={0}
            max={100}
            onChange={handleNewValue}
            onBlur={handleNewValue}
            endAdornment={<>%</>}
        />
    );
}

export default DatasetAllocationInput;