import React, {useEffect, useState} from 'react';
import {OrganizationRequests} from "../../api/requests/OrganizationRequests"
import {useApi} from "../../hooks/useApi"
import {useToasts} from "../../hooks/useToasts"
import {Button, CircularProgress, Stack, Typography} from "@mui/material"
import BillingPortalButton from "./BillingPortalButton"
import {Link as RouterLink} from "react-router-dom"

function CheckoutSuccessPage() {
    const [checkingForPlan, setCheckingForPlan] = useState(true)
    const {currentOrganization, setCurrentOrganization} = useApi()

    const toasts = useToasts()

    useEffect(() => {
        const timer = setInterval(() => {
            checkForUpdate(timer)
        }, 2000)
        return () => clearInterval(timer)
    }, [])

    const checkForUpdate = (timer: NodeJS.Timer) => {
        if (!currentOrganization) return

        OrganizationRequests.get(currentOrganization.id)
            .then(organization => {
                if (organization.billingDetails?.activePlan) {
                    clearInterval(timer)
                    setCurrentOrganization(organization)
                    setCheckingForPlan(false)
                }
            })
            .catch(toasts.showError)

    }
    if (!currentOrganization) {
        return <>No organization is selected.</>
    }

    if (checkingForPlan) {
        return <CircularProgress/>
    }

    if (currentOrganization?.billingDetails?.activePlan) {
        return <>
            <Typography variant="h1" gutterBottom>Success</Typography>
            <Typography variant="body1" paragraph>Your account has been upgraded!</Typography>
            <Stack direction="row" spacing={2}>
                <Button component={RouterLink} variant="contained" to="/welcome">Get started</Button>
                <BillingPortalButton variant="text">Billing portal</BillingPortalButton>
            </Stack>
        </>
    }

    return (
        <>
            <Typography variant="h1" gutterBottom>Something went wrong</Typography>
            <Typography variant="body1">Please contact support@entrypointai.com for assistance.</Typography>
        </>
    );
}

export default CheckoutSuccessPage;