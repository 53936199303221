import React, {useEffect, useState} from 'react';
import {ProjectJson} from "../../api/json/ProjectJson"
import ApiError from "../../api/ApiError"
import {Button, Modal, Stack, Typography} from "@mui/material"
import modalStyles from "../modal/Modal.module.scss"
import FieldOrderList from "./FieldOrderList"
import {AnyFieldJson} from "../../api/json/FieldJson"
import {FieldRequests} from "../../api/requests/FieldRequests"
import AsyncButton from "../button/AsyncButton"
import ErrorMessage from "../error/ErrorMessage"

function FieldOrderModal({project, fields, open, onClose}: {project: ProjectJson, fields: AnyFieldJson[], open: boolean, onClose: () => void}) {
    const [modified, setModified] = useState<AnyFieldJson[]>([])

    const [error, setError] = useState<ApiError>()
    const [saveFinishedCount, setSaveFinishedCount] = useState<number>(0)
    const [saveInProgress, setSaveInProgress] = useState(false)

    const handleSave = () => {
        setError(undefined)
        setSaveInProgress(true)
        setSaveFinishedCount(0)
        modified.forEach(field => {
            FieldRequests.update(project.id, field.id!, undefined, undefined, field.order)
                .then()
                .catch(setError)
                .finally(() => setSaveFinishedCount(prev => prev + 1))
        })
    }

    const updateFields = (updatedFields: AnyFieldJson[]) => {
        let newState = [...modified]
        updatedFields.forEach(updatedField => {
            let index = newState.findIndex( existing => existing.id === updatedField.id)
            if (index === -1) {
                newState.push(updatedField)
            } else {
                newState[index] = updatedField
            }
        })
        setModified(newState)
    }

    useEffect(() => {
        if (saveFinishedCount === modified.length) {
            setSaveInProgress(false)
            if (!error) {
                onClose()
            }
        }
    }, [saveFinishedCount])

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <div className={modalStyles.flexCenteredModalContainer}>
                <Stack spacing={2} className={modalStyles.fieldOrderModal}>
                    <Typography variant="h2">Order Fields</Typography>
                    <Typography>Set the order in which fields should be displayed.</Typography>

                    <FieldOrderList fields={fields}
                                    onChange={updateFields}
                                    sx={{mt:2}}/>

                    <Stack direction="row" alignSelf="flex-end" justifyContent="flex-end" spacing={2}>
                        <Button variant="contained" color="info" onClick={onClose}>
                            Cancel
                        </Button>
                        <AsyncButton variant="contained"
                                     waiting={saveInProgress}
                                     onClick={handleSave} sx={{flex: 1}}>
                            Save & close
                        </AsyncButton>
                    </Stack>
                    {error && <ErrorMessage error={error}/> }
                </Stack>
            </div>
        </Modal>
    );
}

export default FieldOrderModal;