import React, {useEffect, useState} from 'react'
import {
    Box,
    CircularProgress, Fab,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material"
import {useProjectData} from "../../hooks/useProjectData";
import ProjectPageHeader from "../layouts/ProjectPageHeader";
import {useNavigate} from "react-router-dom";
import FinetunesTable, {FinetuneTableColumn} from "../finetunes/FinetunesTable";
import {AnyFinetuneJson} from "../../api/json/FinetuneJson";
import {FinetuneRequests} from "../../api/requests/FinetuneRequests";
import ApiError from "../../api/ApiError"
import FinetuneColumnMenu from "../finetunes/FinetuneColumnMenu"
import ErrorMessage from "../error/ErrorMessage"
import NewTemplatedModelModal from "./NewTemplatedModelModal"
import {TemplatedModelJson} from "../../api/json/TemplatedModelJson"
import {TemplatedModelRequests} from "../../api/requests/TemplatedModelRequests"
import TemplatedModelsTable from "./TemplatedModelsTable"
import AddIcon from "@mui/icons-material/Add"

function TemplatedModelsPage() {
    const {id, project} = useProjectData()

    const [models, setModels] = useState<TemplatedModelJson[]>()
    const [finetunes, setFinetunes] = useState<AnyFinetuneJson[]>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>()
    const [showNewModelModal, setShowNewModelModel] = useState(false)

    const [columns, setColumns] = useState<FinetuneTableColumn[]>(["Score", "Platform", "Model", "Epochs", "Template", "Training Examples", "Created", "Status"])

    const navigate = useNavigate()

    useEffect(() => {
        if (loading || finetunes) return

        loadModels()
        loadFinetunes()
    }, [project])

    const loadModels = () => {
        setLoading(true)
        TemplatedModelRequests.list(id)
            .then(response => {
                setModels(response.content)
                setLoading(false)
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }

    const loadFinetunes = () => {
        setLoading(true)
        FinetuneRequests.list(id)
            .then(response => {
                setFinetunes(response.content)
                setLoading(false)
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }

    if (!project) {
        return <CircularProgress/>
    }

    const disableFinetuning = project.stats.numberOfTrainingExamples < 10
    return (
        <>
            <ProjectPageHeader
                showFab
                pageTitle="Models"
                replaceFab={
                    <Tooltip title={project.templates.length === 0 && "Create a template first"}>
                        <Box>
                            <Fab color="primary"
                                 aria-label="add"
                                 disabled={project.templates.length === 0}
                                 onClick={(event) => setMenuAnchor(event.currentTarget)}
                            >
                                <AddIcon/>
                            </Fab>
                        </Box>
                    </Tooltip>
                }
            />

            <Stack spacing={4}>
                {models && models.length > 0 && (
                    <Stack spacing={2}>
                        <Typography variant="subtitle1">Templated models</Typography>
                        <TemplatedModelsTable project={project} models={models} reload={() => loadModels()}/>
                    </Stack>
                )}

                {!finetunes && loading && <CircularProgress/>}
                {finetunes && finetunes.length > 0 &&
                    <Stack spacing={2}>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="subtitle1">Fine-tuned models</Typography>
                            <FinetuneColumnMenu columns={columns} onChange={setColumns}/>
                        </Stack>
                        <FinetunesTable finetunes={finetunes} columns={columns} reload={() => loadFinetunes()}/>
                    </Stack>
                }
            </Stack>

            {error && <ErrorMessage error={error} sx={{mt:2}}/>}

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
                sx={{mt: 1}}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            setShowNewModelModel(true)
                        }}
                        >
                            <ListItemText>
                                <Typography>Apply a template</Typography>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem disabled={disableFinetuning}
                                  onClick={() => navigate(`/projects/${id}/fine-tunes/start`)}
                        >
                            <ListItemText>
                                <Stack>
                                    <Typography>Start a fine-tune</Typography>
                                    {project.stats.numberOfTrainingExamples < 10 &&
                                        <Typography variant="caption">Add at least 10 examples first.</Typography>
                                    }
                                </Stack>
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>

            <NewTemplatedModelModal project={project}
                                    open={showNewModelModal}
                                    setOpen={setShowNewModelModel}
                                    didCreateModel={newModel => {
                                        let updated = [newModel, ...models ?? []]
                                        setModels(updated)
                                    }}
            />
        </>
    )
}

export default TemplatedModelsPage