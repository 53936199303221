import React, {useState} from 'react';
import TemplateUtil from "../../library/TemplateUtil"
import {Button, Divider, Typography} from "@mui/material"
import TemplateRendererSection from "./TemplateRendererSection"
import {TemplateRendererProps} from "./TemplateRenderer"
import {TemplateChatParts} from "../../api/json/TemplateJson"
import {useProjectData} from "../../hooks/useProjectData"

interface TemplateRendererChatProps extends TemplateRendererProps {
    templateParts: TemplateChatParts
}

function TemplateRendererChat({dataSource, templateParts, promptDisplay, completionDisplay, section, showLabels, staticSystemPromptTreatment}: TemplateRendererChatProps) {
    const {project} = useProjectData()

    const [showStaticSystemPrompt, setShowStaticSystemPrompt] = useState(staticSystemPromptTreatment === "show")

    const {system, user, assistant} = dataSource ?  TemplateUtil.renderChat(templateParts, dataSource, project?.fields) : {...templateParts}
    const systemVariables = TemplateUtil.extractReferencesFromString(templateParts.system)

    const dividerNode = <Divider sx={{width: 60}}/>

    const prompt = (
        <>
            {(systemVariables.length > 0 || showStaticSystemPrompt) &&
                <>
                    {showLabels && <Typography variant="subtitle1">System</Typography>}
                    <TemplateRendererSection value={system} display={promptDisplay}/>
                    {system && user && !showLabels && dividerNode}
                </>
            }
            {systemVariables.length === 0 && system && !showStaticSystemPrompt && (!staticSystemPromptTreatment || staticSystemPromptTreatment == "collapse") &&
                <Button size="small" onClick={() => setShowStaticSystemPrompt(true)} sx={{p:0}}>Show system prompt</Button>
            }
            {showLabels && <Typography variant="subtitle1">User</Typography>}
            {user && <TemplateRendererSection value={user} display={promptDisplay}/>}
        </>
    )

    return (
        <>
            {section !== "completion" && prompt}
            {section === undefined && !showLabels && (system || user) && dividerNode}
            {section !== "prompt" &&
                <>
                    {showLabels && <Typography variant="subtitle1">Completion</Typography>}
                    <TemplateRendererSection value={assistant} display={completionDisplay}/>
                </>
            }
        </>
    )
}

export default TemplateRendererChat;