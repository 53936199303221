import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, CircularProgress, Typography} from "@mui/material";
import {ExampleRequests, ExampleSearchParams} from "../../api/requests/ExampleRequests";
import ExampleJson from "../../api/json/ExampleJson";
import throttle from "lodash.throttle";
import {ProjectJson} from "../../api/json/ProjectJson";
import ApiError from "../../api/ApiError"
import ExampleFeedMulticolumnLayout from "./ExampleFeedMulticolumnLayout"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import ErrorMessage from "../error/ErrorMessage"

interface ExampleFeedProps {
    project: ProjectJson
    searchParams: ExampleSearchParams
    template?: AnyTemplateJson
    reload: number
}

function ExampleFeed({project, searchParams, template, reload}: ExampleFeedProps) {
    const [examples, setExamples] = useState<ExampleJson[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<ApiError>()

    const [page, setPage] = useState<number>(0)
    const [loadingNextPage, setLoadingNextPage] = useState<boolean>(false)
    const [maxPages, setMaxPages] = useState<number>()
    const [resultsCount, setResultsCount] = useState<number>()

    const loadExamples = useCallback(() => {
        setLoading(true)
        ExampleRequests.list(project.id, searchParams, 0)
            .then(response => {
                setPage(0)
                setMaxPages(Math.ceil(response.totalElements / response.size))
                setExamples(response.content)
                setResultsCount(response.totalElements)
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [project, searchParams])

    useEffect(() => {
        if (loading) return
        loadExamples()
    }, [project, reload, searchParams.dataset, searchParams.search?.fieldId, searchParams.search?.value, searchParams.sort])

    const loadNextPage = useCallback(() => {
        const newPage = page + 1
        setPage(newPage)
        setLoadingNextPage(true)
        ExampleRequests.list(project.id, searchParams, newPage)
            .then(response => setExamples([...examples ?? [], ...response.content]))
            .catch(setError)
            .finally(() => setLoadingNextPage(false))
    }, [page, loadingNextPage, examples])

    const pixelsFromBottomToLoadMore = 1200
    const handleScroll = () => {
        if (loadingNextPage || page === maxPages) return

        if (window.scrollY + window.innerHeight >= document.body.offsetHeight - pixelsFromBottomToLoadMore) {
            loadNextPage()
        }
    }

    const throttledHandleScroll = useMemo(
        () => throttle(handleScroll, 300), [page, loadingNextPage, examples]
    )

    useEffect(() => {
        window.addEventListener("scroll", throttledHandleScroll)
        return () => window.removeEventListener("scroll", throttledHandleScroll)
    })

    return (
        <>
            {resultsCount !== undefined &&
                <Typography component="div" variant="caption" sx={{ml: 0.5}}>{resultsCount.toLocaleString()} results</Typography>
            }
            <Box sx={{mt: 1, minHeight: (maxPages && maxPages > 1) ? "100vh" : "auto"}}>
                {loading && (examples === undefined || examples.length === 0) && <CircularProgress sx={{mt: 3}}/>}
                {examples &&
                    <ExampleFeedMulticolumnLayout project={project} template={template} examples={examples} />
                }
                {error && <ErrorMessage error={error} sx={{mt:2}}/>}
            </Box>
        </>
    );
}

export default ExampleFeed;