import React from 'react';
import {FinetuneAi21Json} from "../../api/json/FinetuneJson"
import GridItem from "../models/GridItem"
import {Box, Button} from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

function FinetuneDetailGridItemsOpenAi({finetune}: { finetune: FinetuneAi21Json }) {
    return (
        <>
            <GridItem label="Model type" value={finetune.modelType} fullWidth/>
            <GridItem label="AI21 ID"
                      value={finetune.platformId}
                      fullWidth
                      codeValue
                      extra={
                                        <Box sx={{mt: 1}}>
                                            <Button href="https://studio.ai21.com/custom-models" target="_blank"
                                                    rel="noopener noreferrer" endIcon={<OpenInNewIcon/>}>Custom
                                                Models</Button>
                                        </Box>
                                    }/>
            <GridItem label="Dataset ID" value={finetune.datasetId ?? ""} fullWidth/>
        </>
    );
}

export default FinetuneDetailGridItemsOpenAi;