import React from 'react';
import {Tooltip} from "@mui/material"
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';

function CostTooltip({info, size}: { info?: string | null, size?: "small" | "medium" | "large" | "inherit" }) {
    if (!info) {
        return <></>
    }

    return (
        <Tooltip title={info}>
            <SellOutlinedIcon fontSize={size} color="secondary"/>
        </Tooltip>
    );
}

export default CostTooltip;