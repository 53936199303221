import React, {ReactNode} from 'react';
import {useProjectData} from "../../hooks/useProjectData";
import {Alert, Container} from "@mui/material";
import styles from "./Layout.module.scss";

function ProjectLayoutContainer({children}: { children: ReactNode }) {
    const {id, error} = useProjectData()
    return (
        <Container className={styles.mainContainer} sx={{mt: 4, pb: 12}} key={id}>
            {error ?
                <Alert severity="error">Project could not be loaded ({error.resolveUserMessage()}). Please refresh,
                    try again soon, or log out and log back in.</Alert>
                : children}
        </Container>
    );
}

export default ProjectLayoutContainer;