import React, {useEffect, useState} from 'react';
import {ProjectJson} from "../../api/json/ProjectJson"
import {Box, CircularProgress, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material"
import {useToasts} from "../../hooks/useToasts"
import {EvalRequests} from "../../api/requests/EvalRequests"
import TestRunList from "./TestRunList"
import {AnyModelJson} from "../../api/json/TemplatedModelJson"
import TestRunScoringPanel from "./TestRunScoringPanel"
import EvalJson from "../../api/json/EvalJson"
import EvalSectionHeader from "./EvalSectionHeader"

interface EvalSectionProps {
    project: ProjectJson
    model: AnyModelJson
    updateEval: (evalJson: EvalJson) => void
}

function EvalSection({project, model, updateEval}: EvalSectionProps) {
    const [filter, setFilter] = useState<"all" | "unscored">("all")

    const toasts = useToasts()

    const evalJson = model.eval

    const loadEval = () => {
        EvalRequests.get(model.eval.id)
            .then(updateEval)
            .catch(toasts.showError)
    }

    const reload = () => {
        if (model.eval.completed)
            return

        loadEval()
    }

    useEffect(() => {
        const interval = setInterval(() => reload(), 2000)
        return () => clearInterval(interval)
    }, [model])

    if (!evalJson) {
        return <CircularProgress/>
    }

    const testRunsToScore = evalJson.stats.completedTestRuns - evalJson.stats.scoredTestRuns
    return (
        <>
            <EvalSectionHeader
                project={project}
                evalJson={evalJson}
                totalValidationExamples={project.stats.numberOfValidationExamples}
                updateEval={updateEval}
            />

            {testRunsToScore > 0 &&
                <ToggleButtonGroup
                    value={filter}
                    size="medium"
                    exclusive
                    onChange={(event, value) => {
                        if (!value) return
                        event.currentTarget.scrollIntoView({behavior: 'auto', block: 'start'});
                        setFilter(value)
                    }}
                    aria-label="evaluations filter"
                    sx={{mt: 3}}
                >
                    <ToggleButton value="unscored" sx={{pl: 2, pr: 2}}>
                        Review ({testRunsToScore})
                    </ToggleButton>
                    <ToggleButton value="all" sx={{pl: 2, pr: 2}}>
                        All ({evalJson.stats.completedTestRuns})
                    </ToggleButton>
                </ToggleButtonGroup>
            }

            {evalJson.completed &&
                <Box>
                    {filter === "unscored" && testRunsToScore > 0
                        ?
                        <>

                            <Typography variant="h3" sx={{mt: 3}}>Review & Score</Typography>
                            <TestRunScoringPanel
                                model={model}
                                reloadEvalStats={loadEval}
                            />

                        </>
                        : <>
                            <Typography variant="h3" sx={{mt: 3}}>Test Runs</Typography>
                            <TestRunList model={model} reloadEvalStats={loadEval}/>
                        </>
                    }
                </Box>
            }
        </>
    );
}

export default EvalSection;