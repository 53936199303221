import React, {ReactNode} from 'react';
import {Link, Typography} from "@mui/material";
import ActivityCardBase from "./ActivityCardBase";
import {ActivityType} from "../../../api/enum/ActivityType";
import {Link as RouterLink} from "react-router-dom";
import {ProjectSummaryJson} from "../../../api/json/ProjectSummaryJson";
import {ActivityTemplateJson, AnyActivityJson} from "../../../api/json/AnyActivityJson";
import {UserJson} from "../../../api/json/UserJson"
import {AnyTemplateJson} from "../../../api/json/TemplateJson"
import {resolveNameForModelPlatform} from "../../../api/enum/ModelPlatform"

interface ActivityCardProps {
    activity: AnyActivityJson
    projectContext?: boolean
    adminContext?: boolean
}

function ActivityCard({activity, projectContext, adminContext}: ActivityCardProps) {

    const projectLink = (project: ProjectSummaryJson) => {
        if (adminContext) return project.name
        return <Link component={RouterLink} to={`/projects/${project.id}/overview`}>{project.name}</Link>
    }

    const templateLink = (project: ProjectSummaryJson, template: AnyTemplateJson) => {
        return <Link component={RouterLink} to={`/projects/${project.id}/templates/${template.id}`}>{template.name}</Link>
    }

    const fullName = (user: UserJson) => {
        if (adminContext) return <Link component={RouterLink} to={`/admin/users/${user.id}`}>{user.fullName}</Link>

        return user.fullName
    }

    const getContent = (): ReactNode => {
        if ("project" in activity) {
            const displayNumber = activity.number?.toLocaleString()
            if (projectContext) {
                switch (activity.type) {
                    case ActivityType.PROJECT_CREATE:
                        return <>{fullName(activity.user)} created this project.</>
                    case ActivityType.PROJECT_IMPORT_EXAMPLES:
                        return <>{fullName(activity.user)} imported {displayNumber} examples.</>
                    case ActivityType.PROJECT_CREATE_CLASSIFICATION:
                        return <>{fullName(activity.user)} created {displayNumber} classifications.</>
                    case ActivityType.PROJECT_CREATE_FIELD:
                        return <>{fullName(activity.user)} created {displayNumber} fields.</>
                    case ActivityType.PROJECT_CREATE_TEMPLATE:
                        return <>{fullName(activity.user)} created the {templateLink(activity.project, (activity as ActivityTemplateJson).template)} template.</>
                    case ActivityType.PROJECT_START_FINE_TUNE:
                        if ("finetune" in activity)
                            return <>{fullName(activity.user)} started the <Link component={RouterLink} to={`/projects/${activity.project.id}/fine-tunes/${activity.finetune.id}`}>{activity.finetune.name}</Link> fine-tune with {activity.finetune.trainingExampleCount} training examples.</>
                        break
                }
            } else {
                switch (activity.type) {
                    case ActivityType.PROJECT_CREATE:
                        return <>{fullName(activity.user)} created the {projectLink(activity.project)} project.</>
                    case ActivityType.PROJECT_IMPORT_EXAMPLES:
                        return <>{fullName(activity.user)} imported {displayNumber} examples into {projectLink(activity.project)}.</>
                    case ActivityType.PROJECT_CREATE_CLASSIFICATION:
                        return <>{fullName(activity.user)} created {displayNumber} classifications in {projectLink(activity.project)}.</>
                    case ActivityType.PROJECT_CREATE_FIELD:
                        return <>{fullName(activity.user)} created {displayNumber} fields in {projectLink(activity.project)}.</>
                    case ActivityType.PROJECT_CREATE_TEMPLATE:
                        return <>{fullName(activity.user)} created a template in {projectLink(activity.project)}.</>
                    case ActivityType.PROJECT_START_FINE_TUNE:
                        return <>{fullName(activity.user)} started a fine-tune in {projectLink(activity.project)}.</>
                }
            }
        } else {
            if (activity.type === ActivityType.INTEGRATION_UPDATE) {
                return <>{fullName(activity.user)} updated the {resolveNameForModelPlatform(activity.platform)} integration.</>
            }
        }
    }

    return (
        <ActivityCardBase activity={activity} showUserEmail={adminContext}>
            <Typography component="div" variant="body1">{getContent()}</Typography>
        </ActivityCardBase>
    );
}

export default ActivityCard;