import React, {ReactNode} from 'react';
import {Stack, SxProps} from "@mui/material"

interface SpaceBetweenProps {
    children: ReactNode
    alignItems?: "flex-start" | "center" | "flex-end" | "baseline"
    sx?: SxProps
}

function SpaceBetween({children, alignItems, sx}: SpaceBetweenProps) {
    return (
        <Stack direction={{sm: "row", xs: "column"}} justifyContent="space-between" alignItems={alignItems ?? "center"}
               sx={sx}>
            {children}
        </Stack>
    );
}

export default SpaceBetween;