import React, {createContext, useContext, useEffect, useState} from 'react';
import {
    Ai21Json,
    AnthropicJson,
    AnyIntegrationJson,
    GoogleAiJson,
    GroqCloudJson,
    OpenAiJson,
    ReplicateJson,
} from "../api/json/IntegrationJson"
import {IntegrationRequests} from "../api/requests/IntegrationRequests"
import {useApi} from "./useApi"
import ApiError from "../api/ApiError"
import {ModelPlatform} from "../api/enum/ModelPlatform"

export type IntegrationsHook = {
    integrations?: AnyIntegrationJson[]
    modelPlatforms?: ModelPlatform[]
    openAi: OpenAiJson | null
    ai21: Ai21Json | null
    replicate: ReplicateJson | null
    anthropic: AnthropicJson | null
    googleAi: GoogleAiJson | null
    groqCloud: GroqCloudJson | null
    integrationsLoading: boolean
    integrationsError?: ApiError
    reloadIntegrations: () => void
};

function useIntegrationsData(): IntegrationsHook {
    const [integrations, setIntegrations] = useState<AnyIntegrationJson[]>()
    const [openAi, setOpenAi] = useState<OpenAiJson | null>(null)
    const [ai21, setAi21] = useState<Ai21Json | null>(null)
    const [replicate, setReplicate] = useState<ReplicateJson | null>(null)
    const [anthropic, setAnthropic] = useState<AnthropicJson | null>(null)
    const [googleAi, setGoogleAi] = useState<GoogleAiJson | null>(null)
    const [groqCloud, setGroqCloud] = useState<GroqCloudJson | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    const {currentUser, currentOrganization, token} = useApi()

    useEffect(() => loadIntegrations(),
        [currentUser, currentOrganization]
    )

    const loadIntegrations = () => {
        if (!currentUser || !currentOrganization || !token) return

        setLoading(true)
        IntegrationRequests.list()
            .then(integrations => {
                setIntegrations(integrations)
                setOpenAi(
                    integrations.find(integration => integration.platform === ModelPlatform.OPEN_AI) as OpenAiJson | null
                )
                setAi21(
                    integrations.find(integration => integration.platform === ModelPlatform.AI21) as Ai21Json | null
                )
                setReplicate(
                    integrations.find(integration => integration.platform === ModelPlatform.REPLICATE) as ReplicateJson | null
                )
                setAnthropic(
                    integrations.find(integration => integration.platform === ModelPlatform.ANTHROPIC) as AnthropicJson | null
                )
                setGoogleAi(
                    integrations.find(integration => integration.platform === ModelPlatform.GOOGLE_AI) as GoogleAiJson | null
                )
                setGroqCloud(
                    integrations.find(integration => integration.platform === ModelPlatform.GROQ_CLOUD) as GroqCloudJson | null
                )
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }

    return {
        integrations: integrations,
        modelPlatforms: integrations?.map(it => it.platform).filter(it => it !== null) as ModelPlatform[],
        openAi: openAi,
        ai21: ai21,
        anthropic: anthropic,
        replicate: replicate,
        googleAi: googleAi,
        groqCloud: groqCloud,
        integrationsLoading: loading,
        integrationsError: error,
        reloadIntegrations: loadIntegrations
    };
}

const IntegrationsContext = createContext<IntegrationsHook | null>(null);

type ApiProviderProps = {
    children: React.ReactNode;
};

const IntegrationsProvider: React.FC<ApiProviderProps> = ({ children }) => {
    const data = useIntegrationsData();
    return <IntegrationsContext.Provider value={data}>{children}</IntegrationsContext.Provider>;
};

const useIntegrations = (): IntegrationsHook => {
    const context = useContext(IntegrationsContext);
    if (!context) {
        throw new Error('useIntegrations must be used within an IntegrationsProvider');
    }
    return context;
};

export { IntegrationsProvider, useIntegrations };