import React from 'react'
import {Grid, Typography} from "@mui/material"
import AdminActivitiesFeed from "./AdminActivitiesFeed"
import AdminHeader from "./AdminHeader"
import AdminMarketingLeadsFeed from "./AdminMarketingLeadsFeed"

function AdminHomePage() {
    return (
        <>
            <AdminHeader />
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Typography variant="h2">Marketing Leads</Typography>
                    <AdminMarketingLeadsFeed sx={{mt: 3}}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h2">Latest Activity</Typography>
                    <AdminActivitiesFeed sx={{mt: 3}}/>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminHomePage;