import React from 'react';
import {Box, Stack, Typography} from "@mui/material"
import FieldValueJson from "../../api/json/FieldValueJson"
import {AnyFieldJson} from "../../api/json/FieldJson"
import FieldValueInputWithHelperText from "./FieldValueInputWithHelperText"

interface FieldValueInputGroupProps {
    title: string
    fieldValues: FieldValueJson[]
    fields: AnyFieldJson[]
    onChange: (newValue: string, reference: string) => void
}

function FieldValueInputGroup({title, fieldValues, fields, onChange}: FieldValueInputGroupProps) {
    return (
        <Box>
            <Typography variant="subtitle1">{title}</Typography>
            <Stack spacing={2} sx={{mt: 1.5}}>
                {fields.map(field => {
                        const fieldValue = fieldValues.find(it => it.field.reference === field.reference)
                        return (
                            <FieldValueInputWithHelperText
                                key={field.id}
                                field={field}
                                value={fieldValue?.value ?? ""}
                                onChange={(newValue) => onChange(newValue, field.reference)}
                            />
                        )
                    },
                )}
            </Stack>
        </Box>
    );
}

export default FieldValueInputGroup;