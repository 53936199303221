import React, {useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, Stack, SxProps, TextField} from "@mui/material"
import {UpgradeCodeJson} from "../../../api/json/UpgradeCodeJson"
import NumberInput from "../../form/NumberInput"
import ApiError from "../../../api/ApiError"
import AsyncButton from "../../button/AsyncButton"
import {UpgradeCodeRequests} from "../../../api/requests/UpgradeCodeRequests"
import {UpgradeCodePeriod} from "../../../api/enum/UpgradeCodePeriod"
import ErrorMessage from "../../error/ErrorMessage"

function UpgradeCodeForm({sx, didCreate}: { sx?: SxProps, didCreate: (newUpgradeCode: UpgradeCodeJson) => void }) {
    const [name, setName] = useState<string>("")
    const [numberOfUsers, setNumberOfUsers] = useState<number>(3)
    const [numberOfExamples, setNumberOfExamples] = useState<number>(500)
    const [upgradePeriod, setUpgradePeriod] = useState<UpgradeCodePeriod>(UpgradeCodePeriod.WEEKS)
    const [upgradeDuration, setUpgradeDuration] = useState<number>(2)
    const [maxRedemptions, setMaxRedemptions] = useState<number>(1)

    const [error, setError] = useState<ApiError>()

    const [createInProgress, setCreateInProgress] = useState(false)
    const create = () => {
        setCreateInProgress(true)
        UpgradeCodeRequests.create(name, numberOfUsers, numberOfExamples, upgradePeriod, upgradeDuration, maxRedemptions)
            .then(didCreate)
            .catch(setError)
            .finally(() => setCreateInProgress(false))
    }

    return (
        <Stack spacing={2} sx={sx}>
            <TextField
                label="Name"
                aria-label="name of promo code"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            <Stack direction="row" spacing={2}>
                <NumberInput
                    label="Number of Users"
                    ariaLabel="number of users"
                    min={1}
                    max={1000000}
                    value={numberOfUsers}
                    onChange={setNumberOfUsers}
                    fullWidth
                />
                <NumberInput
                    label="Number of Examples"
                    ariaLabel="number of examples"
                    min={1}
                    max={100000000}
                    value={numberOfExamples}
                    onChange={setNumberOfExamples}
                    fullWidth
                />
            </Stack>

            <Stack direction="row" spacing={2}>
                <NumberInput
                    label="Upgrade Duration"
                    ariaLabel="upgrade duration"
                    min={1}
                    max={365}
                    value={upgradeDuration}
                    onChange={setUpgradeDuration}
                    fullWidth
                />
                <FormControl fullWidth>
                    <InputLabel>Upgrade Period</InputLabel>
                    <Select
                        labelId="period-select-label"
                        value={upgradePeriod}
                        label="Upgrade Period"
                        aria-label="upgrade period"
                        onChange={(e) => setUpgradePeriod(e.target.value as UpgradeCodePeriod)}
                    >
                        <MenuItem value={UpgradeCodePeriod.DAYS}>Days</MenuItem>
                        <MenuItem value={UpgradeCodePeriod.WEEKS}>Weeks</MenuItem>
                        <MenuItem value={UpgradeCodePeriod.MONTHS}>Month</MenuItem>
                        <MenuItem value={UpgradeCodePeriod.YEARS}>Years</MenuItem>
                    </Select>
                </FormControl>
            </Stack>

            <NumberInput
                label="Max Redemptions"
                ariaLabel="max redemptions"
                min={1}
                max={10000}
                value={maxRedemptions}
                onChange={setMaxRedemptions}
                fullWidth
            />
            <Box alignSelf="flex-end">
                <AsyncButton variant="contained"
                             waiting={createInProgress}
                             disabled={name.length === 0}
                             onClick={create}
                >
                    Create
                </AsyncButton>
            </Box>
            {error && <ErrorMessage error={error}/>}
        </Stack>
    );
}

export default UpgradeCodeForm;