import React, {useState} from 'react'
import {Box, Button, CircularProgress, Modal, Stack, Typography} from "@mui/material"
import styles from "./Accounts.module.scss"
import {useAuth0} from "@auth0/auth0-react"
import {Link as RouterLink} from "react-router-dom"
import modalStyles from "../modal/Modal.module.scss"
import {useApi} from "../../hooks/useApi"

function LoginPage() {
    const [termsOpen, setTermsOpen] = useState(false)
    const {loginWithRedirect, isAuthenticated, user, logout, isLoading} = useAuth0()
    const {currentUser} = useApi()

    if (currentUser) {
        if (isLoading) {
            return (
                <Stack alignItems="center">
                    <CircularProgress/>
                </Stack>
            )
        }

        if (isAuthenticated) {
            return (
                <Stack spacing={1} sx={{mt: 3}} alignItems="center">
                    <Typography>You are logged in as {currentUser.email}</Typography>
                    <Button component={RouterLink} variant="contained" className={styles.primaryButton} fullWidth
                            to={"/organizations/select"}>Continue</Button>
                    <Button onClick={() => logout()}>Logout</Button>
                </Stack>
            )
        }
    }

    return (
        <>
            <Typography variant="h1" align="center" sx={{mt: 1}}>Welcome to <span className={styles.coolGradient}>Entry Point</span></Typography>
            <Stack spacing={1} sx={{mt: 3}}>
                <Button variant="contained"
                        className={styles.primaryButton}
                        onClick={() => setTermsOpen(true)}>
                    Create an account
                </Button>

                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Typography>Already have an account?</Typography>
                    <Button onClick={() => loginWithRedirect({
                        appState: {returnTo: "/organizations/select"},
                    })}>
                        Login
                    </Button>
                </Stack>
            </Stack>

            <Modal open={termsOpen} onClose={() => setTermsOpen(false)}>
                <Stack spacing={2} className={modalStyles.large}>
                    <Typography variant="h2">Terms & Conditions</Typography>
                    <Typography>In order to create an account on Entry Point AI, you must first read and agree to the following terms:</Typography>
                    <Box component="iframe" src="https://app.termly.io/document/terms-and-conditions/00d854df-283b-498d-a71f-353e06f4d227" frameBorder={0} sx={{ minHeight: 360, }} />
                    <Stack direction="row" justifyContent="space-between">
                        <Button onClick={() => setTermsOpen(false)}>Cancel</Button>
                        <Button variant="contained" onClick={() => loginWithRedirect({
                            appState: {returnTo: "/onboarding/user"},
                            authorizationParams: {screen_hint: "signup"},
                        })}>I agree</Button>
                    </Stack>
                </Stack>
            </Modal>
        </>
    )
}

export default LoginPage