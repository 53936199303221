import React from 'react';
import {ProjectJson} from "../../api/json/ProjectJson"
import {AnyFieldJson} from "../../api/json/FieldJson"
import {Tooltip} from "@mui/material"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"

function FieldIssueIcon({ project, field}: { project: ProjectJson, field: AnyFieldJson}) {
    let issues: string[] = []

    if (project.stats.totalExamples > 0 && field.stats?.numberOfValues === 0)
        issues.push("Not used in any examples. Consider removing it.")

    if (issues.length === 0) {
        return <></>
    }

    return (
        <Tooltip title={issues.join(" ")}>
            <WarningAmberIcon color="warning" />
        </Tooltip>
    );
}

export default FieldIssueIcon;