import React from 'react';
import {Box, Link, Paper, Stack, Typography} from "@mui/material"
import {Link as RouterLink, useLocation} from "react-router-dom"

function AdminHeaderLink({path, label, strict}: { path: string, label: string, strict?: boolean }) {
    const location = useLocation()

    let selected: boolean
    if (strict) {
        selected = location.pathname === path
    } else {
        selected = location.pathname.includes(path)
    }

    return (
        <Link component={RouterLink} to={path} fontWeight={selected ? 600 : 400} fontSize={16}>{label}</Link>
    );
}

function AdminHeader() {
    return (
        <Box sx={{mb: 5, flex: 1}}>
            <Typography variant="overline">Admin</Typography>
            <Paper variant="outlined" sx={{ p: 1, pl: 2, pr: 2, mt: 1}}>
                <Stack direction="row" spacing={2}>
                    <AdminHeaderLink path="/admin" label="Home" strict/>
                    <AdminHeaderLink path="/admin/users" label="Users"/>
                    <AdminHeaderLink path="/admin/organizations" label="Organizations"/>
                    <AdminHeaderLink path="/admin/projects" label="Projects"/>
                    <AdminHeaderLink path="/admin/upgrade-codes" label="Upgrade Codes"/>
                    <AdminHeaderLink path="/admin/demo-datasets" label="Demo Datasets"/>
                    <AdminHeaderLink path="/admin/marketing-events" label="Marketing Events"/>
                </Stack>
            </Paper>
        </Box>
    );
}

export default AdminHeader;