import React, {useEffect, useState} from 'react';
import {IconButton, InputAdornment, TextField} from "@mui/material"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"

interface SecretKeyTextFieldProps {
    label?: string
    helperText?: string
    value: string
    valid: boolean
    setValue: (newValue: string) => void
}

function SecretKeyTextField({ label, helperText, value, setValue,valid }: SecretKeyTextFieldProps) {
    const [showSecretKey, setShowSecretKey] = useState(false)

    useEffect(() => {
        if (value.length === 0) setShowSecretKey(true)
    }, [value])

    const bullet = "•"

    return (
        <TextField label={label ?? "Secret key"}
                   aria-label="secret key"
                   variant="outlined"
                   autoComplete="secret-key"
                   fullWidth
                   error={!valid && value.length > 0}
                   InputProps={{
                       endAdornment: value.length > 0 &&
                           <InputAdornment position="end">
                               <IconButton
                                   onClick={() => setShowSecretKey(!showSecretKey)}
                                   edge="end"
                               >
                                   {showSecretKey ? <VisibilityOff /> : <Visibility />}
                               </IconButton>
                           </InputAdornment>
                   }}
                   type="text"
                   value={showSecretKey ? value : new Array(value.length + 1).join(bullet)}
                   helperText={helperText}
                   onChange={(event) => {
                       if (showSecretKey) setValue(event.target.value)
                   }}
        />
    );
}

export default SecretKeyTextField;