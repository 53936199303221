import React, {ReactNode} from 'react';
import {Box, Fab, Skeleton, Stack} from "@mui/material";
import BreadcrumbsProject from "./BreadcrumbsProject";
import AddIcon from "@mui/icons-material/Add";
import {useProjectData} from "../../hooks/useProjectData";
import PageHeader from "./PageHeader";
import {Breadcrumb} from "./BreadcrumbsBase"

interface ProjectHeaderProps {
    showFab?: boolean
    replaceFab?: ReactNode
    pageTitle: string
    nextToTitle?: ReactNode
    overline?: string
    breadcrumbs?: Breadcrumb[]
    children?: ReactNode | string
    onFabClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

function ProjectPageHeader({pageTitle, nextToTitle, overline, breadcrumbs, children, showFab, onFabClick, replaceFab}: ProjectHeaderProps) {
    const {project} = useProjectData()

    if (!project) {
        return (
            <>
                <Stack direction="row" spacing={2}>
                    <Skeleton width={80}/>
                    <Skeleton width={80}/>
                    <Skeleton width={80}/>
                </Stack>
                <Box sx={{mt: 4}}>
                    <Skeleton height={60} width={220}/>
                </Box>
            </>
        )
    }

    const fab = (
        <Fab color="primary" aria-label="add" onClick={onFabClick}>
            <AddIcon/>
        </Fab>
    )

    return (
        <>
            <BreadcrumbsProject projectName={project.name} breadcrumbs={breadcrumbs ?? [pageTitle]}/>

            <PageHeader pageTitle={pageTitle}
                        nextToTitle={nextToTitle}
                        overline={overline}
                        fab={replaceFab || (showFab && fab)}
                        sx={{mt: 3}}
            >
                {children}
            </PageHeader>
        </>
    )
}


export default ProjectPageHeader;