import {AnyFieldJson} from "./FieldJson"
import {AnyTemplateJson} from "./TemplateJson"
import {ModelPlatform} from "../enum/ModelPlatform"
import {AnyFinetuneJson} from "./FinetuneJson"
import EvalJson from "./EvalJson"

export type AnyModelJson = TemplatedModelJson | AnyFinetuneJson

export interface TemplatedModelJson {
    id: string
    name: string
    platform: ModelPlatform
    template: AnyTemplateJson
    platformId: string | null
    eval: EvalJson
    created: string
    accessCode: string | null
    projectFields: AnyFieldJson[] | null
}

export const isFinetune = (model: AnyModelJson): model is AnyFinetuneJson => {
    return "completed" in model
}