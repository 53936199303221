import React, {useEffect, useState} from 'react';
import ApiError from "../../api/ApiError"
import {UserJson} from "../../api/json/UserJson"
import {
    Avatar,
    CircularProgress,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import {AdminRequests} from "../../api/requests/AdminRequests"
import {TimeUtil} from "../../library/TimeUtil"
import {Link as RouterLink} from "react-router-dom"
import SearchInput from "../form/SearchInput"
import ErrorMessage from "../error/ErrorMessage"

function AdminUsersTable() {
    const [users, setUsers] = useState<(UserJson)[]>()
    const [total, setTotal] = useState<number>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    useEffect(() => {
        if (loading) return

        setLoading(true)
        reload()
    }, [])

    const reload = (searchValue?: string) => {
        AdminRequests.listUsers(searchValue)
            .then((response) => {
                setError(undefined)
                setUsers(response.content)
                setTotal(response.totalElements)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }

    if (error)
        return <ErrorMessage error={error}/>

    if (loading)
        return <CircularProgress/>

    return (
        <>
            <SearchInput onThrottledChange={(searchValue) => reload(searchValue)}
                         sx={{minWidth: 320}} />
            <br/><br/>

            {total !== undefined && <Typography variant="caption" gutterBottom>{total} users</Typography>}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Account</TableCell>
                        <TableCell>Joined</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users?.map(user =>
                        <TableRow key={user.id}>
                            <TableCell width={40}>
                                <Link component={RouterLink} to={`/admin/users/${user.id}`}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar src={user.avatar?.sizes.thumbnail}/>
                                    <Stack>
                                        <Typography variant="body1">{user.fullName}</Typography>
                                        <Typography fontWeight={500}>{user.email}</Typography>
                                    </Stack>
                                </Stack>
                        </Link>
                            </TableCell>
                            <TableCell>
                                {TimeUtil.ago(user.created)}
                            </TableCell>
                        </TableRow>,
                    )}
                </TableBody>
            </Table>
        </>
    );
}

export default AdminUsersTable;