import React, {useRef, useState} from 'react';
import {FieldRequests} from "../../api/requests/FieldRequests";
import {Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {ProjectJson} from "../../api/json/ProjectJson";
import {AnyFieldJson} from "../../api/json/FieldJson";
import {allFieldTypes, FieldType, resolveNameForFieldType} from "../../api/enum/FieldType";
import AsyncButton from "../button/AsyncButton";
import FieldPredefinedOptionsEditor from "./FieldPredefinedOptionsEditor";
import {PredefinedOptionJson} from "../../api/json/PredefinedOptionJson";
import ConfirmDeleteModal from "../modal/ConfirmDeleteModal";
import {useToasts} from "../../hooks/useToasts"
import ApiError from "../../api/ApiError"
import ErrorMessage from "../error/ErrorMessage"
import FieldNameAndReferenceInput from "./FieldNameAndReferenceInput"
import useEnterShortcut from "../../hooks/useEnterShortcut"

interface FieldFormProps {
    project: ProjectJson
    field?: AnyFieldJson
    didCreate: () => void
    didSave: (finished: boolean) => void
    didDelete: () => void
}

function FieldForm({project, field, didCreate, didSave, didDelete}: FieldFormProps) {
    const [name, setName] = useState<string>(field?.name ?? "")
    const [type, setType] = useState<FieldType>(field?.type ?? FieldType.TEXT)
    const [reference, setReference] = useState<string>(field?.reference ?? "")
    const [helperText, setHelperText] = useState<string>(field?.helperText ?? "")

    const startingOptions = field?.type === FieldType.PREDEFINED_OPTIONS ? field?.predefinedOptions?.sort((a, b) => a.value.localeCompare(b.value)) ?? [] : []
    const [predefinedOptions, setPredefinedOptions] = useState<PredefinedOptionJson[]>(startingOptions)
    const [numberOfDecimalPlaces, setNumberOfDecimalPlaces] = useState(field?.type === FieldType.NUMBER ? field?.numberOfDecimalPlaces : 0)

    const [saveInProgress, setSaveInProgress] = useState(false)
    const [error, setError] = useState<ApiError>()

    const [confirmingDelete, setConfirmingDelete] = useState(false)
    const [deleteError, setDeleteError] = useState<ApiError>()

    const toasts = useToasts()

    const ref = useRef<HTMLDivElement>(null)
    useEnterShortcut(ref, () => handleSave())

    const disableSave = name.length === 0
    const handleSave = () => {
        if (disableSave) return
        setSaveInProgress(true)
        field ? handleUpdate(field, undefined, true) : handleCreate()
    }

    const handleCreate = () => {
        FieldRequests.create(project.id, name, reference, type, helperText, predefinedOptions, numberOfDecimalPlaces)
            .then(didCreate)
            .catch(setError)
            .finally(() => setSaveInProgress(false))
    }

    const handleUpdate = (field: AnyFieldJson, newestPredefinedOptions?: PredefinedOptionJson[], finished: boolean = false) => {
        FieldRequests.update(project.id, field.id!, name, reference, undefined, helperText, newestPredefinedOptions ?? predefinedOptions, numberOfDecimalPlaces)
            .then(updatedField => {
                didSave(finished)
                if (updatedField.type === FieldType.PREDEFINED_OPTIONS && updatedField.predefinedOptions) {
                    setPredefinedOptions(updatedField.predefinedOptions)
                }
                toasts.showSaved()
            })
            .catch(setError)
            .finally(() => setSaveInProgress(false))
    }

    const handleDelete = (field: AnyFieldJson) => {
        FieldRequests.delete(project.id, field.id!)
            .then(() => {
                setConfirmingDelete(false)
                didDelete()
            })
            .catch(error => setDeleteError(error))
    }

    const validateNumberOfDecimalPlaces = (strValue: string) => {
        let numberValue = parseInt(strValue)
        if (isNaN(numberValue)) numberValue = 0
        numberValue = Math.max(Math.min(numberValue, 8), 0)
        return numberValue
    }

    return (
        <Stack spacing={2} ref={ref}>
            {!field &&
                <>
                    <FormControl>
                        <InputLabel id="field-type-select-label">Field Type</InputLabel>
                        <Select
                            labelId="field-type-select-label"
                            value={type}
                            label="Field Type"
                            onChange={(event) => setType(event.target.value as FieldType)}
                        >
                            {allFieldTypes.map(value => (
                                <MenuItem key={value} value={value}>
                                    {resolveNameForFieldType(value)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            }

            <FieldNameAndReferenceInput project={project}
                                        field={field}
                                        name={name}
                                        reference={reference}
                                        setName={setName}
                                        setReference={setReference}
            />

            <FormControl>
                <TextField
                    type="text"
                    label="Helper Text"
                    variant="outlined"
                    value={helperText}
                    onChange={(e) => setHelperText(e.target.value.substring(0, 255))}
                />
                <FormHelperText>Instructions that appear with the field.</FormHelperText>
            </FormControl>
            {type === FieldType.PREDEFINED_OPTIONS &&
                <FieldPredefinedOptionsEditor
                    project={project}
                    fieldId={field?.id}
                    options={predefinedOptions}
                    updateOptions={(newOptions, saveChanges) => {
                        setPredefinedOptions(newOptions)
                        if (field && saveChanges) handleUpdate(field, newOptions, false)
                    }}
                />
            }
            {type === FieldType.NUMBER &&
                <TextField
                    type="number"
                    label="Number of Decimal Places"
                    variant="outlined"
                    value={numberOfDecimalPlaces}
                    onChange={(e) => setNumberOfDecimalPlaces(validateNumberOfDecimalPlaces(e.target.value))}/>
            }
            <Stack direction="row" justifyContent={field ? "space-between" : "flex-end"}>
                {field &&
                    <>
                        {confirmingDelete &&
                            <ConfirmDeleteModal
                                heading={`Delete ${field.name} field`}
                                body="All associated field data will be permanently deleted from every example."
                                open={confirmingDelete}
                                setOpen={setConfirmingDelete}
                                confirmedDelete={() => handleDelete(field)}
                                error={deleteError}
                            />
                        }
                        <Button variant="text" color="error" onClick={() => setConfirmingDelete(true)}>Delete</Button>
                    </>

                }
                <AsyncButton
                    waiting={saveInProgress}
                    disabled={disableSave}
                    onClick={handleSave}
                >
                    Save & close
                </AsyncButton>
            </Stack>

            {error && <ErrorMessage error={error}/>}
        </Stack>
    );
}

export default FieldForm;