import React, {ReactNode} from 'react';
import {AnyActivityJson} from "../../../api/json/AnyActivityJson";
import {Avatar, Box, Stack, Typography} from "@mui/material";
import {TimeUtil} from "../../../library/TimeUtil";

function ActivityCardBase({activity, children, showUserEmail}: { activity: AnyActivityJson, children: ReactNode, showUserEmail?: boolean }) {
    return (
        <Stack direction="row" alignItems="center" spacing={1.5} key={activity.id}>
            <Avatar src={activity.user.avatar?.sizes.thumbnail} />
            <Box>
                <Stack direction="row" spacing={1}>
                    <Typography fontSize={12} color="text.secondary">
                        {TimeUtil.ago(activity.happened)}
                    </Typography>
                    {showUserEmail && <Typography fontSize={12} color="text.secondary">{activity.user.email}</Typography>}
                </Stack>
                {children}
            </Box>
        </Stack>
    )
}

export default ActivityCardBase;