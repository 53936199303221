import React, {Suspense} from 'react'
import {CssBaseline} from "@mui/material"
import AppRouter from "./AppRouter"
import {BrowserRouter} from "react-router-dom"
import DataProviders from "./DataProviders"

function App() {
    return (
        <Suspense>
            <CssBaseline/>
            <DataProviders>
                <AppRouter/>
            </DataProviders>
        </Suspense>
    )
}

export default App
