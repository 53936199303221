import React from 'react';
import {FinetuneReplicateJson} from "../../api/json/FinetuneJson"
import GridItem from "../models/GridItem"
import {Box, Button, Grid} from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

function FinetuneDetailGridItemsReplicate({finetune}: { finetune: FinetuneReplicateJson }) {
    return (
        <>
            {finetune.platformId &&
                <GridItem label="Replicate ID" value={finetune.platformId} codeValue/>
            }
            <GridItem label="Base model" value={`${finetune.baseModelOwner}/${finetune.baseModelName}`} fullWidth/>
            <GridItem label="Base model version" value={finetune.baseModelVersion} fullWidth/>
            <GridItem label="Trained model" value={`${finetune.modelOwner}/${finetune.modelName}`} fullWidth/>
            {finetune.modelVersion &&
                <GridItem label="Trained model version"
                          value={finetune.modelVersion}
                          fullWidth/>
            }

            <Grid item xs={12}>
                <Box sx={{mt: 1}}>
                    {finetune.platformId &&
                        <Button href={`https://replicate.com/p/${finetune.platformId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                endIcon={<OpenInNewIcon/>}>
                            Training
                        </Button>
                    }
                    {finetune.completed &&
                        <Button href={`https://replicate.com/${finetune.modelOwner}/${finetune.modelName}/versions/${finetune.modelVersion}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                endIcon={<OpenInNewIcon/>}
                                sx={{ml:1}}>
                            Run
                        </Button>
                    }
                </Box>
            </Grid>
        </>
    );
}

export default FinetuneDetailGridItemsReplicate;