import React, {useState} from 'react';
import {Button, Stack, TextField, Typography} from "@mui/material"
import ApiError from "../../api/ApiError"
import AsyncButton from "../button/AsyncButton"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import InfoAlert from "../alert/InfoAlert"
import ErrorMessage from "../error/ErrorMessage"
import {AnyModelJson, TemplatedModelJson} from "../../api/json/TemplatedModelJson"
import {TemplatedModelRequests} from "../../api/requests/TemplatedModelRequests"

interface FinetuneSharingAccessSectionProps {
    model: TemplatedModelJson
    updateModel: (model: AnyModelJson) => void
}

function TemplatedModelShareSettings({model, updateModel}: FinetuneSharingAccessSectionProps) {
    const [togglingAccess, setTogglingAccess] = useState(false)
    const [copied, setCopied] = useState(false)
    const [error, setError] = useState<ApiError>()

    const updateSharingAccess = (allow: boolean) => {
        setTogglingAccess(true)
        TemplatedModelRequests.update(model.id, null, allow)
            .then(model => {
                setError(undefined)
                setCopied(false)
                updateModel(model)
            })
            .catch(setError)
            .finally(() => setTogglingAccess(false))
    }

    const sharingLink = model.accessCode ? `${window.location.origin}/share/${model.id}?accessCode=${model.accessCode}` : null

    return (
        <Stack spacing={2} alignItems="flex-start">
            <Stack direction="row" alignItems="center" spacing={2}>
                <IosShareOutlinedIcon />
                <Typography variant="h2">Share</Typography>
                {sharingLink &&
                    <AsyncButton variant="text"
                                 color="error"
                                 waiting={togglingAccess}
                                 onClick={() => updateSharingAccess(false)}>Disable</AsyncButton>
                }
            </Stack>

            {sharingLink
                ? (
                    <>
                        <Stack direction="row" spacing={2} alignItems="center" sx={{width: 1}}>
                            <TextField label="URL"
                                       value={sharingLink}
                                       InputProps={{
                                           readOnly: true,
                                       }}
                                       fullWidth
                                       size="small"
                                       sx={{maxWidth: 480}}
                                       onClick={(event) => {
                                           (event.target as HTMLInputElement).select()
                                       }}
                            />
                            <Button endIcon={copied ? <CheckCircleOutlineIcon/> : <ContentCopyIcon/>}
                                    onClick={() => {
                                        navigator.clipboard.writeText(sharingLink)
                                            .then(() => setCopied(true))
                                    }}>
                                {copied ? "Copied" : "Copy"}
                            </Button>
                            <Button href={sharingLink}
                                    target="_blank"
                                    endIcon={<OpenInNewIcon/>}>
                                Open
                            </Button>
                        </Stack>
                        <InfoAlert>Anyone with this link can incur usage costs—only share it with trusted individuals.</InfoAlert>
                    </>
                )
                : (
                    <>
                        <Typography paragraph>Create a link to share this model with others.</Typography>
                        <AsyncButton variant="outlined"
                                     waiting={togglingAccess}
                                     onClick={() => updateSharingAccess(true)}>Enable</AsyncButton>
                    </>
                )
            }

            {error && <ErrorMessage error={error}/>}
        </Stack>
    );
}

export default TemplatedModelShareSettings;