import React from 'react';
import {CompletionRequestJson} from "../../api/json/CompletionRequestJson"
import {Alert, Box, Card, CardContent, Grid, Stack} from "@mui/material"
import TemplateRenderer from "../templates/TemplateRenderer"
import CompletionRequestHead from "./CompletionRequestHead"
import CompletionCardStack from "./CompletionCardStack"

function CompletionRequestSection({request}: { request: CompletionRequestJson }) {
    return (
        <Stack spacing={0.75}>
            <CompletionRequestHead request={request}/>
            <Box>
                <Grid container columnSpacing={1}>
                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <TemplateRenderer templateParts={request.model.template} dataSource={request.inputs} section={"prompt"}/>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {request.succeeded &&
                            <CompletionCardStack request={request}/>
                        }
                        {request.failed &&
                            <Alert severity="error">{request.failedReason}</Alert>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    )
}

export default CompletionRequestSection;