import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {CompletionOptionsJson} from "../json/CompletionOptionsJson"
import {AnyModelSpecJson} from "../json/ModelSpecJson"
import TransformJobJson from "../json/TransformJobJson"
import {TransformJobEstimateJson} from "../json/TransformJobEstimateJson"

export class TransformJobRequests {
    static create(
        projectId: string,
        transformId: string,
        fieldName: string,
        fieldReference: string,
        modelSpec: AnyModelSpecJson,
        completionOptions: CompletionOptionsJson,
    ): Promise<TransformJobJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/transform-jobs`)
            .withJson({
                transformId: transformId,
                destination: {
                    name: fieldName,
                    reference: fieldReference,
                },
                baseModelPlatform: modelSpec.platform,
                baseModelName: modelSpec.name,
                completionOptions: completionOptions,
            })
            .buildAndSend()
    }

    static get(projectId: string, transformJobId: string): Promise<TransformJobJson> {
        return new RequestBuilder(Method.Get, `projects/${projectId}/transform-jobs/${transformJobId}`)
            .buildAndSend()
    }

    static cancel(
        projectId: string,
        transformJobId: string,
    ): Promise<TransformJobJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/transform-jobs/${transformJobId}/cancels`)
            .buildAndSend()
    }

    static estimate(
        projectId: string,
        transformId: string,
        modelSpec: AnyModelSpecJson,
        completionOptions: CompletionOptionsJson,
    ): Promise<TransformJobEstimateJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/transform-jobs/estimates`)
            .withJson({
                transformId: transformId,
                baseModelPlatform: modelSpec.platform,
                baseModelName: modelSpec.name,
                completionOptions: completionOptions,
            })
            .buildAndSend()
    }
}