import React, {useEffect, useState} from 'react';
import {Checkbox, FormControlLabel, Paper, Stack, TextField, Typography} from "@mui/material"
import NumberInput from "../../form/NumberInput"
import SynthesisSettingsJson from "../../../api/json/SynthesisSettingsJson"
import AsyncButton from "../../button/AsyncButton"
import {SynthesisRequests} from "../../../api/requests/SynthesisRequests"
import {useToasts} from "../../../hooks/useToasts"
import SynthesisEstimateJson from "../../../api/json/SynthesisEstimateJson"
import RightDrawer from "../../misc/RightDrawer"
import {AnyTemplateJson} from "../../../api/json/TemplateJson"
import TemplateSelect from "../../templates/TemplateSelect"
import {ProjectJson} from "../../../api/json/ProjectJson"
import ModelSpecSelect from "../../models/ModelSpecSelect"
import {ModelPlatform} from "../../../api/enum/ModelPlatform"
import {useModelSpecs} from "../../../hooks/useModelSpecs"
import ModelFinder from "../../models/ModelFinder"
import {AnyModelSpecJson} from "../../../api/json/ModelSpecJson"

interface SynthesisSettingsFormProps {
    project: ProjectJson
    settings: SynthesisSettingsJson
    open: boolean
    close: () => void
    didSave: (settings: SynthesisSettingsJson) => void
}

function SynthesisSettingsForm({project, settings, open, close, didSave}: SynthesisSettingsFormProps) {
    const {models} = useModelSpecs()
    const currentModel = models?.find( it => it.name === settings.chatModel)

    const [model, setModel] = useState<AnyModelSpecJson | undefined>(currentModel)
    const [template, setTemplate] = useState<AnyTemplateJson | undefined>(project.templates.find(it => it.id === settings.templateId))
    const [alignmentText, setAlignmentText] = useState<string>(settings.alignmentText)
    const [numberOfExamples, setNumberOfExamples] = useState<number>(settings.numberOfExamples)
    const [autoSave, setAutoSave] = useState<boolean>(settings.autoSave)

    const [batchSize, setBatchSize] = useState<number>(settings.batchSize)
    const [validationRatio, setValidationRatio] = useState<number>(settings.validationRatio)

    const [saveInProgress, setSaveInProgress] = useState<boolean>(false)

    const [estimates, setEstimates] = useState<SynthesisEstimateJson>()

    const toasts = useToasts()

    useEffect(() => estimate(), [batchSize, numberOfExamples, model])

    const estimate = () => {
        if(!template || !model) return

        SynthesisRequests.estimate(project.id, {
            chatModel: model.name,
            templateId: template.id,
            alignmentText: alignmentText,
            numberOfExamples: numberOfExamples,
            batchSize: batchSize,
            validationRatio: validationRatio,
            autoSave: autoSave,
        })
            .then(setEstimates)
            .catch(error => {
                toasts.showError(error)
                setEstimates(undefined)
            })
    }

    const handleSave = () => {
        if (!template || !model) return

        setSaveInProgress(true)
        SynthesisRequests.updateSettings(project.id, {
            chatModel: model.name,
            templateId: template.id,
            alignmentText: alignmentText,
            numberOfExamples: numberOfExamples,
            batchSize: batchSize,
            validationRatio: validationRatio,
            autoSave: autoSave,
        })
            .then(didSave)
            .catch(toasts.showError)
            .finally(() => {
                setSaveInProgress(false)
                close()
            })
    }

    return (
        <RightDrawer
            open={open}
            onClose={close}
        >
            <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h2" gutterBottom>Synthesis Settings</Typography>
                </Stack>

                <ModelFinder instructModelsOnly
                             value={model}
                             onChange={modelSpec => setModel(modelSpec)}/>

                <TemplateSelect templates={project.templates} value={template} onChange={setTemplate} />

                <TextField label="Alignment text"
                           helperText={"Supplement the synthesis prompt with your own instructions, such as \"Try to mention cats where possible.\""}
                           value={alignmentText}
                           multiline
                           onChange={e => setAlignmentText(e.target.value)}
                           onBlur={() => estimate()}
                ></TextField>

                <NumberInput label="Total # of examples to create" value={numberOfExamples} min={1} max={1000}
                             onBlur={setNumberOfExamples}/>
                <NumberInput label="Batch size"
                             helperText="How many to synthesize at a time—higher number takes longer per request but can save tokens."
                             value={batchSize} min={1} max={10} onBlur={setBatchSize}/>
                <NumberInput
                    label="Validation examples"
                    value={Math.round(validationRatio * 100)}
                    min={0}
                    max={100}
                    onBlur={(newValue) => setValidationRatio(Math.round(newValue) / 100)}
                    endAdornment={<>%</>}
                    helperText="Percentage of examples to save in the validation example dataset."
                />

                <FormControlLabel control={
                    <Checkbox
                        checked={autoSave}
                        onChange={(_, checked) => setAutoSave(checked)}
                    />
                } label="Automatically save examples"/>

                {estimates && (
                    <Stack component={Paper} variant="outlined" spacing={0.5} textAlign="right" sx={{p: 2}}>
                        <Typography variant="subtitle2">Estimate</Typography>
                        <Typography variant="body2">Tokens: {estimates.tokens.toLocaleString("en-US")}</Typography>
                        <Typography variant="body2">Cost: ${estimates.cost}</Typography>
                    </Stack>
                )}

                <Stack direction="row" justifyContent="flex-end">
                    <AsyncButton
                        disabled={!model}
                        waiting={saveInProgress}
                        onClick={() => handleSave()}>Save & close</AsyncButton>
                </Stack>
            </Stack>
        </RightDrawer>
    );
}

export default SynthesisSettingsForm;