import PlanJson from "../api/json/PlanJson"
import {PlanTier} from "../api/enum/PlanTier"

export class PlanFactory {
    static ALL: PlanJson[] = [
        {
            tier: PlanTier.STARTER,
            monthlyPrice: 49,
            userLimit: 3,
            exampleLimit: 5000,
        },
        {
            tier: PlanTier.GROWTH,
            monthlyPrice: 99,
            userLimit: 5,
            exampleLimit: 25000,
        },
        {
            tier: PlanTier.PRO,
            monthlyPrice: 249,
            userLimit: 10,
            exampleLimit: 100000,
        },
    ]
}