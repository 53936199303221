import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom"
import ApiError from "../../api/ApiError"
import {TransformRequests} from "../../api/requests/TransformRequests"
import TransformJson, {AnyTransformJson} from "../../api/json/TransformJson"
import ProjectPageHeader from "../layouts/ProjectPageHeader"
import {Alert, Box, Chip, CircularProgress, Grid, IconButton, Paper, Stack, Typography} from "@mui/material"
import {Breadcrumb} from "../layouts/BreadcrumbsBase"
import AsyncButton from "../button/AsyncButton"
import FieldReferenceTextField from "../templates/FieldReferenceTextField"
import {useProjectData} from "../../hooks/useProjectData"
import RandomExampleLoader from "../examples/RandomExampleLoader"
import ExampleJson from "../../api/json/ExampleJson"
import TemplateUtil from "../../library/TemplateUtil"
import ErrorMessage from "../error/ErrorMessage"
import {CompletionOptionsJson, DEFAULT_COMPLETION_OPTIONS} from "../../api/json/CompletionOptionsJson"
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import {AnyModelSpecJson} from "../../api/json/ModelSpecJson"
import {CompletionResponseJson} from "../../api/json/CompletionResponseJson"
import CompletionOptionsFormModal from "../completions/CompletionOptionsFormModal"
import TransformActionsMenu from "./TransformActionsMenu"
import TransformJobSection from "./TransformJobSection"
import {useToasts} from "../../hooks/useToasts"
import animations from "../../Animations.module.scss"
import TemperatureChip from "../completions/TemperatureChip"
import SpaceBetween from "../common/SpaceBetween"

function TransformDetailPage() {
    const {project} = useProjectData()
    const {projectId, transformId} = useParams()

    const [savedTransform, setSavedTransform] = useState<TransformJson>()
    const [editingTransform, setEditingTransform] = useState<AnyTransformJson>(
        {
            name: "Untitled",
            systemPrompt: "",
            userPrompt: "",
        },
    )
    const [example, setExample] = useState<ExampleJson>()
    const [activeSection, setActiveSection] = useState<"system" | "user" | null>(null)

    const [completionSettingsOpen, setCompletionSettingsOpen] = useState(false)

    const [model, setModel] = useState<AnyModelSpecJson>()
    const [completionOptions, setCompletionOptions] = useState<CompletionOptionsJson>({
        ...DEFAULT_COMPLETION_OPTIONS,
        numberOfCompletions: 1,
    })

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    const [saving, setSaving] = useState(false)
    const [saveError, setSaveError] = useState<ApiError>()

    const [generating, setGenerating] = useState(false)
    const [completionResponse, setCompletionResponse] = useState<CompletionResponseJson>()
    const [completionError, setCompletionError] = useState<ApiError>()

    const navigate = useNavigate()
    const toasts = useToasts()

    useEffect(() => {
        if (!projectId || !transformId) return

        setLoading(true)
        TransformRequests.get(projectId, transformId)
            .then(prompt => {
                setSavedTransform(prompt)
                setEditingTransform(prompt)
                setError(undefined)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [projectId, transformId])

    const save = () => {
        if (!projectId) return

        setSaving(true)
        if (savedTransform) {
            TransformRequests.update(projectId, savedTransform.id, editingTransform.name, editingTransform.systemPrompt, editingTransform.userPrompt)
                .then(saved => {
                    setSaveError(undefined)
                    setSavedTransform(saved)
                    setEditingTransform(saved)
                })
                .catch(setSaveError)
                .finally(() => setSaving(false))
        } else {
            TransformRequests.create(projectId, editingTransform.systemPrompt, editingTransform.userPrompt)
                .then(created => {
                    setSavedTransform(created)
                    setEditingTransform(created)
                    window.history.pushState({}, "", `/projects/${projectId}/transforms/${created.id}`)
                })
                .catch(setSaveError)
                .finally(() => setSaving(false))
        }
    }

    const generate = () => {
        if (!model || !projectId || !savedTransform || !completionOptions) return

        setGenerating(true)
        const inputs: Record<string, string> = {}
        example?.fieldValues.forEach(fieldValue => {
            inputs[fieldValue.field.reference] = fieldValue.value
        })
        TransformRequests.complete(projectId, savedTransform.id, model.platform, model.name, inputs, completionOptions)
            .then((response) => {
                setCompletionResponse(response)
                setCompletionError(undefined)
            })
            .catch(setCompletionError)
            .finally(() => setGenerating(false))
    }

    if (error) {
        return <ErrorMessage error={error}/>
    }

    if (loading || !editingTransform || !project) {
        return <CircularProgress/>
    }

    let breadcrumbs: Breadcrumb[] = [
        {
            label: "Transforms",
            url: `/projects/${projectId}/transforms`,
        },
        editingTransform.name,
    ]

    const usedReferences = TemplateUtil.extractReferencesFromString(editingTransform.systemPrompt + editingTransform.userPrompt)
    const unreferencedFields = project.fields.filter(f => usedReferences.indexOf(f.reference) === -1)

    const unsavedChanges = savedTransform?.systemPrompt !== editingTransform.systemPrompt || savedTransform?.userPrompt !== editingTransform.userPrompt

    return (
        <>
            <ProjectPageHeader pageTitle={editingTransform.name}
                               breadcrumbs={breadcrumbs}
                               nextToTitle={
                                   "id" in editingTransform &&
                                   <TransformActionsMenu project={project}
                                                         transform={editingTransform}
                                                         didDelete={() => navigate(`/projects/${projectId}/transforms`)}
                                                         didCopy={copy => {
                                                             if (!unsavedChanges) {
                                                                 navigate(`/projects/${projectId}/transforms/${copy.id}`)
                                                             } else {
                                                                 toasts.showMessage("A copy has been added to the project.")
                                                             }
                                                         }}
                                                         didRename={(transform) => {
                                                             setSavedTransform(transform)
                                                             let updated = {...editingTransform}
                                                             updated.name = transform.name
                                                             setEditingTransform(updated)
                                                         }}/>
                               }
            />

            <Typography variant="h2" gutterBottom>Prompt</Typography>

            <Grid container spacing={2} className={animations.defaultIn}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>System</Typography>
                    <FieldReferenceTextField value={editingTransform.systemPrompt}
                                             unreferencedFields={unreferencedFields}
                                             setValue={(newValue) => {
                                                 const updated = {...editingTransform}
                                                 updated.systemPrompt = newValue
                                                 setEditingTransform(updated)
                                             }}
                                             showInsertButtons={activeSection === "system"}
                                             didFocus={() => setActiveSection("system")}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>User</Typography>
                    <FieldReferenceTextField value={editingTransform.userPrompt}
                                             unreferencedFields={unreferencedFields}
                                             setValue={(newValue) => {
                                                 const updated = {...editingTransform}
                                                 updated.userPrompt = newValue
                                                 setEditingTransform(updated)
                                             }}
                                             showInsertButtons={activeSection === "user"}
                                             didFocus={() => setActiveSection("user")}
                    />
                    {unsavedChanges && "id" in editingTransform && editingTransform?.hasActiveJob &&
                        <Alert severity="warning">Making changes to this prompt while there is an active transform job
                            will affect all remaining outputs for the job.</Alert>
                    }
                </Grid>
            </Grid>

            <AsyncButton variant="contained"
                         waiting={saving}
                         onClick={save}
                         disabled={!unsavedChanges}
                         sx={{mt: 2}}
                         onMouseDown={e => e.preventDefault()}
            >
                Save
            </AsyncButton>
            {saveError && <ErrorMessage error={saveError}/>}

            {"id" in editingTransform &&
                <Grid container sx={{mt: 4}} spacing={4}>
                    <Grid item xs={12} lg={6}>
                        <RandomExampleLoader project={project} example={example} handleExample={setExample}>
                            {example && savedTransform &&
                                <Stack spacing={2}>
                                    <Typography variant="body1" sx={{whiteSpace: "pre-wrap"}}>
                                        {TemplateUtil.renderAny(savedTransform.systemPrompt, example.fieldValues, project.fields)}
                                    </Typography>
                                    <Typography variant="body1" sx={{whiteSpace: "pre-wrap"}}>
                                        {TemplateUtil.renderAny(savedTransform.userPrompt, example.fieldValues, project.fields)}
                                    </Typography>
                                </Stack>
                            }
                        </RandomExampleLoader>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Stack direction="column" spacing={2}>
                            <SpaceBetween>
                                <Typography variant="h2">Test Transform</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AsyncButton variant="contained"
                                                 endIcon={<BoltOutlinedIcon/>}
                                                 disabled={!model || unsavedChanges || !completionOptions}
                                                 waiting={generating}
                                                 tooltip={unsavedChanges ? "Save changes first." : (!model ? "Select a model" : undefined)}
                                                 onClick={() => generate()}
                                    >
                                        Generate
                                    </AsyncButton>
                                    <IconButton onClick={() => setCompletionSettingsOpen(true)}>
                                        <SettingsOutlinedIcon/>
                                    </IconButton>
                                </Stack>
                            </SpaceBetween>

                            <ErrorMessage error={completionError}/>

                            {completionResponse &&
                                <>
                                    <Stack direction="row" spacing={1}>
                                        <Chip variant="outlined" label={completionResponse.modelApiId}/>
                                        <TemperatureChip value={completionResponse.options.temperature}/>
                                    </Stack>
                                    <Stack spacing={0.5} sx={{mt: 1}}>
                                        {completionResponse.results.map((result, index) => (
                                            <Paper key={index} variant="outlined" sx={{p: 2, whiteSpace: "pre-wrap"}}>
                                                {result}
                                            </Paper>
                                        ))}
                                    </Stack>
                                </>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            }

            {"id" in editingTransform &&
                <TransformJobSection project={project} transform={editingTransform} unsavedChanges={unsavedChanges}
                                     sx={{mt: 6}}/>
            }

            <CompletionOptionsFormModal open={completionSettingsOpen}
                                        setOpen={setCompletionSettingsOpen}
                                        model={model}
                                        options={completionOptions}
                                        updateModel={setModel}
                                        updateCompletionOptions={setCompletionOptions}
            />
        </>

    );
}

export default TransformDetailPage;