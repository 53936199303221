import React from 'react';
import {Chip} from "@mui/material";
import {ProjectJson} from "../../api/json/ProjectJson";
import {AnyFieldJson} from "../../api/json/FieldJson";
import {resolveNameForFieldType} from "../../api/enum/FieldType";
import ModalBase from "../modal/ModalBase"
import FieldForm from "./FieldForm"

interface FieldModalProps {
    project: ProjectJson
    field?: AnyFieldJson
    open: boolean
    close: () => void
}

function FieldModal({project, field, open, close}: FieldModalProps) {

    return (
        <ModalBase
            open={open}
            setOpen={() => close()}
            title={`${field ? "Edit" : "New"} Field`}
            topRightButton={field && <Chip label={resolveNameForFieldType(field.type)}/>}
        >
            <FieldForm
                project={project}
                field={field}
                didCreate={() => close()}
                didSave={finished => { if (finished) close() }}
                didDelete={() => close()}
            />
        </ModalBase>
    );
}

export default FieldModal;