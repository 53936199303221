import React from 'react'
import {Outlet} from "react-router-dom"
import {Box, Container} from "@mui/material"
import styles from "./Layout.module.scss"
import BrandMark from "./Nav/BrandMark";

function CenteredBoxLayout() {
    return (
        <Container maxWidth="sm" className={styles.loginSignUpForm}>
            <Outlet/>
        </Container>
    )
}

export default CenteredBoxLayout