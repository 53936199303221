import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    CircularProgress,
    Fab,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {UserRequests} from "../../api/requests/UserRequests";
import {useNavigate, useSearchParams} from "react-router-dom";
import {OrganizationRequests} from "../../api/requests/OrganizationRequests";
import {OrganizationWithRoleJson} from "../../api/json/OrganizationWithRoleJson";
import {useAuth0} from "@auth0/auth0-react"
import {useToasts} from "../../hooks/useToasts"
import AsyncButton from "../button/AsyncButton"
import {useApi} from "../../hooks/useApi"
import ApiError from "../../api/ApiError"
import useEnterShortcut from "../../hooks/useEnterShortcut"
import Logo from "./Logo"
import SpaceBetween from "../common/SpaceBetween"
import AddIcon from "@mui/icons-material/Add"
import ModalBase from "../modal/ModalBase"

function SelectOrganizationPage() {
    const {currentUser, setCurrentUser, setToken, setCurrentOrganization} = useApi()

    const createModalRef = useRef<HTMLDivElement>(null)

    const [createOrgModalOpen, setCreateOrgModalOpen] = useState(false)
    const [createInProgress, setCreateInProgress] = useState(false)
    const [createError, setCreateError] = useState<ApiError>()
    const [name, setName] = useState("")

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const toasts = useToasts()
    const {isAuthenticated, getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        if (!isAuthenticated) return

        getAccessTokenSilently().then(token => {
            setToken(token)
            fetchCurrentUser()
        })
    }, [isAuthenticated])

    useEffect(() => fetchCurrentUser(), [])

    const fetchCurrentUser = () => {
        UserRequests.getCurrentUser()
            .then(setCurrentUser)
            .catch(toasts.showError)
    }

    const selectOrganization = (organization: OrganizationWithRoleJson) => {
        setCurrentOrganization({...organization, role: organization.role})
        navigate(searchParams.get("intent") ?? "/")
    }

    const handleCreate = () => {
        if (createInProgress) return

        setCreateInProgress(true)
        OrganizationRequests.create(name)
            .then(organization => {
                setCreateOrgModalOpen(false)
                selectOrganization(organization)
            })
            .catch(setCreateError)
            .finally(() => setCreateInProgress(false))
    }

    useEnterShortcut(createModalRef, () => {
        if (createOrgModalOpen && name.length > 0) handleCreate()
    })

    if (!currentUser) {
        return <CircularProgress/>
    }

    return (
        <>
            <Stack spacing={2}>
                <SpaceBetween>
                <Typography variant="h1">Select Organization</Typography>
                    <Fab color="primary" aria-label="add" onClick={() => setCreateOrgModalOpen(true)}>
                        <AddIcon/>
                    </Fab>
                </SpaceBetween>
                <List>
                    {currentUser.organizations.map((org, index) =>
                        <ListItemButton key={org.id} divider={index !== currentUser?.organizations.length - 1} onClick={() => selectOrganization(org)}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Logo image={org.logo} />
                                <ListItemText primary={org.name} />
                            </Stack>
                        </ListItemButton>
                    )}
                </List>
            </Stack>

            <ModalBase
                open={createOrgModalOpen}
                setOpen={open => setCreateOrgModalOpen(open)}
                title="Create Organization"
                innerRef={createModalRef}
                buttons={
                <>
                    <Button variant="outlined"
                            color="secondary"
                            onClick={() => setCreateOrgModalOpen(false)}>
                        Cancel
                    </Button>
                    <AsyncButton waiting={createInProgress}
                                 disabled={name.length === 0}
                                 onClick={handleCreate}>Create</AsyncButton>
                </>
                }
                error={createError}
            >
                    <TextField label="Name"
                               variant="outlined"
                               fullWidth
                               onChange={(e) => setName(e.target.value)}/>
            </ModalBase>
        </>
    )
}

export default SelectOrganizationPage;