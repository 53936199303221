import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Box, CircularProgress, Stack, Typography} from "@mui/material";
import {VerificationRequests} from "../../api/requests/VerificationRequests";
import {VerificationJson} from "../../api/json/VerificationJson";
import AsyncButton from "../button/AsyncButton";
import {useApi} from "../../hooks/useApi"
import ApiError from "../../api/ApiError"
import ErrorMessage from "../error/ErrorMessage"

function DeleteOrganizationPage() {
    const [error, setError] = useState<ApiError>()
    const [loading, setLoading] = useState(false)
    const [verification, setVerification] = useState<VerificationJson>()
    const [deleting, setDeleting] = useState(false)
    const [deleted, setDeleted] = useState(false)

    const {code} = useParams()
    const navigate = useNavigate()
    const {currentUser, clearCurrentOrganization} = useApi()

    useEffect(() => {
        if (!code) return

        setLoading(true)
        VerificationRequests.get(code)
            .then(setVerification)
            .catch(setError)
            .finally(() => setLoading(false))
    }, [code])

    const handleDelete = () => {
        if (!code || deleting) return

        setDeleting(true)
        VerificationRequests.verify(code)
            .then(() => {
                clearCurrentOrganization()
                setDeleting(false)
                setDeleted(true)
                setTimeout(() => {
                    if (currentUser) {
                        navigate("/organizations/select")
                    } else {
                        navigate("/")
                    }
                }, 3000)
            })
            .catch(setError)
    };


    if (loading) {
        return <CircularProgress />
    }

    if (error) {
        return <ErrorMessage error={error}/>
    }

    if (!verification) {
        return <Alert severity="error">Verification not found</Alert>
    }

    if (deleted) {
        return <>Organization deleted successfully. Please wait.</>
    }

    if (deleting) {
        return <Stack direction="row" alignItems="center">
            <Box>Deleting...</Box>
            <CircularProgress/>
        </Stack>
    }

    return (
        <>
            <Typography variant="h1" gutterBottom>Delete {verification.organization?.name}</Typography>
            <Typography paragraph>Are you sure you want to permanently delete the {verification.organization?.name} organization? This will take effect immediately, erase all associated data, and cannot be reversed.</Typography>
            <AsyncButton variant="contained" color="error" waiting={deleting} onClick={handleDelete}>Delete forever</AsyncButton>
        </>
    )
}

export default DeleteOrganizationPage