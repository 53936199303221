import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import {PagedTemplateJson} from "../json/PagedResponseJson";
import {AnyTemplateJson, AnyTemplateParts} from "../json/TemplateJson"
import {TemplateType} from "../enum/TemplateType"

export class TemplateRequests {
    static create(projectId: string, name: string | null, parts: AnyTemplateParts | null): Promise<AnyTemplateJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/templates`)
            .withJson({
                name: name,
                parts: parts,
            })
            .buildAndSend()
    }

    static copy(projectId: string, templateId: string): Promise<AnyTemplateJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/templates/${templateId}/copies`)
            .buildAndSend()
    }

    static get(projectId: string, templateId: string): Promise<AnyTemplateJson> {
        return new RequestBuilder(Method.Get, `projects/${projectId}/templates/${templateId}`)
            .buildAndSend()
    }

    static list(projectId: string): Promise<PagedTemplateJson> {
        return new RequestBuilder(Method.Get, `projects/${projectId}/templates`)
            .buildAndSend()
    }

    static update(projectId: string, templateId: string, name: string | null, parts: AnyTemplateParts | null): Promise<AnyTemplateJson> {
        return new RequestBuilder(Method.Put, `projects/${projectId}/templates/${templateId}`)
            .withJson({
                name: name,
                parts: parts,
            })
            .buildAndSend()
    }

    static delete(projectId: string, templateId: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `projects/${projectId}/templates/${templateId}`)
            .buildAndSend()
    }
}