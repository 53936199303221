import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import RequestSender from "../RequestSender"
import SynthesisSettingsJson from "../json/SynthesisSettingsJson"
import SynthesisEstimateJson from "../json/SynthesisEstimateJson"
import SynthesisResultJson from "../json/SynthesisResultJson"

export class SynthesisRequests {

    // MARK: - Synthesis

    static synthesize(id: string, settings?: SynthesisSettingsJson): Promise<SynthesisResultJson[]> {
        const request = new RequestBuilder(Method.Post, `projects/${id}/synthesis`)
            .withJson({ settings: settings })
            .build()
        return RequestSender.sendRequest(request)
    }

    static estimate(id: string, settings?: SynthesisSettingsJson): Promise<SynthesisEstimateJson> {
        const request = new RequestBuilder(Method.Post, `projects/${id}/synthesis/estimates`)
            .withJson({ settings: settings })
            .build()
        return RequestSender.sendRequest(request)
    }

    // MARK: - Results

    static getResults(projectId: string): Promise<SynthesisResultJson[]> {
        const request = new RequestBuilder(Method.Get, `projects/${projectId}/synthesis/results/current`)
            .build()
        return RequestSender.sendRequest(request)
    }

    static clearResults(projectId: string): Promise<void> {
        const request = new RequestBuilder(Method.Post, `projects/${projectId}/synthesis/results/cleared`)
            .build()
        return RequestSender.sendRequest(request)
    }

    // MARK: - Settings

    static getSettings(projectId: string): Promise<SynthesisSettingsJson> {
        const request = new RequestBuilder(Method.Get, `projects/${projectId}/synthesis/settings`)
            .build()
        return RequestSender.sendRequest(request)
    }

    static updateSettings(
        projectId: string,
        settings: SynthesisSettingsJson,
    ): Promise<SynthesisSettingsJson> {
        const request = new RequestBuilder(Method.Put, `projects/${projectId}/synthesis/settings`)
            .withJson({ ...settings })
            .build()
        return RequestSender.sendRequest(request)
    }
}