import React, {useState} from 'react';
import {
    Button,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    SxProps,
    Typography,
} from "@mui/material"
import {EditOutlined, RefreshOutlined} from "@mui/icons-material"
import ExampleEditorModal from "../examples/ExampleEditorModal"
import {TestRunJson} from "../../api/json/TestRunJson"
import {useProjectData} from "../../hooks/useProjectData"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {TestRunRequests} from "../../api/requests/TestRunRequests"
import EvalJson from "../../api/json/EvalJson"
import {useToasts} from "../../hooks/useToasts"

interface TestRunActionMenuProps {
    evalJson: EvalJson
    testRun: TestRunJson
    didUpdate: (testRun: TestRunJson) => void
    didDelete: (exampleId: number) => void
    sx?: SxProps
}

function TestRunActionMenu({evalJson, testRun, didUpdate, didDelete, sx}: TestRunActionMenuProps) {
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>()

    const [running, setRunning] = useState(false)
    const [exampleEditorOpen, setExampleEditorOpen] = useState(false)

    const {project} = useProjectData()
    const toasts = useToasts()

    const handleRerun = () => {
        setRunning(true)
        TestRunRequests.run(evalJson.id, testRun.example.id)
            .then(testRun => {
                didUpdate(testRun)
            })
            .catch(toasts.showError)
            .finally(() => {
                setRunning(false)
                setMenuAnchor(undefined)
            })
    }

    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                size="small"
                endIcon={<ArrowDropDownIcon/>}
                onClick={(event) => setMenuAnchor(event.currentTarget)}
                sx={sx}>
                Actions
            </Button>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            setExampleEditorOpen(true)
                        }}>
                            <ListItemIcon>
                                <EditOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Edit Example</Typography>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            disabled={running}
                            onClick={() => {
                                handleRerun()
                            }}>
                            <ListItemIcon>
                                {running ? <CircularProgress size={20} color="secondary"/> : <RefreshOutlined/>}
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Re-run</Typography>
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>

            {project &&
                <ExampleEditorModal
                    open={exampleEditorOpen}
                    onClose={() => setExampleEditorOpen(false)}
                    project={project}
                    example={testRun.example}
                    didUpdate={example => {
                        const updated: TestRunJson = {...testRun}
                        updated.example = example
                        didUpdate(updated)
                    }}
                    didDelete={() => didDelete(testRun.example.id)}
                />
            }
        </>
    );
}

export default TestRunActionMenu;