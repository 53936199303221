import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {UpgradeCodeJson} from "../json/UpgradeCodeJson"
import {UpgradeCodePeriod} from "../enum/UpgradeCodePeriod"
import {UpgradeCodeRedemptionJson} from "../json/UpgradeCodeRedemptionJson"

export class UpgradeCodeRequests {
    static list(): Promise<UpgradeCodeJson[]> {
        return new RequestBuilder(Method.Get, `upgrade-codes`)
            .withoutOrganization()
            .buildAndSend()
    }

    static create(name: string, numberOfUsers: number, numberOfExamples: number, upgradePeriod: UpgradeCodePeriod, upgradeDuration: number, maxRedemptions: number): Promise<UpgradeCodeJson> {
        return new RequestBuilder(Method.Post, `upgrade-codes`)
            .withoutOrganization()
            .withJson({
                name: name,
                numberOfUsers: numberOfUsers,
                numberOfExamples: numberOfExamples,
                upgradePeriod: upgradePeriod,
                upgradeDuration: upgradeDuration,
                maxRedemptions: maxRedemptions,
            })
            .buildAndSend()
    }

    static redeem(code: string): Promise<UpgradeCodeRedemptionJson> {
        return new RequestBuilder(Method.Post, `upgrade-codes/${code}/redemptions`).buildAndSend()
    }

    static delete(id: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `upgrade-codes/${id}`).buildAndSend()
    }
}