import React, {ReactNode} from 'react';
import {Box} from "@mui/material"

function InlineCode({children}: { children: ReactNode }) {
    return (
        <Box component="span"
             sx={{
                 backgroundColor: "#f2f2f2",
                 padding: "2px",
                 fontFamily: "\"Courier\", serif",
             }}>
            {children}
        </Box>
    );
}

export default InlineCode;