import React, {useCallback, useEffect, useState} from 'react'
import {Box, Fab, Modal, Paper, Stack, Table, TableBody, TableContainer, Tooltip, Typography} from "@mui/material"
import modalStyles from "../../modal/Modal.module.scss"
import {InviteJson} from "../../../api/json/InviteJson";
import SendInviteForm from "./SendInviteForm";
import {InviteRequests} from "../../../api/requests/InviteRequests";
import MembershipJson from "../../../api/json/MembershipJson";
import {UserRequests} from "../../../api/requests/UserRequests";
import {RoleCheck} from "../../../library/RoleCheck";
import PageHeader from "../../layouts/PageHeader";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MembersPageMembershipRow from "./MembersPageMembershipRow";
import MembersPageInviteRow from "./MembersPageInviteRow";
import {useApi} from "../../../hooks/useApi"
import ApiError from "../../../api/ApiError"
import BreadcrumbsOrganization from "../../layouts/BreadcrumbsOrganization"
import ErrorMessage from "../../error/ErrorMessage"

type Person = MembershipJson | InviteJson

function MembersPage() {
    const [invitesError, setInvitesError] = useState<ApiError>()
    const [membersError, setMembersError] = useState<ApiError>()
    const [invites, setInvites] = useState<InviteJson[]>([])
    const [memberships, setMemberships] = useState<MembershipJson[]>([])
    const [modalOpen, setModalOpen] = useState(false)

    const loadMembers = useCallback(() => {
        UserRequests.list()
            .then(page => setMemberships(page.content))
            .catch(setMembersError)
    }, [])

    const loadInvites = useCallback(() => {
        InviteRequests.list()
            .then(page => setInvites(page.content))
            .catch(setInvitesError)
    }, [])

    useEffect(() => {
        loadMembers()
        loadInvites()
    }, [loadMembers, loadInvites])

    const {currentOrganization} = useApi()

    const disableInvites = currentOrganization?.usageLimits?.numberOfUsers ? (memberships.length + invites.length) >= currentOrganization.usageLimits.numberOfUsers : false
    const fab = (
        RoleCheck.isAdmin()
            ? (
                <Tooltip title={disableInvites ? "Upgrade to a paid plan to invite more users." : undefined}>
                    <span>
                        <Fab color="primary" aria-label="add" disabled={disableInvites} onClick={() => setModalOpen(true)}>
                            <PersonAddIcon/>
                        </Fab>
                    </span>
                </Tooltip>
            )
            : undefined
    )

    const people: Person[] = [...memberships, ...invites]

    if (!RoleCheck.isAdmin()) {
        return <>This page is for admins only.</>
    }

    return (
        <>
            <PageHeader pageTitle="Members" fab={fab}/>

            {membersError && <ErrorMessage error={membersError}/>}
            {invitesError && <ErrorMessage error={invitesError}/>}
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {people.map(person => {
                            if ("user" in person) {
                                return <MembersPageMembershipRow key={person.user.id} membership={person} lastUser={memberships.length === 1} didUpdate={loadMembers} didDelete={loadMembers} />
                            } else {
                                return <MembersPageInviteRow key={person.id} invite={person} didDelete={loadInvites} />
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Box className={modalStyles.default}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h2">Invite</Typography>
                    </Stack>
                    <Box sx={{mt: 2}}>
                        <SendInviteForm handleSent={() => {
                            setModalOpen(false)
                            loadInvites()
                        }
                        }/>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default MembersPage