import React from 'react'
import {Autocomplete, FormControl, InputLabel, MenuItem, Select, SxProps, TextField, Theme} from "@mui/material"
import {PredefinedOptionJson} from "../../api/json/PredefinedOptionJson";

interface PredefinedOptionSelectProps {
    label?: string
    value?: string
    choices: PredefinedOptionJson[]
    size?: "small" | "medium"
    onChange: (value: string) => void
    sx?: SxProps<Theme>
}

function PredefinedOptionSelect({ label, value, choices, size, sx, onChange }: PredefinedOptionSelectProps) {
    const nonEmptyChoices = choices.filter(choice => choice.value !== "")
    const allowEmpty = nonEmptyChoices.length < choices.length

    const defaultValue = choices.length === 0 ? "" : choices[0].value
    const defaultLabel = "Predefined Option"

    nonEmptyChoices.sort((a, b) => a.value.localeCompare(b.value))
    if (nonEmptyChoices.length > 15) {
        const options = nonEmptyChoices.map(choice => { return { label: choice.value }})
        return (
            <Autocomplete
                disablePortal
                disableClearable={!allowEmpty}
                value={{label: value ?? defaultValue}}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                onChange={(event, value) => onChange(value?.label ?? "")}
                renderInput={(params) => <TextField {...params} label={label} />}
                options={options}
            />
        )
    }

    return (
        <FormControl sx={sx}>
            <InputLabel id="classification-select-label">{label ?? defaultLabel}</InputLabel>
            <Select
                labelId="classification-select-label"
                value={value ?? defaultValue}
                size={size}
                label={label ?? defaultLabel}
                onChange={(event) => onChange(event.target.value)}
            >
                {allowEmpty && <MenuItem value={""}></MenuItem>}
                {nonEmptyChoices.map(c => (
                    <MenuItem key={c.id} value={c.value}>{c.value}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default PredefinedOptionSelect