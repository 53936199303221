import React, {useState} from 'react';
import {Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, Typography} from "@mui/material";
import modalStyles from "./Modal.module.scss";
import AsyncButton from "../button/AsyncButton";
import ApiError from "../../api/ApiError"
import {ProjectRequests} from "../../api/requests/ProjectRequests"
import {downloadArrayBuffer} from "../../library/DownloadHelper"
import {FileDownloadOutlined} from "@mui/icons-material"
import {ProjectJson} from "../../api/json/ProjectJson"
import {allTemplateSyntax, resolveNameForSyntax, TemplateSyntax} from "../../api/enum/TemplateSyntax"
import {JsonlStructure, resolveNameForJsonlStructure} from "../../api/enum/JsonlStructure"
import TemplateSelect from "../templates/TemplateSelect"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import ErrorMessage from "../error/ErrorMessage"

interface ExportModalProps {
    project: ProjectJson
    open: boolean
    setOpen: (open: boolean) => void
}

function ExportModal({project, open, setOpen}: ExportModalProps) {
    const [format, setFormat] = useState<"csv" | "jsonl">("csv")
    const [syntax, setSyntax] = useState<TemplateSyntax | "">("")
    const [jsonlStructure, setJsonlStructure] = useState<JsonlStructure | "">("")
    const [exportInProgress, setExportInProgress] = useState(false)
    const [template, setTemplate] = useState<AnyTemplateJson>()
    const [error, setError] = useState<ApiError>()
    const handleExportJsonl = () => {
        if (exportInProgress || !template) return

        setExportInProgress(true)
        ProjectRequests.exportJsonl(project.id, template.id,syntax !== "" ? syntax : null, jsonlStructure !== "" ? jsonlStructure : null)
            .then(response => {
                downloadArrayBuffer(response, "dataset.jsonl")
                setOpen(false)
            })
            .catch(setError)
            .finally(() => setExportInProgress(false))
    }

    const handleExportCsv = () => {
        if (exportInProgress) return

        setExportInProgress(true)
        ProjectRequests.exportCsv(project.id)
            .then(response => {
                downloadArrayBuffer(response, "dataset.csv")
                setOpen(false)
            })
            .catch(setError)
            .finally(() => setExportInProgress(false))
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Stack spacing={3} className={modalStyles.default}>
                <Typography variant="h2">Export Examples</Typography>

                <FormControl fullWidth>
                    <InputLabel id="plan-label">Format</InputLabel>
                    <Select
                        labelId="format-label"
                        value={format}
                        label="Format"
                        onChange={(e) => setFormat(e.target.value as "csv" | "jsonl")}
                    >
                        <MenuItem value="csv">CSV</MenuItem>
                        <MenuItem value="jsonl">JSONL</MenuItem>
                    </Select>
                </FormControl>

                {format === "jsonl" &&
                    <>
                        <TemplateSelect templates={project.templates} value={template} onChange={setTemplate} />

                        <FormControl fullWidth>
                            <InputLabel id="plan-label">JSONL Structure</InputLabel>
                            <Select
                                labelId="format-label"
                                value={jsonlStructure}
                                label="JSONL Structure"
                                onChange={(e) => setJsonlStructure(e.target.value as any)}
                            >
                                <MenuItem value={JsonlStructure.TEXT}>{resolveNameForJsonlStructure(JsonlStructure.TEXT)}</MenuItem>
                                <MenuItem value={JsonlStructure.PROMPT_COMPLETION}>{resolveNameForJsonlStructure(JsonlStructure.PROMPT_COMPLETION)}</MenuItem>
                                <MenuItem value={JsonlStructure.OPEN_AI_CHAT_MESSAGES}>{resolveNameForJsonlStructure(JsonlStructure.OPEN_AI_CHAT_MESSAGES)}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="plan-label">Syntax</InputLabel>
                            <Select
                                labelId="format-label"
                                value={syntax}
                                label="Syntax"
                                onChange={(e) => setSyntax(e.target.value as any)}
                            >
                                <MenuItem value="">None</MenuItem>
                                {allTemplateSyntax.map(syntax => (
                                        <MenuItem value={syntax}>{resolveNameForSyntax(syntax)}</MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    </>
                }

                <Stack direction="row" alignSelf="flex-end" justifyContent="flex-end" spacing={2}>
                    <Button variant="outlined"
                            color="secondary"
                            disabled={exportInProgress}
                            onClick={() => setOpen(false)}
                            sx={{flex: 1}}>
                        Cancel
                    </Button>

                    <AsyncButton
                        id="export-button"
                        waiting={exportInProgress}
                        disabled={format === "jsonl" ? !template : false}
                        color="primary"
                        startIcon={<FileDownloadOutlined/>}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={() => {
                            if (format === "csv") handleExportCsv()
                            else if (format === "jsonl") handleExportJsonl()
                        }}
                    >
                        Export
                    </AsyncButton>
                </Stack>
                {error && <ErrorMessage error={error}/>}
            </Stack>
        </Modal>
    )
}

export default ExportModal;