import React, {useState} from 'react';
import {
    Card,
    Chip, Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Stack,
    Typography,
} from "@mui/material"
import SpaceBetween from "../../common/SpaceBetween"
import ApiError from "../../../api/ApiError"
import ErrorMessage from "../../error/ErrorMessage"
import ModalBase from "../../modal/ModalBase"
import ConfirmDeleteModal from "../../modal/ConfirmDeleteModal"
import {MarketingEventJson} from "../../../api/json/MarketingEventJson"
import {MarketingEventRequests} from "../../../api/requests/MarketingEventRequests"
import MarketingEventForm from "./MarketingEventForm"
import {AddToCalendarButton} from "add-to-calendar-button-react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {DeleteOutline, EditOutlined, DownloadOutlined} from "@mui/icons-material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import {MarketingEventRegistrationRequests} from "../../../api/requests/MarketingEventRegistrationRequests"
import {downloadArrayBuffer} from "../../../library/DownloadHelper"

interface MarketingEventCardProps {
    marketingEvent: MarketingEventJson
    didCreate?: (created: MarketingEventJson) => void
    didUpdate: (updated: MarketingEventJson) => void
    didDelete: (deleteId: string) => void
    didCopy: (copied: MarketingEventJson) => void
}

function MarketingEventCard({marketingEvent, didCreate, didUpdate, didCopy, didDelete}: MarketingEventCardProps) {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement>()

    const [editOpen, setEditOpen] = useState(false)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

    const [deleting, setDeleting] = useState(false)
    const [deleteError, setDeleteError] = useState<ApiError>()

    const [copying, setCopying] = useState(false)
    const [copyError, setCopyError] = useState<ApiError>()

    const [exportingCsv, setExportingCsv] = useState(false)
    const [exportCsvError, setExportCsvError] = useState<ApiError>()

    const handleDelete = () => {
        setDeleting(true)
        MarketingEventRequests.delete(marketingEvent.id)
            .then(() => {
                setDeleteError(undefined)
                setConfirmDeleteOpen(false)
                didDelete(marketingEvent.id)
            })
            .catch(setDeleteError)
            .finally(() => setDeleting(false))
    }

    const handleCopy = () => {
        setCopying(true)
        MarketingEventRequests.copy(marketingEvent.id)
            .then(copied => {
                setCopyError(undefined)
                didCopy(copied)
            })
            .catch(setCopyError)
            .finally(() => setCopying(false))
    }

    const handleExportCsv = () => {
        setExportingCsv(true)
        MarketingEventRegistrationRequests.exportCsv(marketingEvent.id)
            .then(response => {
                downloadArrayBuffer(response, `${marketingEvent.name} - ${marketingEvent.numberOfRegistrations} Registrations.csv`)

                setExportCsvError(undefined)
                setMenuAnchor(undefined)
            })
            .catch(setExportCsvError)
            .finally(() => setExportingCsv(false))
    }

    const startDateTime = new Date(marketingEvent.start);
    const endDateTime = new Date(marketingEvent.start);
    endDateTime.setMinutes(startDateTime.getMinutes() + marketingEvent.duration);

    const lastDay = new Date(startDateTime)
    lastDay.setDate(lastDay.getDate() + marketingEvent.occurrences - 1);

    const formattedStartDate = Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
    }).format(startDateTime);

    const formattedLastDay = Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
    }).format(lastDay);

    const year = Intl.DateTimeFormat("en-US", {year: "numeric"}).format(startDateTime)

    function formatTime(date: Date) {
        const formatter = new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: date.getMinutes() !== 0 ? 'numeric' : undefined,
            hour12: true,
        });

        return formatter.format(date);
    }

    function getDateString(date: Date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        // Construct the formatted date string using template literals
        return `${year}-${month}-${day}`;
    }

    function getTimeString(date: Date) {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        // Construct the formatted time string using template literals
        return `${hours}:${minutes}:${seconds}`;
    }

    return (
        <Card variant="outlined" sx={{p: 2, overflow: "visible"}}>
            <SpaceBetween>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Chip color={marketingEvent.published ? "primary" : "info"}
                          label={marketingEvent.published ? "Published" : "Draft"}/>
                    {marketingEvent.numberOfRegistrations > 0 &&
                        <Chip color="secondary" variant="outlined"
                              label={`${marketingEvent.numberOfRegistrations} registrations`}/>
                    }
                </Stack>
                <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                    <MoreVertIcon/>
                </IconButton>
            </SpaceBetween>

            <Typography variant="h3" gutterBottom sx={{mt: 1}}>{marketingEvent.name}</Typography>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList>
                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            setEditOpen(true)
                        }}>
                            <ListItemIcon>
                                <EditOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Edit</Typography>
                            </ListItemText>
                        </MenuItem>

                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            handleCopy()
                        }}>
                            <ListItemIcon>
                                <ContentCopyIcon sx={{width: 22}}/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Make a copy</Typography>
                            </ListItemText>
                        </MenuItem>

                        <MenuItem disabled={exportingCsv} onClick={() => {
                            handleExportCsv()
                        }}>
                            <ListItemIcon>
                                <DownloadOutlined sx={{width: 22}}/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Get Registrations</Typography>
                            </ListItemText>
                        </MenuItem>

                        <Divider/>

                        <MenuItem onClick={() => {
                            setMenuAnchor(undefined)
                            setConfirmDeleteOpen(true)
                        }}>
                            <ListItemIcon>
                                <DeleteOutline color="error"/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography color="error">Delete</Typography>
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>

            <Stack spacing={1}>
                <Typography variant="body1" fontStyle="italic">
                    {marketingEvent.occurrences > 1 ? `${formattedStartDate} – ${formattedLastDay}` : formattedStartDate}, {year}, {formatTime(startDateTime)} – {formatTime(endDateTime)} ({Intl.DateTimeFormat().resolvedOptions().timeZone})
                </Typography>
                <Typography paragraph fontSize="small"
                            sx={{whiteSpace: "pre-wrap"}}>{marketingEvent.description}</Typography>
                <Typography paragraph fontSize="small">Join here: {marketingEvent.meetingUrl}</Typography>

                <AddToCalendarButton name={marketingEvent.name}
                                     description={marketingEvent.calendarDescription}
                                     startDate={getDateString(startDateTime)}
                                     startTime={getTimeString(startDateTime)}
                                     endDate={getDateString(endDateTime)}
                                     endTime={getTimeString(endDateTime)}
                                     timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                     organizer="Entry Point AI|events@entrypointai.com"
                                     recurrence="daily"
                                     recurrence_interval={1}
                                     recurrence_count={marketingEvent.occurrences}
                                     size="4"
                                     options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
                                     hideBranding
                />
                <ErrorMessage error={deleteError}/>
                <ErrorMessage error={copyError}/>
            </Stack>

            <ConfirmDeleteModal heading={`Delete ${marketingEvent.name} event`}
                                body="Are you sure you want to delete the marketing event?"
                                open={confirmDeleteOpen}
                                setOpen={setConfirmDeleteOpen}
                                confirmedDelete={() => handleDelete()}
            />

            <ModalBase title="Edit Marketing Event" size="large" open={editOpen} setOpen={setEditOpen}>
                <MarketingEventForm existing={marketingEvent}
                                    didUpdate={(updated) => {
                                        setEditOpen(false)
                                        didUpdate(updated)
                                    }}
                />
            </ModalBase>
        </Card>
    );
}

export default MarketingEventCard;