import React from 'react';
import {FinetuneOpenAiJson, resolveOpenAiPlaygroundUrl} from "../../api/json/FinetuneJson"
import {Box, Button, Typography} from "@mui/material"
import GridItem from "../models/GridItem"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

function FinetuneDetailGridItemsOpenAi({finetune}: { finetune: FinetuneOpenAiJson }) {
    return (
        <>
            {finetune.model &&
                <GridItem label="Fine-tuned model" value={finetune.model} fullWidth codeValue/>
            }
            {finetune.platformId &&
                <GridItem label="OpenAI ID"
                          value={finetune.platformId}
                          fullWidth
                          extra={
                                            <Box sx={{mt:1}}>
                                                <Button href={`https://platform.openai.com/finetune/${finetune.platformId}`} target="_blank" rel="noreferrer noopener" endIcon={<OpenInNewIcon/>}>Job</Button>
                                                {finetune.completed && <Button href={resolveOpenAiPlaygroundUrl(finetune)} endIcon={<OpenInNewIcon/>} target="_blank" rel="noreferrer noopener" sx={{ml:1}}>Playground</Button>}
                                            </Box>
                                        }/>
            }
        </>
    );
}

export default FinetuneDetailGridItemsOpenAi;