import {AnyFinetuneJson} from "./FinetuneJson";
import {ModelPlatform} from "../enum/ModelPlatform"
import React from "react"

export interface FinetuneStatus {
    label: string
    time: string
    muiColor: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
    failedReason?: string | null
    percentProgress?: number
}

export function resolveFinetuneStatus(finetune: AnyFinetuneJson): FinetuneStatus {
    if (finetune.completed) {
        return {label: "Complete", time: finetune.completed, muiColor: "success"}
    } else if (finetune.failed) {
        return {label: "Failed", time: finetune.failed, muiColor: "error", failedReason: finetune.failedReason}
    } else if (finetune.started) {
        return {label: "Started", time: finetune.created, muiColor: "secondary"}
    } else {
        return {label: "Preparing", time: finetune.created, muiColor: "secondary"}
    }
}