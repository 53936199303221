import React from 'react';
import {Alert, AlertTitle, Typography} from "@mui/material"
import AsyncButton from "../button/AsyncButton"

interface DangerBoxProps {
    buttonLabel: string
    title: string
    description?: string
    onAction: () => void
    actionPending: boolean
}

function DangerActionBox({title, description, buttonLabel, onAction, actionPending}: DangerBoxProps) {
    return (
        <Alert icon={false} severity="error" sx={{ width: "100%" }} action={
            <AsyncButton waiting={actionPending} color="error"
                         onClick={onAction}
                         sx={{alignSelf: "center", mr: 2}}>
                {buttonLabel}
            </AsyncButton>
        }>
            <AlertTitle>{title}</AlertTitle>
            {description && <Typography>{description}</Typography>}
        </Alert>
    );
}

export default DangerActionBox;