import React from 'react';
import {TestRunJson} from "../../api/json/TestRunJson"
import {Alert, Box, Grid, Paper, Stack, Tooltip, Typography} from "@mui/material"
import TemplateRenderer from "../templates/TemplateRenderer"
import TemplateRendererSection from "../templates/TemplateRendererSection"
import {DisplayFormat} from "../../api/enum/DisplayFormat"
import {useToasts} from "../../hooks/useToasts"
import {WarningAmberOutlined} from "@mui/icons-material"
import {TestRunRequests} from "../../api/requests/TestRunRequests"
import EvalJson from "../../api/json/EvalJson"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import ScoreInput from "./ScoreInput"
import TestRunActionMenu from "./TestRunActionMenu"
import SpaceBetween from "../common/SpaceBetween"
import {TimeUtil} from "../../library/TimeUtil"

interface TestRunCardProps {
    evalJson: EvalJson
    template: AnyTemplateJson
    testRun: TestRunJson
    didUpdate: (testRun: TestRunJson) => void
    didDelete: (exampleId: number) => void
    limitMaxHeight?: boolean
}

function TestRunCard({evalJson, template, testRun, didUpdate, didDelete, limitMaxHeight}: TestRunCardProps) {
    let maxHeightSx = limitMaxHeight ? {overflowY: "auto", maxHeight: "36rem"} : {}

    const toasts = useToasts()

    const updateScore = (testRun: TestRunJson, newScore: number | null) => {
        TestRunRequests.updateScore(evalJson.id, testRun.example.id, newScore ? (newScore - 1) * 25 : 0)
            .then(updated => {
                didUpdate(updated)
            })
            .catch(toasts.showError)
    }

    return (
        <Stack spacing={0.5}>
            <SpaceBetween>
                <Typography variant="caption">{TimeUtil.ago(testRun.created)}</Typography>
                <TestRunActionMenu evalJson={evalJson}
                                   testRun={testRun}
                                   didUpdate={didUpdate}
                                   didDelete={didDelete}
                                   sx={{alignSelf: "flex-end"}}
                />
            </SpaceBetween>

            <Stack direction="row" spacing={0.5} alignItems="flex-end">
                <Paper variant="outlined" sx={{p: 1.5, flex: 1}}>
                    <Typography variant="subtitle1">Prompt</Typography>
                    <TemplateRenderer dataSource={testRun.example.fieldValues}
                                      templateParts={template}
                                      staticSystemPromptTreatment="hide"
                                      section="prompt"
                                      sx={maxHeightSx}
                    />
                </Paper>
            </Stack>

            <Stack direction="row" spacing={0.5}>
                <Paper variant="outlined" sx={{p: 1.5, flex: 1}}>
                    <Typography variant="subtitle1">Output</Typography>

                    {testRun.failed &&
                        <Alert color="error">{testRun.failedReason}</Alert>
                    }

                    {testRun.output !== null &&
                        <Box sx={maxHeightSx}>
                            <TemplateRendererSection value={testRun.output}
                                                     display={DisplayFormat.PLAIN_TEXT}/>
                        </Box>
                    }

                    {testRun.output !== null &&
                        <Stack direction="row" spacing={2} sx={{pt: 1}}>
                            <ScoreInput method={evalJson.scoringMethod}
                                        testRun={testRun}
                                        updateScore={updateScore}
                            />

                            {testRun.reason &&
                                <Typography variant="caption">{testRun.reason}</Typography>
                            }
                        </Stack>
                    }
                </Paper>

                <Paper variant="outlined" sx={{p: 2, flex: 1}}>
                    <Stack direction="row" spacing={1}>
                        <Typography variant="subtitle1">Example</Typography>
                        {testRun.exampleIssue &&
                            <Tooltip title="There may be an issue with this example.">
                                <WarningAmberOutlined color="warning"/>
                            </Tooltip>
                        }
                    </Stack>

                    <TemplateRenderer dataSource={testRun.example.fieldValues}
                                      templateParts={template}
                                      section="completion"
                                      sx={maxHeightSx}
                    />
                </Paper>
            </Stack>

        </Stack>
    );
}

export default TestRunCard;