import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {PagedTransformJson} from "../json/PagedResponseJson"
import TransformJson from "../json/TransformJson"
import {ModelPlatform} from "../enum/ModelPlatform"
import {CompletionOptionsJson} from "../json/CompletionOptionsJson"
import {CompletionResponseJson} from "../json/CompletionResponseJson"

export class TransformRequests {
    static create(projectId: string, systemPrompt: string, userPrompt: string): Promise<TransformJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/transforms`)
            .withJson({
                systemPrompt: systemPrompt,
                userPrompt: userPrompt,
            })
            .buildAndSend()
    }

    static copy(projectId: string, transformId: string): Promise<TransformJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/transforms/${transformId}/copies`)
            .buildAndSend()
    }

    static list(projectId: string, page: number = 0): Promise<PagedTransformJson> {
        const queryParams = new URLSearchParams()
        queryParams.append("page", page.toString())

        return new RequestBuilder(Method.Get, `projects/${projectId}/transforms?${queryParams.toString()}`)
            .buildAndSend()
    }

    static get(projectId: string, transformId: string): Promise<TransformJson> {
        return new RequestBuilder(Method.Get, `projects/${projectId}/transforms/${transformId}`)
            .buildAndSend()
    }

    static update(projectId: string, transformId: string, name?: string, systemPrompt?: string, userPrompt?: string): Promise<TransformJson> {
        return new RequestBuilder(Method.Put, `projects/${projectId}/transforms/${transformId}`)
            .withJson({
                name: name,
                systemPrompt: systemPrompt,
                userPrompt: userPrompt,
            })
            .buildAndSend()
    }

    static delete(projectId: string, transformId: string): Promise<void> {
        return new RequestBuilder(Method.Delete, `projects/${projectId}/transforms/${transformId}`)
            .buildAndSend()
    }

    static complete(
        projectId: string,
        transformId: string,
        platform: ModelPlatform,
        baseModelName: string,
        inputs: Record<string, string>,
        options: CompletionOptionsJson,
    ): Promise<CompletionResponseJson> {
        return new RequestBuilder(Method.Post, `projects/${projectId}/transforms/${transformId}/completions`)
            .withJson({
                platform: platform,
                baseModelName: baseModelName,
                inputs: inputs,
                options: options,
            })
            .buildAndSend()
    }
}