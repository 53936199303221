import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {CsvColumnIntentJson} from "../json/CsvColumnIntentJson";
import {CreateImportJobResponseJson, ImportJobJson} from "../json/ImportJobJson"
import {PagedResponseJson} from "../json/PagedResponseJson"
import {ImportJobFileAnalysisJson} from "../json/ImportJobColumnAnalysisJson"

export class ImportJobRequests {

    static create(projectId: string, csv: File): Promise<CreateImportJobResponseJson> {
        const formData = new FormData()
        formData.append("csv", csv)

        return new RequestBuilder(Method.Post, `projects/${projectId}/import-jobs`)
            .withFormData(formData)
            .buildAndSend()
    }

    static list(projectId: string, activeOnly?: boolean): Promise<PagedResponseJson<ImportJobJson>> {
        const queryParams = new URLSearchParams()
        if (activeOnly && activeOnly) queryParams.append("activeOnly", "true")

        return new RequestBuilder(Method.Get, `projects/${projectId}/import-jobs?${queryParams.toString()}`)
            .buildAndSend()
    }

    static get(projectId: string, importJobId: string): Promise<ImportJobJson> {
        return new RequestBuilder(Method.Get, `projects/${projectId}/import-jobs/${importJobId}`)
            .buildAndSend()
    }

    static getFileAnalysis(projectId: string, importJobId: string): Promise<ImportJobFileAnalysisJson> {
        return new RequestBuilder(Method.Get, `projects/${projectId}/import-jobs/${importJobId}/file-analysis`)
            .buildAndSend()
    }

    static finalize(projectId: string, jobId: string, columnIntents: CsvColumnIntentJson[], validationPercent: number): Promise<ImportJobJson> {
        return new RequestBuilder(Method.Put, `projects/${projectId}/import-jobs/${jobId}`)
            .withJson({
                validationPercent: validationPercent,
                columnIntents: columnIntents,
            })
            .buildAndSend()
    }
}