export enum TokenizerAlgorithm {
    GPT = "GPT",
    JURASSIC = "JURASSIC",
    LLAMA = "LLAMA",
    MISTRAL = "MISTRAL",
}

export function resolveNameForTokenizerAlgorithm(algorithm: TokenizerAlgorithm) {
    switch (algorithm) {
        case TokenizerAlgorithm.GPT:
            return "GPT"
        case TokenizerAlgorithm.JURASSIC:
            return "Jurassic"
        case TokenizerAlgorithm.LLAMA:
            return "Llama"
        case TokenizerAlgorithm.MISTRAL:
            return "Mistral"
    }
}

