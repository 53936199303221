import React, {useEffect, useState} from 'react'
import SecretKeyTextField from "../SecretKeyTextField"
import {Ai21Json} from "../../../api/json/IntegrationJson"
import {IntegrationSettingsFormProps} from "./IntegrationSettingsFormProps"
import {ModelPlatform} from "../../../api/enum/ModelPlatform"

interface Ai21SettingsFormProps extends IntegrationSettingsFormProps {
    ai21: Ai21Json | null
}

function Ai21SettingsForm({ai21, onChange}: Ai21SettingsFormProps) {
    const [secretKey, setSecretKey] = useState<string>(ai21?.secretKey ?? "")
    const [isValidSecretKey, setIsValidSecretKey] = useState(true)

    useEffect(() => {
        if (secretKey === undefined) return

        const secretKeyIsValid = secretKey.length >= 32
        setIsValidSecretKey(secretKeyIsValid)

        const unsavedChanges = !ai21 || (secretKey !== "" && secretKey !== ai21.secretKey)

        onChange({
            platform: ModelPlatform.AI21,
            id: ai21?.id ?? null,
            secretKey: secretKey !== "" ? secretKey : null,
        }, secretKeyIsValid, unsavedChanges)
    }, [secretKey])

    return (
        <>
            <SecretKeyTextField value={secretKey}
                                setValue={setSecretKey}
                                valid={isValidSecretKey}/>
        </>
    )
}

export default Ai21SettingsForm