import React, {useEffect, useState} from 'react';
import ApiError from "../../api/ApiError"
import {
    CircularProgress,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import {AdminRequests} from "../../api/requests/AdminRequests"
import {OrganizationAdminJson} from "../../api/json/OrganizationAdminJson"
import styles from "../accounts/Accounts.module.scss"
import {TimeUtil} from "../../library/TimeUtil"
import {Link as RouterLink} from "react-router-dom"
import ErrorMessage from "../error/ErrorMessage"

function AdminOrganizationsTable() {
    const [organizations, setOrganizations] = useState<(OrganizationAdminJson)[]>()
    const [total, setTotal] = useState<number>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    useEffect(() => {
        if (loading) return

        setLoading(true)
        AdminRequests.listOrganizations()
            .then((response) => {
                setError(undefined)
                setOrganizations(response.content)
                setTotal(response.totalElements)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [])

    if (error)
        return <ErrorMessage error={error}/>

    if (loading)
        return <CircularProgress/>

    return (
        <>
            {total && <Typography variant="caption" gutterBottom>{total} organizations</Typography>}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Logo</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Users</TableCell>
                        <TableCell>Projects</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {organizations?.map(org =>
                        <TableRow key={org.id}>
                            <TableCell>
                                {org.logo &&
                                    <img src={org.logo.sizes.thumbnail} className={styles.organizationLogo}/>}
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    <Link component={RouterLink} to={`/admin/organizations/${org.id}`}>{org.name}</Link>
                                </Typography>
                                <Typography variant="caption">Created {TimeUtil.ago(org.created)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Stack>
                                    {org.users.map(user => (
                                        <Link component={RouterLink} to={`/admin/users/${user.id}`} fontWeight={500}>{user.email}</Link>
                                    ))}
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Stack>
                                    {org.projects.map(project => (
                                        <Typography fontSize={14}>{project.name}</Typography>
                                    ))}
                                </Stack>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
}

export default AdminOrganizationsTable;