import React, {useEffect, useState} from 'react';
import {Alert, Box, CircularProgress, Container, Divider, Grid, Stack, Typography} from "@mui/material"
import {useParams, useSearchParams} from "react-router-dom"
import ApiError from "../../api/ApiError"
import InferencePromptForm from "../inference/InferencePromptForm"
import AsyncButton from "../button/AsyncButton"
import CenteredContent from "../layouts/CenteredContent"
import animations from "../../Animations.module.scss"
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import CompletionOptionsForm from "../completions/CompletionOptionsForm"
import {CompletionOptionsJson} from "../../api/json/CompletionOptionsJson"
import SpaceBetween from "../common/SpaceBetween"
import {CompletionRequestJson} from "../../api/json/CompletionRequestJson"
import ErrorMessage from "../error/ErrorMessage"
import TemplateRendererSection from "../templates/TemplateRendererSection"
import {TemplatedModelRequests} from "../../api/requests/TemplatedModelRequests"
import {AnyModelJson} from "../../api/json/TemplatedModelJson"

function TemplatedModelSharePage() {
    const [params] = useSearchParams()
    const accessCode = params.get("accessCode")

    const {modelId} = useParams()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ApiError>()

    const [model, setModel] = useState<AnyModelJson>()
    const [fieldValues, setFieldValues] = useState<Record<string, string>>({})
    const [options, setOptions] = useState<CompletionOptionsJson>()

    const [completionLoading, setCompletionLoading] = useState(false)
    const [completion, setCompletion] = useState<CompletionRequestJson>()
    const [completionError, setCompletionError] = useState<ApiError>()

    useEffect(() => {
        if (!modelId || !accessCode) return

        setLoading(true)
        TemplatedModelRequests.getByAccessCode(modelId, accessCode)
            .then(setModel)
            .catch(setError)
            .finally(() => setLoading(false))
    }, [modelId, params])

    const getCompletion = () => {
        if (!model || !accessCode || !options) return

        setCompletionLoading(true)
        TemplatedModelRequests.completeByAccessCode(model.id, fieldValues, options, accessCode)
            .then(completionRequest => {
                setCompletion(completionRequest)
                setCompletionError(undefined)
            })
            .catch(setCompletionError)
            .finally(() => setCompletionLoading(false))
    }

    if (loading)
        return (
            <CenteredContent>
                <CircularProgress />
            </CenteredContent>
        )

    if (error) {
        return (
            <CenteredContent>
                <ErrorMessage error={error}/>
            </CenteredContent>
        )
    }

    if (!model?.projectFields) {
        return (
            <CenteredContent>
                <Alert severity="error">Fields missing</Alert>
            </CenteredContent>
        )
    }

    if (model) {
        return (
            <Container sx={{ mt: 10 }}>
                <SpaceBetween alignItems="flex-start">
                    <Box>
                        <Typography variant="h1" gutterBottom>{model.name}</Typography>
                        <Typography paragraph>Fill out the form to generate output.</Typography>
                    </Box>
                    <AsyncButton waiting={completionLoading}
                                 disabled={Object.values(fieldValues).length == 0}
                                 onClick={getCompletion}
                                 endIcon={<BoltOutlinedIcon/>}
                    >
                        Generate
                    </AsyncButton>
                </SpaceBetween>

                <Grid container spacing={4} sx={{pt: 2}}>
                    <Grid item md={6} xs={12}>
                        <Stack sx={{position: {md: "sticky"}, top: 30}} spacing={4}>
                            <Stack spacing={2}>
                                <Typography variant="h2">Input</Typography>
                                <InferencePromptForm fields={model.projectFields!}
                                                     template={model.template}
                                                     fieldValues={fieldValues}
                                                     updateFieldValues={setFieldValues}
                                />
                            </Stack>
                            <Stack spacing={2}>
                                <Typography variant="h2">Settings</Typography>
                                <CompletionOptionsForm platform={model.platform} onChange={setOptions} />
                                {completionError && <ErrorMessage error={completionError} sx={{mt:2}}/> }
                            </Stack>
                        </Stack>
                    </Grid>
                    {completion &&
                        <Grid item md={6} xs={12} className={animations.defaultIn} sx={{ overflowY: "auto"}}>
                            <Typography variant="h2" gutterBottom>Output</Typography>
                            <Stack spacing={2}>
                                {completion.results.map((result, index) => (
                                    <>
                                        {/* Could provide a way to render markdown here, maybe it should be a project setting */}
                                        <TemplateRendererSection value={result.text}/>
                                        {completion?.results.length > 0 && index < completion?.results.length - 1 &&
                                            <Divider/>}
                                    </>
                                ))}
                            </Stack>
                        </Grid>
                    }
                </Grid>
            </Container>
        )
    }

    return <></>
}

export default TemplatedModelSharePage;