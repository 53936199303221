import React, {ReactNode} from 'react';
import {ModalOpenProps} from "./ModalOpenProps"
import modalStyles from "./Modal.module.scss"
import {Box, Divider, Modal, Stack, Typography} from "@mui/material"
import SpaceBetween from "../common/SpaceBetween"
import ApiError from "../../api/ApiError"
import ErrorMessage from "../error/ErrorMessage"

interface ModalBaseProps extends ModalOpenProps {
    title?: string
    topRightButton?: ReactNode
    children: ReactNode
    noContentPadding?: boolean
    keepMounted?: boolean
    size?: "small" | "large"
    innerRef?: React.RefObject<HTMLDivElement>
    buttons?: ReactNode
    error?: ApiError
}

function ModalBase({
                       open,
                       setOpen,
                       title,
                       topRightButton,
                       children,
                       noContentPadding,
                       keepMounted,
                       size,
                       innerRef,
                       buttons,
                       error,
                   }: ModalBaseProps) {
    let className = modalStyles.small
    if (size === "large") {
        className = modalStyles.newLarge
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            keepMounted={keepMounted}
        >
            <Box ref={innerRef} className={className} sx={{backgroundColor: "#f7f7f7"}}>
                {(title || topRightButton) &&
                    <>
                        <SpaceBetween sx={{p: 2, backgroundColor: "#fff"}}>
                            <Typography variant="h3" fontSize={18} fontWeight={500}
                                        sx={{color: "#555"}}>{title}</Typography>
                            {topRightButton}
                        </SpaceBetween>
                        <Divider/>
                    </>
                }
                <Box sx={{p: noContentPadding ? 0 : 2}}>
                    {children}
                    {error && <ErrorMessage error={error} sx={{mt:2}}/>}
                </Box>
                {buttons &&
                    <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{pl: 2, pr: 2, pb: 2}}>
                        {buttons}
                    </Stack>
                }
            </Box>
        </Modal>
    );
}

export default ModalBase;