import React from 'react';
import {LinearProgress, Stack, Tooltip, Typography} from "@mui/material"
import EvalJson from "../../api/json/EvalJson"

function EvalProgressBar({evalJson, totalValidationExamples}: {evalJson: EvalJson, totalValidationExamples: number}) {
    return (
        <Stack direction="row" spacing={2} alignItems="center" sx={{mt: 3}}>
            <Typography variant="body1">In progress</Typography>
            <Tooltip
                title={`${evalJson.stats.completedTestRuns} of ${totalValidationExamples} complete`}>
                <LinearProgress variant="determinate"
                                value={(evalJson.stats.completedTestRuns / totalValidationExamples) * 100}
                                sx={{width: "100%", maxWidth: 300}}
                />
            </Tooltip>
        </Stack>
    );
}

export default EvalProgressBar;