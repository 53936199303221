import React, {useEffect, useState} from 'react'
import {Box, CircularProgress} from "@mui/material"
import {useProjectData} from "../../hooks/useProjectData"
import {ExampleSearchParams} from "../../api/requests/ExampleRequests";
import ProjectPageHeader from "../layouts/ProjectPageHeader";
import ExampleFeed from "./ExampleFeed";
import ExampleFeedControls from "./ExampleFeedControls";
import ExampleEditorModal from "./ExampleEditorModal"
import ExamplesPageActionsMenu from "./ExamplesPageActionsMenu"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import {getDefaultTemplate} from "../../library/ProjectUtil"

function ExamplesPage() {
    const [searchParams, setSearchParams] = useState<ExampleSearchParams>()
    const [reloadCounter, setReloadCounter] = useState(0)

    const [createModalOpen, setCreateModalOpen] = useState(false)

    const {id, project, reloadProject} = useProjectData()

    const [template, setTemplate] = useState<AnyTemplateJson | undefined>(getDefaultTemplate(project))

    // Reload the project to make sure we have up-to-date templates and fields
    useEffect(() => reloadProject(), [])

    useEffect(() => {
        if (!project) return

        setTemplate(getDefaultTemplate(project))
    }, [project])

    const headerProps = {
        showFab: project ? project.fields.length > 0 : false,
        pageTitle: "Examples",
        nextToTitle: <ExamplesPageActionsMenu/>,
        onFabClick: () => setCreateModalOpen(true),
    }

    if (!project) {
        return <CircularProgress/>
    }

    if (project.fields.length == 0) {
        return (
            <ProjectPageHeader {...headerProps}>
                Nothing to see here yet — import your data or create fields first.
            </ProjectPageHeader>
        )
    }

    const handleCreate = (finished: boolean) => {
        reloadProject()
        setCreateModalOpen(!finished)
        setReloadCounter(reloadCounter + 1)
    }

    return (
        <>
            <ProjectPageHeader {...headerProps} />
            <ExampleFeedControls project={project}
                                 template={template}
                                 onSearchParamChange={setSearchParams}
                                 onTemplateChange={setTemplate}
            />

            {searchParams &&
                <>
                    <Box sx={{mt: 3}}>
                        <ExampleFeed project={project}
                                     template={template}
                                     searchParams={searchParams}
                                     reload={reloadCounter}
                        />
                    </Box>
                </>
            }

            <ExampleEditorModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                keepMounted
                project={project}
                template={template}
                defaultDataset={searchParams?.dataset}
                didCreate={handleCreate}
                allowSaveAndNext
            />
        </>
    )
}

export default ExamplesPage