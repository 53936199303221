import React, {ReactNode} from 'react';
import {Stack, Typography} from "@mui/material"
import FormContainer from "../layouts/FormContainer"

function DangerZone({children}: {children: ReactNode}) {
    return (
        <FormContainer sx={{mt: 6}}>
            <Typography variant="h2">Danger Zone</Typography>
            {children}
        </FormContainer>
    );
}

export default DangerZone;