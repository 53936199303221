import React, {ReactNode} from 'react';
import {ScoringMethod} from "../../api/enum/ScoringMethod"
import {Box, Rating, SxProps} from "@mui/material"
import DangerousIcon from "@mui/icons-material/Dangerous"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import {TestRunJson} from "../../api/json/TestRunJson"

interface ScoreInputProps {
    method: ScoringMethod
    testRun: TestRunJson
    updateScore: (testRun: TestRunJson, score: number) => void
    sx?: SxProps
}

function ScoreInput({method, testRun, updateScore, sx}: ScoreInputProps) {
    let component: ReactNode = (
        <Rating
            value={testRun.score !== null ? (testRun.score / 25) + 1 : undefined}
            onChange={(event, value) => updateScore(testRun, value!)}
        />
    )

    if (method === ScoringMethod.EXACT_MATCH) {
        component = testRun.score === 0 ? <DangerousIcon color="error"/>
            : <CheckCircleOutlineIcon color="success"/>
    }

    return (
        <Box sx={sx}>
            {component}
        </Box>
    )
}

export default ScoreInput;