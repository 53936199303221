import React from 'react'
import {Outlet} from "react-router-dom"
import {Box, Link, Stack} from "@mui/material"
import styles from "./Layout.module.scss"
import BrandMark from "./Nav/BrandMark";

function GrayBrandedBackground() {
    const linkProps = {
        fontSize: "small",
        color: "text.secondary",
        target: "_blank",
        rel: "noopener noreferrer",
    }
    return (
        <Box className={styles.grayContainer}>
            <Box width={40} sx={{position: "absolute", ml: 2, mt: 2}}>
                <a href="https://www.entrypointai.com" target="_blank" rel="noopener noreferrer">
                    <BrandMark/>
                </a>
            </Box>

            <Outlet/>

            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}
                   sx={{position: "absolute", bottom: "30px", right: "45px"}}>
                <Link
                    href="https://app.termly.io/document/terms-and-conditions/00d854df-283b-498d-a71f-353e06f4d227" {...linkProps}>
                    Terms of Use
                </Link>
                <Link
                    href="https://app.termly.io/document/privacy-policy/cdeae627-2863-40d3-a2b0-715d359efb1a" {...linkProps}>
                    Privacy Policy
                </Link>
            </Stack>
        </Box>
    )
}

export default GrayBrandedBackground