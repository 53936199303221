import React from 'react';
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined"
import {Chip} from "@mui/material"

function TemperatureChip({value}: {value:number}) {
    return (
        <Chip variant="outlined" label={value} icon={<LocalFireDepartmentOutlinedIcon/>}/>
    );
}

export default TemperatureChip;