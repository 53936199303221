import React from 'react';
import {AnyModelJson, isFinetune} from "../../api/json/TemplatedModelJson"
import {Button, Tooltip, Typography} from "@mui/material"
import {Link as RouterLink} from "react-router-dom"
import {ScoringMethod} from "../../api/enum/ScoringMethod"
import {ProjectJson} from "../../api/json/ProjectJson"

function ModelScoreCell({project, model}: { project: ProjectJson, model: AnyModelJson }) {
    if (project.stats.numberOfValidationExamples === 0) {
        return (
            <Tooltip title="Requires validation examples.">
                <Typography variant="body2">N/A</Typography>
            </Tooltip>
        )
    }

    if (isFinetune(model)) {
        if(model.completed === null) {
            return <></>
        }

        if (model.failed) {
            return (
                <Tooltip title="Fine-tune failed.">
                    <Typography variant="body2">N/A</Typography>
                </Tooltip>
            )
        }
    }


    if (model.eval.completed === null) {
        return <Typography variant="body2">In progress</Typography>
    }

    if (model.eval.stats.completedTestRuns > model.eval.stats.scoredTestRuns) {
        return (
            <Button component={RouterLink} to={`/projects/${project?.id}/${isFinetune(model) ? "fine-tunes" : "models"}/${model.id}`}
                    variant="outlined"
                    size="small"
            >
                Review
            </Button>
        )
    }

    return (
        <>
            <Typography>{model.eval.stats ? `${Math.round(model.eval.stats.score * 10) / 10}%` : ""}</Typography><br/>
            {project?.evalSettings.scoringMethod === ScoringMethod.EXACT_MATCH &&
                <Typography variant="caption">
                    {Math.round(model.eval.stats.score / 100 * model.eval.stats.completedTestRuns)} of {model.eval.stats.completedTestRuns}
                </Typography>
            }
        </>
    );
}

export default ModelScoreCell;