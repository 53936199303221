import React, {ReactElement, ReactNode} from 'react';
import {useProjectData} from "../../../hooks/useProjectData";
import {Link, useLocation} from "react-router-dom";
import styles from "./Nav.module.scss";
import {Box, Chip, Stack, SvgIconProps, SxProps} from "@mui/material"
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import {SvgIconComponent} from "@mui/icons-material"

interface SidebarLinkProps {
    to: string
    children: ReactNode
    icon: SvgIconComponent
    chipLabel?: string
}

function SidebarLink({to, icon: Icon, chipLabel, children}: SidebarLinkProps) {
    const {id} = useProjectData()
    const url = `/projects/${id}/${to}`

    const location = useLocation()
    const pathComponents = location.pathname.split("/")
    let currentPage = pathComponents[pathComponents.length - 1]
    if (currentPage === id) {
        currentPage = "overview"
    }

    const iconProps: SvgIconProps = {fontSize: "small", color: "secondary"}
    const chipProps: { size: "small", sx: SxProps } = {
        size: "small",
        sx: {display: {md: "inherit", xs: "none"}},
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Link className={`${styles.navLink} ${currentPage === to ? styles.current : ""}`} to={url}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <>
                        <Icon {...iconProps} />
                        <Box>{children}</Box>
                    </>
                </Stack>
            </Link>
            {chipLabel && <Chip label={chipLabel} {...chipProps}/>}
        </Stack>
    );
}

export default SidebarLink;