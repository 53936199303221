import React, {useState} from 'react';
import {Button, Modal, Stack, Typography} from "@mui/material"
import modalStyles from "../modal/Modal.module.scss"
import AsyncButton from "../button/AsyncButton"
import ApiError from "../../api/ApiError"
import DatasetAllocationInput from "./DatasetAllocationInput"
import {ProjectJson} from "../../api/json/ProjectJson"
import {ExampleRequests} from "../../api/requests/ExampleRequests"
import ErrorMessage from "../error/ErrorMessage"

function DatasetAllocationModal({ project, open, onClose }: { project: ProjectJson, open: boolean, onClose: () => void}) {
    const [validationPercent, setValidationPercent] = useState<number | null>(project.stats.percentValidationExamples ?? 0)
    const [allocateInProgress, setAllocateInProgress] = useState(false)
    const [error, setError] = useState<ApiError>()

    const handleAllocate = () => {
        if (!project || validationPercent === null) return

        setAllocateInProgress(true)
        ExampleRequests.updateAll(project.id, validationPercent)
            .then(() => {
                setError(undefined)
                onClose()
            })
            .catch(setError)
            .finally(() => setAllocateInProgress(false))
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Stack spacing={2} className={modalStyles.default}>
                <Typography variant="h2">Allocate Examples</Typography>
                <Typography>Adjust the percentage of examples reserved for evaluating model performance on unseen data.</Typography>

                <DatasetAllocationInput currentNumberOfTrainingExamples={project.stats.numberOfTrainingExamples}
                                        currentNumberOfValidationExamples={project.stats.numberOfValidationExamples}
                                        validationPercent={validationPercent}
                                        onChange={setValidationPercent}
                                 />

                <Stack direction="row" alignSelf="flex-end" justifyContent="flex-end" spacing={2}>
                    <Button variant="contained" color="info" onClick={onClose}>
                        Cancel
                    </Button>
                    <AsyncButton variant="contained"
                                 waiting={allocateInProgress}
                                 onClick={handleAllocate} sx={{flex: 1}}>
                        Update & close
                    </AsyncButton>
                </Stack>
                {error && <ErrorMessage error={error}/>}
            </Stack>
        </Modal>
    );
}

export default DatasetAllocationModal;