import React, {ReactNode} from 'react';
import {Box, Paper, Stack, SxProps} from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function InfoAlert({children, actionButton, className, sx}: { children: ReactNode, actionButton?: ReactNode, className?: string, sx?: SxProps }) {
    return (
        <Paper className={className} sx={{backgroundColor: "rgba(0,0,0,0.025)", pl: 3, pr: 3, pt: 2, pb: 2, color: "#5d6987", border: "1px solid #d4d4d4", ...sx}}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} alignItems="center">
                    <InfoOutlinedIcon sx={{color: "#8590AB"}}/>
                    <Box>
                        {children}
                    </Box>
                </Stack>
                {actionButton && actionButton}
            </Stack>
        </Paper>
    );
}

export default InfoAlert;