import React, {useEffect} from 'react'
import {useAuth0} from "@auth0/auth0-react"
import {useApi} from "../../hooks/useApi"

function LogoutPage() {
    const {logout} = useAuth0()
    const {clearAll} = useApi()

    useEffect(() => {
        clearAll()
        logout()
    }, [])
    return <>Logging out...</>
}

export default LogoutPage