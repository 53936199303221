import React from 'react'
import {Stack} from "@mui/material"
import styles from "./Nav.module.scss"
import {useProjectData} from "../../../hooks/useProjectData";
import {RoleCheck} from "../../../library/RoleCheck";
import SidebarLink from "./SidebarLink";
import SidebarGroup from "./SidebarGroup"
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined"
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined"
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined"
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined"
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined"
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined"
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined"
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import SidebarImportExportButtons from "./SidebarImportExportButtons"

function ProjectSidebar() {
    const {project} = useProjectData()

    const shortCount = (count: number): string => {
        if (count >= 10000) {
            return Math.floor(count / 1000) + "k"
        } else if (count >= 1000) {
            return Math.floor(count / 100) / 10 + "k"
        }
        return count.toString()
    }

    if (!project)
        return <></>

    return (
        <Stack spacing={3} className={styles.sidebar}>
            <SidebarLink to="overview" icon={SpeedOutlinedIcon}>Overview</SidebarLink>

            <SidebarGroup title="Create" atLeastManager>
                <SidebarLink to="models"
                             icon={RocketLaunchOutlinedIcon}
                             chipLabel={project?.stats.numberOfModels.toString()}>
                    Models
                </SidebarLink>

                <SidebarLink to="templates"
                             icon={TextSnippetOutlinedIcon}
                             chipLabel={project?.templates.length.toLocaleString()}>
                    Templates
                </SidebarLink>
            </SidebarGroup>

            <SidebarGroup title="Test" atLeastManager>
                <SidebarLink to="playground"
                             icon={PlayCircleOutlineOutlinedIcon}>
                    Playground
                </SidebarLink>
            </SidebarGroup>

            <SidebarGroup title="Build">
                <SidebarLink to="examples"
                             icon={ManageSearchIcon}
                             chipLabel={shortCount(project?.stats.totalExamples ?? 0)}>
                    Examples
                </SidebarLink>

                {RoleCheck.atLeastManager() &&
                    <>
                        <SidebarLink to="synthesis"
                                     icon={AutoFixHighOutlinedIcon}>
                            Synthesis
                        </SidebarLink>
                        <SidebarLink to="transforms"
                                     icon={AutoAwesomeOutlinedIcon}>
                            Transforms
                        </SidebarLink>
                        <SidebarLink to="fields"
                                     icon={ViewColumnOutlinedIcon}
                                     chipLabel={project?.fields.length.toString()}>
                            Fields
                        </SidebarLink>
                    </>
                }
            </SidebarGroup>

            <SidebarGroup title="Track" atLeastManager>
                <SidebarLink to="completions"
                             icon={PolylineOutlinedIcon}
                             chipLabel={shortCount(project?.stats.numberOfCompletionRequests ?? 0)}>
                    Completions
                </SidebarLink>
            </SidebarGroup>

            <SidebarGroup title="Setup" atLeastManager>
                <SidebarLink to="settings"
                             icon={SettingsOutlinedIcon}>
                    Settings
                </SidebarLink>
            </SidebarGroup>

            <SidebarImportExportButtons project={project}/>
        </Stack>
    )
}

export default ProjectSidebar