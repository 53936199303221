import React from 'react';
import {Link, Stack, Typography} from "@mui/material"
import {ProjectJson} from "../../../api/json/ProjectJson"
import {Link as RouterLink} from "react-router-dom"
import {CsvColumnIntentJson} from "../../../api/json/CsvColumnIntentJson"

interface CsvImportConfirmationProps {
    project: ProjectJson
    columnIntents: CsvColumnIntentJson[]
}

function CsvImportConfirmationStep({project, columnIntents}: CsvImportConfirmationProps) {
    const newFieldCount = columnIntents.filter(intent => intent.newField !== null).length
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h1" gutterBottom>Import Started</Typography>
            </Stack>
            <Typography paragraph variant="body1">Your import has been added to the processing queue and will be ready soon.</Typography>
            {newFieldCount > 0 ?
                <>
                <Typography paragraph variant="body1"><Typography fontWeight={500}>{newFieldCount} new field(s) will be created.</Typography> You may want to create a new template or edit your existing one(s).</Typography>
                    <Link component={RouterLink} to={`/projects/${project.id}/templates`} fontWeight={500}>View templates</Link>
                    </>
                :
                <Link component={RouterLink} to={`/projects/${project.id}/examples`} fontWeight={500}>View examples</Link>
            }
        </>
    )
}

export default CsvImportConfirmationStep;