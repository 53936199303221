import React from 'react';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import {
    Checkbox,
    IconButton, ListItemButton,
    ListItemIcon,
    ListItemText, ListSubheader,
    Menu,
    MenuList,
    Paper,
    Typography,
} from "@mui/material"
import {FinetuneTableColumn} from "./FinetunesTable"

interface FinetuneColumnMenu {
    columns: FinetuneTableColumn[]
    onChange: (columns: FinetuneTableColumn[]) => void
}

function FinetuneColumnMenu({onChange, columns}: FinetuneColumnMenu) {
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>()

    const allColumns: FinetuneTableColumn[] = ["Score", "Platform", "Model", "Epochs", "Training Examples", "Template", "Created", "Status"]

    return (
        <>
            <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <ViewColumnOutlinedIcon/>
            </IconButton>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(undefined)}
            >
                <Paper variant="outlined">
                    <MenuList dense>
                        <ListSubheader>Visible Columns</ListSubheader>
                        {allColumns.map(column =>
                            <ListItemButton key={column} onChange={() => {
                                let newColumns = [...columns]
                                if (newColumns.includes(column)) {
                                    let index = newColumns.indexOf(column)
                                    newColumns.splice(index, 1)
                                } else {
                                    newColumns.push(column)
                                }
                                onChange(newColumns)
                            }
                            } sx={{pt: 0, pb: 0}}>
                                <ListItemIcon>
                                    <Checkbox checked={columns.includes(column)}/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography>
                                        {column}
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>,
                        )}
                    </MenuList>
                </Paper>
            </Menu>
        </>
    );
}

export default FinetuneColumnMenu;