import React, {useState} from 'react';
import {CompletionRequestJson} from "../../../api/json/CompletionRequestJson"
import {Box, Chip, IconButton, Modal, Stack, Typography} from "@mui/material"
import SpaceBetween from "../../common/SpaceBetween"
import CompletionCardStack from "../../completions/CompletionCardStack"
import modalStyles from "../../modal/Modal.module.scss"
import CodeBox from "../../common/CodeBox"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import {DisplayFormat} from "../../../api/enum/DisplayFormat"

function PlaygroundCompletionRequestCard({request, display}: { request: CompletionRequestJson, display?: DisplayFormat }) {
    const [showDetails, setShowDetails] = useState(false)

    const getStatusColor = (httpCode: number): "success" | "error" | "warning" => {
        if (httpCode >= 200 && httpCode < 300) {
            return "success"
        }
        if (httpCode >= 400) {
            return "error"
        }
        return "warning"
    }
    return (
        <Box>
            <SpaceBetween alignItems="baseline">
                <Typography variant="caption">{request.model.name}</Typography>
                {request.httpRequestDetails &&
                    <IconButton size="small" color="secondary" onClick={() => setShowDetails(true)}>
                        <InfoOutlinedIcon fontSize="small"/>
                    </IconButton>
                }
            </SpaceBetween>

            <CompletionCardStack request={request} display={display} sx={{mt: 1}}/>

            {request.httpRequestDetails &&
                <Modal
                    open={showDetails}
                    onClose={() => setShowDetails(false)}
                    keepMounted={false}
                >
                    <Stack spacing={2} className={modalStyles.xl}>
                        <SpaceBetween>
                            <Typography variant="h2">HTTP Request Details</Typography>
                            <Chip label={request.httpRequestDetails.responseCode}
                                  color={getStatusColor(request.httpRequestDetails.responseCode)}/>
                        </SpaceBetween>
                        <Box>
                            <Typography variant="subtitle1">Endpoint</Typography>
                            <CodeBox>{request.httpRequestDetails.endpoint}</CodeBox>
                            <Typography variant="subtitle1" sx={{mt: 2}}>Request</Typography>
                            <CodeBox>{request.httpRequestDetails.requestBody}</CodeBox>
                            <Typography variant="subtitle1" sx={{mt: 2}}>Response</Typography>
                            <CodeBox>{request.httpRequestDetails.responseBody}</CodeBox>
                        </Box>
                    </Stack>
                </Modal>
            }
        </Box>
    );
}

export default PlaygroundCompletionRequestCard;