export enum FieldType {
    TEXT = "TEXT",
    URL = "URL",
    NUMBER = "NUMBER",
    PREDEFINED_OPTIONS = "PREDEFINED_OPTIONS",
}

export const allFieldTypes = [FieldType.TEXT, FieldType.NUMBER, FieldType.PREDEFINED_OPTIONS]

export const resolveNameForFieldType = (type: FieldType): string => {
    switch (type) {
        case FieldType.TEXT:
            return "Text"
        case FieldType.URL:
            return "URL"
        case FieldType.NUMBER:
            return "Number"
        case FieldType.PREDEFINED_OPTIONS:
            return "Predefined Options"
    }
}