import React, {useState} from 'react';
import TransformJson from "../../api/json/TransformJson"
import {
    Box,
    Button, Fab, Grid, Paper,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography,
} from "@mui/material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import NewTransformJobModal from "./NewTransformJobModal"
import {ProjectJson} from "../../api/json/ProjectJson"
import AsyncButton from "../button/AsyncButton"
import TransformJobCard from "./TransformJobCard"
import TransformJobJson from "../../api/json/TransformJobJson"
import SpaceBetween from "../common/SpaceBetween"
import AddIcon from "@mui/icons-material/Add"

interface TransformJobSectionProps {
    project: ProjectJson
    transform: TransformJson
    unsavedChanges?: boolean
    sx?: SxProps
}

function TransformJobSection({project, transform, unsavedChanges, sx}: TransformJobSectionProps) {
    const [transformJobs, setTransformJobs] = useState<TransformJobJson[]>(transform.transformJobs)
    const [showStartJobModal, setShowStartJobModal] = useState(false)

    const activeJob = transformJobs.find(it => it.active) != null
    return (
        <>
            <Stack spacing={2} sx={sx}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h2">Jobs</Typography>
                    <Tooltip title={activeJob ? "Only one job may run at a time." : null}>
                        <Box alignSelf="flex-start">
                            <Fab color="primary"
                                 size="small"
                                 disabled={unsavedChanges || activeJob}
                                 onClick={() => setShowStartJobModal(true)}
                            >
                                <AddIcon/>
                            </Fab>
                        </Box>
                    </Tooltip>
                </Stack>

                <Box>
                {transformJobs.length > 0 &&
                    <Grid container spacing={2}>
                        {transformJobs.map(job => (
                            <Grid item sm={12} md={6} lg={4} key={job.id} sx={{overflow: "visible"}}>
                                <TransformJobCard
                                    project={project}
                                    job={job}
                                    didCancel={canceled => {
                                        let updated = [...transformJobs]
                                        let index = transformJobs.findIndex(it => it.id === canceled.id)
                                        updated[index] = canceled
                                        setTransformJobs(updated)
                                    }}
                                    didReload={newValue => {
                                        let updated = [...transformJobs]
                                        let index = updated.findIndex(job => job.id === newValue.id)
                                        if (index !== -1) {
                                            updated[index] = newValue
                                            setTransformJobs(updated)
                                        }
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                }
                </Box>
            </Stack>

            <NewTransformJobModal project={project}
                                  transform={transform}
                                  open={showStartJobModal}
                                  setOpen={setShowStartJobModal}
                                  didStart={newJob => {
                                        let updated = [newJob, ...transformJobs]
                                        setTransformJobs(updated)
                                    }
                                    }
            />
        </>
    );
}

export default TransformJobSection;