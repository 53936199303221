import {MembershipRole} from "../api/enum/MembershipRole";
import API from "../api/API"

export class RoleCheck {
    static atLeastManager() {
        const organization = API.getCurrentOrganization()
        return organization?.role === MembershipRole.ADMIN || organization?.role === MembershipRole.MANAGER
    }

    static isAdmin() {
        const organization = API.getCurrentOrganization()
        return organization?.role === MembershipRole.ADMIN
    }
}
