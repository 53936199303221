import React, {ReactNode} from 'react';
import {Box, Stack, SxProps, Theme, Typography} from "@mui/material";

interface PageHeaderProps {
    pageTitle: string
    nextToTitle?: ReactNode
    overline?: string
    children?: string | ReactNode
    fab?: ReactNode
    sx?: SxProps<Theme>
}

function PageHeader({pageTitle, nextToTitle, overline, children, fab, sx}: PageHeaderProps) {
    return (
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{pb: 3, ...sx}}>
            <Stack spacing={1} sx={{ mt: 1 }}>
                <Box>
                    {overline &&
                        <Typography variant="overline">{overline}</Typography>
                    }
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="h1">{pageTitle}</Typography>
                        {nextToTitle && nextToTitle}
                    </Stack>
                </Box>
                {children && (
                    <Box sx={{pl: 0.3}}>
                        {children}
                    </Box>)
                }
            </Stack>
            {fab && fab}
        </Stack>
    )
}


export default PageHeader;