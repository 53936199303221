import React from 'react'
import {Box, Button, Divider, Link, Paper, Popover, Stack, Typography} from "@mui/material"
import styles from "./Nav.module.scss"
import BrandMark from "./BrandMark"
import {Link as RouterLink, useLocation} from "react-router-dom";
import {RoleCheck} from "../../../library/RoleCheck";
import {useAuth0} from "@auth0/auth0-react"
import {useApi} from "../../../hooks/useApi"
import CheckoutButton from "../../billing/CheckoutButton"
import Logo from "../../accounts/Logo"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import {SystemRole} from "../../../api/enum/SystemRole"
import QuickProjectSelect from "./QuickProjectSelect"

function NavBar() {
    const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLDivElement>()

    const location = useLocation()
    const pathComponents = location.pathname.split("/")
    let currentPage = pathComponents[pathComponents.length - 1]

    const {currentUser, currentOrganization} = useApi()

    const { logout } = useAuth0()
    const { clearAll } = useApi()

    const handleLogout = () => {
        clearAll()
        logout()
    }

    return (
        <Stack component="nav" direction="row" justifyContent="space-between" className={styles.navBar}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <Box className={styles.logoIcon}>
                    <Link component={RouterLink} to="/">
                        <BrandMark/>
                    </Link>
                </Box>
                <QuickProjectSelect/>
                <Link component={RouterLink}
                      className={`${styles.navLink} ${currentPage === "" ? styles.current : ""}`}
                      to="/">
                    Projects
                </Link>
                {RoleCheck.isAdmin() &&
                    <>
                        <Link component={RouterLink}
                              className={`${styles.navLink} ${currentPage === "integrations" ? styles.current : ""}`}
                              to="/integrations">Integrations</Link>
                        <Link component={RouterLink}
                              className={`${styles.navLink} ${currentPage === "members" ? styles.current : ""}`}
                              to="/organization/members">Members</Link>
                        {currentUser?.systemRole === SystemRole.ADMIN &&
                            <Link component={RouterLink}
                                  className={`${styles.navLink} ${location.pathname.includes("/admin") ? styles.current : ""}`}
                                  to="admin">
                                Admin
                            </Link>
                        }
                    </>
                }
            </Stack>

            <Stack direction="row" spacing={2}>
                {!currentOrganization?.billingDetails?.activePlan &&
                    <CheckoutButton>Upgrade</CheckoutButton>
                }
                <Button startIcon={<OpenInNewOutlinedIcon/>}
                        href="https://docs.entrypointai.com/"
                        target="_blank"
                        rel="noopener"
                        sx={{ display: {lg: "flex", xs: "none"}}}
                >
                    Docs
                </Button>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    onClick={(event) => setPopoverAnchor(event.currentTarget)}
                    sx={{cursor: "pointer"}}
                >
                    <Logo image={currentOrganization?.logo} />
                    <Typography>{currentOrganization?.name}</Typography>
                    <ArrowDropDownIcon color="secondary" />
                </Stack>
            </Stack>

            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={() => setPopoverAnchor(undefined)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper variant="outlined" sx={{p: 4}}>
                    <Stack spacing={2}>
                        <Stack>
                            <Typography gutterBottom variant="h4">Organization</Typography>
                            {RoleCheck.isAdmin() && <>
                                <Typography variant="subtitle1">
                                    <Link component={RouterLink} to="/organization">Settings</Link>
                                </Typography>
                            </>}
                            <Typography variant="subtitle1" sx={{mt:0.5}}>
                                <Link component={RouterLink} to="/organizations/select">Switch</Link>
                            </Typography>
                        </Stack>
                        <Divider/>
                        <Stack spacing={1}>
                            <Typography variant="h4">Account</Typography>
                            <Stack>
                                <Typography variant="body1">{currentUser?.fullName}</Typography>
                                <Typography variant="body1">{currentUser?.email}</Typography>
                            </Stack>
                            <Typography variant="subtitle1">
                                <Link component={RouterLink} to="/user">My Account</Link>
                            </Typography>
                        </Stack>

                        <Button variant="outlined" onClick={() => handleLogout()}>Logout</Button>
                    </Stack>
                </Paper>
            </Popover>
        </Stack>
    )
}

export default NavBar