import React, {useEffect, useState} from 'react';
import {CircularProgress} from "@mui/material";
import {AnyFieldJson} from "../../api/json/FieldJson";
import {useProjectData} from "../../hooks/useProjectData";
import ProjectPageHeader from "../layouts/ProjectPageHeader";
import FieldsTable from "./FieldsTable";
import {FieldRequests} from "../../api/requests/FieldRequests"
import ApiError from "../../api/ApiError"
import FieldsPageActionsMenu from "./FieldsPageActionsMenu"
import ErrorMessage from "../error/ErrorMessage"
import FieldModal from "./FieldModal"

function FieldsPage() {
    const {id, project, reloadProject} = useProjectData()
    const [fields, setFields] = useState<AnyFieldJson[]>(project?.fields ?? [])
    const [error, setError] = useState<ApiError>()

    const [modalOpen, setModalOpen] = useState(false)
    const [editingField, setEditingField] = useState<AnyFieldJson>()

    useEffect(() => loadFields(), [])
    const loadFields = () => {
        FieldRequests.list(id)
            .then(setFields)
            .catch(setError)
    }

    const handleEdit = (field: AnyFieldJson) => {
        setEditingField(field)
        setModalOpen(true)
        reloadProject()
        loadFields()
    }

    const handleClose = () => {
        setEditingField(undefined)
        setModalOpen(false)
        reloadProject()
        loadFields()
    }

    if (!fields || !project) {
        return <CircularProgress/>
    }

    const actionsMenu = (
        <FieldsPageActionsMenu project={project} fields={fields} reload={() => {
            reloadProject()
            loadFields()
        }}/>
    )

    if (error)
        return <ErrorMessage error={error}/>

    return (
        <>
            <ProjectPageHeader
                showFab
                pageTitle="Fields"
                nextToTitle={actionsMenu}
                onFabClick={() => {
                    setEditingField(undefined)
                    setModalOpen(true)
                }}
            >
                {project.fields.length === 0 && "Define the types of data your models will use."}
            </ProjectPageHeader>

            {project.fields.length > 0 &&
                <FieldsTable project={project} fields={fields} handleEdit={handleEdit} />
            }

            <FieldModal project={project} field={editingField} open={modalOpen} close={() => handleClose()} />
        </>
    )
}

export default FieldsPage;