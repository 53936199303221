import RequestBuilder from "../RequestBuilder"
import {Method} from "../enum/Method"
import {ModelPlatform} from "../enum/ModelPlatform"

export interface PublicStatsJson {
    finetuneTimes: {
        platform: ModelPlatform
        minutes: number
    }[]
}

export class PublicStatsRequests {
    static getStats(): Promise<PublicStatsJson> {
        return new RequestBuilder(Method.Get, `stats`).buildAndSend()
    }
}