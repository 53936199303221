import React, {useEffect, useState} from 'react';
import {TestRunJson} from "../../api/json/TestRunJson"
import ApiError from "../../api/ApiError"
import {AnyModelJson} from "../../api/json/TemplatedModelJson"
import {PagedResponseJson} from "../../api/json/PagedResponseJson"
import ScrollPaginator from "../misc/ScrollPaginator"
import {TestRunRequests} from "../../api/requests/TestRunRequests"
import {Stack} from "@mui/material"
import animations from "../../Animations.module.scss"
import TestRunCard from "./TestRunCard"

interface TestRunsLoaderProps {
    model: AnyModelJson
    reloadEvalStats: () => void
}

function TestRunList({model, reloadEvalStats}: TestRunsLoaderProps) {
    const [pages, setPages] = useState<PagedResponseJson<TestRunJson>[]>([])
    const [error, setError] = useState<ApiError>()

    let testRuns = pages.flatMap(it => it.content)

    useEffect(() => {
        setPages([])
    }, [])

    const loadPage = (pageNumber: number) => {
        TestRunRequests.list(model.eval.id, pageNumber, false)
            .then(response => setPages([...pages, response]))
            .catch(setError)
    }

    return (
        <ScrollPaginator pages={pages}
                         loadNextPage={loadPage}
                         error={error}
        >
            <Stack spacing={3} sx={{mt: 2}} className={animations.defaultIn}>
                {testRuns.map(testRun =>
                    <TestRunCard
                        key={testRun.example.id}
                        evalJson={model.eval}
                        template={model.template}
                        testRun={testRun}
                        didUpdate={(updated) => {
                            const newPages = pages.map(page => ({
                                ...page,
                                content: page.content.map(existing =>
                                    existing.example.id === updated.example.id ? {...updated} : existing,
                                ),
                            }))
                            setPages(newPages)
                        }}
                        didDelete={exampleId => {
                            const newPages = pages.map(page => ({
                                ...page,
                                content: page.content.filter(existing =>
                                    existing.example.id !== exampleId
                                ),
                            }))
                            setPages(newPages)
                            reloadEvalStats()
                        }}
                        limitMaxHeight
                    />,
                )}
            </Stack>
        </ScrollPaginator>
    )
}

export default TestRunList;