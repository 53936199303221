import React, {createContext, useCallback, useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {ProjectRequests} from "../api/requests/ProjectRequests"
import {ProjectJson} from "../api/json/ProjectJson"
import API from "../api/API"
import {useApi} from "./useApi"
import ApiError from "../api/ApiError"

export interface ProjectProviderState {
    id: string
    project?: ProjectJson
    error?: ApiError
}

export interface ProjectProviderActions {
    reloadProject: () => void
    setProject: (project: ProjectJson) => void
}

const Context = createContext<ProjectProviderState & ProjectProviderActions>({
    id: "",
    reloadProject: () => {
    },
    setProject: () => {
    }
})

export const useProjectData = (): ProjectProviderState & ProjectProviderActions => {
    const context = useContext(Context)
    if (context === null) {
        throw new Error('useProjectData must be used within a ProjectProvider tag')
    }
    return context
}

export const ProjectProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
    const {projectId} = useParams()
    if (!projectId) throw new Error("Missing project \"id\" from page path")

    const [state, setState] = useState<ProjectProviderState>({id: projectId as string})

    const {currentUser, currentOrganization, token} = useApi()
    const reloadProject = () => fetchProject()

    const fetchProject = useCallback(() => {
        if (!currentUser || !currentOrganization || !token) return

        ProjectRequests.get(projectId)
            .then(response => setState({id: projectId, project: response}))
            .catch(error => setState({id: projectId, error: error}))
    }, [projectId, currentUser, currentOrganization, token])

    useEffect(() => fetchProject(), [projectId, fetchProject, token, currentOrganization, token])

    // Watch for server-sent events that indicate the project needs updating
    useEffect(() => {
        const eventSource = new EventSource(`${API.getBaseUrl()}/api/sse/projects/${projectId}`);

        const handleMessage = (message: MessageEvent<any>) => {
            // For some reason calling it instantly was too fast and not getting the updated project
            setTimeout(() => fetchProject(), 1000)
        }

        eventSource.addEventListener("message", handleMessage)
        return () => {
            eventSource.removeEventListener("message", handleMessage)
            eventSource.close()
        }
    }, [projectId])

    return (
        <Context.Provider value={{
            ...state,
            reloadProject: reloadProject,
            setProject: (project) => setState({id: project.id, project: project, error: undefined})
        }
        }>
            {props.children}
        </Context.Provider>
    )
}
