import RequestBuilder from "../RequestBuilder";
import {Method} from "../enum/Method";
import {AnyModelSpecJson} from "../json/ModelSpecJson"

export class ModelSpecRequests {
    static list(): Promise<AnyModelSpecJson[]> {
        return new RequestBuilder(Method.Get, `model-specs`)
            .withoutOrganization()
            .buildAndSend()
    }
}