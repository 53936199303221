import React from 'react';
import {Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import styles from "./IntegrationCard.module.scss"
import {IntegrationDetails} from "./IntegrationDetails"
import {resolveNameForModelPlatform} from "../../api/enum/ModelPlatform"

function IntegrationCard({platform, subheading, className, path, iconSrc}: IntegrationDetails) {
    const navigate = useNavigate()

    return (
        <div className={`${className} ${styles.card}`} onClick={() => navigate(`./${path}`)}>
            <Box className={styles.inside}>
                {iconSrc && <img src={iconSrc} />}
                <Typography variant="h2">
                    {resolveNameForModelPlatform(platform)}
                </Typography>
                <Typography variant="body1">
                    {subheading}
                </Typography>
            </Box>
        </div>
    );
}

export default IntegrationCard;