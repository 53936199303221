import React from 'react';
import {Box, Drawer, DrawerProps} from "@mui/material"

function RightDrawer({children, ...props}: DrawerProps) {
    return (
        <Drawer
            anchor="right"
            {...props}
        >
            <Box width={520} sx={{p: 4}}>
                {children}
            </Box>
        </Drawer>
    );
}

export default RightDrawer;