import React from 'react';
import TransformJson from "../../api/json/TransformJson"
import {Grid} from "@mui/material"
import TransformCard from "./TransformCard/TransformCard"
import {useNavigate} from "react-router-dom"
import {useProjectData} from "../../hooks/useProjectData"

interface PromptsFeedProps {
    transforms: TransformJson[]
}

function TransformsFeed({transforms}: PromptsFeedProps) {
    const {id} = useProjectData()
    const navigate = useNavigate()

    return (
        <Grid spacing={2} container>
            {transforms.map(prompt =>
                <Grid item sm={12} md={6} lg={4} key={prompt.id} sx={{overflow: "visible"}}>
                    <TransformCard transform={prompt} onClick={() => navigate(`/projects/${id}/transforms/${prompt.id}`)}/>
                </Grid>,
            )}
        </Grid>
    );
}

export default TransformsFeed;