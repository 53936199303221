import React, {useState} from 'react';
import {Button, Grid, Stack, Typography} from "@mui/material"
import AsyncButton from "../../button/AsyncButton"
import {ImportJobRequests} from "../../../api/requests/ImportJobRequests"
import ApiError from "../../../api/ApiError"
import {ProjectJson} from "../../../api/json/ProjectJson"
import {CsvColumnIntentJson} from "../../../api/json/CsvColumnIntentJson"
import {calculateDefaultValidationPercent} from "../../../library/TrainingToValidationRatioHelper"
import InfoAlert from "../../alert/InfoAlert"
import DatasetAllocationInput from "../../examples/DatasetAllocationInput"
import ErrorMessage from "../../error/ErrorMessage"

interface CsvImportUploadStepProps {
    jobId: string
    columnIntents: CsvColumnIntentJson[]
    totalRows: number
    project: ProjectJson
    handleBack: () => void
    didFinalize: () => void
}

function CsvImportFinalizeStep({jobId, columnIntents, totalRows, project, handleBack, didFinalize}: CsvImportUploadStepProps) {
    const [finalizing, setFinalizing] = useState<boolean>(false)
    const [finalizeError, setFinalizeError] = useState<ApiError>()

    const defaultPercentValidation = project.stats.percentValidationExamples ?? calculateDefaultValidationPercent(totalRows)
    const [validationPercent, setValidationPercent] = useState<number>(defaultPercentValidation)

    const handleFinalizeJob = () => {
        if (!jobId) return
        setFinalizing(true)

        ImportJobRequests.finalize(project.id, jobId, columnIntents, validationPercent)
            .then(() => didFinalize())
            .catch(setFinalizeError)
            .finally(() => setFinalizing(false))
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h1" gutterBottom>Validation</Typography>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Button onClick={handleBack}>Back</Button>
                    <AsyncButton waiting={finalizing} variant="contained" onClick={handleFinalizeJob}>Finish</AsyncButton>
                </Stack>
            </Stack>

            <Typography paragraph variant="body1">
                Allocate a percentage of examples to validate your model's performance. This adjusts the entire dataset after import.
            </Typography>

            <Grid container spacing={3} sx={{mt: 0}} alignItems="center">
                <Grid item lg={3}>
                    <DatasetAllocationInput currentNumberOfTrainingExamples={project.stats.numberOfTrainingExamples + totalRows}
                                            currentNumberOfValidationExamples={project.stats.numberOfValidationExamples}
                                            validationPercent={validationPercent}
                                            onChange={setValidationPercent} />
                </Grid>
                <Grid item lg={9}>
                    <InfoAlert>Reserving a percentage of your examples for validation allows you to evaluate the performance of your model. If you want to use all your examples for training, set the value to 0.</InfoAlert>
                </Grid>
            </Grid>

            {finalizeError &&
                <ErrorMessage error={finalizeError} sx={{mt:2}}/>
            }
        </>
    )
}

export default CsvImportFinalizeStep;